import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CloudFileUploadIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 120 93">
      <path d="M65.8662 54.9745C65.173 54.1909 64.177 53.7422 63.1307 53.7422C62.0845 53.7422 61.0885 54.1909 60.3953 54.9745L48.3953 68.5397C47.0588 70.0505 47.2001 72.3586 48.7109 73.695C50.2217 75.0315 52.5298 74.8902 53.8662 73.3794L60 66.4455V89.7426C60 91.4715 61.4015 92.873 63.1304 92.873C64.8593 92.873 66.2609 91.4715 66.2609 89.7426V66.4449L72.3953 73.3794C73.7317 74.8902 76.0398 75.0315 77.5506 73.695C79.0613 72.3586 79.2026 70.0505 77.8662 68.5397L65.8662 54.9745Z" fill="black"/>
      <path d="M93.6455 23.475C87.947 9.97082 73.7756 0 59.2907 0C39.5246 0 22.3657 17.2539 22.0451 37.1878C9.61544 38.9193 0 49.9862 0 63.3429C0 77.8969 12.5796 89.7382 26.7605 89.7382H35.6252C36.5521 89.7389 37.4412 89.3686 38.0967 88.7087C38.7521 88.0489 39.12 87.1537 39.1193 86.2205C39.12 85.2873 38.7521 84.3922 38.0967 83.7323C37.4412 83.0724 36.5521 82.7021 35.6252 82.7028H26.7605C16.4321 82.7028 8.03167 74.0194 8.03167 63.3429C8.03167 52.4912 16.1391 43.9895 26.4915 43.9895C28.374 43.9886 29.9174 42.4865 29.9819 40.5924L30.0704 37.9195C30.0704 21.4653 43.179 8.07889 59.2907 8.07889C71.5435 8.07889 82.6025 16.0278 86.8086 27.8617C87.2623 29.1394 88.4054 30.0405 89.7463 30.1774C102.415 31.4656 111.968 42.7329 111.968 56.3808C111.968 70.893 100.941 82.7037 87.3881 82.7037H83.73C82.8031 82.703 81.9139 83.0734 81.2585 83.7332C80.6031 84.3931 80.2352 85.2882 80.2359 86.2214C80.2352 87.1546 80.6031 88.0498 81.2585 88.7096C81.9139 89.3695 82.8031 89.7399 83.73 89.7391H87.3881C104.796 89.7391 120 74.7752 120 56.3808C120 39.8773 108.815 26.1126 93.6455 23.475Z" fill="#99DBFF"/>
    </SvgIcon>
  );
}
