import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CloseIcon from '@mui/icons-material/Close';

const PREFIX = 'CreativePreview';

const classes = {
  divider: `${PREFIX}-divider`,
  header: `${PREFIX}-header`,
  icon: `${PREFIX}-icon`,
  player: `${PREFIX}-player`,
  preview: `${PREFIX}-preview`,
  previewWrap: `${PREFIX}-previewWrap`,
  title: `${PREFIX}-title`
};

const StyledBox = styled(Box)(({
  theme: { spacing }
}) => ({
  [`& .${classes.divider}`]: {
    borderColor: '#fff',
  },

  [`& .${classes.header}`]: {
    marginTop: spacing(5),
    marginBottom: spacing(2),
  },

  [`& .${classes.icon}`]: {
    color: '#70767f',
  },

  [`& .${classes.player}`]: {
    maxWidth: 870,
    margin: '0 auto',
  },

  [`&.${classes.preview}`]: {
    backgroundColor: 'rgba(38, 47, 60, 0.95)',
    position: 'absolute',
    top: -3,
    bottom: 0,
    left: -16,
    right: 0,
    zIndex: 1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  [`& .${classes.previewWrap}`]: {
    height: '100%',
    width: '90%',
  },

  [`& .${classes.title}`]: {
    color: '#fff',
  }
}));

const CreativePreview = ({ handleClose, name, url }) => (
  <StyledBox className={classes.preview}>
    <Box className={classes.previewWrap}>
      <Grid container spacing={3}>
        <Grid
          container
          item
          className={classes.header}
          alignItems="center"
          justifyContent="space-between"
          xs={12}
        >
          <Grid container item alignItems="center" xs>
            <Grid item>
              <IconButton onClick={handleClose} size="large">
                <ArrowBackIosIcon className={classes.icon} />
              </IconButton>
            </Grid>

            <Grid item alignItems="center">
              <Typography className={classes.title} variant="h3">
                Preview Creative: {name}
              </Typography>
            </Grid>
          </Grid>

          <Grid item alignContent="center">
            <IconButton onClick={handleClose} size="large">
              <CloseIcon className={classes.icon} />
            </IconButton>
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Grid className={classes.player} item xs={12}>
            <ReactPlayer
              playing
              controls
              url={url}
              width="100%"
              height="auto"
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  </StyledBox>
);

CreativePreview.propTypes = {
  handleClose: PropTypes.func,
  name: PropTypes.string,
  url: PropTypes.string,
};

export default CreativePreview;
