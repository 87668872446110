import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlayCircle,
  faRemove,
  faPercent,
} from '@fortawesome/pro-solid-svg-icons';
import { formatBytes } from './utils';

// Styled components
const StyledPaper = styled(Paper)({
  padding: 16,
  marginBottom: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  border: 'none',
});

const StyledAvatar = styled(Avatar)({
  width: 274,
  height: 154,
  backgroundColor: '#E7E7E7',
  color: 'transparent',
  borderRadius: 0,
});

const FileInfoStack = styled(Stack)({
  width: '100%',
  marginLeft: 16,
  marginRight: 8,
  marginBottom: 8,
});

const FileUploadPreview = ({
  index,
  filesLength,
  file,
  onDelete,
  progress,
  weightedRotation,
}) => {
  const isComplete = parseInt(progress, 10) === 100; // Determine if upload is complete

  // State to hold the value of the input
  const [inputValue, setInputValue] = useState('0');

  useEffect(() => {
    if (weightedRotation) {
      if (typeof filesLength === 'number' && filesLength > 0) {
        setInputValue((100 / filesLength).toFixed(0));
      } else {
        setInputValue('--');
      }
    } else {
      setInputValue('--');
    }
  }, [weightedRotation, filesLength]);

  const isImage = file?.type?.startsWith('image/');
  const isVideo = file?.type?.startsWith('video/');
  const imageSrc = isImage || isVideo ? URL.createObjectURL(file) : undefined;

  return (
    <StyledPaper id={`file-preview-${index}`}>
      <StyledAvatar src={imageSrc} />
      <FileInfoStack direction="column">
        {!isComplete && (
          <>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="body2">
                <Box component="span" sx={{ ml: 1, color: 'grey.7' }}>
                  <FontAwesomeIcon icon={faPlayCircle} size="1x" />{' '}
                  {file.name}
                </Box>
              </Typography>
              <Typography variant="caption" color={'grey.7'}>
                {`${formatBytes(file.size)} ${isVideo ? `• ${file.duration}s • 1080P` : ''}`}
                <IconButton onClick={() => onDelete(index)} size="small">
                  <FontAwesomeIcon icon={faRemove} />
                </IconButton>
              </Typography>
            </Stack>
            <LinearProgress
              variant="determinate"
              value={progress}
              sx={{ backgroundColor: 'grey.11', width: '100%' }}
            />
          </>
        )}
        {isComplete && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <TextField
              label="Creative Title"
              variant="outlined"
              defaultValue={file.path}
              size="small"
              sx={{ flex: 3 }}
            />
            <FormControl size="small" sx={{ flex: 2 }}>
              <InputLabel htmlFor={`language-select-${index}`}>
                Language
              </InputLabel>
              <Select
                id={`language-select-${index}`}
                defaultValue="English"
                input={<OutlinedInput label="Language" />}
              >
                <MenuItem value="English">English</MenuItem>
              </Select>
            </FormControl>
            <TextField
              label="Rotation"
              variant="outlined"
              size="small"
              value={inputValue}
              disabled={!weightedRotation}
              onChange={e => setInputValue(e.target.value)}
              sx={{ flex: 1 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <FontAwesomeIcon icon={faPercent} />
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
        )}
      </FileInfoStack>
    </StyledPaper>
  );
};

FileUploadPreview.propTypes = {
  index: PropTypes.number.isRequired,
  filesLength: PropTypes.number.isRequired,
  file: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  progress: PropTypes.number.isRequired,
  weightedRotation: PropTypes.bool.isRequired,
};

export default FileUploadPreview;
