import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useAuth } from '../components/hooks';
import { useAPI } from '../components/hooks/api';

const UserContext = React.createContext(null);

UserContext.displayName = 'UserContext';

export const UserContextProvider = ({ children }) => {
  const { authState } = useAuth();
  const { useGet } = useAPI();
  const [user, setUser] = useState(null);

  const getUser = async () => {
    try {
      const user = await useGet('/users/me/');

      setUser(user);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (authState.isAuthenticated) {
      getUser();
    }
  }, [authState?.isAuthenticated]);

  return (
    <UserContext.Provider value={{ user, getUser }}>{children}</UserContext.Provider>
  );
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContext;
