import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Link, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import {
  Box,
  Chip,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';

import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import { formatToUSD } from '../util';
import { useAPI } from '../hooks/api';

const PREFIX = 'DisplayIndexPage';

const classes = {
  head: `${PREFIX}-head`,
  body: `${PREFIX}-body`,
  link: `${PREFIX}-link`,
  noWrap: `${PREFIX}-noWrap`,
  height: `${PREFIX}-height`,
  scheduled: `${PREFIX}-scheduled`,
  menuIcon: `${PREFIX}-menuIcon`,
  name: `${PREFIX}-name`,
  stats: `${PREFIX}-stats`,
  tableCell: `${PREFIX}-tableCell`,
  tableHeader: `${PREFIX}-tableHeader`
};

const StyledAppHeader = styled(AppHeader)(({
  theme: { typography }
}) => ({
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '700',
  },

  [`& .${classes.noWrap}`]: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    height: 81,
  },

  [`& .${classes.height}`]: {
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.scheduled}`]: {
    color: '#1dafff',
    background: 'rgba(29, 175, 255, 0.24)',
  },

  [`& .${classes.menuIcon}`]: {
    marginRight: 12,
  },

  [`& .${classes.name}`]: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: 200,
  },

  [`& .${classes.stats}`]: {
    fontWeight: '700',
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.tableCell}`]: {
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.tableHeader}`]: {
    ...typography.h5,
    color: 'rgba(114, 115, 131, 0.8)',
    fontSize: '0.75rem',
    minHeight: 81,
    height: 81,
  }
}));

const StyledTableCell = TableCell;

const headCells = [
  { id: 'creativeName', label: 'Creative', disablePadding: false },
  { id: 'adGroupName', label: 'Ad Group', disablePadding: false },
  { id: 'startDate', label: 'Start date', disablePadding: false },
  { id: 'endDate', label: 'End date', disablePadding: false },
  { id: 'spend', label: 'Spend', disablePadding: false },
  // { id: 'visits', label: 'Visits', disablePadding: false },
  { id: 'costPerVisit', label: 'Cost per visit', disablePadding: false },
  { id: 'status', label: 'Status', disablePadding: false },
];

function CreativeTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        <StyledTableCell
          padding="checkbox"
          classes={{
            head: classes.head,
            body: classes.body
          }}>
          <Checkbox
            size="small"
            onChange={props.onSelectAllClick}
            inputProps={{ 'aria-label': 'select all creatives' }}
          />
        </StyledTableCell>

        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            classes={{
              head: classes.head,
              body: classes.body
            }}>
            {headCell.label}
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

CreativeTableHead.propTypes = {
  onSelectAllClick: PropTypes.func.isRequired,
};

const DisplayIndexPage = props => {

  const adContext = useContext(AdvertiserContext);
  const { adGroupId, campaignId } = useParams();
  const { useGet } = useAPI();

  const [creatives, setCreatives] = useState([]);
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (adContext.id !== null) {
      getDisplays();
    }
  }, [adContext]);

  function getDisplays() {
    return useGet(`/static_display_lineitems/${adGroupId}`)
      .then(res => {
        console.log('response from  static_display', res);

        if (res && res.creatives) {
          const creativeRequests = res.creatives.map(c => getCreative(c));

          return Promise.all(creativeRequests)
            .then(displayCreatives => {
              console.log(
                'Response from creative requests',
                displayCreatives
              );
              setCreatives(displayCreatives);
              return displayCreatives;
            })
            .catch(error => {
              console.log('Error getting creatives', error);
            });
        }
        return res;
      })
      .catch(err => console.error(err));
  }

  function getCreative(url) {
    return useGet(url)
      .then(res => res)
      .catch(err => {
        console.error(err);
      });
  }

  const handleSelect = id => {
    if (!selected.includes(id)) {
      setSelected(prev => [...prev, id]);
    } else {
      setSelected(prev => prev.filter(p => p !== id));
    }
  };

  const handleSelectAll = event => {
    if (event.target.checked) {
      setSelected(creatives.map(n => n.id));
      return;
    }

    setSelected([]);
  };

  const isSelected = id => selected.includes(id);

  const creativeStatusIconRender = (status) => {
    if (status === true) {
      return <Chip label="Active" color="primary" />;
    }

    return <Chip label="Inactive" />;
  };

  const formatEndDate = endDate => {
    if (Date.parse(endDate)) {
      return moment(endDate).format('l');
    }
    return 'No End Date';
  };

  return (
    <StyledAppHeader history={props.history}>
      <Box m={5} mb={2}>
        <Typography>
          <strong>
            <Link className={classes.link} to={`/campaigns/`}>
              Campaigns
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          <strong>
            <Link
              className={classes.link}
              to={`/campaigns/${campaignId}/adgroups/${adGroupId}`}>
              Ad Groups
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          <span>Display Creative</span>
        </Typography>
      </Box>

      <Box
        border={1}
        borderColor="grey.300"
        p={6}
        pt={4}
        m={4}
        borderRadius="20px">
        <Toolbar disableGutters>
          <Typography variant="h6" component="div">
            Display Creative
          </Typography>
        </Toolbar>

        <TableContainer>
          <Table>
            <CreativeTableHead onSelectAllClick={handleSelectAll} />

            <TableBody>
              {creatives.map((creative, index) => {
                const isItemSelected = isSelected(creative.id);
                const labelId = `creative-table-checkbox-${index}`;
                const { id, staticdisplaylineitem_set: stats } = creative;
                const url = `/campaigns/${campaignId}/adgroups/${adGroupId}/displays/${creative.id}`;

                console.log('creativecreativecreative', creative);

                return (
                  <TableRow
                    hover
                    onClick={() => handleSelect(id)}
                    role="checkbox"
                    tabIndex={-1}
                    key={`${creative.id}-${index}`}
                    aria-checked={isItemSelected}
                    selected={isItemSelected}
                    data-testid="creative-table-row"
                  >
                    <TableCell className={classes.height} padding="checkbox">
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>

                    <TableCell
                      className={classes.tableCell}
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                    >
                      <Link className={classes.link} to={url}>
                        {creative.name}
                      </Link>
                    </TableCell>

                    <TableCell
                      className={clsx(classes.tableCell, classes.noWrap)}
                      align="left">
                      {stats[0].name}
                    </TableCell>

                    <TableCell className={classes.tableCell} align="left">
                      {moment(stats[0].start_date).format('l')}
                    </TableCell>

                    <TableCell className={classes.tableCell} align="left">
                      {formatEndDate(stats[0].end_date)}
                    </TableCell>

                    <TableCell className={classes.stats} align="left">
                      {stats != null
                        ? formatToUSD(stats[0].total_spend)
                        : '-'}
                    </TableCell>

                    {/* <TableCell
                      className={classes.stats}
                      align="left"
                    >
                      {stats != null ? stats.visit_count : '-'}
                    </TableCell> */}

                    <TableCell className={classes.stats} align="left">
                      {stats != null
                        ? formatToUSD(stats.total_spend / stats.visit_count)
                        : '-'}
                    </TableCell>

                    <TableCell className={classes.height} align="left">
                      {creativeStatusIconRender(creative.active)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </StyledAppHeader>
  );
};

DisplayIndexPage.propTypes = {
  history: PropTypes.object,
};

export default DisplayIndexPage;
