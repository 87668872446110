import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material';
import { OutOfSyncTooltip } from './styledComponents';

const IndicatorDot = styled("span")(({ signal }) => {
  const backgroundColor = signal === true ? "green" : signal === false ? "red" : "grey";

  return {
    display: 'inline-block',
    width: '12px',
    height: '12px',
    borderRadius: '50%',
    backgroundColor,
  };
});

const DeliverySignal = ({ signal }) => (
  <OutOfSyncTooltip title="This indicates if your ad group is delivering in the last hour. Please use reporting to see more granular analysis of performance after processing" arrow>
    <IndicatorDot signal={signal} />
  </OutOfSyncTooltip>
);

DeliverySignal.propTypes = {
  signal: PropTypes.bool,
};

export default DeliverySignal;
