import {
  generatePath,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import { routes } from '../routes';
import { sections } from '../constants';

export const useWizardQuery = () => {
  const { campaignId, sectionId = sections.campaign, paneId } = useParams();
  const location = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(location.search);

  const usefulParams = {
    activeSection: sectionId,
    activeCampaign: campaignId,
    activePane: paneId,
  };

  const handleHistoryPush = pathname => {
    history.replace({ pathname, search: query.toString() });
  };

  const goToCampaign = (campaignId, sectionId, paneId) => {
    handleHistoryPush(
      generatePath(routes.campaign, {
        campaignId,
        sectionId,
        paneId,
      }),
    );
  };

  const goToSection = (sectionId, paneId) => {
    handleHistoryPush(
      generatePath(routes.campaign, {
        campaignId,
        sectionId,
        paneId,
      }),
    );
  };

  return {
    query,
    goToCampaign,
    goToSection,
    campaignId,
    groupId: sectionId,
    ...usefulParams,
  };
};
