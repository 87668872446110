import React from 'react';
import PropTypes from 'prop-types';
import { Content } from './Content';
import { Icon } from './Icon';
import { HtmlTooltip } from '../../styledComponents';

export const WarningQCTooltip = ({ items }) => {
  if (items.length === 0) {
    return <Icon />;
  }

  return (
    <HtmlTooltip
      interactive
      data-testid="warning-tooltip"
      title={<Content items={items} />}
    >
      <Icon />
    </HtmlTooltip>
  );
};

WarningQCTooltip.propTypes = {
  items: PropTypes.array,
};
