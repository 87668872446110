export const packageCopy = {
  default:{
    reach: {
      benefits: `Brand Safe, High Quality Publishers.   Lower cost due to a broad range of living room quality content.`,
      bestFor: `Performance Advertisers looking to maximize Exposure but drive down CPA/CAC.  Concerned with brand safety but prioritizing target audience above content.`,
      cpm: [10, 20],
    },
    bundles: {
      benefits: `Inventory organized by genre, content, network, and app type for specific content or publisher targeting.`,
      bestFor: `Broad Branding advertisers that aren't targeting a specific audience that can be identified via available data segments.`,
      cpm: [15, 35],
    },
    private: {
      benefits: `Experienced and High Volume CTV Advertisers with one or multiple direct relationships looking to leverage tvScience to take their buying to the next level.`,
      bestFor: `Experienced and High Volume CTV Advertisers with one or multiple direct relationships looking to leverage tvScience to take their buying to the next level.`,
      cpm: null,
    },
  },
  nbcu: {
    reach: {
      benefits: `One of the largest premium digital video advertising inventory pools in the marketplace with cross-genre, cross-device options, all in one place.`,
      bestFor: `Best for advertisers who want to access NBCU's exceptional portfolio with premium shows and iconic brands`,
      cpm: [17, 21],
    },
    bundles: {
      benefits: `Choose from six content packages featuring premium NBCU streaming content organized by genre.`,
      bestFor: `Best for advertisers who wish to reach viewers of specific types of content`,
      cpm: [17, 21],
    },
    private: {
      benefits: `Brand Safe, High Quality Publishers. Lower cost due to a broad range of living room quality content.`,
      bestFor: `Performance Advertisers looking to maximize exposure but drive down CPA/CAC. Concerned with brand safety but prioritizing target audience above content`,
      cpm: null,
    },
  }
};
