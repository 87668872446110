import { useFormContext } from 'react-hook-form';
import { fields } from '../constants';
import { useMemo, useState } from 'react';
import { useGetAllowedTargeting } from '../../../../../components/hooks/apis/allowedTargeting';
import { getTargetingValue } from '../utils';
import { useDomain } from '@components/hooks/domain';
import Bundle from '../../../../../types/bundle';

export const useInventory = ({ bundles }: { bundles: Bundle[] }) => {
  const { setValue, control, watch } = useFormContext();
  const { data } = useGetAllowedTargeting();
  const domain = useDomain();
  const [isCustomInventoryExpanded, setIsCustomInventoryExpanded] =
    useState(false);

  const [customInventoryItems, advancedInventory] = watch([
    fields.advancedCustomInventory.path,
    fields.advancedInventory.path,
  ]);

  const actualAdvancedInventory = useMemo(() => {
    const { os = {}, device = {} } = advancedInventory ?? {};

    return [
      ...os.ids.map((key: string) => ({
        ...getTargetingValue(key, data),
        included: !os.blacklist,
      })),
      ...device.ids.map((key: string) => ({
        ...getTargetingValue(key, data),
        included: !device.blacklist,
      })),
    ];
  }, [advancedInventory, data]);

  const selectRecommendedInventory = () => {
    if (domain.peacock) {
      const singlePeacockBundle = bundles.filter(item => item.bundle_name == 'Run Of Network')[0]
      setValue(fields.advancedCustomInventory.path, [singlePeacockBundle]);
    } else {
      const recommendedBundles =
        bundles.filter(({ recommended }) => recommended);
      setValue(fields.advancedCustomInventory.path, recommendedBundles);
    }
  };

  return {
    customInventoryItems,
    setValue,
    control,
    domain,
    isCustomInventoryExpanded,
    setIsCustomInventoryExpanded,
    inventory: actualAdvancedInventory,
    selectRecommendedInventory,
  };
};
