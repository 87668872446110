import { useHistory } from 'react-router-dom';

interface Save {
  step: string;
  exit?: boolean;
}

type HandleSaveFunction = () => Promise<boolean>;
type CallbackFunction = (arg1: boolean, arg2: boolean, arg3: boolean) => void;

export const useSaveExit = () => {
  const history = useHistory();

  const saveProgress = async (
    save: Save | undefined,
    step: string,
    handleSave: HandleSaveFunction,
    callback: CallbackFunction
  ): Promise<boolean | void> => {
    if (save?.step && save.step === step) {
      const res = await handleSave();
      if (res) {
        return callback(false, false, false);
      }
      return res;
    }
  };

  const saveAndExit = (save: Save | undefined, step: string): void => {
    // Handle Save & Exit button click
    if (save?.step && save.step === step) {
      if (save.exit) {
        history.push('/');
      }
    }
  };

  return {
    saveAndExit,
    saveProgress,
  };
};
