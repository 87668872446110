import { yupResolver } from '@hookform/resolvers/yup';
import { defaults } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  fields,
  formSchema,
  defaultValues as initialValues,
} from '../formConfig';
import { transformAdGroupToFields } from '../utils';
import { useDomain, useRoles } from '@hooks';

export const useGroupSectionForm = ({
  adGroup,
  bidStrategies,
  bundles,
  library,
  appBundleList,
  targeting,
  audiences,
  campaign,
}) => {
  const roles = useRoles();
  const domain = useDomain();
  const values = useMemo(
    () =>
      defaults(
        transformAdGroupToFields(adGroup, {
          customInventory: bundles,
          library,
          appBundleList,
          audiences,
          allowedTargeting: targeting,
          campaign,
        }),
        initialValues(),
      ),
    [adGroup, campaign, library, targeting, audiences, bundles],
  );

  const form = useForm({
    resolver: yupResolver(
      formSchema({ campaign, bidStrategies, roles, domain }),
    ),
    reValidateMode: 'all',
    mode: 'all',
    values,
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const { watch, trigger } = form;

  const [
    inventoryOption,
    endDate,
    endTime,
  ] = watch([
    fields.inventoryOption.path,
    fields.endDate.path,
    fields.endTime.path,
  ]);

  useEffect(() => {
    if (inventoryOption) {
      trigger(fields.advancedCustomInventory.path);
    }
  }, [trigger, inventoryOption]);

  useEffect(() => {
    if (endDate || endTime) {
      trigger(fields.endDate.path);
      trigger(fields.endTime.path);
    }
  }, [trigger, endDate, endTime]);

  return {
    form,
  };
};
