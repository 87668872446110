import useSWR from 'swr';

import { useCurrentSession } from '../currentSession';

export const useGetCurrentAdvertiser = () => {
  const cachedId = parseInt(localStorage.getItem('AdvertiserContext') || '0', 10);
  const { getV1, apiIsReady } = useCurrentSession();

  const fetcher = ({ url, params }) =>
    getV1(url, { params }).then(res => res.data);

  const url = `/advertisers/${cachedId}/`;

  const swr = useSWR((cachedId && apiIsReady) ? { url } : null, fetcher);

  const { data, error, isLoading } = swr;

  return {
    data,
    error,
    isLoading,
  };
};
