import React from 'react';

export const SingularIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="210" height="200" viewBox="0 0 210 200" fill="#A1ACB3" {...props}>
    <path
      d="M132.664 51.8532C142.844 66.5237 148.179 84.0012 147.922 101.845C147.923 101.982 147.969 102.116 148.052 102.226C148.135 102.336 148.252 102.417 148.384 102.455C148.517 102.494 148.659 102.489 148.788 102.442C148.918 102.394 149.029 102.306 149.104 102.19C150.472 99.8995 151.706 97.5311 152.798 95.0971C156.932 86.0279 159.203 76.2242 159.479 66.2644C159.755 56.3047 158.029 46.3908 154.403 37.1076C146.156 30.5439 136.667 25.7077 126.504 22.8885C116.341 20.0692 105.712 19.3249 95.2545 20.6999C95.1133 20.7228 94.9835 20.7912 94.885 20.8947C94.7865 20.9983 94.7247 21.1312 94.7091 21.2731C94.6935 21.415 94.725 21.5581 94.7987 21.6805C94.8723 21.8029 94.9842 21.8978 95.1171 21.9506C110.315 28.0014 123.38 38.4078 132.664 51.8532Z"
    />
    <path
      d="M161.163 98.7177C153.579 115.75 140.542 129.787 124.1 138.626C123.981 138.693 123.887 138.798 123.832 138.923C123.777 139.048 123.764 139.188 123.796 139.321C123.828 139.454 123.902 139.573 124.007 139.661C124.113 139.748 124.244 139.799 124.381 139.806C128.3 139.817 132.213 139.515 136.083 138.9C154.877 135.912 171.939 126.199 184.084 111.576C185.704 101.089 185.176 90.3822 182.53 80.1045C179.885 69.8268 175.178 60.1916 168.694 51.7819C168.608 51.6741 168.489 51.5977 168.355 51.5648C168.221 51.532 168.08 51.5445 167.954 51.6005C167.828 51.6564 167.725 51.7526 167.659 51.8738C167.594 51.9951 167.571 52.1345 167.594 52.2702C169.946 68.0479 167.714 84.1678 161.163 98.7177ZM79.4629 51.579C96.4472 50.4105 113.36 54.5961 127.832 63.5378C127.951 63.6135 128.091 63.6478 128.232 63.6355C128.372 63.6232 128.504 63.5649 128.608 63.4697C128.711 63.3744 128.78 63.2477 128.804 63.1092C128.828 62.9707 128.805 62.8282 128.739 62.704C127.647 60.77 126.462 58.8899 125.189 57.0702C119.448 48.7382 112.068 41.6607 103.496 36.2697C94.925 30.8786 85.3431 27.287 75.335 25.7138C65.4973 29.6381 56.5356 35.4677 48.9668 42.8663C41.3979 50.2648 35.3719 59.0859 31.2363 68.8205C31.1783 68.9501 31.1653 69.0952 31.1994 69.233C31.2335 69.3707 31.3128 69.4931 31.4246 69.5807C31.5364 69.6684 31.6744 69.7162 31.8165 69.7166C31.9587 69.717 32.0969 69.67 32.2092 69.5831C45.8603 59.1041 62.2878 52.8451 79.4629 51.579Z"
    />
    <path
      d="M137.682 147.875C117.818 151.082 97.4695 146.794 80.6007 135.845C80.4821 135.769 80.3417 135.735 80.2014 135.747C80.0611 135.76 79.9289 135.818 79.8253 135.913C79.7218 136.008 79.6527 136.135 79.6289 136.274C79.6051 136.412 79.6279 136.555 79.6938 136.679C81.1449 139.312 82.7718 141.846 84.5692 144.26C96.7758 160.297 114.631 171.107 134.522 174.503C144.396 170.574 153.39 164.728 160.984 157.303C168.577 149.879 174.618 141.025 178.758 131.253C178.816 131.124 178.829 130.979 178.795 130.841C178.761 130.703 178.681 130.581 178.57 130.493C178.458 130.406 178.32 130.358 178.178 130.357C178.035 130.357 177.897 130.404 177.785 130.491C166.073 139.522 152.29 145.497 137.682 147.875Z"
    />
    <path
      d="M77.4237 149.751C66.3714 135.007 60.7602 116.906 61.5387 98.5092C61.5377 98.3713 61.4921 98.2375 61.4089 98.1274C61.3256 98.0174 61.2091 97.937 61.0765 97.8983C60.9439 97.8595 60.8023 97.8644 60.6728 97.9121C60.5432 97.9599 60.4324 98.0481 60.357 98.1636C59.1752 100.111 58.1254 102.058 57.1525 104.072C52.9808 113.184 50.6604 123.031 50.3255 133.044C49.9905 143.057 51.6477 153.037 55.2012 162.407C63.5261 169.143 73.136 174.121 83.4473 177.038C93.7587 179.955 104.556 180.749 115.185 179.374C115.326 179.351 115.456 179.283 115.554 179.179C115.653 179.076 115.715 178.943 115.73 178.801C115.746 178.659 115.714 178.516 115.641 178.393C115.567 178.271 115.455 178.176 115.322 178.123C100.341 172.279 87.2381 162.47 77.4237 149.751Z"
    />
    <path
      d="M48.0942 100.736C55.3961 83.8322 68.3394 69.9742 84.7231 61.5191C84.8424 61.4518 84.9366 61.3477 84.9915 61.2223C85.0464 61.097 85.0589 60.9572 85.0273 60.8242C84.9957 60.6911 84.9216 60.5718 84.8161 60.4845C84.7107 60.3971 84.5797 60.3462 84.4428 60.3396C82.2112 60.2683 79.9136 60.3396 77.6875 60.4768C67.6825 61.3834 57.9592 64.2734 49.0873 68.9775C40.2154 73.6817 32.3732 80.1054 26.0201 87.8724C24.3049 98.4279 24.7605 109.221 27.3591 119.595C29.9577 129.97 34.6451 139.707 41.1356 148.215C41.2212 148.323 41.3403 148.399 41.4742 148.432C41.608 148.465 41.7491 148.453 41.875 148.397C42.001 148.341 42.1047 148.245 42.1698 148.123C42.235 148.002 42.2579 147.863 42.2349 147.727C39.8714 131.818 41.8941 115.581 48.0942 100.736Z"
    />
  </svg>
);
