import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Alert,
  Box,
  Button,
  Grid,
  Paper,
  Divider,
  Typography,
  Link,
  Tabs,
  Tab,
  IconButton,
  Tooltip,
  Switch,
} from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import clsx from 'clsx';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CreativeLibrary from './CreativeLibrary';
import DisplayLibrary from './DisplayLibrary';
import FileDropzone from './FileDropzone';
import FileProgress from './FileProgress';
import AlertBox from './AlertBox';
import Title from './Title';
import ImageSpecifications from './ImageSpecifications';
import { VastTag } from './VastTag';
import { useAPI } from './hooks/api';
import { useCopy, usePermissions } from './hooks';
import { useUpload } from './hooks/upload';
import { CreativeQuantityControl } from './modals';
import { Scopes, Themes } from '../constants';
import uploadIcon from '../images/nbcu/padman-icon-upload.svg';
import ModalContext from '../providers/ModalContext';
import { useClearSwrCache } from '../swr';
import { useDomain } from './hooks/domain';

const PREFIX = 'FileUploader';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  divider: `${PREFIX}-divider`,
  tooltipWidth: `${PREFIX}-tooltipWidth`,
  files: `${PREFIX}-files`,
  toggleInfo: `${PREFIX}-toggleInfo`,
  toggleTextActive: `${PREFIX}-toggleTextActive`,
  main: `${PREFIX}-main`,
  tabs: `${PREFIX}-tabs`,
  info: `${PREFIX}-info`
};

const StyledGrid = styled(Grid)(({
  theme: { spacing, palette }
}) => ({
  [`&.${classes.container}`]: {
    height: '100%',
    marginBottom: spacing(0),
  },

  [`& .${classes.divider}`]: {
    marginBottom: spacing(4),
  },

  [`& .${classes.tooltipWidth}`]: {
    backgroundColor: `#f2f3f5`,
    color: palette.text.primary,
    padding: spacing(2),
    maxWidth: 180,
  },

  [`& .${classes.files}`]: {
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    maxHeight: 422,
    overflow: 'auto',
    width: '100%',
  },

  [`& .${classes.toggleInfo}`]: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },

  [`& .${classes.toggleTextActive}`]: {
    color: '#181C20',
  },

  [`& .${classes.main}`]: {
    height: '100%',
  },

  [`& .${classes.tabs}`]: {
    borderBottom: `1px solid #e0e0e0`,
    width: `100%`,
    position: `relative`,
  },

  [`& .${classes.info}`]: {
    fontSize: '0.8125rem',
  }
}));

const StyledButton = Button;

const Copies = {
  [Themes.DEFAULT]: {
    INTRO: null,
    FileDropzone: {
      image: {
        DESCRIPTION: 'Drag Your Display Files here, or',
        specifications: <ImageSpecifications />,
      },
      video: {
        DESCRIPTION: 'Drag Your Creative Files here, or',
        specifications: (
          <div className="Dropzone-specs">
            <p>Recommended Aspect Ratio: 16:9</p>
            <p>Recommended ad lengths: :15, :30 and :60</p>
            <p>Supported file formats: MP4 and MOV</p>
            <p>Max File Size: 500MB</p>
          </div>
        ),
      },
    },
  },
  [Themes.NBCU]: {
    INTRO:
      'Upload your creative video files. If you’ve previously uploaded creative flies, they can be found in the Creative Library tab.',
    FileDropzone: {
      image: {
        DESCRIPTION: 'Drag Your Display Files here or',
        specifications: <ImageSpecifications />,
      },
      video: {
        DESCRIPTION: 'Drag your creative files here or',
        specifications: (
          <div className="Dropzone-specs">
            <p>Required aspect ratio: 16:9</p>
            <p>Supported ad lengths: :6, :15, :30, :45, :60 and :90</p>
            <p>Supported file formats: MP4 and MOV</p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://together.nbcuni.com/nbcu-creative-guidelines/programmatic/"
              onClick={event => {
                event.stopPropagation();
              }}
            >
              Full NBCU creative specifications
            </a>
          </div>
        ),
      },
    },
  },
};

const MAX_FILE_SIZE = 500 * 1024 * 1024; // 500MB in bytes

const FileUploader = props => {
  const {
    accept,
    adGroupId,
    setCurrentPreview,
    setIsCreativePreview,
    type,
    showVastTag,
    withWeighting = false,
    uploadView: view,
  } = props;

  const { usePost } = useAPI();
  const { setModal } = useContext(ModalContext);
  const domain = useDomain()
  const { register, control, watch, formState, getValues, setValue } = useFormContext();
  const { hasPermission } = usePermissions();
  const Copy = useCopy(Copies);

  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const weightedDelivery = watch('weightedDelivery');

  const { fields, append, replace } = useFieldArray({
    name: 'creatives',
    keyName: 'internalId',
    control,
  });

  // TODO: get rid of this hook by taking all the logic out
  const {
    formatFileName,
    isUploading,
    setIsUploading,
    progressBars,
    setProgressBars,
    setIsUploadSuccess,
    uploadError,
    setUploadError,
    uploadCreative,
    uploadDisplay,
  } = useUpload({
    uploadMetadataState: [fields, replace],
  });

  const initialView = () => (view ? view : 'start');
  const [uploadView, setUploadView] = useState(initialView());
  const [tab, setTab] = useState(0);
  const [showVideoCheckAlert, setShowVideoCheckAlert] = useState(false);
  const { clearCache } = useClearSwrCache();

  const firstCreativeErrorMessage = useMemo(() => {
    const firstErrorsCreative = formState.errors?.creatives?.find(creative => creative) || {};
    const firstCreativeErrorField = Object.keys(firstErrorsCreative)[0];

    return firstErrorsCreative[firstCreativeErrorField]?.message;
  }, [formState]);

  useEffect(() => {
    if (fields.length > 0) {
      setUploadView('progress');
    }
  }, [fields]);

  useEffect(() => {
    setShowVideoCheckAlert(showQCFunctionality);
  }, [showQCFunctionality]);

  useEffect(() => {
    const nextView = fields.length > 0 ? 'progress' : 'start';

    setUploadView(nextView);
  }, [fields]);

  useEffect(() => {
    fields.forEach(file => {
      if (file && file.name && progressBars[file.name] === null) {
        setProgressBars(prev => ({
          ...prev,
          [file.name]: 100,
        }));
      }
    });
  }, [fields]);

  const handleSetTab = (event, newTab) => {
    setTab(newTab);
  };

  const handleBatchAssign = files => {
    if (files.length <= 0) {
      return;
    }

    if (props.handleAssign) {
      props.handleAssign(files);

      return;
    }

    append(files);
  };

  const handleUnassign = file => {
    if (props.handleUnassign) {
      props.handleUnassign(file);

      return;
    }

    replace(fields.filter(creative => creative.id !== file.id));
  };

  const handleAddVastTags = async (vastTags) => {
    append(vastTags);
  }

  const handleEditVastTag = async ({ id, ...tag }) => {
    replace(fields.map(field => {
      if (field.id === id) return { ...field, ...tag }
      return field;
    }));
  }

  const handleUpload = files => {
    if (type === 'image') {
      return uploadDisplay(files);
    }

    return uploadCreative(files);
  };

  const handleError = error => {
    setUploadError(error);
  };

  const handleDrop = acceptedFiles => {
    setUploadError(null);
    setIsUploadSuccess(false);
    setUploadView('progress');

    const url = type === 'image' ? '/static_display_creatives' : '/creatives';

    if (props.setIsLoading) {
      props.setIsLoading(true);
    }

    if (props.setIsUploading) {
      props.setIsUploading(true);
    }

    return handleUpload(acceptedFiles)
      .then(responses => {
        // Handle upload errors
        if (responses && responses.response) {
          return Promise.reject(responses.response);
        }

        const filePromises = responses.map(r => {
          const fileData = {
            advertiser: r.data.advertiser,
            name: r.data.name,
          };

          if (type === 'video') {
            fileData.video_asset = r.data.url;
            // replaces the extension string after the first period detected with an empty string
            fileData.name = fileData.name.replace(/\.[^/.]+$/, '');
          }

          if (type === 'image') {
            fileData.image_asset = r.data.url;
            fileData.active = true;
          }

          return usePost(url, fileData).then((response) => {
            clearCache([url]);
            return response;
          });
        });

        return filePromises;
      })
      .then(responses => {
        // handle success from file upload
        console.log('Upload success!');

        return Promise.all(responses)
          .then(fileRes => {
            const filesArray = fileRes.map(file => file.data);

            if (props.handleAssign) {
              props.handleAssign(filesArray);
            } else {
              const creatives = getValues('creatives');

              const [prevCreatives, uploadableCreatives] = creatives.reduce(
                (accumulation, creative) => {
                  const index = creative.uploadable ? 1 : 0;

                  accumulation[index].push(creative);

                  return accumulation;
                },
                [[], []]
              );

              const nextCreatives = filesArray.map((creative, index) => {
                const { click_url, weighting } = uploadableCreatives[index] || creative;

                return {
                  ...creative,
                  click_url,
                  weighting,
                };
              });

              replace([...prevCreatives, ...nextCreatives]);
            }

            if (props.setIsLoading) {
              props.setIsLoading(false);
            }

            if (props.setIsUploading) {
              props.setIsUploading(false);
            }

            return fileRes;
          })
          .catch(error => console.error(error));
      })
      .catch(error => {
        // handle error
        console.log('Upload failed!');
        console.log(error.request);
        console.log('Error in file uploader', error);
        setIsUploading(false);
        setIsUploadSuccess(false);
        handleError(error.data.error);

        if (props.setIsUploading) {
          props.setIsUploading(false);
        }
      });
  };

  const handleDropRejected = fileRejections => {
    const errors = fileRejections.map(({ file, errors }) => {
      if (errors[0].code === 'file-too-large') {
        return `File ${file.name} is too large. Max size is 512MB.`;
      }
      return `File ${file.name} was rejected. ${errors[0].message}`;
    });
    setUploadError(errors);
  };

  // Removes file from metadata array and HOC files prop
  const handleRemoveFile = key => {
    const file = fields.find(data => data.id === key);
    const lineItems = _.filter(file, function (v, k) {
      return _.includes(k, 'lineitem_set');
    });

    replace(fields.filter(f => f.id !== key));


    if (
      (props.handleDeleteFile && lineItems[0].length === 0) ||
      (lineItems[0].length === 1 && lineItems[0][0].id === adGroupId)
    ) {
      props.handleDeleteFile(file.id);
    }
  };

  const handleCloseVideoCheckAlert = () => {
    setShowVideoCheckAlert(false);
  };

  const handleCreativeQualityControlClick = (event) => {
    event.preventDefault();

    setModal({
      isOpen: true,
      component: CreativeQuantityControl,
    });
  };

  const renderUploadView = () => (
    <Box width="100%">
      {uploadView === 'start' && (
        <Fragment>
          {Copy.INTRO && <p>{Copy.INTRO}</p>}

          {showVideoCheckAlert && (
            <Box
              component={Alert}
              alignItems="center"
              my={2}
              icon={<InfoOutlinedIcon fontSize="inherit" />}
              severity="info"
              action={
                <IconButton
                  aria-label="close"
                  size="inherit"
                  onClick={handleCloseVideoCheckAlert}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
            >
              We will check your video specs to ensure it meets minimum quality requirements. Issues will be automatically fixed in most cases.

              <Link
                size="small"
                color="inherit"
                component={StyledButton}
                onClick={handleCreativeQualityControlClick}
                underline="hover">
                Learn more
              </Link>
            </Box>
          )}

          <FileDropzone
            accept={accept}
            handleDrop={handleDrop}
            handleDropRejected={handleDropRejected}
            maxSize={MAX_FILE_SIZE}
            uploadIcon={domain.peacock && uploadIcon}
            description={Copy.FileDropzone[type].DESCRIPTION}
            specifications={Copy.FileDropzone[type].specifications}
          />

          {fields.length > 0 || uploadError && (
            <Box mt={4}>
              <Button
                color="secondary"
                onClick={() => {
                  setUploadView('progress');
                }}
                size="small"
              >
                View Progress
              </Button>
            </Box>
          )}
        </Fragment>
      )}

      {uploadView === 'progress' && (
        <Grid
          container
          direction="column"
          spacing={3}
        >
          {firstCreativeErrorMessage && (
            <Grid item xs={12}>
              <Box mb={3}>
                <AlertBox
                  {...alert.activeAlert}
                  isAlertOpen
                  type="MAJOR"
                  message={firstCreativeErrorMessage}
                />
              </Box>
            </Grid>
          )}

          {type === 'video' && <Grid item><Title>Verify Creative Title, Language, and Weighting</Title></Grid>}
          {type === 'image' && <Grid item><Title>Verify Display Title</Title></Grid>}

          {withWeighting && (
            <Grid item>
              <Grid
                container
                alignItems="center"
                spacing={1}
              >
                <Grid
                  item
                  className={clsx(!weightedDelivery && classes.toggleTextActive)}
                >
                  Random Delivery
                </Grid>
                <Grid item>
                  <Tooltip title="With Random Delivery the creative will be served at random, which translates to approximately even distribution">
                    <InfoOutlinedIcon
                      className={classes.toggleInfo}
                      fontSize="small"
                      color="secondary"
                    />
                  </Tooltip>
                </Grid>

                <Grid item>
                  <Switch
                    {...register('weightedDelivery')}
                    checked={weightedDelivery}
                    onChange={(e) => setValue('weightedDelivery', e.target.checked)}
                    inputProps={{ 'aria-label': 'secondary checkbox', 'data-testid': 'weighted-delivery-switch' }}
                    size="small"
                  />
                </Grid>
                <Grid
                  item
                  className={clsx(weightedDelivery && classes.toggleTextActive)}
                >
                  Weighted Delivery
                </Grid>
                <Grid item>
                  <Tooltip title="Weighted Delivery allows you to determine the max allocation of the ad group budget that can go to each creative by assigning a value between 1 and 100 (%) to determine the relative weight with which the creative will be served">
                    <InfoOutlinedIcon
                      className={classes.toggleInfo}
                      fontSize="small"
                      color="secondary"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          )}

          <Grid item>
            <Paper className={classes.files} variant='outlined'>
              {fields.length > 0 &&
                fields.map((meta, index) => {
                  const name =
                    meta && meta.fileName
                      ? meta.fileName
                      : meta && meta.name
                        ? meta.name
                        : 'meta-err';

                  // TODO: Need data coming from BE for fileSize and resolution
                  return (
                    <Fragment key={meta.internalId}>
                      <FileProgress
                        index={index}
                        duration={meta.duration}
                        fileSize={meta.file_size_bytes}
                        fileId={meta.id}
                        formatFileName={formatFileName}
                        handleRemoveFile={handleRemoveFile}
                        disabledWeightedDelivery={!weightedDelivery}
                        name={name}
                        progressBars={progressBars}
                        resolution={meta.resolution}
                        control={control}
                        field="creatives"
                        withWeighting={withWeighting}
                      />

                      {fields.length !== index + 1 && <Divider />}
                    </Fragment>
                  );
                })}
            </Paper>
          </Grid>

          <Grid item>
            <Button
              color="secondary"
              onClick={() => {
                setUploadView('start');
              }}
              disabled={isUploading}
              size="small"
              startIcon={<CloudUploadOutlinedIcon />}
            >
              {type === 'video' && 'Upload More Creatives'}
              {type === 'image' && 'Upload More Displays'}
            </Button>
          </Grid>

          {isUploading && (
            <Grid item>
              <Tooltip
                classes={{ tooltip: classes.tooltipWidth }}
                title={'Please wait while media is uploading'}
              >
                <InfoOutlinedIcon
                  className={classes.info}
                  fontSize="small"
                  color="secondary"
                />
              </Tooltip>
            </Grid>
          )}
        </Grid>
      )}
    </Box >
  );

  return (
    <StyledGrid className={classes.container} container>
      <Grid item xs={12}>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          justifyContent="space-between"
        >
          <Box
            className={classes.main}
            flexGrow={1}
            width="100%"
            height="100%"
          >
            {domain.peacock && (
              <Title>Upload Creative</Title>
            )}

            <Box mb={4} width="100%">
              <Tabs
                className={classes.tabs}
                onChange={handleSetTab}
                value={tab}
              >
                {type === 'video' && <Tab label="Upload Creative" data-testid="upload-creative-tab" />}
                {showVastTag && type === 'video' && (
                  <Tab label="By VAST Tag" />
                )}
                {type === 'video' && <Tab label="Creative Library" data-testid="creative-library-tab" />}

                {type === 'image' && <Tab label="Upload Display" data-testid="upload-disaplay-tab" />}
                {type === 'image' && <Tab label="Display Media Library" data-testid="display-media-library-tab" />}
              </Tabs>
            </Box>

            {uploadError && (
              <Box mb={2}>
                <Alert
                  icon={<WarningIcon />}
                  severity="error"
                  action={
                    <IconButton size="small">
                      <CloseIcon onClick={() => setUploadError(null)} />
                    </IconButton>
                  }
                >
                  {Array.isArray(uploadError) ? (
                    <>
                      {uploadError.map((error, index) => (
                        <Typography key={index}>{error}</Typography>
                      ))}
                    </>
                  ) : (
                    uploadError
                  )}
                </Alert>
              </Box>
            )}

            {tab === 0 && renderUploadView()}

            {tab === 1 &&
              type === 'video' &&
              showVastTag && (
                <VastTag
                  name='vastTags'
                  onAdd={handleAddVastTags}
                  onEdit={handleEditVastTag}
                  onRemove={handleRemoveFile}
                />

              )}

            {tab === 1 && type === 'video' && !showVastTag && (
              <CreativeLibrary
                assigned={fields}
                handleBatchAssign={handleBatchAssign}
                handleUnassign={handleUnassign}
                setCurrentPreview={setCurrentPreview}
                setIsPreview={setIsCreativePreview}
              />
            )}

            {tab === 2 && type === 'video' && showVastTag && (
              <CreativeLibrary
                assigned={fields}
                handleBatchAssign={handleBatchAssign}
                handleUnassign={handleUnassign}
                setCurrentPreview={setCurrentPreview}
                setIsPreview={setIsCreativePreview}
              />
            )}

            {tab === 1 && type === 'image' && (
              <DisplayLibrary
                assigned={fields}
                handleBatchAssign={handleBatchAssign}
                handleUnassign={handleUnassign}
                setCurrentPreview={setCurrentPreview}
                setIsPreview={setIsCreativePreview}
              />
            )}

            {showQCFunctionality && uploadView !== 'start' && (
              <Box component={Typography} variant="body2" display="flex">
                <InfoOutlinedIcon fontSize="small" color="secondary" /> &nbsp;

                We will check your file formatting to ensure it meets minimum quality requirements. Issues will be automatically fixed in most cases. &nbsp;

                <Link
                  color="secondary"
                  component="button"
                  variant="body2"
                  onClick={handleCreativeQualityControlClick}
                  underline="hover">
                  Learn more about this feature
                </Link>
              </Box>
            )}
          </Box>
        </Box>
      </Grid>
    </StyledGrid>
  );
};

FileUploader.propTypes = {
  accept: PropTypes.string,
  adGroupId: PropTypes.number,
  handleAssign: PropTypes.func,
  handleUnassign: PropTypes.func,
  handleDeleteFile: PropTypes.func,
  setIsLoading: PropTypes.func,
  setCurrentPreview: PropTypes.func,
  setIsCreativePreview: PropTypes.func,
  setIsUploading: PropTypes.func,
  type: PropTypes.string,
  showVastTag: PropTypes.bool,
  withWeighting: PropTypes.bool,
  uploadView: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};

export default FileUploader;
