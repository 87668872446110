import { Themes } from './themes';

const FEMALE = 'Female';
const MALE = 'Male';

export const Gender = {
  FEMALE,
  MALE
};

export const GenderCopies = {
  [Themes.DEFAULT]: {
    [Gender.FEMALE]: 'Female',
    [Gender.MALE]: 'Male',
  },
  [Themes.NBCU]: {
    [Gender.FEMALE]: 'Women',
    [Gender.MALE]: 'Men',
  },
}

export const GenderSelection = {
  [Themes.DEFAULT]: {
    ...GenderCopies[Themes.DEFAULT],
    ALL: 'All Genders',
  },
  [Themes.NBCU]: {
    ...GenderCopies[Themes.NBCU],
    ALL: 'All Genders',
  },
};
