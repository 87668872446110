import { Stack } from '@mui/material';
import { CloudFileUploadIcon } from '@v2/components/icons/CloudFileUploadIcon';
import React, { forwardRef } from 'react';
import { useAddZipCodes } from './hooks/useAddZipCodes';
import { StyledDropzone, StyledEditor, StyledUploadText } from './styles';
import { AddZipCodesProps } from './types';

const AddZipCodes = forwardRef<HTMLDivElement, AddZipCodesProps>(
  ({ value, onChange, onBlur, error, wrongZipCodes, ...props }, ref) => {
    const {
      editorRef,
      showDropzone,
      handleZipCodeChange,
      getInputProps,
      getRootProps,
      html,
      isDragActive,
    } = useAddZipCodes({
      onChange,
      wrongZipCodes,
      value,
    });

    return (
      <Stack
        {...props}
        ref={ref}
        sx={{ position: 'relative', ...props.sx }}
        {...getRootProps()}
      >
        {showDropzone && (
          <StyledDropzone isDragActive={isDragActive}>
            <input {...getInputProps()} />
            <CloudFileUploadIcon sx={{ width: '120px', height: '93px' }} />
            <StyledUploadText sx={{ mt: 4 }}>
              Upload Zip Codes
            </StyledUploadText>
          </StyledDropzone>
        )}

        <Stack flex={1} sx={{ position: 'relative' }}>
          <StyledEditor
            error={!!error || !!wrongZipCodes.length}
            onBlur={onBlur}
            innerRef={editorRef}
            onChange={handleZipCodeChange}
            html={html}
            sx={{ whiteSpace: 'pre-wrap' }}
            data-testid="zip-codes-editor"
          />
        </Stack>
      </Stack>
    );
  },
);

AddZipCodes.displayName = 'AddZipCodes';

export default AddZipCodes;
