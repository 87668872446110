import { object } from 'yup';
import { fields } from './constants';


export const defaultValues = () => {
  const defaultVals = Object.entries(fields).reduce(
    (acc, [, value]) => ({
      ...acc,
      [value.path]: value.defaultValue,
    }),
    {},
  );
  return defaultVals;
};

const objectiveGoalsRelatedPaths = [
  fields.objectiveGoalEvent.path,
  fields.objectiveGoalType.path,
  fields.objectiveGoalValueCost.path,
  fields.objectiveGoalValueMultiplier.path,
];

export const validationSchema = ({ objectiveGoalsAllowed }) => {
  const schema = Object.keys(fields).reduce((acc, key) => {
    const nextField = fields[key];
    if (
      nextField.rule &&
      (objectiveGoalsAllowed || !objectiveGoalsRelatedPaths.includes(nextField.path))
    ) {
      acc[nextField.path] = nextField.rule;
    }
    return acc;
  }, {});
  return object().shape(schema);
};
