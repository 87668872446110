import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import ModalWrapper from '../ModalWrapper';

const PREFIX = 'RolesAndPrivileges';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  learn: `${PREFIX}-learn`,
  sub: `${PREFIX}-sub`
};

const StyledModalWrapper = styled(ModalWrapper)(({
  theme: { spacing }
}) => ({
  [`& .${classes.container}`]: {
    maxWidth: '480px',
    width: '100%',
    margin: '0 auto',
  },

  [`& .${classes.title}`]: {
    marginTop: -40,
    marginBottom: spacing(8),
    borderBottom: '1px solid #e5e7eb',
  },

  [`& .${classes.learn}`]: {
    display: 'inline',
    verticalAlign: 'bottom',
    fontSize: '0.725rem',
  },

  [`& .${classes.sub}`]: {
    color: '#5c6c73',
    fontSize: '0.725rem',
  }
}));

const RolesAndPrivileges = ({ onClose }) => (
  <StyledModalWrapper
    isOpen
    hasSubmit
    submit="Got it"
    maxWidth="md"
    onClose={onClose}
    onSubmit={onClose}
    noCancel
    noFooterBg
  >
    <DialogTitle className={classes.title}>
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        pt={1}
      >
        <Box>
          <Box mb={1}>
            <Typography variant="h3">User Roles & Privileges</Typography>
          </Box>
        </Box>

        <IconButton onClick={onClose} size="large">
          <CloseIcon />
        </IconButton>
      </Box>
    </DialogTitle>

    <Box
      mx={'auto'}
      display="flex"
      flexDirection="column"
      gap="18px"
      className={classes.container}
    >
      <Typography variant="h5">Organization roles determine high-level permissions:</Typography>

      <Box display="flex" flexDirection="column" gap="6px">
        <Typography variant="h5">Organization admin</Typography>
        <Typography>
          Has superpower across the entire organization, all its ad accounts, campaigns, and all its users.
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap="6px">
        <Typography variant="h5"> Organization member</Typography>
        <Typography>
          Most users will have this role. Within ad accounts, an organization member can manage portions specified by their ad account role. At the organization level, they have read-only access.
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap="6px">
        <Typography variant="h5"> Organization guest</Typography>
        <Typography>
          A guest is from outside the organization and can manage parts of campaigns specified by their ad account role.
        </Typography>
      </Box>

      <Box mb={2} />

      <Typography variant='h5'>Ad account roles determine users&rsquo; ability to access campaigns, reports, or users:</Typography>

      <Box display="flex" flexDirection="column" gap="6px">
        <Typography variant="h5">Ad account admin</Typography>

        <Typography>
          Has superpower across the campaigns, budgets, and users for the entire ad account.
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap="6px">
        <Typography variant="h5">Campaign manager</Typography>

        <Typography>
          Campaign manager: Has access to create and manage all the campaigns in an ad account, but does not manage users at any level.
        </Typography>
      </Box>

      <Box display="flex" flexDirection="column" gap="6px">
        <Typography variant="h5">Analyst</Typography>

        <Typography>
          This role&rsquo;s main purpose is reporting; analysts have read-only access.
        </Typography>
      </Box>
    </Box>
  </StyledModalWrapper>
);

RolesAndPrivileges.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default RolesAndPrivileges;
