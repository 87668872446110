import { useContext } from 'react';

import { useAuth } from './auth';
import SSOUserContext from '../SSOUserContext';

export const useSSOUser = () => {
  const { auth } = useAuth();
  const ssoUserContext = useContext(SSOUserContext);

  const getSSOUser = async () => {
    const user = await auth.getUser();

    ssoUserContext.setUser(user);
  };

  return { getSSOUser };
};
