import React from 'react';
import PropTypes from 'prop-types';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckIcon from '@mui/icons-material/Check';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AccordionActions,
} from './Accordion';
import StrategyMissingEvents from './StrategyMissingEvents';
import Info from './Info';
import { LightningIcon } from '../icons';

const RequiredEventRequiredTargetStrategy = props => {
  const {
    strategy,
    currentStrategy,
    events,
    isExpanded,
    handleExpand,
    handleFormChange,
    extraData,
  } = props;

  const handleInputChange = event => {
    handleFormChange(event.target.name, event.target.value);
  };

  return events.length === 0 ? (
    <StrategyMissingEvents
      strategy={strategy}
      isExpanded={isExpanded}
      handleExpand={handleExpand}
    />
  ) : (
    <Accordion
      key={strategy.url}
      square
      expanded={isExpanded}
      onChange={handleExpand}
    >
      <AccordionSummary
        aria-controls={`${strategy.url}-content`}
        id={`${strategy.url}-header`}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <RadioGroup
              row
              value={currentStrategy.bid_strategy}
              onChange={handleInputChange}
              name="bid_strategy"
            >
              <FormControlLabel
                value={strategy.url}
                control={<Radio />}
                label={strategy.display_name}
              />
            </RadioGroup>
          </Grid>
          <Grid item>
            {isExpanded ? (
              <CheckIcon style={{ color: '#1DAFFF', fontSize: 16 }} />
            ) : (
              <Tooltip title={strategy.tooltip}>
                <InfoOutlinedIcon
                  style={{ color: '#1DAFFF', fontSize: 16 }}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" xs={11} spacing={2}>
          <Grid item>
            <Box sx={{ pb: 2 }}>{strategy.description}</Box>
          </Grid>
          <Grid item>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="event-label">Conversion Event</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={currentStrategy.bid_strategy_event || ''}
                onChange={handleInputChange}
                label="Options"
                name="bid_strategy_event"
              >
                {events?.map(event => (
                  <MenuItem key={event.id} value={event.url}>
                    {event.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <TextField
              color="secondary"
              label={extraData.targetLabel}
              value={currentStrategy.bid_strategy_target || ''}
              onChange={handleInputChange}
              fullWidth
              variant="outlined"
              name="bid_strategy_target"
              type="number"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
                inputProps: { min: 0, step: 0.01 },
              }}
            />
            <Info message={extraData.infoMessage} />
          </Grid>
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Grid container>
          <Grid
            item
            container
            justifyContent="center"
            alignItems="center"
            xs={1}
          >
            <LightningIcon width="28px" height="28px" />
          </Grid>
          <Grid item xs={10}>
            <p>
              <b>Pro tip:</b> Advertisers like you tipically see a 45-55%
              decrease in cost per conversion with this bid strategy.
            </p>
          </Grid>
        </Grid>
      </AccordionActions>
    </Accordion>
  );
};

RequiredEventRequiredTargetStrategy.propTypes = {
  strategy: PropTypes.object.isRequired,
  currentStrategy: PropTypes.object.isRequired,
  events: PropTypes.array,
  isExpanded: PropTypes.bool.isRequired,
  handleExpand: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  extraData: PropTypes.shape({
    infoMessage: PropTypes.string.isRequired,
    targetLabel: PropTypes.string.isRequired,
  }),
};

export default RequiredEventRequiredTargetStrategy;
