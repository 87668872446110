import { useMemo } from 'react';

export const useSectionsInfo = () => {
  const info = useMemo(() => {
    return [];
  }, []);

  return {
    info,
  };
};
