import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, TableRow, Typography, styled } from '@mui/material';
import { useTableRowGeneral } from './hooks/useTableRowGeneral';
import clsx from 'clsx';
import { tableOptions } from './constants';

const TableRowStyled = styled(TableRow, {
  shouldForwardProp: prop => prop !== 'progress',
})(({ theme, progress }) => ({
  position: 'relative',
  'td:not(.loading-indicator):not(.sticky)': {
    position: 'relative',
    zIndex: 2,
  },
  'td:first-of-type:after': {
    content: '""',
    position: 'absolute',
    transition: 'width 0.3s',
    pointerEvents: 'none',
    top: 0,
    left: 0,
    opacity: 0.5,
    width: `${progress < 100 ? progress : 0}%`,
    height: '100%',
    backgroundColor: theme.palette.blue[11],
    zIndex: 5,
  },
}));

export const TableRowGeneral = ({ item, search, columns }) => {
  const { isUploading } = useTableRowGeneral({
    item,
  });
  return (
    <TableRowStyled
      className={clsx({
        'is-uploading': isUploading,
      })}
      progress={item.progress}
      data-testid="creative-item"
    >
      <TableCell className="loading-indicator" />
      {columns.map(column => (
        <TableCell
          className={clsx({
            sticky:
              tableOptions.sticky.left.includes(column.id) ||
              tableOptions.sticky.right.includes(column.id),
            'sticky-left': tableOptions.sticky.left.includes(column.id),
            'sticky-right': tableOptions.sticky.right.includes(column.id),
          })}
          sx={column.cellSx}
          key={`${item.id}-${column.id}`}
        >
          {column.render ? (
            column.render(item, {
              search,
            })
          ) : (
            <Typography variant="body2">
              {item[column.id] ??
                column.defaultValue?.(item, {
                  search,
                }) ??
                '-'}
            </Typography>
          )}
        </TableCell>
      ))}
    </TableRowStyled>
  );
};

TableRowGeneral.propTypes = {
  item: PropTypes.object,
  search: PropTypes.string,
  columns: PropTypes.array,
};
