import { faArrowLeft, faInfoCircle } from '@fortawesome/pro-light-svg-icons';
import { faClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Divider, IconButton, Stack, styled } from '@mui/material';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Info } from '../Info';

const OverlayBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(6),
  position: 'fixed',
  left: `calc(${theme.spacing(15)} + 22.18%)`,
  right: 0,
  top: theme.spacing(8), // 64px offset for site header
  bottom: 0,
  zIndex: 999,
  '@media (max-width: 1699px)': {
    left: theme.spacing(13.625), // 109px offset for left nav
  },
}));

const WrapperBoxStyled = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: 20,
  boxShadow: theme.shadows[3],
  flex: 1,
  height: '100%',
  overflow: 'hidden',
  position: 'relative',
  zIndex: 999,
}));

const AdvancedBoxStyled = styled(Stack)(({ theme }) => ({
  margin: theme.spacing(5),
  flex: 1,
  overflow: 'auto',
}));

const ContentWrapperStyled = styled(Stack)(() => ({
  marginTop: 0,
  flex: 'auto',
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
}));

const BackIcon = styled(IconButton)(({ theme }) => ({
  color: theme.palette.blue[6],
}));

const DividerStyled = styled(Divider)(({ theme }) => ({
  marginRight: theme.spacing(1.75),
  marginLeft: theme.spacing(1),
  borderWidth: 1,
}));

const HeaderWrapperStyled = styled(Stack)(() => ({
  width: '100%',
}));

const InfoBoxInfoIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(2.25),
  height: theme.spacing(2.25),
}));

const InfoBoxCloseStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[6],
}));

const InfoBoxStyled = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  alignItems: 'center',
  padding: theme.spacing(1, 2, 1, 5),
  color: theme.palette.grey[6],
}));

export const AdvancedBox = ({
  header,
  children,
  onClose,
  onInfoClose,
  info,
  error,
  ...props
}) => {
  return (
    <OverlayBoxStyled>
      <WrapperBoxStyled {...props}>
        {error && <Info type="error">{error}</Info>}
        {info && (
          <InfoBoxStyled
            sx={{
              backgroundColor: 'blue.10',
            }}
          >
            <Stack alignItems="center" direction="row" spacing={1.5} flex={1}>
              <InfoBoxInfoIconStyled
                sx={{
                  color: 'blue.1',
                }}
                icon={faInfoCircle}
              />
              {info}
            </Stack>
            <IconButton
              onClick={onInfoClose}
            >
              <InfoBoxCloseStyled icon={faClose} />
            </IconButton>

          </InfoBoxStyled>
        )}
        <AdvancedBoxStyled flex={1} spacing={4}>
          <Stack
            justifyItems="center"
            alignItems="center"
            direction="row"
            // spacing={3}
          >
            <BackIcon onClick={onClose}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </BackIcon>
            <DividerStyled flexItem orientation="vertical" />
            <HeaderWrapperStyled>{header}</HeaderWrapperStyled>
          </Stack>
          <ContentWrapperStyled>
            {children}
          </ContentWrapperStyled>
        </AdvancedBoxStyled>
      </WrapperBoxStyled>
    </OverlayBoxStyled>
  );
};

AdvancedBox.propTypes = {
  ...Stack.propTypes,
  info: PropTypes.node,
  error: PropTypes.node,
  header: PropTypes.node,
  onClose: PropTypes.func,
  onInfoClose: PropTypes.func,
};
