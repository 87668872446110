import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';

import InnerGeoTargetingRow from './InnerGeoTargetingRow';

const VirtualizedGeoTargetingRow = ({ data, index, style }) => {
  const { setGeoResults, setTargetEntireUS, geoResults, copy } = data;
  const target = geoResults[index];
  const targetId = target.id || target.code;

  const handleDeleteTarget = useCallback(() => {
    // Utilize useEffect function for removing Entire US
    if (target.id && target.id === 'entire-us') {
      setTargetEntireUS(false);
      return;
    }

    // Handle the rest of the results
    setGeoResults(prev => {
      const newResults = prev.filter((r, i) => r !== target && i !== index);

      if (target && target === prev[index]) {
        return newResults;
      }

      if (newResults.length === 0) {
        return [];
      }
    });
  }, [setGeoResults, setTargetEntireUS, target, index]);

  const handleToggleTargetExcluded = useCallback(
    value => {
      if (!targetId) {
        return;
      }

      if (targetId) {
        const isExcluded = value;
        setGeoResults(prev => {
          // Create immutable copies
          const updatedResult = update(prev[index], {
            blacklist: { $set: isExcluded },
          });

          const newResults = update(prev, {
            $splice: [[index, 1, updatedResult]],
          });

          return newResults;
        });
      }
    },
    [setGeoResults, targetId, index]
  );

  return (
    <InnerGeoTargetingRow
      target={target}
      index={index}
      handleDeleteTarget={handleDeleteTarget}
      handleToggleTargetExcluded={handleToggleTargetExcluded}
      copy={copy}
      style={style}
    />
  );
};

VirtualizedGeoTargetingRow.propTypes = {
  data: PropTypes.object,
  index: PropTypes.number,
  style: PropTypes.object,
};

export default VirtualizedGeoTargetingRow;
