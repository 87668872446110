import { createTheme } from '@mui/material/styles';

export const nbcuTheme = createTheme({
  typography: {
    fontFamily: "PeacockSans,Helvetica,Arial,sans-serif",
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold: 900,
    h1: {
      fontSize: "2.5rem",
      color: "#47505D",
      fontWeight: 700
    },
    h2: {
      fontSize: "1.875rem",
      color: "#181C20",
      fontWeight: 700
    },
    h3: {
      fontSize: "1.5rem",
      color: "#181C20",
      fontWeight: 700
    },
    h4: {
      fontSize: "1.25rem",
      color: "#031620",
      fontWeight: 700
    },
    h5: {
      fontSize: "0.875rem",
      color: "#181c20",
      fontWeight: 700
    },
    subtitle1: {
      fontSize: "1.5rem",
      color: "#727383"
    },
    body1: {},
    body2: {
      fontSize: "0.875rem",
      color: "#30363E"
    },
    paragraph: {
      fontSize: "0.75rem",
      color: "#47505d"
    },
    button: {
      textTransform: "none"
    }
  },
  shape: {
    borderRadius: 8
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "0.875rem",
        }
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiButton: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: "#77858C",
          backgroundColor: "#EDF1F2",
          fontSize: "0.75rem"
        },
        colorPrimary: {
          color: "#0fbf84",
          backgroundColor: "rgba(122, 220, 187, 0.55)"
        }
      }
    },
    MuiTabs: {
      defaultProps: {
        textColor: "inherit"
      },
      styleOverrides: {
        indicator: {
          backgroundColor: "#1dafff"
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          background: "inherit",
          boxShadow: "0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 8px 10px 1px rgba(0, 0, 0, 0.14)"
        }
      }
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          fontWeight: "normal",
          borderWidth: 0,
          background: "inherit",
          color: "inherit",
          padding: "20px",
          "&:hover, &.Mui-selected, &.Mui-selected:hover": {
            color: "inherit",
            background: "#d9f1ff"
          },
          "&:not(:first-child)::before": {
            content: "\"\"",
            position: "absolute",
            top: "50%",
            transform: "translateY(-50%)",
            left: 0,
            width: "1px",
            height: "50%",
            backgroundColor: "#c0c8cc",
            marginLeft: "-1px",
            zIndex: 1
          },
          "&.Mui-selected + &:not(.Mui-selected)::before, &:not(.Mui-selected) + &.Mui-selected::before, &:hover + &:not(.Mui-selected)::before, &:not(.Mui-selected) + &:hover::before": {
            content: "none"
          }
        }
      }
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: "#979797",
          height: 8
        },
        thumb: {
          height: 24,
          width: 24,
          backgroundColor: "#1dafff",
          marginTop: -8,
          marginLeft: -12,
          "&:focus, &:hover, &$active": {
            boxShadow: "inherit"
          }
        },
        active: {},
        valueLabel: {
          left: "calc(-50% + 4px)"
        },
        mark: {
          width: 0
        },
        track: {
          height: 8,
          borderRadius: 4,
          backgroundColor: "#069de0"
        },
        rail: {
          height: 8,
          borderRadius: 4
        }
      }
    }
  },
  mixins: {},
  palette: {
    text: {
      primary: "#47505d",
      overlay: "inherit"
    },
    mode: "light",
    type: "light",
    secondary: {
      main: "#1dafff",
      light: "hsl(201, 100%, 56%)",
      dark: "#0d94df",
      contrastText: "#fff"
    },
    primary: {
      main: "#009061",
      light: "#0fbf84",
      dark: "#014831",
      contrastText: "#fff"
    },
    grey: {
      main: "#9aa0a6"
    },
    background: {
      overlay: "#fff"
    },
    border: {
      overlay: "inherit"
    }
  }
});
