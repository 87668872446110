const getEnvironmentPrefix : () => string = () => {
  switch (process.env.TVS_ENV) {
    case 'production':
      return 'tvs-prod-';
    case 'qa':
      return 'tvs-qa-';
  }

  return 'tvs-nonprod-';
};

// Create user key from first 8 digits of SHA-1 hash of their email
export const getUserKey: (email: string) => Promise<string> = async (email) => {
  if (email == '') return 'tvs-ue-undefined';

  const data = new TextEncoder().encode(email);
  const hashBuffer = await window.crypto.subtle.digest("SHA-1", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');

  return `tvs-ue-${hashHex.substring(0, 8)}`;
};

export const getOrganizationKey : (org_id: number) => string = (org_id) => {
  return `${getEnvironmentPrefix()}org-${org_id}`
};

export const getAdvertiserKey : (adv_id: number) => string = (adv_id) => {
  return `${getEnvironmentPrefix()}adv-${adv_id}`
};
