import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import ManageAdAccount from './ManageAdAccount/ManageAdAccount';
import { useAPI } from '../hooks/api';
import AdvertiserContext from '../AdvertiserContext';
import { AdAccountsTable } from './AdAccountsTable';
import { OrgChips } from '../OrgChips';
import { CreateAccountModal } from './CreateAccountModal';

const AdAccounts = ({ org }) => {
  const { useGet, useGetAll } = useAPI();

  const adContext = useContext(AdvertiserContext);
  const [currentAccount, setCurrentAccount] = useState(null);
  const isManaging = !!currentAccount;
  const isAddDialogOpen = currentAccount && !currentAccount.ad_account_id;
  const [categories, setCategories] = useState([]);
  const [adAccounts, setAdAccounts] = useState([]);

  useEffect(() => {
    if (adContext.primary_org) {
      fetchAdAccounts();
      useGetAll('/advertiser_categories/', [], items => {
        setCategories(items || []);
      });
    }
  }, [adContext?.primary_org]);

  const fetchAdAccounts = () => {
    useGet('/advertiser_users/management/').then(items => {
      setAdAccounts(items || []);
    });
  };

  const handleManageAdAccount = acct => {
    setCurrentAccount(acct);
  };

  const handleCloseAdAccount = () => {
    setCurrentAccount(null);
    fetchAdAccounts();
  };

  const handleSaveAdAccount = () => { };

  return (
    <>
      {isManaging && !isAddDialogOpen ? (
        <ManageAdAccount
          org={org}
          categories={categories}
          currentAccount={currentAccount}
          onCancel={handleCloseAdAccount}
          onSubmit={handleSaveAdAccount}
        />
      ) : (
        <Box display="flex" flexDirection="column" height="100%">
          <OrgChips org={org} />
          <AdAccountsTable
            adAccounts={adAccounts}
            handleManageAdAccount={handleManageAdAccount}
            handleAddAdAccount={() => setCurrentAccount({})}
          />
        </Box>
      )}
      {isAddDialogOpen && (
        <CreateAccountModal
          onClose={() => setCurrentAccount(null)}
          onSuccess={data => {
            setAdAccounts(s => [...s, data])
            setCurrentAccount(null);
            fetchAdAccounts();
          }}
          categories={categories}
        />
      )}
    </>
  );
};

AdAccounts.propTypes = {
  org: PropTypes.object,
};

export default AdAccounts;
