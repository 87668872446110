import React from 'react';
import { PropTypes } from 'prop-types';
import { Stack, Typography, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWarning } from '@fortawesome/pro-regular-svg-icons';

const WarningIconStyled = styled(FontAwesomeIcon, {
  shouldForwardProp: prop => prop !== 'type',
})(({ theme, type }) => ({
  color: type === 'error' ? theme.palette.red[1] : theme.palette.red[2],
  paddingTop: theme.spacing(0.375),
}));

const WarningMessageStyled = styled(Typography, {
  shouldForwardProp: prop => prop !== 'type',
})(({ theme, type }) => ({
  color: type === 'error' ? theme.palette.red[1] : theme.palette.grey[4],
}));

export const ErrorMessage = ({ children, type = 'warning', ...props }) => {
  return (
    <Stack direction="row" spacing={1} {...props}>
      <WarningIconStyled type={type} icon={faWarning} />
      <WarningMessageStyled type={type} variant="body2">
        {children}
      </WarningMessageStyled>
    </Stack>
  );
};

ErrorMessage.propTypes = {
  ...Stack.propTypes,
  children: PropTypes.node,
  type: PropTypes.oneOf(['warning', 'error']),
};
