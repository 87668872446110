import { useCurrentSession } from '../currentSession';
import useSWR, { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';

const buildPlatformInviteKey = inviteId => ({
  url: `/platform_invites/${inviteId}/`,
});

export const useGetPlatformInvite = inviteId => {
  const { getV1 } = useCurrentSession();

  const fetcher = ({ url, params }) =>
    getV1(url, { params }).then(res => res.data);

  const swr = useSWR(buildPlatformInviteKey(inviteId), fetcher, {
    suspense: true,
    revalidateOnMount: true,
  });

  const { data, error, isLoading, isValidating } = swr;

  return {
    platformInvite: data,
    error,
    isLoading,
    isValidating,
  };
};

export const useAcceptPlatformInvite = inviteId => {
  const { postV1 } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const fetcher = ({ url }, { arg }) =>
    postV1(url, arg).then(res => res.data);

  return useSWRMutation(
    { url: `/platform_invites/${inviteId}/accept/` },
    fetcher,
    {
      onSuccess: () => {
        mutate(buildPlatformInviteKey(inviteId), undefined, {
          revalidate: false,
        });
      },
    },
  );
};

export const useJoinPlatformInvite = inviteId => {
  const { postV1 } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const fetcher = ({ url }, { arg }) =>
    postV1(url, arg).then(res => res.data);

  return useSWRMutation(
    { url: `/platform_invites/${inviteId}/join/` },
    fetcher,
    {
      onSuccess: () => {
        mutate(buildPlatformInviteKey(inviteId), undefined, {
          revalidate: false,
        });
      },
    },
  );
};
