import { IconButton, Stack, styled } from '@mui/material';
import React from 'react';
import { useEdit } from './hooks/useEdit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CREATIVE_WARNINGS, MAX_TITLE_LENGTH, fields } from '../constants';
import { faClose, faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { LinkStyled } from '../../../styles';
import { EditField } from './EditField';

const CloseStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[6],
  width: theme.spacing(1.75),
  height: theme.spacing(1.75),
}));

const CloseBoxStyled = styled(Stack)(({ theme }) => ({
  paddingTop: theme.spacing(1.25),
}));

export const Edit = () => {
  const { tags, form, disabled } = useEdit();

  return (
    <Stack flex={1} spacing={2.25}>
      {tags.fields.map((currentField, index) => (
        <Stack key={currentField.id} direction="row" spacing={3}>
          <EditField
            flex={1}
            control={form.control}
            name={`${fields.vastTags.path}.${index}.name`}
            inputProps={{
              label: 'Creative Title',
            }}
            error={
              form.formState.errors[fields.vastTags.path]?.[index]?.name
                ?.message
            }
            warning={
              currentField.length > MAX_TITLE_LENGTH &&
              CREATIVE_WARNINGS.maxLength
            }
          />
          <EditField
            flex={3}
            control={form.control}
            name={`${fields.vastTags.path}.${index}.vast_tag_url`}
            inputProps={{
              label: 'VAST Tag URL',
              placeholder: 'Enter VAST Tag URL',
              multiline: true,
              minRows: 1,
            }}
            error={
              form.formState.errors[fields.vastTags.path]?.[index]
                ?.vast_tag_url?.message
            }
          />
          {tags.fields.length > 1 && (
            <CloseBoxStyled
              display="flex"
              alignItems="center"
              alignSelf="flex-start"
            >
              <IconButton
                variant="text"
                onClick={() => {
                  tags.remove(index);
                }}
              >
                <CloseStyled icon={faClose} />
              </IconButton>
            </CloseBoxStyled>
          )}
        </Stack>
      ))}
      {!disabled && (
        <LinkStyled
          onClick={() => {
            tags.append({
              title: '',
              url: '',
            });
          }}
          underline="hover"
          color="primary"
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Add another VAST Tag
        </LinkStyled>
      )}
    </Stack>
  );
};
