import { useEffect, useMemo, useState } from 'react';
import { useCreativeContext } from '../../hooks/useCreativeContext';
import { useFormContext } from 'react-hook-form';
import { fields } from '../../constants';
import { every } from 'lodash';

export const useUpload = () => {
  const [uploadingIds, setUploadingIds] = useState(new Set());
  const [isUploading, setIsUploading] = useState(false);
  const { drop, toggleAssignCreative, tab, cancelUploading } =
    useCreativeContext();
  const { watch, setValue } = useFormContext();

  const files = watch(fields.files.path);

  const visibleUploadingFiles = files.filter(({ id }) =>
    uploadingIds.has(id)
  );

  const handleDrop = files => {
    setIsUploading(true);
    drop(
      files,
      files => {
        setUploadingIds(new Set(files.map(({ id }) => id)));
      },
      ({ prev, current }) => {
        setUploadingIds(prevState => {
          const newState = new Set(prevState);
          newState.delete(prev);
          newState.add(current.id);
          return newState;
        });
      }
    ).finally(() => {
      setIsUploading(false);
    });
  };

  const handleDeleteLocal = id => {
    setValue(
      fields.files.path,
      files.filter(f => f.id !== id)
    );
  };

  const handleRemove = id => {
    handleDeleteLocal(id);
    toggleAssignCreative(
      files.find(f => f.id === id),
      false
    );
  };

  const handleCancel = id => {
    handleDeleteLocal(id);
    cancelUploading(id);
  };

  useEffect(() => {
    if (tab.value?.length) {
      setUploadingIds(new Set(tab.value));
    }
  }, [tab]);

  const uploading = isUploading;
  const processing =
    uploading &&
    every(visibleUploadingFiles, ({ progress }) => progress === 100);

  const nextButtonContent = useMemo(() => {
    if (processing) {
      return 'Processing...';
    }
    if (uploading) {
      return 'Uploading...';
    }

    return 'Next: Manage Creative';
  }, [uploading, processing]);

  return {
    drop: handleDrop,
    remove: handleRemove,
    cancel: handleCancel,
    uploading,
    processing,
    disabled: !visibleUploadingFiles.length || uploading || processing,
    nextButtonContent,
    files,
  };
};
