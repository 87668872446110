import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Button, Typography } from '@mui/material';

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'styleType'
})(({
  styleType,
  theme: {
    typography,
    spacing,
    palette
} }) => ({
  ...(styleType === 'small' && {
    minWidth: 100,
    fontSize: '.875rem'
  }),

  ...(styleType === 'regular' && {
    color: palette.secondary.main,
    fontSize: '0.9375rem',
    minWidth: 130,
    paddingLeft: spacing(2),
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: spacing(2),
  }),

  ...(styleType !== 'small' && styleType !== 'regular' && {
    ...typography.h4,
    color: '#fff',
    fontSize: '1.075rem',
    marginLeft: spacing(2),
    padding: spacing(2),
    minWidth: 200,
  })
}));

const SuccessMassage = styled(Typography)(({
  theme: { palette }
}) => ({
  color: palette.primary.light,
}));

/////////////////////////////
// ASYNC BUTTON COMPONENT
/////////////////////////////
// TODO: rewrite to use only `disabled` property instead of `isDisabled`
const AsyncButton = props => {
  const {
    children,
    textButton,
    isDisabled,
    isLoading,
    isSuccess,
    loadingButton,
    successButton,
    successMsg,
    onClick,
    type,
    nativeType,
    variant,
    classes,
    ...other
  } = props;

  const standBy = textButton ? textButton : children ? children : 'Submit';
  const success = successButton ? successButton : 'Submitted';
  const loading = loadingButton ? loadingButton : 'Submitting...';

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-end"
      style={{ textAlign: 'right' }}
      width="100%"
    >
      {isSuccess && successMsg && (
        <SuccessMassage component="span">
          {successMsg}
        </SuccessMassage>
      )}

      <StyledButton
        className={classes}
        color="secondary"
        disabled={isDisabled || isLoading}
        disableElevation={isSuccess}
        disableRipple
        endIcon={props.endIcon ? props.endIcon : false}
        onClick={onClick}
        variant={variant ? variant : 'contained'}
        styleType={type}
        type={nativeType || type}
        {...other}
      >
        {isLoading
          ? loading
          : isSuccess
          ? success
          : standBy}
      </StyledButton>
    </Box>
  );
};

AsyncButton.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool,
  children: PropTypes.node,
  textButton: PropTypes.string,
  endIcon: PropTypes.node,
  errorButton: PropTypes.string,
  loadingButton: PropTypes.string,
  successButton: PropTypes.string,
  successMsg: PropTypes.string,
  onClick: PropTypes.func,
  classes: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  nativeType: PropTypes.string,
  variant: PropTypes.string,
};

export default AsyncButton;
