const OS = 'OS';
const DEVICE = 'DEVICE';

export const TargetingTypes = {
  OS,
  DEVICE
};

export const TargetingTypeKeys = {
  [TargetingTypes.OS]: 'os',
  [TargetingTypes.DEVICE]: 'device',
};
