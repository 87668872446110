export interface Dimension {
  creative_width: number;
  creative_height: number;
  creative_size_name: string;
}

export const useDimensions = (): { dimensions: Dimension[] } => {
  const dimensions: Dimension[] = [
    {
      creative_width: 0,
      creative_height: 0,
      creative_size_name: "0x0"
    },
    {
      creative_width: 1,
      creative_height: 1,
      creative_size_name: "1x1"
    },
    {
      creative_width: 88,
      creative_height: 31,
      creative_size_name: "88x31"
    },
    {
      creative_width: 120,
      creative_height: 60,
      creative_size_name: "120x60"
    },
    {
      creative_width: 120,
      creative_height: 240,
      creative_size_name: "120x240"
    },
    {
      creative_width: 120,
      creative_height: 600,
      creative_size_name: "120x600"
    },
    {
      creative_width: 125,
      creative_height: 125,
      creative_size_name: "125x125"
    },
    {
      creative_width: 160,
      creative_height: 600,
      creative_size_name: "160x600"
    },
    {
      creative_width: 180,
      creative_height: 150,
      creative_size_name: "180x150"
    },
    {
      creative_width: 200,
      creative_height: 200,
      creative_size_name: "200x200"
    },
    {
      creative_width: 234,
      creative_height: 60,
      creative_size_name: "234x60"
    },
    {
      creative_width: 250,
      creative_height: 250,
      creative_size_name: "250x250"
    },
    {
      creative_width: 300,
      creative_height: 50,
      creative_size_name: "300x50"
    },
    {
      creative_width: 300,
      creative_height: 250,
      creative_size_name: "300x250"
    },
    {
      creative_width: 300,
      creative_height: 600,
      creative_size_name: "300x600"
    },
    {
      creative_width: 300,
      creative_height: 1050,
      creative_size_name: "300x1050"
    },
    {
      creative_width: 320,
      creative_height: 50,
      creative_size_name: "320x50"
    },
    {
      creative_width: 320,
      creative_height: 100,
      creative_size_name: "320x100"
    },
    {
      creative_width: 320,
      creative_height: 480,
      creative_size_name: "320x480"
    },
    {
      creative_width: 336,
      creative_height: 280,
      creative_size_name: "336x280"
    },
    {
      creative_width: 468,
      creative_height: 60,
      creative_size_name: "468x60"
    },
    {
      creative_width: 480,
      creative_height: 320,
      creative_size_name: "480x320"
    },
    {
      creative_width: 728,
      creative_height: 90,
      creative_size_name: "728x90"
    },
    {
      creative_width: 768,
      creative_height: 1024,
      creative_size_name: "768x1024"
    },
    {
      creative_width: 970,
      creative_height: 90,
      creative_size_name: "970x90"
    },
    {
      creative_width: 970,
      creative_height: 250,
      creative_size_name: "970x250"
    },
    {
      creative_width: 1024,
      creative_height: 768,
      creative_size_name: "1024x768"
    }
  ];

  return {
    dimensions
  };
};
