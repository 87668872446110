import { useContext } from 'react';
import AdvertiserContext from '../AdvertiserContext';
import { Tenants, Themes } from '../../constants';

// Define the structure of a tenant
interface Tenant {
  id: number;
  url: string;
}

export const useTenant = (): Tenant => {
  const adContext = useContext(AdvertiserContext);
  return Tenants[adContext.theme as keyof typeof Themes];
};
