import { ReactNode, useContext, useMemo } from 'react';
import AdvertiserContext from '../AdvertiserContext';
import { Themes } from '../../constants';

type CopyDictionary = {
  [key: string]: ReactNode;
};

type ThemeValue = (typeof Themes)[keyof typeof Themes];

type CopiesType = {
  //@ts-ignore
  [key in Themes]: CopyDictionary;
};

export const useCopy = (copies: CopiesType) => {
  const adContext = useContext(AdvertiserContext);

  return useMemo(() => {
    const copy = Object.keys(copies[Themes.DEFAULT]).reduce(
      (acc: CopyDictionary, key) => {
        // We explicitly check for `undefined` here because the theme copy
        // might be `null`, which should be preserved as meaning "don't show
        // any copy here", whereas `undefined` means "use the default copy
        // here"
        acc[key] =
          copies[adContext.theme][key] !== undefined
            ? copies[adContext.theme][key]
            : copies[Themes.DEFAULT][key];
        return acc;
      },
      {},
    );
    return copy;
  }, [adContext.theme, copies]);
};

export const useCopyNested = <T extends ThemeValue, C = any>(
  copies: Record<T, C>,
): C => {
  const adContext = useContext(AdvertiserContext);
  let key = (adContext.theme ?? Themes.DEFAULT) as T;
  return copies[key];
};
