import React from 'react';
import { formatEndDate } from '@utils/date';
import { TableCell, TableRow } from '@mui/material';
import moment from 'moment';
import { useGetCampaign } from '@components/hooks/apis/campaigns';
import PropTypes from 'prop-types';

export const CreativeDetail = ({ detail }) => {
  const { campaign } = useGetCampaign(
    detail.campaign.split('/campaigns/')[1].slice(0, -1),
  );

  return (
    <TableRow tabIndex={-1}>
      <TableCell align="left">{campaign?.name}</TableCell>
      <TableCell align="left">{detail.name}</TableCell>
      <TableCell align="left">
        {moment(detail.start_date).format('l')}
      </TableCell>
      <TableCell align="left">{formatEndDate(detail.end_date)}</TableCell>
    </TableRow>
  );
};

CreativeDetail.propTypes = {
  detail: PropTypes.object,
};
