import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Chip,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Check from '@mui/icons-material/Check';

const PREFIX = 'BillingAccountsTable';

const classes = {
  check: `${PREFIX}-check`,
  chip: `${PREFIX}-chip`,
  activeChip: `${PREFIX}-activeChip`,
  activeCheck: `${PREFIX}-activeCheck`,
  pendingChip: `${PREFIX}-pendingChip`,
  rowHover: `${PREFIX}-rowHover`,
  tableCell: `${PREFIX}-tableCell`,
  tableHeaderRow: `${PREFIX}-tableHeaderRow`,
  tableHeaderCell: `${PREFIX}-tableHeaderCell`,
  tableBodyCell: `${PREFIX}-tableBodyCell`,
  add: `${PREFIX}-add`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({
  theme: { palette, spacing }
}) => ({
  [`& .${classes.check}`]: {
    width: 20,
    height: 20,
  },

  [`& .${classes.chip}`]: {
    marginLeft: spacing(2),
    padding: spacing(1),
    fontWeight: 500,
  },

  [`& .${classes.activeChip}`]: {
    backgroundColor: '#c2f1e3',
    color: '#05734e',
  },

  [`& .${classes.activeCheck}`]: {
    color: palette.common.green,
  },

  [`& .${classes.pendingChip}`]: {
    backgroundColor: palette.error.main,
    color: palette.common.white,
  },

  [`& .${classes.rowHover}`]: {
    '&:hover': {
      backgroundColor: '#d9f1ff',
    },
  },

  [`& .${classes.tableCell}`]: {
    minHeight: 81,
  },

  [`& .${classes.tableHeaderRow}`]: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },

  [`& .${classes.tableHeaderCell}`]: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    color: '#13171a',
    fontSize: '0.875rem',
    fontWeight: 'bold'
  },

  [`& .${classes.tableBodyCell}`]: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    color: '#13171a',
    fontWeight: 100,
    textOverflow: 'ellipsis',
  },

  [`& .${classes.add}`]: {
    color: '#1dafff',
    display: 'flex',
    alignItems: 'center',
    marginTop: '16px',
    textDecoration: 'none'
  }
}));

const BillingAccountsTable = ({
  billingAccounts,
  handleManageBillingAccount,
  handleAddBillingAccount,
}) => {


  const headCells = [
    { id: 'Billing Account', label: 'Billing Account' },
    { id: 'Primary Payment Method', label: 'Primary Payment Method' },
    { id: 'Billing Contact', label: 'Billing Contact' },
    { id: 'Account Standing', label: 'Account Standing' },
    { id: 'Action', label: 'Action' },
  ];

  return (
    (<Root>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {
                headCells.map(headCell => (
                  <TableCell
                    key={headCell.id}
                    className={clsx(classes.tableCell, classes.tableHeaderCell)}
                  >
                    {headCell.label}
                  </TableCell>
                ))
              }
            </TableRow>
          </TableHead>

          <TableBody>
            {billingAccounts?.map((acct, index) => (
              <TableRow key={index} className={classes.rowHover}>
                {
                  [
                    acct.name,
                    acct.billing_method,
                    `${acct?.secondary_first_name} ${acct?.secondary_last_name}`,
                  ].map((content, idx) => (
                    <TableCell key={idx} className={clsx(classes.tableCell, classes.tableBodyCell)}>{content}</TableCell>
                  ))
                }
                <TableCell className={clsx(classes.tableCell, classes.tableBodyCell)}>
                  <Chip
                    label={"Active"}
                    className={clsx(classes.chip, classes.activeChip)}
                    icon={ <Check className={clsx(classes.check, classes.activeCheck)}/>}
                  />
                </TableCell>
                <TableCell className={clsx(classes.tableCell, classes.tableBodyCell)}>
                  <Button
                    onClick={() => handleManageBillingAccount(acct)}
                    color="secondary"
                  >
                    Manage
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={1} ml={1}>
          <Button
            color="secondary"
            size="small"
            startIcon={<AddCircleOutlineIcon fontSize="small" />}
            onClick={handleAddBillingAccount}
            className={classes.add}
          >
            Add Billing Account
          </Button>
      </Box>
    </Root>)
  );
};

BillingAccountsTable.propTypes = {
  billingAccounts: PropTypes.array.isRequired,
  handleManageBillingAccount: PropTypes.func.isRequired,
  handleAddBillingAccount: PropTypes.func.isRequired,
};

export default BillingAccountsTable;
