import { styled } from '@mui/material/styles';

export const SuccessSnackbar = styled('div')(() => ({
  backgroundColor: '#0fbf84',
  color: '#fff',
  fontSize: '0.875rem',
  fontWeight: 400,
  letterSpacing: '.35px',
  maxWidth: 400,
}));
