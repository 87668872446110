import React from 'react';
import PropTypes from 'prop-types';

import { AccordionActions, Stack } from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDownLeft } from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation } from '@fortawesome/pro-solid-svg-icons';
import { useWizardContext } from '../../hooks/useWizardContext';
import SectionAction from './SectionAction';
import {
  TriangleExclamationIconStyled,
  ValidationButtonStyled,
} from './styles';

export const SectionActions = ({
  nextCallback: nextCallbackProp,
  nextLabel: nextLabelProp,
  nextDisabled = false,
  prevDisabled = false,
  prevCallback: prevCallbackProp,
  prevLabel: prevLabelProp,
  sectionKey,
  showValidator,
  validatorCallback,
}) => {
  const { navigation } = useWizardContext();
  const { goToNext, goToPrev, hasNext, hasPrev } = navigation;

  const nextLabel = nextLabelProp || 'next';
  const prevLabel = prevLabelProp || 'prev';

  const nextCallback = nextCallbackProp || goToNext;
  const prevCallback = prevCallbackProp || goToPrev;

  const nextEnabled = !nextDisabled && (hasNext || nextCallbackProp);
  const prevEnabled = !prevDisabled && (hasPrev || prevCallbackProp);

  return (
    <AccordionActions
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <SectionAction
        label={prevLabel}
        handler={() => prevCallback({ sectionKey, navigation })}
        disabled={!prevEnabled}
        data-testid="prev-button"
      />
      <Stack direction="row" spacing={3}>
        {showValidator && (
          <ValidationButtonStyled
            onClick={() => validatorCallback()}
            size="small"
          >
            <TriangleExclamationIconStyled icon={faTriangleExclamation} />
            Highlight missing/incorrect fields
          </ValidationButtonStyled>
        )}
        <SectionAction
          label={nextLabel}
          variant="contained"
          handler={() => nextCallback({ sectionKey, navigation })}
          disabled={!nextEnabled}
          endIcon={<FontAwesomeIcon icon={faArrowTurnDownLeft} />}
          data-testid="next-button"
        />
      </Stack>
    </AccordionActions>
  );
};

SectionActions.propTypes = {
  nextCallback: PropTypes.func,
  nextLabel: PropTypes.string,
  prevCallback: PropTypes.func,
  prevLabel: PropTypes.string,
  sectionKey: PropTypes.string,
  nextDisabled: PropTypes.bool,
  prevDisabled: PropTypes.bool,
  showValidator: PropTypes.bool,
  validatorCallback: PropTypes.func,
};
