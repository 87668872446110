import useSWR from 'swr';
import { useCurrentSession } from '../currentSession';
import { useFlags } from '../flags';

export const useGetRecommendedInventory = (campaignId?: number) => {
  const { apiIsReady, get } = useCurrentSession();
  const { flags, Flags } = useFlags();
  const recommendationsAllowed =
    flags[Flags.USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS];

  const fetcher = async ({
    url,
    params,
  }: {
    url: string;
    params: { campaign_id?: number };
  }): Promise<number[]> => get(url, { ...params }).then(res => res.data);

  const url = 'quickstart/inventory_recommendations/';

  return useSWR(
    apiIsReady && recommendationsAllowed
      ? { url, params: { campaign_id: campaignId } }
      : null,
    fetcher,
  );
};
