import React, { forwardRef } from 'react';
import { MenuItem } from '@mui/material';
import { TextFieldStyled } from '../styles';
import { EventPickerProps, CustomInputLabelProps } from './types';

const EventPicker = forwardRef<HTMLDivElement, EventPickerProps>(
  ({ eventSet, id, label, value, ...props }, ref) => {
    return (
      <TextFieldStyled
        {...props}
        value={value}
        sx={{ marginLeft: 0, marginBottom: 2.5 }}
        ref={ref}
        select
        fullWidth
        InputLabelProps={
          { 'data-testid': id, shrink: !!value } as CustomInputLabelProps
        }
        id={id}
        label={label}
      >
        {eventSet?.map(({ value, name }, idx) => (
          <MenuItem
            key={idx}
            value={value}
            data-testid={`${id}-${idx}`}
          >
            {name}
          </MenuItem>
        ))}
      </TextFieldStyled>
    );
  });

EventPicker.displayName = 'EventPicker';

export default EventPicker;
