import { faUserGroup } from '@fortawesome/pro-regular-svg-icons';
import React, { Fragment } from 'react';
import {
  SectionPreviewIcon,
  SectionPreviewTypography,
  SectionSubTitle,
  sectionProps,
  useWizardPreview,
} from '../../Wizard';
import { useGroupSectionTitles } from './hooks/useGroupSectionTitles';

const GroupSectionPreview = ({ sectionKey, group }) => {
  const { active } = useWizardPreview({ key: sectionKey });
  const { title, subtitle } = useGroupSectionTitles({ group });
  return (
    <Fragment>
      <SectionPreviewIcon isActive={active} icon={faUserGroup} />
      <SectionPreviewTypography isActive={active}>
        {subtitle && (
          <SectionSubTitle>{subtitle}</SectionSubTitle>
        )}
        {title}
      </SectionPreviewTypography>
    </Fragment>
  );
};

GroupSectionPreview.propTypes = {
  ...sectionProps,
};

export default GroupSectionPreview;
