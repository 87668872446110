import React from 'react';
import PropTypes from 'prop-types';
import { Typography, styled } from '@mui/material';

const Ellipsed = styled(Typography)`
  ${({ disabled }) =>
    disabled
      ? ''
      : `
  white-space: nowrap;`}
  display: inline-block;
  width: 100%;
  ${({ disabled }) =>
    disabled
      ? ''
      : `
  text-overflow: ellipsis;
  overflow: hidden;
  `}
`;

const Text = ({ children, ellipsis = false, ...props }) => {
  return (
    <Ellipsed disabled={!ellipsis} {...props}>
      {children}
    </Ellipsed>
  );
};

Text.propTypes = {
  children: PropTypes.node,
  ellipsis: PropTypes.bool,
  props: PropTypes.shape({ ...Typography.propTypes }),
};

export default Text;
