import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Box, Button, Stack, Typography, styled } from '@mui/material';
import { CloudFileUploadIcon } from '@v2/components/icons/CloudFileUploadIcon';

const Dropzone = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDragActive',
})(({ theme, isDragActive }) => ({
  border: `3px dashed ${isDragActive ? theme.palette.primary.main : theme.palette.grey[9]}`,
  backgroundColor: isDragActive
    ? theme.palette.blue[11]
    : theme.palette.background.paper,
  borderRadius: 5,
  padding: theme.spacing(12),
  cursor: 'pointer',
}));

const RecommendedFileFormat = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[6],
  textAlign: 'center',
  fontSize: '0.938rem',
}));

const FileDropzone = ({ onDrop, ...props }) => {
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: 'video/mp4, video/x-m4v, video/*',
    onDrop,
  });

  return (
    <Dropzone
      {...props}
      isDragActive={isDragActive}
      {...getRootProps()}
    >
      <input {...getInputProps()} />

      <Stack spacing={3} alignItems="center">
        <CloudFileUploadIcon sx={{ width: '120px', height: '93px' }} />
        <Typography variant="h3">
          Drag your video files here, or
        </Typography>

        <div>
          <Button variant="outlined">
            Select Files
          </Button>
        </div>

        <RecommendedFileFormat variant="body2">
          Recommended File Format: MOV or MP4 with Video Ad-ID | Min
          Dimension: 1920x1080 | Min Bit Rate: 15 Mbps | Max File Size: 10
          GB
        </RecommendedFileFormat>
      </Stack>
    </Dropzone>
  );
};

FileDropzone.propTypes = {
  ...Box.propTypes,
  onDrop: PropTypes.func.isRequired,
};

export default FileDropzone;
