import useSWR from 'swr';
import { useCurrentSession } from '../currentSession';

export const useGetAllBidStrategies = () => {
  const { getV1, apiIsReady } = useCurrentSession();
  const fetcher = async ({ url }) => {
    try {
      const response = await getV1(url);
      return response.data;
    } catch (error) {
      console.log('Failed to fetch bid strategies:', error);
      return {};
    }
  };
  const swr = useSWR(
    apiIsReady
      ? {
          url: '/bid_strategies/',
        }
      : null,
    fetcher,
  );

  const { data, error, isLoading } = swr;

  return {
    data: data?.results ?? [],
    error,
    isLoading,
  };
};

export const useBidStrategies = (bidStrategiesId, params = {}) => {
  const { getV1, apiIsReady } = useCurrentSession();
  const fetcher = async ({ url, params }) => {
    try {
      const response = await getV1(url, { params });
      return response.data;
    } catch (error) {
      console.log('Failed to fetch bid strategies:', error);
      return {};
    }
  };
  const swr = useSWR(
    bidStrategiesId && apiIsReady
      ? {
          url: `/bid_strategies/${bidStrategiesId}/`,
          params,
        }
      : null,
    fetcher,
  );

  const { data, error, isLoading } = swr;

  return {
    bidStrategy: data,
    error,
    isLoading,
  };
};
