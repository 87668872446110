import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Button,  Grid, Paper, Tabs, Tab } from '@mui/material';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useSnackbar } from 'notistack';
import { useFieldArray, useFormContext } from 'react-hook-form';
import CampaignFooter from './CampaignFooter';
import DisplayLibrary from './DisplayLibrary';
import FileDropzone from './FileDropzone';
import FileProgress from './FileProgress';
import ImageSpecifications from './ImageSpecifications';
import Title from './Title';
import { useUpload } from './hooks/upload';
import { useAPI } from './hooks/api';
import { useCopy } from './hooks';
import { useLoader } from './hooks/loader';
import { Themes } from '../constants';

const PREFIX = 'DisplayUploader';

const classes = {
  container: `${PREFIX}-container`,
  divider: `${PREFIX}-divider`,
  files: `${PREFIX}-files`,
  main: `${PREFIX}-main`,
  tabs: `${PREFIX}-tabs`
};

const Root = styled('form')(({
  theme: { spacing }
}) => ({
  [`& .${classes.container}`]: {
    height: '100%',
    marginBottom: spacing(0),
  },

  [`& .${classes.divider}`]: {
    marginBottom: spacing(4),
  },

  [`& .${classes.files}`]: {
    paddingLeft: spacing(4),
    paddingRight: spacing(4),
    paddingTop: spacing(4),
    marginTop: spacing(4),
    maxHeight: 422,
    overflow: 'auto',
    width: '100%',
  },

  [`& .${classes.main}`]: {
    height: '100%',
  },

  [`& .${classes.tabs}`]: {
    borderBottom: `1px solid #e0e0e0`,
    width: `100%`,
    position: `relative`,
  }
}));

const Copies = {
  [Themes.DEFAULT]: {
    FILE_DROPZONE_DESCRIPTION: 'Drag Your Display Files here, or',
  },
  [Themes.NBCU]: {
    FILE_DROPZONE_DESCRIPTION: 'Drag Your Display Files here or',
  }
};

const DisplayUploader = props => {

  const { enqueueSnackbar } = useSnackbar();

  const { usePost } = useAPI();
  const { isLoading } = useLoader();
  const { control } = useFormContext();
  const Copy = useCopy(Copies);

  const { fields, replace } = useFieldArray({
    name: "displays",
    keyName: 'internalId',
    control,
  });

  const {
    formatFileName,
    deleteFile,
    isUploading,
    progressBars,
    uploadError,
    setIsUploading,
    setIsUploadSuccess,
    setUploadError,
    uploadDisplay,
  } = useUpload({
    uploadMetadataState: [
      fields,
      replace,
    ],
  });

  const {
    isSubmitDisabled,
    handleBreadcrumbsStatus,
    setCurrentPreview,
    setIsDisplayPreview,
    onSubmit,
    onBack,
  } = props;

  const [tab, setTab] = useState(0);
  const [uploadView, setUploadView] = useState('start');

  useEffect(() => {
    handleBreadcrumbsStatus('creative', 75);
  }, []);

  useEffect(() => {
    const nextView = fields && fields.length > 0 ? 'progress' : 'start';

    setUploadView(nextView);
 }, [fields]);

  useEffect(() => {
    handleErrorMessage(uploadError);
  }, [uploadError]);

  const handleErrorMessage = async error => {
    if (error) {
      await enqueueSnackbar(
        error,
        {
          variant: 'error',
          autoHideDuration: 10000,
          preventDuplicate: true,
        }
      )
    }
  }

  const handleSetTab = (event, newTab) => {
    setTab(newTab);
  };

  const handleBatchAssign = files => {
    if (files.length > 0) {
      replace([...fields, ...files]);
    }
  };

  const handleUnassign = file => {
    replace(fields.filter(p => p.url !== file.url));
  };

  const handleDrop = acceptedFiles => {
    setUploadError(null);
    setIsUploadSuccess(false);
    setUploadView('progress');

    return uploadDisplay(acceptedFiles)
      .then(responses => {
        // Handle upload errors
        if (responses && responses.response) {
          return Promise.reject(responses.response);
        }

        const displayPromises = responses.map(r => {
          const displayData = {
            active: true,
            advertiser: r.data.advertiser,
            image_asset: r.data.url,
            name: r.data.name,
          };

          return usePost('/static_display_creatives/', displayData);
        });

        return displayPromises;
      })
      .then(responses => {
        // handle success from file upload
        console.log('Upload success!');

        return Promise.all(responses)
          .then(displayRes => {
            const displaysArray = [];

            displayRes.forEach(r => {
              displaysArray.push(r.data);
            });

            replace([...fields, ...displaysArray]);

            setIsUploading(false);
            return displayRes;
          })
          .catch(error => {
            console.error(error)
            return error;
          });
      })
      .catch(error => {
        // handle error
        console.log('Upload failed!');
        console.log('Error in uploading display', error);
        setIsUploading(false);
        setIsUploadSuccess(false);

        if (uploadError === null) {
          setUploadError(`There was an error uploading your display. Please try again.`);
        }
      });
  };

  // Removes file from metadata array and HOC displays prop
  const handleRemoveFile = (id) => {
    replace(fields.filter(d => d.id !== id));

    return deleteFile(`/static_display_creatives/${id}`)
      .then(response => {
        console.log('response from removing display', response);
      })
      .catch(error => console.log('Error deleting display:', error));
  };

  return (
    <Root onSubmit={onSubmit}>
      <Grid className={classes.container} container>
        <Grid item xs={12}>
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="space-between"
          >
            <Box
              className={classes.main}
              flexGrow={1}
              width="100%"
              height="100%"
            >
              <Box mb={4} width="100%">
                <Tabs
                  className={classes.tabs}
                  onChange={handleSetTab}
                  value={tab}
                >
                  <Tab label="Upload Display" />
                  <Tab label="Display Media Library" />
                </Tabs>
              </Box>

              {tab === 0 && (
                <Box width="100%">
                  {uploadView === 'start' && (
                    <FileDropzone
                      accept="image/jpeg, image/png, image/gif"
                      handleDrop={handleDrop}
                      description={Copy.FILE_DROPZONE_DESCRIPTION}
                      specifications={<ImageSpecifications />}
                    />
                  )}

                  {uploadView === 'progress' && (
                    <Grid container>
                      <Title>Add title</Title>

                      <Grid container>
                        <Paper className={classes.files} variant="outlined">
                          {fields.length > 0 && fields.map((meta, index) => {
                            const name = meta.fileName || meta.name;

                            return (
                              <FileProgress
                                index={index}
                                fileId={meta.id}
                                fileSize={meta.fileSize}
                                formatFileName={formatFileName}
                                handleRemoveFile={handleRemoveFile}
                                key={meta.internalId}
                                name={name}
                                progressBars={progressBars}
                                resolution={null}
                                control={control}
                                type="image"
                                field="displays"
                              />
                            );
                          })}
                        </Paper>
                      </Grid>

                      <Grid container>
                        <Box mt={4}>
                          <Button
                            color="secondary"
                            onClick={() => {
                              setUploadView('start');
                            }}
                            size="small"
                            startIcon={<CloudUploadOutlinedIcon />}
                          >
                            Upload More Displays
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                </Box>
              )}

              {tab === 1 && (
                <DisplayLibrary
                  assigned={fields}
                  handleBatchAssign={handleBatchAssign}
                  handleUnassign={handleUnassign}
                  setCurrentPreview={setCurrentPreview}
                  setIsPreview={setIsDisplayPreview}
                />
              )}
            </Box>

            <Box mt={4}>
              <Grid container item xs={12}>
                <CampaignFooter
                  nativeType="submit"
                  isDisabled={isSubmitDisabled || isUploading}
                  isLoading={isLoading}
                  back={'Upload Creative'}
                  endIcon={<RefreshIcon className={classes.refresh} />}
                  next={'Save & Create A New Ad Group'}
                  onBack={onBack}
                />
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Root>
  );
};

DisplayUploader.propTypes = {
  isSubmitDisabled: PropTypes.bool,
  handleBreadcrumbsStatus: PropTypes.func,
  setCurrentPreview: PropTypes.func,
  setIsDisplayPreview: PropTypes.func,
  onSubmit: PropTypes.func,
  onBack: PropTypes.func,
};

export default DisplayUploader;
