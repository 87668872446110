import { values } from 'lodash';
import {
  customInventoryTabValues,
  inventoryCategoryName,
} from '../constants';

export const customInventoryTypes = {
  [customInventoryTabValues.appName]: 'app_name',
  [customInventoryTabValues.appBundleList]: 'app_bundle_list',
  [customInventoryTabValues.dealId]: 'deal_id',
};

export const customInventoryAccessibleTypes = values(inventoryCategoryName);
