import React, { useContext, useEffect, } from 'react'
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types'
import MaUTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { useSnackbar } from 'notistack'

import TableToolbar from './PaymentMethodsTableToolbar'
import { useAdvertisers } from './hooks/advertisers';
import AdvertiserContext from './AdvertiserContext';

const PREFIX = 'EnhancedTable';

const classes = {
  root: `${PREFIX}-root`,
  hover: `${PREFIX}-hover`,
  root2: `${PREFIX}-root2`
};

const StyledTableContainer = styled(TableContainer)({
  [`& .${classes.root}`]: {
    height: 50
  },

  [`& .${classes.hover}`]: {
    "&$hover:hover": {
      backgroundColor: 'rgba(33, 150, 243, .2)',
    },
  },

  [`& .${classes.root2}`]: {
    paddingTop: 0,
    paddingBottom: 0
  }
});

const StyledTableRow = TableRow;

const StyledTableCell = TableCell;

const EnhancedTable = ({
  columns,
  data,
  setData,
  updateMyData,
  skipPageReset,
  setIsEnterCard,
}) => {

  const { enqueueSnackbar } = useSnackbar();
  const [selectedRowId, setSelectedRowId] = React.useState(null);
  const { setDefaultPayment } = useAdvertisers();
  const adContext = useContext(AdvertiserContext);

  useEffect(() => {
    if (adContext.default_payment_profile === null && data.length !== 0) {
      setDefault(data[0].url)
    } else {
      setSelectedRowId(adContext.default_payment_profile)
    }
  }, [])

  const handleClick = async (url) => {
    await setDefaultPayment(url)
    adContext.default_payment_profile = url
    setSelectedRowId(url)
    enqueueSnackbar('Payment method updated', {
      autoHideDuration: 3000,
      variant: 'success',
    });
  }

  const setDefault = async (url) => {
    await setDefaultPayment(url)
    setSelectedRowId(url)
  }

  useEffect(() => {
    if (adContext.default_payment_profile === null && data.length !== 0) {
      setDefault(data[0].url)
    }
  }, [data])

  useEffect(() => {
    setSelectedRowId(adContext.default_payment_profile)
  }, [adContext])

  const {
    getTableProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      autoResetSelectedRows: false,
      columns,
      data,
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          Header: "Default",
          id: 'url',
          Cell: function Cell({ row }) {
            return (
              <div>
                <FormControlLabel value={row.original.url} control={<Radio color="secondary" />} />
              </div>
            )
          },
        },
        ...columns,
      ])
    }
  )

  const addNewPaymentMethod = user => {
    const newData = data.concat([user])
    setData(newData)
  }

  return (
    <StyledTableContainer>
      <TableToolbar
        setIsEnterCard={setIsEnterCard}
        addNewPaymentMethod={addNewPaymentMethod}
      />
      <RadioGroup aria-label="default" name="defaultPayment" value={selectedRowId} onChange={(e, value) => { handleClick(value) }}>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup, idx) => (
              <TableRow key={idx} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <TableCell key={column.id} colSpan={column.id === 'cardNumber' ? '4' : '1'}>
                    {column.render('Header')}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>

          <TableBody>
            {page.map((row, idx) => {
              prepareRow(row)
              return (
                <StyledTableRow
                  hover={true}
                  key={idx}
                  {...row.getRowProps()}
                  classes={{
                    root: classes.root,
                    hover: classes.hover
                  }}>
                  {row.cells.map(cell => {
                    return (
                      <StyledTableCell
                        key={cell.column.id}
                        colSpan={cell.column.id === 'cardNumber' ? '4' : '1'}
                        {...cell.getCellProps()}
                        classes={{
                          root: classes.root2
                        }}>
                        {cell.render('Cell')}
                      </StyledTableCell>
                    );
                  })}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      </RadioGroup>
    </StyledTableContainer>
  );
}

EnhancedTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  updateMyData: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
  setIsEnterCard: PropTypes.func.isRequired,
  row: PropTypes.object
}

export default EnhancedTable
