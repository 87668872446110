import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListItem,
  Popper,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ContentEditable from 'react-contenteditable';

export const StyledBox = styled(Stack)(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.palette.background.paper,
}));

export const GeographyBox = styled(Stack)(({ theme }) => ({
  name: 'box-geography-selector',
  position: 'relative',
  width: '100%',
  backgroundColor: theme.palette.blue[11],
  borderRadius: theme.shape.borderRadius,
  '&:hover .close-icon': {
    display: 'block',
  },
}));

export const TextFieldStyled = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    color: 'text.primary',
    fontSize: '1rem',
    fontWeight: 500,
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'transparent',
    '& fieldset': {
      borderColor: 'transparent',
    },
    '&:hover fieldset': {
      borderColor: 'blue.6',
    },
    '&.Mui-focused': {
      backgroundColor: 'white',
    },
    '&.Mui-focused fieldset': {
      borderWidth: 1,
    },
  },
  '.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiOutlinedInput-root':
    {
      paddingRight: 0,
    },
}));

export const StyledSelect = styled(Select)({
  '& .MuiSelect-select': {
    paddingBottom: 0,
    paddingTop: 0,
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
});

export const StyledIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  fontSize: '22px',

  '&.fa-location-minus': {
    color: theme.palette.red[1],
  },

  '&.fa-location-plus': {
    color: theme.palette.blue[12],
  },
}));

export const StyledDropzone = styled(Stack, {
  shouldForwardProp: prop => prop !== 'isDragActive',
})<{
  isDragActive: boolean;
}>(({ theme, isDragActive }) => ({
  border: `${theme.spacing(0.375)} dashed ${isDragActive ? theme.palette.primary.main : theme.palette.blue[6]}`,
  backgroundColor: theme.palette.blue[11],
  borderRadius: theme.spacing(2.75),
  padding: theme.spacing(12),
  textAlign: 'center',
  cursor: 'pointer',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 10,
  opacity: 0.95,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
}));

export const StyledUploadText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  fontSize: '1.5rem',
  fontWeight: 700,
  lineHeight: '2rem',
}));

export const StyledEditor = styled(ContentEditable, {
  shouldForwardProp: prop => prop !== 'error',
})<{
  error: boolean;
}>(({ theme }) => ({
  flex: 1,
  border: `${theme.spacing(0.125)} solid ${theme.palette.grey[3]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2, 2.5),
  resize: 'vertical',
  outline: 'none',
  overflow: 'auto',
  '&:hover': {
    borderColor: `${theme.palette.blue[6]}`,
  },
  '&:active': {
    borderColor: `${theme.palette.blue[6]}`,
  },
  span: {
    '&.errored': {
      color: theme.palette.red[1],
    },
  },
}));

export const StyledChipSelector = styled(Stack)(({ theme }) => ({
  cursor: 'text',
  flex: 1,
  border: `${theme.spacing(0.125)} solid ${theme.palette.grey[3]}`,
  borderRadius: theme.spacing(1),
  padding: theme.spacing(2, 2.5),
  resize: 'vertical',
  outline: 'none',
  overflow: 'auto',
  '&:hover': {
    borderColor: `${theme.palette.blue[6]}`,
  },
  '&:active': {
    borderColor: `${theme.palette.blue[6]}`,
  },
  span: {
    '&.errored': {
      color: theme.palette.red[1],
    },
  },
}));

export const StyledChipSelectorInner = styled(Stack)(({ theme }) => ({
  flexWrap: 'wrap',
  flexDirection: 'row',
  gap: theme.spacing(1),
}));

export const StyledChipSelectorItem = styled(Stack)<{
  errored: boolean;
  focused: boolean;
}>(({ theme, errored, focused }) => ({
  flexDirection: 'row',
  display: 'inline-flex',
  gap: theme.spacing(1.25),
  height: theme.spacing(4.5),
  padding: theme.spacing(0.5, 2),
  borderRadius: theme.spacing(2.4375),
  border: focused
    ? `${theme.spacing(0.125)} solid ${theme.palette.blue[6]}`
    : 'none',

  background: focused
    ? theme.palette.common.white
    : errored
      ? theme.palette.red[5]
      : theme.palette.grey[11],
  color: errored ? theme.palette.red[1] : theme.palette.text.primary,
  justifyContent: 'center',
  alignItems: 'center',

  '&:focus': {
    border: `${theme.spacing(0.125)} solid ${theme.palette.blue[6]}`,
    outline: 'none',
  },
}));

export const StyledChipSelectorItemRemover = styled(FontAwesomeIcon)<{
  errored: boolean;
}>(({ theme, errored }) => ({
  cursor: 'pointer',
  color: errored ? theme.palette.red[4] : theme.palette.grey[5],
}));

export const StyledTransparentInput = styled('input')(({ theme }) => ({
  border: 'none',
  outline: 'none',
  backgroundColor: 'transparent',
  color: theme.palette.text.primary,
  fontSize: theme.typography.body1.fontSize,
  fontWeight: theme.typography.body1.fontWeight,
}));

export const StyledChipSelectorItemInput = styled(StyledTransparentInput)(
  () => ({
    width: '100%',
    height: '100%',
  }),
);

export const StyledPopper = styled(Popper)(({ theme }) => ({
  background: theme.palette.common.offWhite,
  borderRadius: theme.spacing(1.25),
  boxShadow: `0px 3px 14px 2px #0000001F`,
}));

export const StyledListItem = styled(ListItem)<{ active: boolean }>(
  ({ theme, active }) => ({
    cursor: 'pointer',
    padding: theme.spacing(0.5, 2),
    height: theme.spacing(6.25),
    backgroundColor: active
      ? theme.palette.grey[11]
      : theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.grey[11],
    },
  }),
);
