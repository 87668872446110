import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';

const SectionAction = ({ label, handler, disabled, ...props }) => {
  return (
    <Button onClick={handler} disabled={disabled} {...props}>
      {label}
    </Button>
  );
};

SectionAction.propTypes = {
  ...Button.propTypes,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  handler: PropTypes.func,
};

export default SectionAction;
