import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import { Alert, Collapse, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningIcon from '@mui/icons-material/Warning';

const PREFIX = 'AlertBox';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`,
  description: `${PREFIX}-description`,
  delimiter: `${PREFIX}-delimiter`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  },

  [`& .${classes.title}`]: {
    fontWeight: 'bold',
    lineHeight: 1.5,
  },

  [`& .${classes.description}`]: {
    display: 'flex',
    fontWeight: 'normal',
    gap: '0.25rem',
  },

  [`& .${classes.delimiter}`]: {
    userSelect: 'none',
  }
}));

const severities = {
  PARTIAL: "warning",
  RELEASE: "info",
  MAJOR: "error",
}

const alertIcons = {
  RELEASE: <InfoOutlinedIcon/>,
  PARTIAL: <WarningIcon/>,
};

const AlertBox = (props) => (
  <Root className={classes.root}>
    <Collapse in={props.isAlertOpen}>
      <Alert
        variant="filled"
        icon={alertIcons[props.type] || <InfoOutlinedIcon/>}
        color={severities[props.type]}
        action={
          props.action ? props.action : (
            props.closeable && (
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  props.closeAlert(false);
                }}
              >
                <CloseIcon fontSize={"inherit"}/>
              </IconButton>
            )
          )
        }
      >
          <span className={classes.description}>
            <span className={classes.title}>{props.title}</span>
            <span className={classes.delimiter}>-</span>
            {/*
              https://stackoverflow.com/a/44970199 -- Found a super hacky solution
              Splits the message amongst all substrings that match the pattern, url indexes will always be an odd number
              If the index is odd, then we make it into an anchor tag
              If the index is even, we just return the text
            */}
            {
              props.message
                .split(/(https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*))/g)
                .map((part, index) => index % 2 === 0 ? part : <a href={part} target="_blank" rel="noopener noreferrer">{part}</a>)
            }
          </span>
      </Alert>
    </Collapse>
  </Root>
);

AlertBox.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  type: PropTypes.oneOf(['PARTIAL', 'RELEASE', 'MAJOR']),
  closeable: PropTypes.bool,
  isAlertOpen: PropTypes.bool,
  closeAlert: PropTypes.func,
  action: PropTypes.object
}

export default AlertBox;
