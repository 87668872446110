import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import fbIcon from '../../../images/logo-facebook.png';
import googleIcon from '../../../images/logo-google-ads.png';
import igIcon from '../../../images/logo-instagram.png';
import scIcon from '../../../images/logo-snapchat.png';
import { Box, Checkbox, InputAdornment, TextField, Typography } from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

const PREFIX = 'ChannelItem';

const classes = {
  channelRow: `${PREFIX}-channelRow`,
  iconWrap: `${PREFIX}-iconWrap`,
  label: `${PREFIX}-label`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`&.${classes.channelRow}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  [`& .${classes.iconWrap}`]: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(2),
  },

  [`& .${classes.label}`]: {
    fontSize: '.875rem',
  }
}));

const channelIcons = {
  'Facebook Ads': fbIcon,
  'Google Ads': googleIcon,
  'Instagram Ads': igIcon,
  Snapchat: scIcon,
};

export const ChannelItem = ({channel, onChange}) => (
  <StyledBox
    className={classes.channelRow}
    mb={4}
    width="100%"
  >
    <Box width="10%">
      <Checkbox
        checked={channel.selected}
        onChange={() => onChange({ selected: !channel.selected })}
        size="small"
      />
    </Box>

    <Box display="flex" alignItems="center" width="45%">
      <div className={classes.iconWrap}>
        <img
          src={channelIcons[channel.source.display_name]}
          alt={`${channel.source.display_name} logo`}
          width="100%"
        />
      </div>

      <Typography className={classes.label}>
        {channel.source.display_name}
      </Typography>
    </Box>

    <Box width="45%">
      <TextField
        fullWidth
        disabled={!channel.selected}
        color="secondary"
        type="number"
        label="Avg. Last Click Cost"
        placeholder="Enter cost"
        variant="outlined"
        value={channel.cost_per_click}
        onChange={event =>
          onChange({ cost_per_click: event.target.value })
        }
        InputProps={{
          startAdornment: (
            <InputAdornment position="end">
              <AttachMoneyIcon fontSize="small" />
            </InputAdornment>
          ),
          inputProps: { min: 0 },
        }}
      />
    </Box>
  </StyledBox>
)


ChannelItem.propTypes = {
  channel: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
}
