import React from 'react';
import moment from 'moment-timezone';
import {
  Box,
  Chip,
  Paper,
  Container,
  Grid,
  Typography,
  Skeleton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { useGetLatestTenantTermAndCondition } from '../hooks/apis/termsAndConditions';
import { useTenant } from '../hooks';
import AppHeader from '../AppHeader';

const PREFIX = 'TermsAndConditionsPage';

const classes = {
  container: `${PREFIX}-container`,
  paper: `${PREFIX}-paper`,
  pageTitle: `${PREFIX}-pageTitle`,
  date: `${PREFIX}-date`,
  content: `${PREFIX}-content`
};

const StyledAppHeader = styled(AppHeader)(({
  theme: { spacing }
}) => ({
  [`& .${classes.container}`]: {
    paddingTop: spacing(10),
    paddingBottom: spacing(10),
  },

  [`& .${classes.paper}`]: {
    color: '#13171a',
    padding: spacing(10),
  },

  [`& .${classes.pageTitle}`]: {
    fontSize: '2.5rem',
    fontWeight: "bold",
    color: "#181c20",
  },

  [`& .${classes.date}`]: {
    color: '#5c6b73',
    fontSize: '0.75rem',
  },

  [`& .${classes.content}`]: {
    fontSize: '1rem',
  }
}));

const TermsAndConditionsPage = () => {
  const tenant = useTenant();
  const { data, isLoading } = useGetLatestTenantTermAndCondition(tenant.id);
  const { effective_date, version, text } = data || {};

  return (
    <StyledAppHeader hidenav="true" navSkipSaveStatus="">
      <Box className={classes.container}>
        <Container maxWidth="lg">
          <Paper className={classes.paper} variant="outlined" elevation={0}>
            <Grid
              container
              direction="column"
              spacing={6}
            >
              <Grid
                item
                container
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid item xs={9}>
                  <Typography variant="h1" className={classes.pageTitle}>
                    Terms & Conditions
                  </Typography>
                  <p className={classes.date}>
                    {isLoading && <Skeleton animation="wave" height={10} width="20%" />}
                    {!isLoading && `Effective ${moment(effective_date).format('MMM D, YYYY')}`}
                  </p>
                </Grid>
                <Grid
                  container
                  item
                  xs
                  justifyContent="flex-end"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item xs={6}>
                    {isLoading && <Skeleton animation="wave" height={10} />}
                    {!isLoading && <Chip icon={<InsertDriveFileOutlinedIcon />} label={`Version: ${version}`} />}
                  </Grid>
                  <Grid item xs={6}>
                    {isLoading && <Skeleton animation="wave" height={10} />}
                    {!isLoading && <Chip icon={<LocationOnOutlinedIcon />} label="United States" />}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                {isLoading && (
                  <>
                    <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={10} style={{ marginBottom: 6 }} />
                    <Skeleton animation="wave" height={10} width="80%" />
                  </>
                )}

                {!isLoading && <div className={classes.content} dangerouslySetInnerHTML={{ __html: text }} />}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
    </StyledAppHeader>
  );
};

export default TermsAndConditionsPage;
