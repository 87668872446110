export const categories = [
  { label: "I'm not in a Sensitive Category", value: 'NONE' },
  { label: 'Alcohol', value: 'ALCOHOL' },
  { label: 'Cannabis / CBD', value: 'WEED' },
  { label: 'Credit', value: 'LENDING' },
  { label: 'Employment', value: 'EMPLOYMENT' },
  { label: 'Gambling', value: 'GAMBLING' },
  { label: 'Housing', value: 'HOUSING' },
  { label: 'Mature Content', value: 'NUDITY' },
  { label: 'Politics', value: 'POLITICS' },
];
