import React, { useState, useEffect, Suspense } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Toolbar,
  Typography,
  CircularProgress,
  Link as MUILink,
  Box,
  Stack,
  Tabs,
  Tab,
} from '@mui/material';
import DisplaysTable from '@components/Creatives/DisplaysTable';
import CreativesTable from '@components/Creatives/CreativesTable';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/pro-regular-svg-icons';
import { RoutePaths } from '@constants/routes';
import { View } from '../constants';

const CreativesView = ({ onActionChange }) => {
  const displaysMatch = useRouteMatch(RoutePaths.DISPLAYS);

  const [tab, setTab] = useState(0);

  useEffect(() => {
    const nextTab = displaysMatch ? 1 : 0;
    setTab(nextTab);
  }, [displaysMatch]);

  const handleSetTab = (event, newTab) => {
    setTab(newTab);
  };

  const renderLoading = () => (
    <Box marginTop={12} width="100%" textAlign="center">
      <Box height="100%" width="100%" margin="0 auto" textAlign="center">
        <CircularProgress />
      </Box>
    </Box>
  );

  return (
    <Box border={1} borderColor="grey.300" p={6} pt={4} m={4} borderRadius="20px">
      <Toolbar disableGutters>
        <Typography variant="h6" component="div">Creative</Typography>
      </Toolbar>

      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
        mb={4}
        borderBottom="1px solid #e0e0e0"
      >
        <Tabs onChange={handleSetTab} value={tab}>
          <Tab label="Creatives" />
          <Tab label="Displays" />
        </Tabs>

        <MUILink
          underline="none"
          onClick={() => onActionChange(View.ADD_CREATIVE)}
        >
          <FontAwesomeIcon icon={faPlusCircle} /> Add Creative
        </MUILink>
      </Stack>

      <ErrorBoundary fallback={<div>Error loading items</div>}>
        <Suspense fallback={renderLoading()}>
          {tab === 0 && <CreativesTable />}
          {tab === 1 && <DisplaysTable />}
        </Suspense>
      </ErrorBoundary>
    </Box>
  );
};

CreativesView.propTypes = {
  onActionChange: PropTypes.func.isRequired,
};

export default CreativesView;
