import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Collapse,
  Divider,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  List,
  Paper,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationOffOutlinedIcon from '@mui/icons-material/LocationOffOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

import { useGeo } from './hooks/geo';
import { useCopy } from './hooks';
import { formatDemographic } from './util';
import { GenderSelection, Themes } from '../constants';

const PREFIX = 'ReviewCampaign';

const classes = {
  active: `${PREFIX}-active`,
  collapse: `${PREFIX}-collapse`,
  divider: `${PREFIX}-divider`,
  sectionDivider: `${PREFIX}-sectionDivider`,
  icon: `${PREFIX}-icon`,
  iconWrap: `${PREFIX}-iconWrap`,
  list: `${PREFIX}-list`,
  listExpand: `${PREFIX}-listExpand`,
  listItem: `${PREFIX}-listItem`,
  listSection: `${PREFIX}-listSection`,
  listHeader: `${PREFIX}-listHeader`,
  paper: `${PREFIX}-paper`,
  ul: `${PREFIX}-ul`
};

const StyledPaper = styled(Paper)((
  {
    theme
  }
) => ({
  [`& .${classes.active}`]: {
    color: theme.palette.primary.main,
  },

  [`& .${classes.collapse}`]: {
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.divider}`]: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
  },

  [`& .${classes.sectionDivider}`]: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    height: 2,
    backgroundColor: theme.palette.grey.main,
  },

  [`& .${classes.icon}`]: {
    width: `0.6em`,
  },

  [`& .${classes.iconWrap}`]: {
    minWidth: 30,
  },

  [`& .${classes.list}`]: {
    position: 'relative',
    height: '100%',
    width: '100%',
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },

  [`& .${classes.listExpand}`]: {
    cursor: `pointer`,
  },

  [`& .${classes.listItem}`]: {
    paddingBottom: 0,
    paddingTop: 0,
    fontSize: `1rem`,
  },

  [`& .${classes.listSection}`]: {
    backgroundColor: 'inherit',
  },

  [`& .${classes.listHeader}`]: {
    backgroundColor: `#fff`,
    color: `#181c20`,
    fontSize: `1.25rem`,
  },

  [`&.${classes.paper}`]: {
    width: '100%',
    height: '100%',
    maxHeight: 450,
    overflow: 'auto',
  },

  [`& .${classes.ul}`]: {
    backgroundColor: 'inherit',
    padding: 0,

    ['&:last-of-type']: {
      paddingBottom: theme.spacing(2),
    },
  }
}));

const subHeaders = ['Ad Groups', 'Creative', 'Tracking'];

const Copies = {
  [Themes.DEFAULT]: {
    genderSelect: GenderSelection[Themes.DEFAULT],
  },
  [Themes.NBCU]: {
    genderSelect: GenderSelection[Themes.NBCU],
  },
};

////////////////////////////////
// REVIEW CAMPAIGN COMPONENT
////////////////////////////////
const ReviewCampaign = (props) => {

  const [expands, setExpands] = useState({});
  const { formatGeoForClient } = useGeo();
  const Copy = useCopy(Copies);

  const { adGroups, creatives, displays } = props;

  const handleExpand = (sectionId, name, index) => {
    const id = `${sectionId}-${name}-${index}`;

    setExpands((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderActiveDetail = (creative) => {
    return (
      <ListItem key={`${creative.name}`} className={classes.listItem}>
        <ListItemIcon className={classes.iconWrap}>
          <FiberManualRecordIcon
            className={clsx(classes.icon, classes.active)}
          />
        </ListItemIcon>

        <ListItemText primary={creative.active ? 'Active' : 'Not Active'} />
      </ListItem>
    );
  };

  const renderGeoDetail = (geo, index) => {
    const name = geo.place_name ? geo.place_name : geo.text;

    return (
      <ListItem key={`${name}-${index}`} className={classes.listItem}>
        <ListItemIcon className={classes.iconWrap}>
          {geo.blacklist ? (
            <LocationOffOutlinedIcon className={classes.icon} />
            ) : (
            <LocationOnOutlinedIcon className={classes.icon} />
          )}
        </ListItemIcon>

        <ListItemText primary={name} />
      </ListItem>
    );
  };

  const renderCreativeDetails = (creative) => (
    <List component="div" dense disablePadding>
      {creative.active && renderActiveDetail(creative)}
    </List>
  );

  const renderGroupDetails = (group) => {
    const { age, gender, geo, income, segments, type } = group.targeting;

    const targetingData = {
      segments,
      type,
    };

    const geoResults = !Array.isArray(geo)
      ? formatGeoForClient(geo)
      : geo;

    const demoText = formatDemographic(age[0], age[1], income[0], income[1], Copy.genderSelect[gender]);

    return (
      <List component="div" dense disablePadding>
        {geoResults && geoResults.length > 0 &&
          geoResults.map((g, i) => renderGeoDetail(g, i))}

        <ListItem className={classes.listItem}>
          <ListItemIcon className={classes.iconWrap}>
            <PersonOutlineOutlinedIcon className={classes.icon} />
          </ListItemIcon>

          <ListItemText primary={demoText} />
        </ListItem>

        {targetingData && (targetingData.segments || targetingData.type) && (
          <ListItem className={classes.listItem}>
            <ListItemIcon className={classes.iconWrap}>
              <TrackChangesIcon className={classes.icon} />
            </ListItemIcon>

            <ListItemText primary={`${targetingData.segments.length} Targeting Segments`} />
          </ListItem>
        )}
      </List>
    );
  };

  const renderCreative = (creative, sectionId, index) => (
    <Fragment key={`${sectionId}-${creative.name}-${index}`}>
      <ListItem
        button
        className={classes.listExpand}
        onClick={() => handleExpand(sectionId, creative.name, index)}
      >
        <ListItemText primary={`${creative.name}`} />

        {expands[`${sectionId}-${creative.name}-${index}`] ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItem>

      <Collapse
        className={classes.collapse}
        in={expands[`${sectionId}-${creative.name}-${index}`]}
        timeout="auto"
      >
        <Divider className={classes.divider} />

        {renderCreativeDetails(creative)}
      </Collapse>

      {index !== creatives.length - 1 && (
        <Divider className={classes.divider} />
      )}

      {index === creatives.length - 1 && displays.length > 0 && (
        <Divider className={classes.divider} />
      )}
    </Fragment>
  );

  const renderDisplayGroup = (sectionId) => (
    <Fragment key={`${sectionId}-Display-Retargeting`}>
      <ListItem button className={classes.listExpand}>
        <ListItemText
          primary={`Display Retargeting: ${props.displayPercent}%`}
        />
      </ListItem>
    </Fragment>
  );

  const renderDisplayCreative = (display, sectionId, index) => (
    <Fragment key={`${sectionId}-${display.name}-${index}`}>
      <ListItem
        button
        className={classes.listExpand}
        onClick={() => handleExpand(sectionId, display.name, index)}
      >
        <ListItemText primary={`${display.name}`} />

        {expands[`${sectionId}-${display.name}-${index}`] ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItem>

      <Collapse
        className={classes.collapse}
        in={expands[`${sectionId}-${display.name}-${index}`]}
        timeout="auto"
      >
        <Divider className={classes.divider} />

        <List component="div" dense disablePadding>
          {display.active && renderActiveDetail(display)}
        </List>
      </Collapse>

      {index !== displays.length - 1 && <Divider className={classes.divider} />}
    </Fragment>
  );

  const renderGroup = (group, sectionId, index) => (
    <Fragment key={`${sectionId}-${group.name}-${index}`}>
      <ListItem
        button
        className={classes.listExpand}
        onClick={() => handleExpand(sectionId, group.name, index)}
      >
        <ListItemText primary={`${group.name}`} />

        {expands[`${sectionId}-${group.name}-${index}`] ? (
          <ExpandLess />
        ) : (
          <ExpandMore />
        )}
      </ListItem>

      <Collapse
        className={classes.collapse}
        in={expands[`${sectionId}-${group.name}-${index}`]}
        timeout="auto"
      >
        <Divider className={classes.divider} />

        {renderGroupDetails(group)}
      </Collapse>

      {index !== adGroups.length - 1 &&
        <Divider className={classes.divider} />}

      {index === adGroups.length - 1 && displays.length > 0 && (
        <Divider className={classes.divider} />)}
    </Fragment>
  );

  return (
    <StyledPaper className={classes.paper} variant="outlined">
      <List className={classes.list} dense disablePadding>
        {['adGroups', 'creative'].map((sectionId, index) => (
          <li key={`section-${sectionId}`} className={classes.listSection}>
            <ul className={classes.ul}>
              <ListSubheader className={classes.listHeader}>
                {subHeaders[index]}
              </ListSubheader>

              {/* Ad Groups */}
              {index === 0 &&
                adGroups.map((group, i) =>
                  renderGroup(group, sectionId, i))}

              {index === 0 &&
                displays.length > 0 &&
                renderDisplayGroup(sectionId)}

              {/* Creative Uploads */}
              {index === 1 &&
                creatives.map((creative, i) =>
                  renderCreative(creative, sectionId, i))}

              {index === 1 && displays.length > 0 &&
                displays.map((d, i) =>
                  renderDisplayCreative(d, sectionId, i))}
            </ul>

            {index !== 1 && <Divider className={classes.sectionDivider} />}
          </li>
        ))}
      </List>
    </StyledPaper>
  );
};

ReviewCampaign.propTypes = {
  adGroups: PropTypes.array,
  creatives: PropTypes.array,
  displays: PropTypes.array,
  displayPercent: PropTypes.string,
};

export default ReviewCampaign;
