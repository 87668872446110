export const selectStyles = {
  styleOverrides: {
    select: ({ theme }) => ({
      paddingRight: theme.spacing(42 / 8), // compensate for custom icon
      '+ input + .MuiSelect-icon': {
        display: 'none',
      },
      '&:after': {
        content:
          "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' width='16px' height='16px' fill='%23435159'%3E%3Cpath d='M201.4 374.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 306.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z'/%3E%3C/svg%3E\")",
        position: 'absolute',
        top: `calc(50% - ${theme.spacing(1)}px)`,
        right: theme.spacing(2),
      },
    }),
  },
};
