import axios from 'axios';

// API CONSTANTS
const BASE_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const apiInstance = axios.create({
  baseURL: `${process.env.API_URL}/v2/`,
  headers: {
    ...BASE_HEADERS,
  },
});

export default apiInstance;
