import React from 'react';
import PropTypes from 'prop-types';
import { RadioGroup, FormControlLabel, Radio, Grid, Box } from '@mui/material';

import { Accordion, AccordionDetails, AccordionSummary } from './Accordion';

const UnsupportedStrategy = props => {
  const { strategy, isExpanded, handleExpand } = props;

  return (
    <Accordion
      key={strategy.url}
      square
      expanded={isExpanded}
      onChange={handleExpand}
    >
      <AccordionSummary
        aria-controls={`${strategy.id}-content`}
        id={`${strategy.id}-header`}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <RadioGroup row value="bidStrategy">
              <FormControlLabel
                disabled
                value=""
                control={<Radio />}
                label={strategy.display_name}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" xs={11} spacing={2}>
          <Grid item>
            <Box sx={{ pb: 2 }}>{strategy.description}</Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

UnsupportedStrategy.propTypes = {
  strategy: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  handleExpand: PropTypes.func.isRequired,
};

export default UnsupportedStrategy;
