import React, {
  useContext,
  useState,
} from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Button, Divider, Grid, IconButton, Menu, MenuItem, ListItemText } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { AdAccountSwitcher, CreateCampaignButton } from './TopBarActions';
import SSOUserContext from './SSOUserContext';
import { useAuth, useUser } from './hooks';
import { useClearSwrCache } from '../swr';

const PREFIX = 'LoginButton';

const classes = {
  divider: `${PREFIX}-divider`
};

const StyledGrid  = styled(Grid)(() => ({
  [`& .${classes.divider}`]: {
    background: `#014831`,
    marginLeft: `1.125rem`,
    marginRight: `1.125rem`,
    height: `1.5rem`,
  }
}));

const menuPosition = {
  vertical: 'bottom',
  horizontal: 'right',
};

// TODO:
// - Break apart logic for finer use cases
// - Ultimately rename or repurpose component

/////////////////////////////
// LOGIN BUTTON COMPONENT
/////////////////////////////
const LoginButton = () => {
  const { auth } = useAuth();
  const { clearCacheCompletely } = useClearSwrCache();
  const { user: ssoUser } = useContext(SSOUserContext);
  const { user } = useUser();
  const history = useHistory();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleMenuOpen = event => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const login = () => {
    history.push('/login');
  };

  const logout = () => {
    handleMenuClose();
    clearCacheCompletely();
    auth.signOut();
  };

  if (!ssoUser) {
    return (
      <Button color="inherit" onClick={login}>
        Login
      </Button>
    );
  }

  // Render full component
  return (
    <StyledGrid container alignItems="center" justifyContent="flex-end">
      <AdAccountSwitcher />
      <Grid item>
        <Divider
          className={clsx(classes.divider, 'Vertical-divider')}
          orientation="vertical"
        />
      </Grid>

      <>
        <CreateCampaignButton />
        <Grid item>
          <Divider
            className={clsx(classes.divider, 'Vertical-divider')}
            orientation="vertical"
          />
        </Grid>
      </>

      <Grid item>
        <IconButton onClick={handleMenuOpen} color="inherit" size="large">
          <AccountCircleIcon />
        </IconButton>

        <Menu
          getContentAnchorEl={null}
          anchorEl={menuAnchorEl}
          anchorOrigin={menuPosition}
          transformOrigin={menuPosition}
          open={!!menuAnchorEl}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={logout}>
            <ListItemText
              secondary={
                <span>
                  {user?.first_name || ''} {user?.last_name || ''} <br /> {user?.email || ''}
                </span>
              }
              primary="Logout"
            />
          </MenuItem>
        </Menu>
      </Grid>
    </StyledGrid >
  );
};

export default LoginButton;
