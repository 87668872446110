import React from 'react';
import { PropTypes } from 'prop-types';

import { Box, Divider, Stack, styled } from '@mui/material';


const BoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  zIndex: 9,
}));

export const AdvancedBoxFooter = ({ children }) => {
  return (
    <BoxStyled>
      <Divider />
      <Stack direction="row" justifyContent="space-between" mt={4} pr={2} pb={2}>
        {children}
      </Stack>
    </BoxStyled>
  );
}

AdvancedBoxFooter.propTypes = {
  children: PropTypes.node,
};
