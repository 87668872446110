
import React from 'react'
import PropTypes from 'prop-types'
import { IconButton, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


export const VastTagListItemAction = ({
  onRemove
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleAction = (actionFn) => {
    setAnchorEl(null);
    actionFn();
  }

  const handleArrowDownClick = event => {
    setAnchorEl(event.target);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={handleArrowDownClick}
      >
        <KeyboardArrowDownIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => handleAction(onRemove)}>
          Remove
        </MenuItem>
      </Menu>
    </>
  )

}

VastTagListItemAction.propTypes = {
  onRemove: PropTypes.func.isRequired,
}
