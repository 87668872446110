import React, { useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useStateMachine } from 'little-state-machine';
import { useForm, useFieldArray } from 'react-hook-form';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { updateDeviceOverlay } from '../actions';
import IncludePanel from './IncludePanel';
import IncludeToggle from './IncludeToggle';
import AlertBox from './AlertBox';

const PREFIX = 'DeviceOverlay';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  divider: `${PREFIX}-divider`,
  header: `${PREFIX}-header`,
  text: `${PREFIX}-text`,
  arrow: `${PREFIX}-arrow`,
  arrowButton: `${PREFIX}-arrowButton`
};

const Root = styled('form')((
  {
    theme
  }
) => ({
  [`&.${classes.container}`]: {
    display: 'grid',
    gridAutoRows: '1fr 0.5fr 4fr 1fr',
    gridGap: '20px',
    alignItems: 'center',
    backgroundColor: 'rgba(38, 47, 60, 0.95)',
    padding: theme.spacing(8),
    height: '100%',
    overflow: 'auto',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 2,
  },

  [`& .${classes.divider}`]: {
    backgroundColor: 'rgba(154, 160, 166, 0.5)',
  },

  [`& .${classes.header}`]: {
    lineHeight: 1.75,
    color: '#fff',
  },

  [`& .${classes.text}`]: {
    fontSize: '1.25rem',
    lineHeight: 2.1,
    fontWeight: 'normal',
    color: '#fff',
  },

  [`& .${classes.arrow}`]: {
    color: '#1dafff',
    marginLeft: '20px',
  },

  [`& .${classes.arrowButton}`]: {
    width: '20px',
    minWidth: '0px',
    padding: '0 0px',
  }
}));

const DeviceOverlay = props => {
  const { onClose } = props;

  const { state, actions } = useStateMachine({ updateDeviceOverlay });

  const { formState, handleSubmit, control } = useForm({
    defaultValues: state.data.inventoryStep.deviceOverlayModal
  });

  const { fields: deviceTypes, replace: replaceDeviceTypes } = useFieldArray({
    name: 'deviceTypes',
    keyName: 'internalId',
    rules: {
      validate(value) {
        if (!value.some(element => element.selected)) {
          return "One or more device types must be included";
        }

        return true;
      }
    },
    control
  });

  const { fields: operatingSystems, replace: replaceOperatingSystems } = useFieldArray({
    name: 'operatingSystems',
    keyName: 'internalId',
    rules: {
      validate(value) {
        if (!value.some(element => element.selected)) {
          return "One or more device types must be included";
        }

        return true;
      }
    },
    control
  });

  const isAllDeviceTypesExcluded = useMemo(() => deviceTypes.every(type => !type.selected), [deviceTypes]);
  const isAllOperatingSystemsExcluded = useMemo(() => operatingSystems.every(type => !type.selected), [operatingSystems]);

  const StyledToggleDivider = styled(Divider)({
    backgroundColor: 'rgba(154, 160, 166, 0.5)',
    margin: '2px 10px',
    zIndex: 1,
  });

  const onSubmit = data => {
    actions.updateDeviceOverlay(data);
    onClose();
  };

  const onToggleIncludeAllDeviceTypes = (isAllExcluded) => {
    replaceDeviceTypes(deviceTypes.map((type) => ({
      ...type,
      selected: isAllExcluded
    })));
  };

  const onToggleIncludeAllOperatingSystems = (isAllExcluded) => {
    replaceOperatingSystems(operatingSystems.map((system) => ({
      ...system,
      selected: isAllExcluded
    })));
  };

  return (
    <Root
      className={classes.container}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item container alignItems="center">
        <Button
          onClick={() => onClose(false)}
          className={classes.arrowButton}
          startIcon={<ArrowBackIcon className={classes.arrow}/>}
        />
        <StyledToggleDivider flexItem orientation="vertical" />
        <Typography className={classes.header} variant="h3">
          Configure advanced targeting by Device Type and Operating System
        </Typography>
      </Grid>

      <Box my={1}>
        <Divider className={classes.divider} />
      </Box>

      <Box my={1}>
        <Typography className={classes.text} >
          Select the Device Types and Operating Systems you want to include or exclude
        </Typography>
      </Box>

      <Grid
        item
        container
        spacing={3}
        alignItems="stretch"
        justifyContent="space-around"
      >
        <Grid item xs={6}>
          <IncludePanel
            title="Target by Device Type"
            isAllExcluded={isAllDeviceTypesExcluded}
            onToggleIncludeAll={onToggleIncludeAllDeviceTypes}
          >
            {formState.errors.deviceTypes && formState.errors.deviceTypes.root && (
              <AlertBox
                title="Invalid Targeting"
                message={formState.errors.deviceTypes.root.message}
                type="MAJOR"
                isAlertOpen
              />
            )}

            {deviceTypes.map((type, index) => (
              <IncludeToggle
                key={type.internalId}
                title={type.title}
                name={`deviceTypes.${index}.selected`}
                control={control}
              />
            ))}
          </IncludePanel>
        </Grid>

        <Grid item xs={6}>
          <IncludePanel
            title="Target by Operating System"
            isAllExcluded={isAllOperatingSystemsExcluded}
            onToggleIncludeAll={onToggleIncludeAllOperatingSystems}
          >
            {formState.errors.operatingSystems && formState.errors.operatingSystems.root && (
              <AlertBox
                title="Invalid Targeting"
                message={formState.errors.operatingSystems.root.message}
                type="MAJOR"
                isAlertOpen
              />
            )}

            {operatingSystems.map((type, index) => (
              <IncludeToggle
                key={type.internalId}
                title={type.title}
                name={`operatingSystems.${index}.selected`}
                control={control}
              />
            ))}
          </IncludePanel>
        </Grid>
      </Grid>

      <Grid item container alignItems="center" justifyContent="flex-end">
        <Grid item>
          <Button
            onClick={() => onClose(false)}
            color="secondary"
            size="small"
            className="Button--small"
          >
            Cancel
          </Button>
        </Grid>

        <Grid item>
          <Button
            type="submit"
            disabled={false}
            color="secondary"
            variant="contained"
            size="medium"
            className="Button--medium"
          >
            Save
          </Button>
        </Grid>
      </Grid>
    </Root>
  );
};

DeviceOverlay.propTypes = {
  onClose: PropTypes.func,
};

export default DeviceOverlay;
