import { first, flatMap, isEmpty, last, map } from 'lodash';
import { allValue, ages, incomes } from './constants';
import { isBetween } from '@v2/components/ui/ButtonSlider';

export const getNumbers = values =>
  values === allValue
    ? values
    : flatMap(values)
      .map(v => (v === allValue ? v : v))
      .sort((a, b) => (a > b ? 1 : -1));

export const fromArray = values =>
  Array.isArray(values) ? values[0] : values;

export const getAllNumbers = options => getNumbers(map(options, 'value'));

export const isTheSame = ([firstValue, lastValue], [first, last]) => {
  return firstValue === first && lastValue === last;
};

export const getOptionsByValue = (value, options) => {
  if (!value || value[0] === allValue) {
    return [allValue];
  }

  const numbers = getNumbers(map(options, 'value'));
  const [firstNumber, lastNumber] = value;

  if (firstNumber === first(numbers)[0] && lastNumber === last(numbers)[0]) {
    return [allValue];
  }

  return numbers
    .map(([fN, lN], idx) => {
      if (fN >= firstNumber && lN <= lastNumber) {
        return options[idx].value;
      }
    })
    .filter(v => v !== undefined);
};

export const getOptionsRange = (value, options) => {
  if (isEmpty(value) || value === allValue) return value;

  const numbers =
    value[0] === allValue ? getAllNumbers(options) : getNumbers(value);

  return [first(numbers), last(numbers)];
};

const getLabels = ([firstValue, lastValue], options) => {
  const firstOption =
    options.find(v => isBetween(v.value, [firstValue, lastValue])) ?? first(options);
  const lastOption =
    options.findLast(v => isBetween(v.value, [firstValue, lastValue])) ?? last(options);

  const firstLabel = first(firstOption.label.split('-')).trim();
  const lastLabel = last(lastOption.label.split('-')).trim();

  return [firstLabel, lastLabel];
};

const isAll = ([firstValue, lastValue], options) => {
  return (
    firstValue <= first(options).value[0] &&
    lastValue >= last(options).value[1]
  );
};

export const getAgesLabel = values => {
  const numbers = getNumbers(values);

  const withoutAll = ages.slice(1);

  if (isEmpty(values) || isAll([first(numbers), last(numbers)], withoutAll)) {
    return 'All Ages';
  }

  if (last(numbers) === last(withoutAll).value[1]) {
    return `Ages above ${first(numbers)}`;
  }

  if (first(numbers) === first(withoutAll).value[0]) {
    return `Ages below ${last(numbers)}`;
  }

  const [firstLabel, lastLabel] = getLabels(
    [first(numbers), last(numbers)],
    withoutAll,
  );

  return `Ages ${firstLabel} - ${lastLabel}`;
};

export const getIncomeLabel = values => {
  const numbers = getNumbers(values);

  const withoutAll = incomes.slice(1);

  if (isEmpty(values) || isAll([first(numbers), last(numbers)], withoutAll)) {
    return 'All Household Incomes';
  }

  if (last(numbers) === last(withoutAll).value[1]) {
    return `Income above $${first(numbers)}K`;
  }

  if (first(numbers) === first(withoutAll).value[0]) {
    return `Income below $${last(numbers)}K`;
  }

  const [firstLabel, lastLabel] = getLabels(
    [first(numbers), last(numbers)],
    withoutAll,
  );

  return `Income ${firstLabel} - ${lastLabel}`;
};

export const transformFromAll = v => (v === allValue ? [] : v);
