import { Themes } from './themes';

export const Tenant = {
  DEFAULT: 1,
  NBCU: 2,
};

export const Tenants = {
  [Themes.DEFAULT]: {
    id: Tenant.DEFAULT,
    url: `${process.env.API_URL}/v1/tenants/1/`
  },
  [Themes.NBCU]: {
    id: Tenant.NBCU,
    url: `${process.env.API_URL}/v1/tenants/2/`,
  }
};
