import * as ld from 'launchdarkly-react-client-sdk';
import { useState, useEffect, useContext, useMemo } from 'react';
import {
  getAdvertiserKey,
  getOrganizationKey,
  getUserKey,
} from '@utils/keys';
import AdvertiserContext from '../AdvertiserContext';
import { useOrg, useUser } from '../hooks'

type CustomLDContext = ld.LDMultiKindContext & {
  user: {
    key: string;
    email: string;
    name: string;
    id: number;
  };
  organization: {
    key: string;
    name: string;
    id: number;
  };
  advertiser: {
    key: string;
    name: string;
    id: number;
  };
};

export const useLDContext = (): CustomLDContext | undefined => {
  const [ldContext, setLdContext] = useState<CustomLDContext>({
    kind: 'multi',
    user: {
      key: '',
      email: '',
      name: '',
      id: 0,
    },
    organization: {
      key: '',
      name: '',
      id: 0,
    },
    advertiser: {
      key: '',
      name: '',
      id: 0,
    },
  });
  const adContext = useContext(AdvertiserContext);
  const { org } = useOrg();
  const { user } = useUser();

  const memoizedAdContext = useMemo(() => ({
    id: adContext?.id,
    name: adContext?.name
  }), [adContext?.id, adContext?.name])

  useEffect(() => {
    if (user && org && memoizedAdContext) {
      updateLDContext();
    }
  }, [memoizedAdContext, org, user]);

  const updateLDContext = async () => {
    try {
      const user_key = await getUserKey(user?.email || '');
      const user_email = user?.email || '';
      const user_name = `${user?.first_name} ${user?.last_name}`;
      const user_id = user?.id || 0;

      const org_key = getOrganizationKey(org?.id || 0);
      const org_name = org?.name || '';
      const org_id = org?.id || 0;

      const adv_key = getAdvertiserKey(adContext?.id || 0);
      const adv_name = adContext?.name || '';
      const adv_id = adContext?.id || 0;

      setLdContext({
        kind: 'multi',
        user: {
          key: user_key,
          email: user_email,
          name: user_name,
          id: user_id,
        },
        organization: {
          key: org_key,
          name: org_name,
          id: org_id,
        },
        advertiser: {
          key: adv_key,
          name: adv_name,
          id: adv_id,
        },
      });
    } catch (err) {
      console.error('Error generating user key', err);
    }
  };

  return ldContext.user.key ? ldContext : undefined;
};
