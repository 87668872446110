import PropTypes from 'prop-types';
import React from 'react';
import { AdvancedBox } from '../../AdvancedBox';
import { useAdvancedCreative } from './hooks/useAdvancedCreative';
import { useCreativeWrapper } from './hooks/useCreativeWrapper';
import { ByVASTTag } from './ByVASTTag';
import { Library } from './Library';
import { Upload } from './Upload';
import { CreativeContext, TABS } from './constants';
import { FormProvider } from 'react-hook-form';
import { Manage } from './Manage';

const AdvancedCreativeInner = ({
  creatives,
  onClose,
  onCloseInfo,
  info,
  library,
  tabs,
  ...props
}) => {
  const {
    tab,
    toggleAssignCreative,
    toManage,
    setTab,
    toLibrary,
    closeInfo,
    upload,
  } = useAdvancedCreative({
    creatives,
    library,
    onCloseInfo,
  });

  return (
    <CreativeContext.Provider
      value={{
        library,
        tab,
        setTab,
        toggleAssignCreative,
        ...upload,
      }}
    >
      {Object.keys(tabs).includes(TABS.CREATIVE_LIBRARY) &&
        tab.key === TABS.CREATIVE_LIBRARY && (
          <Library
            {...props}
            info={info[TABS.CREATIVE_LIBRARY]}
            onClose={onClose}
            onSubmit={toManage}
            onInfoClose={closeInfo(TABS.CREATIVE_LIBRARY)}
          />
        )}
      {Object.keys(tabs).includes(TABS.UPLOAD_ASSET) &&
        tab.key === TABS.UPLOAD_ASSET && (
          <Upload
            {...props}
            info={info[TABS.UPLOAD_ASSET]}
            onClose={toLibrary}
            onSubmit={toManage}
            onInfoClose={closeInfo(TABS.UPLOAD_ASSET)}
          />
        )}
      {Object.keys(tabs).includes(TABS.VAST_TAG) &&
        tab.key === TABS.VAST_TAG && (
          <ByVASTTag
            {...props}
            info={info[TABS.VAST_TAG]}
            onInfoClose={closeInfo(TABS.VAST_TAG)}
            onClose={toLibrary}
            onCreate={upload.createVastTags}
            onSubmit={toManage}
          />
        )}
      {Object.keys(tabs).includes(TABS.MANAGE) && tab.key === TABS.MANAGE && (
        <Manage
          {...props}
          onUpdate={upload.updateCreative}
          info={info[TABS.MANAGE]}
          onInfoClose={closeInfo(TABS.MANAGE)}
          onClose={toLibrary}
        />
      )}
    </CreativeContext.Provider>
  );
};

export const AdvancedCreative = ({
  creatives,
  onSubmit,
  weightingRotation,
  ...props
}) => {
  const { form, submit } = useCreativeWrapper({
    creatives,
    onSubmit,
    weightingRotation,
  });

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(submit)}>
        <AdvancedCreativeInner {...props} onSubmit={submit} />
      </form>
    </FormProvider>
  );
};

AdvancedCreative.propTypes = {
  ...AdvancedBox.propTypes,
  library: PropTypes.array,
  weightingRotation: PropTypes.string,
  onCloseInfo: PropTypes.func,
  info: PropTypes.object,
  tabs: PropTypes.object,
  isDisplay: PropTypes.bool,
};

AdvancedCreativeInner.propTypes = {
  ...AdvancedCreative.propTypes,
};
