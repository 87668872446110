import React from 'react';
import { PropTypes } from 'prop-types';
import { Stack, styled } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons';

const VideoContentWrapperStyled = styled(Stack)({
  position: 'relative',
});

const VideoWrapperStyled = styled('video')(() => ({
  width: '100%',
  height: '100%',
  borderRadius: 0,
}));

const FullWidthBlockStyled = styled('div')({
  position: 'absolute',
  width: '100%',
  height: '100%',
});

const VideoPlayStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
}));

const VideoOverlayStyled = styled(FullWidthBlockStyled, {
  shouldForwardProp: prop => prop !== 'progress' && prop !== 'actionable',
})(({ progress, actionable }) => ({
  touchAction: progress === 100 ? 'auto' : 'none',
  cursor: progress === 100 && actionable ? 'pointer' : 'default',
  background: 'transparent',
}));

const VideoBackdropStyled = styled(FullWidthBlockStyled, {
  shouldForwardProp: prop => prop !== 'progress',
})(({ theme, progress }) => ({
  background: theme.palette.grey[7],
  opacity: 1 - progress / 100,
}));

export const VideoPreview = ({
  src,
  onPlay,
  progress = 100,
  PlayIconProps = {},
  ...props
}) => {
  return (
    <VideoContentWrapperStyled {...props}>
      <VideoWrapperStyled src={src} />
      <VideoOverlayStyled
        onClick={onPlay}
        actionable={!!onPlay}
        progress={progress}
      >
        {onPlay && progress === 100 && (
          <VideoPlayStyled {...PlayIconProps} icon={faPlayCircle} />
        )}
        {onPlay && progress < 100 && (
          <VideoBackdropStyled progress={progress} />
        )}
      </VideoOverlayStyled>
    </VideoContentWrapperStyled>
  );
};

VideoPreview.propTypes = {
  ...Stack.propTypes,
  progress: PropTypes.number,
  PlayIconProps: PropTypes.object,
  src: PropTypes.string,
  onPlay: PropTypes.func,
};
