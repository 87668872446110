import { FormFields } from '../AdminForm/constants';

export const AdminAPIFields = {
  ADVERTISER_ID: 'nbcu_advertiser_salesforce_id',
  BILLING_ID: 'nbcu_advertiser_billing_id',
  COST_MODAL: 'cost_model',
  APPROVED_TO_SPEND: 'approved_to_spend',
};

export const AdminFieldMappings = {
  [FormFields.ADVERTISER_ID]: AdminAPIFields.ADVERTISER_ID,
  [FormFields.BILLING_ID]: AdminAPIFields.BILLING_ID,
  [FormFields.COST_MODEL]: AdminAPIFields.COST_MODAL,
  [FormFields.BILLING_APPROVED]: AdminAPIFields.APPROVED_TO_SPEND,
};
