import { Stack, Typography, styled } from '@mui/material';

export const ContentWrapperStyled = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(4, 3, 7.5),
}));

export const CaptionBoxStyled = styled(Stack)(({ theme }) => ({
  color: theme.palette.grey[4],
  paddingTop: 20,
}));

export const CaptionTypographyStyled = styled(Typography)({
  fontSize: 12,
});
