import { array, bool, object, string } from 'yup';

export const validationSchema = object().shape({
  account_name: string().required('Required'),
  isSameAddress: bool(),
  company_name: string().required('Required'),

  address1: string().required('Required'),
  address2: string(),
  city: string().required('Required'),
  state: string().required('Required'),
  zip: string().required('Required'),

  secondary_contact: string().required('Required'),
  invoice_emails: array().of(string()),
  secondary_first_name: string().required('Required'),
  secondary_last_name: string().required('Required'),
  billing_method: string().required('Required'),
  selectedCard: string()
    .when('billing_method', {
      is: 'CC',
      then: string().required('Required'),
    }),
  hasAcceptedTerms: bool().oneOf([true], 'Required'),
});

export const getInitialValues = ({ account, org }) => {
  return {
    account_name: account?.name,
    isSameAddress: false,
    company_name: org.name || '',

    address1: account?.street_address || '',
    address2: account?.street_address_2 || '',
    city: account?.city || '',
    state: account?.state || '',
    zip: account?.postal_code || '',

    secondary_contact: account?.secondary_contact,
    secondary_first_name: account?.secondary_first_name,
    secondary_last_name: account?.secondary_last_name,
    billing_method: account?.billing_method || '',
    selectedCard: '',

    hasAcceptedTerms: true,
  };
};

export const formValuesToApi = values => {
  return {
    name: values.account_name,

    street_address: values.address1,
    street_address_2: values.address2,
    city: values.city,
    state: values.state,
    postal_code: values.zip,

    secondary_contact: values.secondary_contact,
    secondary_first_name: values.secondary_first_name,
    secondary_last_name: values.secondary_last_name,
    billing_method: values.billing_method,
    default_payment_profile: values.selectedCard,
  }
}
