import { useState } from 'react';
import { useAPI } from './api';
import { useGeo } from './geo';

interface AdGroup {
  id: string;
  is_control_group: boolean;
  targeting: string;
  [key: string]: any;
}

interface ParsedAdGroup extends Omit<AdGroup, 'targeting'> {
  targeting: {
    geo: any;
    [key: string]: any;
  };
}

interface Campaign {
  amount: number;
  display: {
    displayBudget: number;
  };
  isDisplay: boolean;
  url: string;
}

export const useAdGroups = () => {
  const { useGetAll, usePatch } = useAPI();
  const { formatGeo, formatGeoForClient } = useGeo();
  const [adGroups, setAdGroups] = useState<ParsedAdGroup[]>([]);
  const [isAdGroup, setIsAdGroup] = useState<boolean>(false);
  const [currentAdGroup, setCurrentAdGroup] = useState<ParsedAdGroup | null>(null);

  const getAdGroups = async (campaignId: string, campaignStep: string): Promise<ParsedAdGroup[] | Error> => {
    let draftAdGroups: ParsedAdGroup[];
    try {
      await useGetAll(`/lineitems?campaign=${campaignId}`, [], (total: AdGroup[]) => {
        const filtered = total.filter(l => !l.is_control_group);
        draftAdGroups = filtered.map(l => {
          const parsed = JSON.parse(l.targeting);
          return {
            ...l,
            targeting: {
              ...parsed,
              geo: formatGeoForClient(parsed.geo),
            },
          };
        });
        if (draftAdGroups && draftAdGroups.length > 0) {
          if (draftAdGroups.length === 1) {
            if (['LaunchCampaign', 'TrackingSetup'].includes(campaignStep)) {
              setAdGroups(draftAdGroups);
            } else {
              setAdGroups([]);
            }
          } else {
            if (['LaunchCampaign', 'TrackingSetup'].includes(campaignStep)) {
              setAdGroups(draftAdGroups);
            } else {
              setAdGroups([...draftAdGroups.slice(0, -1)]);
            }
          }
        }
      });
      return draftAdGroups!;
    } catch (error) {
      console.error('Error getting ad groups', error);
      return error as Error;
    }
  };

  const getDisplays = async (campaignId: string): Promise<any[]> => {
    let draftDisplays: any[];
    await useGetAll(`/static_display_lineitems?campaign=${campaignId}`, [], (total: any[]) => {
      draftDisplays = total;
    });
    return draftDisplays!;
  };

  const saveAdGroups = (adGroups: ParsedAdGroup[], campaign: Campaign): Promise<any[]> => {
    const { amount, display, isDisplay, url } = campaign;
    if (adGroups && adGroups.length > 0) {
      const formattedBudget = !isDisplay
        ? amount
        : (amount - display.displayBudget);
      let totalBudget = formattedBudget;
      const adGroupBudget = totalBudget / adGroups.length;

      const adGroupRequests = adGroups.map((group, index) => {
        const { targeting } = group;
        totalBudget = totalBudget - adGroupBudget;
        const adGroupDaily =
          index !== adGroups.length - 1
            ? adGroupBudget
            : Math.max(adGroupBudget, totalBudget);
        const targetingGeo = Array.isArray(targeting.geo)
          ? formatGeo(targeting.geo)
          : targeting.geo;
        const data = {
          ...group,
          daily_budget: adGroupDaily,
          targeting: JSON.stringify({
            ...targeting,
            geo: targetingGeo,
          }),
          campaign: url,
          draft: false,
        };
        return usePatch(`/lineitems/${group.id}`, data);
      });

      return Promise.all(adGroupRequests)
        .then((...responses: any[]) => {
          return responses.map(g => {
            console.log('Response from /lineitems', g);
            return g;
          });
        })
        .catch(error => {
          console.error(error);
          throw error;
        });
    }
    return Promise.resolve([]);
  };

  return {
    adGroups,
    isAdGroup,
    currentAdGroup,
    setCurrentAdGroup,
    setIsAdGroup,
    setAdGroups,
    getAdGroups,
    getDisplays,
    saveAdGroups,
  };
};
