import { useSWRConfig } from 'swr';

export function localStorageProvider() {
  const withCache = process.env.USE_SWR_CACHE === 'true';

  if (!withCache) {
    return new Map();
  }

  const map = new Map(JSON.parse(localStorage.getItem('app-cache') || '[]'));
  window.addEventListener('beforeunload', () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem('app-cache', appCache);
  });

  return map;
}

export const useClearSwrCache = () => {
  const { mutate } = useSWRConfig();

  // A function that allows clearing the cache when you need to the components to refetch
  // Accepts an array of string cache keys
  const clearCache = (cacheKeys = []) => {
    mutate(
      key => {
        return cacheKeys.includes(key?.url);
      },
      undefined,
    );
  };

  // Clear the entire cache
  const clearCacheCompletely = () => {
    mutate(() => true, undefined, { revalidate: false });
  };
  return { clearCache, clearCacheCompletely };
};
