import React, { Suspense, useEffect } from 'react';
import AppHeader from '../../AppHeader';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import LoadingSpinner from '../../ui/LoadingSpinner';
import { useParams, Link, Redirect, generatePath } from 'react-router-dom';
import {
  useAcceptPlatformInvite,
  useGetPlatformInvite,
} from '@apis/platformInvite';
import PropTypes from 'prop-types';
import AcceptInviteForm from './AcceptInviteForm';
import { RoutePaths } from '@constants/routes';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';
import AppInviteError from './AppInviteError';
import { useErrors } from '@hooks';
import { validateAcceptPlatformInvite } from './utils';

const AcceptInviteDetails = ({ inviteId }) => {
  const { platformInvite } = useGetPlatformInvite(inviteId);
  const { trigger } = useAcceptPlatformInvite(inviteId);
  const { handleError } = useErrors();

  if (platformInvite?.status === 'ACCEPTED') {
    return (
      <Redirect
        to={generatePath(RoutePaths.JOIN, {
          inviteId: platformInvite.invite_id,
        })}
      />
    );
  }

  if (!validateAcceptPlatformInvite(platformInvite)) {
    return <AppInviteError />;
  }

  const handleSubmit = async values => {
    try {
      const result = await trigger({
        user: {
          email: values.email,
          first_name: values.first_name,
          last_name: values.last_name,
        },
        organization: {
          name: values.organization_name,
          type: platformInvite.context?.organization_type ?? 'Advertiser',
        },
      });
      if (result?.next_step_url) {
        window.location.href = result.next_step_url;
      }
    } catch (e) {
      handleError(e);
    }
  };

  if (!platformInvite) {
    return null;
  }

  return (
    <Grid container item direction="row" display="flex" md={12}>
      <Grid item md={6}>
        <Stack spacing={3}>
          <Typography variant="h4">
            Welcome back, {platformInvite.first_name}!
          </Typography>
          <Typography variant="body1" color="grey.5">
            Let&apos;s finish setting up your user account.
          </Typography>
          <img
            style={{ backgroundSize: 'contain', width: '100%' }}
            src={require('../../../images/accept-invitation.png')}
            alt=""
          />
        </Stack>
      </Grid>
      <Grid item md={6}>
        <Stack sx={{ maxWidth: { md: 420 }, margin: '0 auto' }} spacing={8}>
          <AcceptInviteForm
            platformInvite={platformInvite}
            onSubmit={handleSubmit}
          />
          <Typography sx={{ textAlign: 'center', width: '100%' }}>
            Already have an account? <Link to={RoutePaths.HOME}>Sign in</Link>
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

AcceptInviteDetails.propTypes = {
  inviteId: PropTypes.string.isRequired,
};

const AcceptInvitePage = () => {
  // Clear the user's advertiser context so that if they accept the invite, we do not
  // try to reference the old advertiser context
  useEffect(() => {
    if (localStorage.getItem('AdvertiserContext')) {
      localStorage.removeItem('AdvertiserContext');
    }
  }, []);

  const { inviteId } = useParams();
  return (
    <AppHeader hidenav="true">
      <Container maxWidth="lg">
        <Box sx={{ paddingTop: { xs: 10, md: 20 } }}>
          <ErrorBoundary
            fallback={<AppInviteError isMissingCode={!inviteId} />}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <AcceptInviteDetails inviteId={inviteId} />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Container>
    </AppHeader>
  );
};

export default AcceptInvitePage;
