import React from 'react';
import { FirstCellStyled, SelectionElementRowStyled } from '../styles';
import { PropTypes } from 'prop-types';
import { optionalOptionType } from '../types';
import { TableCell } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

export const TableLeftRow = ({
  data,
  index,
  isTargeted,
  renderCell,
  PlaceholderCell,
  onChange,
}) => {
  return (
    <SelectionElementRowStyled
      data-testid={
        data
          ? `table-left-row-${data.id}`
          : `table-left-row-placeholder-${index}`
      }
      className={data ? 'hover-row' : ''}
      targeted={data && isTargeted}
      highlighted={index % 2}
      onClick={() => {
        if (data) {
          onChange(data);
        }
      }}
    >
      <FirstCellStyled>
        {data ? renderCell(data) : <PlaceholderCell data={data} />}
      </FirstCellStyled>
      <TableCell>
        <AddCircleOutlineIcon
          className="hover-icon"
          color="primary"
          sx={{
            display: !data || !isTargeted ? 'none' : 'block',
          }}
        />
      </TableCell>
    </SelectionElementRowStyled>
  );
};

TableLeftRow.propTypes = {
  index: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  data: optionalOptionType,
  isTargeted: PropTypes.bool,
  renderCell: PropTypes.func.isRequired,
  PlaceholderCell: PropTypes.func,
};
