import React, { Fragment, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import numeral from 'numeral';
import MiddleEllipsis from '../../helpers/ellipsis'
import {
  Button,
  Collapse,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Divider,
  TableHead,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';

import VastTagListExtension from './VastTagListExtension';
import { VastTagListItemAction } from './VastTagListItemAction';

const PREFIX = 'VastTagList';

const classes = {
  groupCell: `${PREFIX}-groupCell`,
  expanded: `${PREFIX}-expanded`,
  name: `${PREFIX}-name`,
  spinner: `${PREFIX}-spinner`,
  table: `${PREFIX}-table`,
  editButton: `${PREFIX}-editButton`,
  divider: `${PREFIX}-divider`,
  dense: `${PREFIX}-dense`,
  blankCell: `${PREFIX}-blankCell`
};

const StyledTableContainer = styled(TableContainer)(({
  theme: { palette, spacing }
}) => ({
  [`& .${classes.groupCell}`]: {
    whiteSpace: 'nowrap',
  },

  [`& .${classes.expanded}`]: {
    paddingLeft: 40,
  },

  [`& .${classes.name}`]: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 220,
  },

  [`& .${classes.spinner}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    alignItems: 'center',
    padding: spacing(4),
  },

  [`&.${classes.table}`]: {
    marginTop: spacing(2),
    maxHeight: 375,

    [`& tbody tr:last-child td`]: {
      borderBottom: 'unset',
    },
  },

  [`& .${classes.editButton}`]: {
    color: palette.secondary.main,
    minWidth: 45,
    textTransform: 'uppercase',
  },

  [`& .${classes.divider}`]: {
    marginLeft: 5,
    marginRight: 5,
  },

  [`& .${classes.dense}`]: {
    color: 'rgba(114, 115, 131, 0.8)',
    fontSize: '.8rem',
    lineHeight: 'normal',
  },

  [`& .${classes.blankCell}`]: {
    maxWidth: 40,
    width: 40,
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  }
}));

const columns = [
  { id: 'title', label: 'Creative Title', align: 'left', class: '' },
  { id: 'bitrates', label: 'Bit Rates', align: 'left', class: '' },
  { id: 'duration', label: 'Duration', align: 'center', class: '' },
  { id: 'mimeType', label: 'Mime Type', align: 'center', class: '' },
  {
    id: 'action',
    label: 'Action',
    align: 'center',
    class: '',
    disablePadding: true,
  },
];

const VastTagList = ({ data, onEdit, onRemove }) => {


  const [expanded, setExpanded] = useState({});

  const handleEditClick = id => {
    setExpanded(prev => {
      return {
        ...prev,
        [id]: !prev[id],
      };
    });
  };

  const handleEdit = tag => {
    onEdit(tag);
    handleEditClick(tag.id);
  };

  return (
    <StyledTableContainer className={classes.table}>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell className={classes.blankCell} />

            {columns.map(cell => (
              <TableCell
                key={cell.id}
                className={classes.dense}
                align={cell.align ? cell.align : 'center'}
                padding={cell.disablePadding ? 'none' : 'default'}
              >
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {data.map(({ id, name, bitrates, duration, mimeType }, index) => {
            const isExpanded = expanded[id];

            return (
              <Fragment key={id}>
                <TableRow className={clsx(isExpanded ? classes.root : '')}>
                  <TableCell className={classes.height} padding="none">
                    <IconButton onClick={() => { }} size="medium">
                      <PlayCircleFilledIcon color="secondary" />
                    </IconButton>
                  </TableCell>

                  <TableCell id={`creative-${id}`} padding="none" scope="row">
                    <Grid container alignItems="center">
                      <Grid className={classes.name} item xs>
                        <Tooltip title={name}>
                          <div>
                            <MiddleEllipsis>
                              <span>{name}</span>
                            </MiddleEllipsis>
                          </div>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>

                  <TableCell className={classes.groupCell} align="center">
                    {bitrates}
                  </TableCell>

                  <TableCell align="center">
                    {`${numeral(Math.ceil(duration)).format('0.0')}s`}
                  </TableCell>

                  <TableCell align="center">{mimeType}</TableCell>

                  <TableCell align="center">
                    <Grid container alignItems="center" wrap="nowrap">
                      <Grid item>
                        <Button
                          className={classes.editButton}
                          disableRipple
                          size="small"
                          onClick={() => handleEditClick(id)}
                        >
                          Edit
                        </Button>
                      </Grid>
                      <Divider
                        className={classes.divider}
                        orientation="vertical"
                        flexItem
                      />
                      <Grid item>
                        <VastTagListItemAction
                          onRemove={() => onRemove(id)}
                        />
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>

                {isExpanded && (
                  <TableRow>
                    <TableCell className={classes.expanded} colSpan={6}>
                      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                        <VastTagListExtension
                          name={`vastTags.${index}`}
                          onEdit={tag => handleEdit(tag)}
                          onRemove={() => onRemove(id)}
                        />
                      </Collapse>
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            );
          })}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

VastTagList.propTypes = {
  data: PropTypes.array.isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default VastTagList;
