import { faRocket } from '@fortawesome/pro-regular-svg-icons';
import React, { Fragment } from 'react';
import {
  SectionPreviewIcon,
  SectionPreviewTypography,
  sectionProps,
  useWizardPreview,
} from '../../Wizard';
import { useCopy } from '@hooks';
import { Copies } from './constants';

const ReviewSectionPreview = ({ sectionKey }) => {
  const Copy = useCopy(Copies);
  const { active } = useWizardPreview({ key: sectionKey });
  return (
    <Fragment>
      <SectionPreviewIcon isActive={active} icon={faRocket} />
      <SectionPreviewTypography isActive={active}>
        {Copy.title}
      </SectionPreviewTypography>
    </Fragment>
  );
};

ReviewSectionPreview.propTypes = {
  ...sectionProps,
};

export default ReviewSectionPreview;
