import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import FileCopyIcon from '@mui/icons-material/FileCopy';

const Code = styled('code')({
  whiteSpace: 'pre',
  overflowX: 'auto',
  paddingTop: 0,
});

const CodeBlock = ({ children, ...rest }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(children);

    setCopied(true);
    const timer = setTimeout(() => setCopied(false), 2000);

    return () => clearTimeout(timer);
  };

  return (
    <Box position="relative" {...rest}>
      <Code>{children}</Code>
      <Tooltip title={copied ? 'Copied!' : 'Copy'}>
        <IconButton
          size="small"
          onClick={handleCopy}
          sx={{
            position: 'absolute',
            right: 1,
            top: 1,
          }}
        >
          <FileCopyIcon />
        </IconButton>
      </Tooltip>
    </Box>
  );
}

CodeBlock.propTypes = {
  children: PropTypes.string.isRequired,
};

export default CodeBlock;
