import React from 'react';
import PropTypes from 'prop-types';
import { icons } from './constants';

const Icon = ({ name, ...props }) => {
  const icon = icons[name];
  return icon ? <img {...props} src={icon} /> : null;
};

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
};

export default Icon;
