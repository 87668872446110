import useSWRMutation from 'swr/mutation';
import { useCurrentSession } from '../currentSession';

export const useGetInventoryAvailability = () => {
  const { post, apiIsReady } = useCurrentSession();

  const url = 'targetingreach/inventory/';

  return useSWRMutation(apiIsReady ? url : null, (url, { arg }) =>
    post(url, arg).then(res => res.data.inventory_availability),
  );
};
