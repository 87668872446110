import React, { useRef, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Button, ButtonGroup, Collapse, Grid, Typography } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails
} from './ui/Accordians';
import DayParting from './DayParting';
import FrequencyCaps from './FrequencyCaps';
import ManageAdGroup from './ManageAdGroup';
import ManageDisplay from './ManageDisplay';
import ModalWrapper from './ModalWrapper';
import OverlayWrapper from './OverlayWrapper';
import DeviceOverlay from './DeviceOverlay';
import CustomAppTargeting from './CustomAppTargeting';
import { useLoader } from './hooks/loader';
import { useDomain } from './hooks/domain';

const PREFIX = 'AddAdGroup';

const classes = {
  activeButton: `${PREFIX}-activeButton`,
  buttonGroup: `${PREFIX}-buttonGroup`,
  inactiveButton: `${PREFIX}-inactiveButton`,
  panels: `${PREFIX}-panels`
};

const StyledModalWrapper = styled(ModalWrapper)(({
  theme: { spacing }
}) => ({
  [`& .${classes.activeButton}`]: {
    fontSize: '1rem',
    backgroundColor: '#c3e7fa',
    color: '#181c20',
    fontWeight: 'normal',
    boxShadow: 'none !important',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    minWidth: 170,
    '&:hover': {
      backgroundColor: '#c3e7fa',
      cursor: 'unset',
    },

    '& .MuiSvgIcon-root': {
      marginRight: spacing(1),
    },
  },

  [`& .${classes.buttonGroup}`]: {
    marginTop: spacing(2),
    boxShadow: 'none',

    '& .MuiButton-root': {
      border: 'none',
    },
  },

  [`& .${classes.inactiveButton}`]: {
    fontSize: '1rem',
    backgroundColor: '#ffffff',
    color: '#181c20',
    fontWeight: 'normal',
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
    minWidth: 170,
    '&:hover': {
      color: '#ffffff',
    },
  },

  [`& .${classes.panels}`]: {
    height: 720,
    overflow: 'auto',
    minHeight: 720,
  }
}));

// Edit sections
const panels = [
  { title: 'Select Ad Group Type', id: 'type' },
];

const AddAdGroup = props => {
  const domain = useDomain();

  const {
    campaign,
    adGroup,
    isNew,
    isOpen,
    showVastTag,
    onClose,
    setHasSaved,
    showGenre,
    setCampaign,
    adGroupRef = useRef(null),
    displayRef = useRef(null),
    showType = true,
  } = props;

  const { isLoading, setIsLoading } = useLoader();
  const {
    isLoading: isUploading,
    setIsLoading: setIsUploading
  } = useLoader();

  const [isAgeError, setIsAgeError] = useState(false);
  const [type, setType] = useState('');
  const [expanded, setExpanded] = useState({
    type: true,
  });
  const [isDayParts, setIsDayParting] = useState(false);
  const [dayParts, setDayParts] = useState([]);
  const [isFreqCaps, setIsFreqCaps] = useState(false);
  const [freqCaps, setFreqCaps] = useState([]);
  const [isCustomTargeting, setIsCustomTargeting] = useState(false);
  const [customTargeting, setCustomTargeting] = useState({});
  const [isDeviceOverlay, setIsDeviceOverlay] = useState(false);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!showType) {
      setType('ctv-ott');
    }
  }, [showType]);

  const handleExpanded = panel => event => {
    if (event && event.target != null) {
      setExpanded(x => ({
        ...x,
        [panel]: !x[panel],
      }));
    }
  };

  const handleCreateAdGroup = async () => {
    try {
      setIsLoading(true);

      const res = await adGroupRef.current.handleUpdateAdGroup();

      console.log('res from save ad', res)

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error Saving new Ad Group', error);
    }
  };

  const handleCreateDisplay = async () => {
    try {
      setIsLoading(true);

      const res = await displayRef.current.handleUpdateDisplay();

      console.log('res from save display', res)

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error('Error Saving new Ad Group', error);
    }
  };

  const handleCloseOverlay = () => {
    setIsFreqCaps(false);
    setIsDayParting(false);
    setIsDeviceOverlay(false);
    setIsCustomTargeting(false);
  };

  const renderType = () => (
    <ButtonGroup
      variant="contained"
      color="primary"
      aria-label="contained primary button group"
      className={classes.buttonGroup}
    >
      <Button
        disableRipple
        color="secondary"
        size="large"
        className={
          type === 'ctv-ott'
            ? classes.activeButton
            : classes.inactiveButton
        }
        onClick={() => setType('ctv-ott')}
      >
        CTV OTT
      </Button>

      <Button
        disableRipple
        color="secondary"
        size="large"
        className={
          type === 'display'
            ? classes.activeButton
            : classes.inactiveButton
        }
        onClick={() => setType('display')}
      >
        Display Retargeting
      </Button>
    </ButtonGroup>
  );

  // // Handle component renders
  const renderPanelDetails = id => {
    switch (id) {
      case 'type':
        return renderType();
      default:
        return null;
    }
  };

  // Render an accordion panel for each editable section
  const renderPanel = panel => (
    <Accordion
      expanded={expanded[panel.id]}
      onChange={handleExpanded(panel.id)}
      TransitionProps={{ unmountOnExit: panel.id !== 'demo' }}
    >
      <AccordionSummary aria-controls="adgroup-panel" id={`edit-${panel.id}`}>
        <Grid
          className={classes.panelHeader}
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <Grid item>
            <Typography>{panel.title}</Typography>
          </Grid>

          <Grid item>
            {expanded[panel.id] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Box py={1} px={2} width="100%">
          {renderPanelDetails(panel.id)}
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  const renderOverlay = () => (
    <OverlayWrapper
      isOpen={isFreqCaps || isDayParts || isDeviceOverlay || isCustomTargeting}
      onClose={handleCloseOverlay}
      aria-labelledby="overlay-dialog"
    >
      {isFreqCaps && (
        <FrequencyCaps
          isManage
          setIsFreqCaps={setIsFreqCaps}
          freqCaps={freqCaps}
          setFreqCaps={setFreqCaps}
        />
      )}

      {isDayParts && (
        <DayParting
          isManage
          setDayParting={setIsDayParting}
          dayParts={dayParts}
          setDayParts={setDayParts}
          title={`Configure ${domain.peacock ? 'dayparting' : 'day-parting'}`}
        />
      )}

      {isDeviceOverlay && (
        <DeviceOverlay onClose={handleCloseOverlay} />
      )}

      {isCustomTargeting && (
        <CustomAppTargeting
          isManage
          setIsCustomTargeting={setIsCustomTargeting}
          customTargeting={customTargeting}
          setCustomTargeting={setCustomTargeting}
        />
      )}
    </OverlayWrapper>
  )

  return (
    <StyledModalWrapper
      isDisabled={isAgeError}
      isOpen={isOpen}
      isLoading={isLoading}
      isUploading={isUploading}
      isOverlay={isFreqCaps || isDayParts || isDeviceOverlay || isCustomTargeting}
      overlay={renderOverlay()}
      onCloseOverlay={handleCloseOverlay}
      onClose={onClose}
      onSubmit={event => {
        event.preventDefault();

        if (type) {
          if (type === 'ctv-ott') {
            return handleCreateAdGroup();
          }

          if (type === 'display') {
            return handleCreateDisplay();
          }
        }
      }}
      submit="Create Ad Group"
      hasSubmit={type !== ''}
    >
      <Box flexGrow={1}>
        <Box mb={4}>
          <Typography variant="h3">Add Ad Group</Typography>
        </Box>

        <Grid container spacing={3}>
          {showType && panels.map((s, i) => (
            <Grid key={s.id} item xs={12}>
              {renderPanel(s, i)}
            </Grid>
          ))}

          {console.log('adGroup', adGroup)}

          <Grid container item xs={12}>
            <Collapse in={type === 'ctv-ott'}>
              {type === 'ctv-ott' &&
                <ManageAdGroup
                  adGroup={adGroup}
                  campaign={campaign}
                  isNew={isNew}
                  isOpen={isOpen}
                  onClose={onClose}
                  showVastTag={showVastTag}
                  adGroupRef={adGroupRef}
                  setCampaign={setCampaign}
                  setHasSaved={setHasSaved}
                  setIsAgeError={setIsAgeError}
                  setIsUploading={setIsUploading}
                  showGenre={showGenre}
                  setIsFreqCaps={setIsFreqCaps}
                  freqCaps={freqCaps}
                  setFreqCaps={setFreqCaps}
                  dayParts={dayParts}
                  setDayParts={setDayParts}
                  setIsDayParting={setIsDayParting}
                  setIsDeviceOverlay={setIsDeviceOverlay}
                  setIsCustomTargeting={setIsCustomTargeting}
                  customTargeting={customTargeting}
                />}
            </Collapse>
          </Grid>

          <Grid container item xs={12}>
            <Collapse
              style={{ width: '100%' }}
              in={type === 'display'}
              timeout={1000}
            >
              <ManageDisplay
                campaign={campaign}
                display={adGroup}
                displayRef={displayRef}
                isOpen={isOpen}
                isNew
                onClose={onClose}
                setHasSaved={setHasSaved}
                setIsUploading={setIsUploading}
              />
            </Collapse>
          </Grid>
        </Grid>
      </Box>
    </StyledModalWrapper>
  );
};

AddAdGroup.propTypes = {
  campaign: PropTypes.object,
  adGroup: PropTypes.object,
  isOpen: PropTypes.bool,
  isNew: PropTypes.bool,
  showType: PropTypes.bool,
  showVastTag: PropTypes.bool,
  onClose: PropTypes.func,
  setCampaign: PropTypes.func,
  setHasSaved: PropTypes.func,
  showGenre: PropTypes.bool,
  adGroupRef: PropTypes.object,
  displayRef: PropTypes.object,
};

export default AddAdGroup;
