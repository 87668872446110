import { Themes } from './themes';

const Nbcu = [
  {
    key: 'CPA',
    value: 'CPA'
  },
  {
    key: 'ROAS',
    value: 'ROAS'
  },
  {
    key: 'CPM',
    value: 'CPM'
  },
]

const Default = [
  {
    key: 'CPA',
    value: 'Optimize CPA'
  },
  {
    key: 'ROAS',
    value: 'Optimize ROAS'
  },
  {
    key: 'CPM',
    value: 'Optimize CPM'
  },
]

export const GoalsListThemes = {
  [Themes.DEFAULT]: Default,
  [Themes.NBCU]: Nbcu,
};
