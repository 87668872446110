import RequiredEventOptionalMaxCPMStrategy from './RequiredEventOptionalMaxCPMStrategy';
import OptionalMaxCPMStrategy from './OptionalMaxCPMStrategy';
import FlatCPMStrategy from './FlatCPMStrategy';
import RequiredEventRequiredTargetStrategy from './RequiredEventRequiredTargetStrategy';
import RequiredCPMStrategy from './RequiredCPMStrategy'
import UnsupportedStrategy from './UnsupportedStrategy';

export const Strategies = {
  RequiredEventOptionalMaxCPMStrategy,
  OptionalMaxCPMStrategy,
  FlatCPMStrategy,
  RequiredEventRequiredTargetStrategy,
  UnsupportedStrategy,
  RequiredCPMStrategy
};
