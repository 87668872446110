import React from 'react'
import { HtmlTooltip } from "@components/styledComponents";
import styled from "@emotion/styled";

const StyledTooltip = styled(({ className, ...props }) => (
  <HtmlTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  fontSize: 12,
  '& .MuiTooltip-tooltip': {
    background: '#EDF1F2',
    color: 'rgba(0, 0, 0, 0.87)',
    width: 269,
    fontSize: 12,
    border: '1px solid #dadde9',
    borderRadius: '4px',
    boxShadow: theme.shadows[1],
    padding: '16px',
    fontWeight: 700,
  },
}));

export default StyledTooltip;
