import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Grid,
  Button,
  Typography,
} from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import ModalWrapper from './ModalWrapper';
import CodeBlock from './CodeBlock';
import { useDomain } from './hooks/domain';

const AddMobileAppTrackingEvent = ({ isOpen, data, onClose }) => {
  const domain = useDomain();
  const {
    outcomeLid,
    integrationName = null,
    onIntegrationGuideClick = null,
  } = data;

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <Box flexGrow={1}>
        <Box mb={4}>
          <Typography variant="h3">Add Mobile App Tracking Event</Typography>
        </Box>

        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h4">Step 1: Copy your Tracking ID</Typography>

            <CodeBlock
              width="50%"
              my={2}
            >
              {outcomeLid}
            </CodeBlock>
          </Grid>

          {onIntegrationGuideClick && integrationName && (domain.default) && (
            <Grid item>
              <Typography variant="h4">Step 2: Open the {integrationName} Integration Guide</Typography>

              <Box my={2}>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  endIcon={<CallMadeIcon />}
                  onClick={onIntegrationGuideClick}
                >
                  Open Integration Guide
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
      </Box>
    </ModalWrapper>
  );
};

AddMobileAppTrackingEvent.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.shape({
    outcomeLid: PropTypes.string.isRequired,
    integrationName: PropTypes.string,
    onIntegrationGuideClick: PropTypes.func
  }),
  onClose: PropTypes.func.isRequired,
};

export default AddMobileAppTrackingEvent;
