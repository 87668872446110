import { getDays } from './constants';

export const convertTime = time => {
  let hours = time >= 12 ? time - 12 : time;
  if (hours === 0) {
    hours = 12;
  }
  const period = time >= 12 ? 'PM' : 'AM';
  return `${hours}${period}`;
};
export const convertTimeRangeToAmPM = data => {
  const startTime = convertTime(data[0]);
  const endTime = convertTime(data[1]);
  return `${startTime} - ${endTime}`;
};

export function valueToTime(value) {
  const amPm = value < 12 || value === 24 ? 'AM' : 'PM';
  let hour = value % 12;
  hour = hour ? hour : 12;
  return `${hour} ${amPm}`;
}

export const daysOfWeek = [
  {
    label: 'Sun',
    value: 'su',
  },
  {
    label: 'Mon',
    value: 'mo',
  },
  {
    label: 'Tue',
    value: 'tu',
  },
  {
    label: 'Wed',
    value: 'we',
  },
  {
    label: 'Thu',
    value: 'th',
  },
  {
    label: 'Fri',
    value: 'fr',
  },
  {
    label: 'Sat',
    value: 'sa',
  },
];

const daysList = getDays();

export const toDaysList = days =>
  Object.entries(days)
    .filter(([, v]) => v.selected)
    .map(([k]) => daysList[k].day)
    .join(', ');

export const convertTimeToExtended = value => [value[0] + 12, value[1] + 12];
