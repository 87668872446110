import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { Icon } from './Icon';

const PREFIX = 'BidStrategyBadge';

const classes = {
  container: `${PREFIX}-container`,
  icon: `${PREFIX}-icon`,
  strategyName: `${PREFIX}-strategyName`
};

const Root = styled('div')(({
  theme: { spacing }
}) => ({
  [`&.${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'start',
    gap: spacing(0.75),
    whiteSpace: 'nowrap',
  },

  [`& .${classes.icon}`]: {
    fontSize: '1rem',
  },

  [`& .${classes.strategyName}`]: {
    fontWeight: 400,
    fontSize: 14,
  }
}));

export const BidStrategyBadge = ({ bidStrategy }) => (
  <Root className={classes.container}>
    {bidStrategy ? (
      <Fragment>
        <Icon />
        <span className={classes.strategyName}>
            {bidStrategy.display_name}
          </span>
        {bidStrategy?.tooltip && (
          <Tooltip title={bidStrategy.tooltip}>
            <InfoOutlinedIcon
              className={classes.icon}
              fontSize="small"
              color="secondary"
            />
          </Tooltip>
        )}
      </Fragment>
    ) : (
      <span className={classes.strategyName}>Manual</span>
    )}
  </Root>
);

BidStrategyBadge.propTypes = {
  bidStrategy: PropTypes.object,
};
