import React, { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Avatar,
  Box,
  Button,
  ClickAwayListener,
  Grid,
  InputAdornment,
  InputBase,
  Popper,
  Typography,
  Autocomplete,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SearchIcon from '@mui/icons-material/Search';
import PropTypes from 'prop-types';

import { useAdvertisers } from '../../hooks';

import VirtualizedList from './VirtualizedList';
import AdAccountOption from './AdAccountOption';

const PREFIX = 'AdAccountSwitcher';

const classes = {
  avatar: `${PREFIX}-avatar`,
  avatarAdUser: `${PREFIX}-avatarAdUser`,
  listbox: `${PREFIX}-listbox`,
  orgName: `${PREFIX}-orgName`,
  popperDisablePortal: `${PREFIX}-popperDisablePortal`,
  userButton: `${PREFIX}-userButton`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.avatar}`]: {
    width: `40px`,
    height: `40px`,
    fontSize: `18px !important`,
  },

  [`& .${classes.avatarAdUser}`]: {
    color: 'rgb(255 255 255 / 60%)',
    fontSize: '0.785rem',
  },

  [`& .${classes.listbox}`]: {
    maxHeight: '70vh',
  },

  [`& .${classes.orgName}`]: {
    fontSize: '1.015rem',
  },

  [`& .${classes.popperDisablePortal}`]: {
    position: 'relative',
  },

  [`& .${classes.userButton}`]: {
    color: '#ffffff',
  }
}));

const StyledPopper = styled(Popper)(() => ({
  borderRadius: 3,
  border: '1px solid rgba(27,31,35,.15)',
  boxShadow: '0 3px 12px rgba(27,31,35,.15)',
  minWidth: 375,
  maxWidth: 500,
  zIndex: 1,
  fontSize: 13,
  color: '#586069',
  backgroundColor: '#f6f8fa',
}));

const StyledInputBase = styled(InputBase)(({ theme: { palette, transitions } }) => ({
  padding: 10,
  width: '100%',
  borderBottom: '1px solid #dfe2e5',

  '& input': {
    borderRadius: 4,
    backgroundColor: palette.common.white,
    padding: 8,
    transition: transitions.create(['border-color', 'box-shadow']),
    border: '1px solid #ced4da',
    fontSize: '.825rem',

    '&:focus': {
      borderColor: palette.secondary.main,
    },
  },
}));

const TVSAvatar = props => (
  <Avatar className={props.className}>{props.letter}</Avatar>
);

TVSAvatar.propTypes = {
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  letter: PropTypes.string,
};

const AdAccountSwitcher = () => {
  const history = useHistory();
  const {
    advertisers,
    fetchingAdvertisers,
    currentAdvertiser,
    updateCurrentAdvertiser,
  } = useAdvertisers();
  const location = useLocation();

  const anchorRef = useRef(null);

  const [search, setSearch] = useState('');
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    // If focusing the input, do not close
    if (reason === 'toggleInput') {
      return;
    }

    if (reason !== 'blur') {
      setOpen(false);
    }
  };

  const handleChangeAdvertiser = advertiser => {
    setOpen(false);

    if (
      ![
        '/',
        '/campaigns',
        '/reports',
        '/creatives',
        '/tracking',
        '/support',
        '/manager',
      ].includes(location.pathname)
    ) {
      return history.push('/', { advertiser });
    }

    return updateCurrentAdvertiser(advertiser);
  };

  const handleOpenAdvertisers = () => {
    setOpen(true);
  };

  return (
    <StyledGrid item>
      <Button
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        ref={anchorRef}
        className={classes.userButton}
        disableRipple
        onClick={handleOpenAdvertisers}
        startIcon={
          <TVSAvatar
            letter={
              currentAdvertiser &&
              currentAdvertiser.id &&
              currentAdvertiser.name.charAt(0)
            }
            className={classes.avatar}
          />
        }
        endIcon={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        data-testid="ad-account-switcher-button"
      >
        <Box
          display="flex"
          flexDirection="column"
          m={0}
          p={0}
          textAlign="left"
        >
          <Typography
            variant="h4"
            color="inherit"
            className={classes.orgName}
          >
            {currentAdvertiser?.org_name}
          </Typography>

          <Typography variant="body2" className={classes.avatarAdUser}>
            {currentAdvertiser?.ad_account_name}
          </Typography>
        </Box>
      </Button>

      <StyledPopper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement="bottom-start"
        disablePortal
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Autocomplete
            open
            onClose={handleClose}
            classes={{
              popperDisablePortal: classes.popperDisablePortal,
              listbox: classes.listbox,
            }}
            ListboxComponent={VirtualizedList}
            inputValue={search}
            onInputChange={(event, newValue, reason) => {
              if (reason === 'input') {
                setSearch(newValue);
              }
            }}
            onChange={(event, value, reason) => {
              if (reason === 'selectOption') {
                handleChangeAdvertiser(value);
              }
            }}
            value={currentAdvertiser}
            size="small"
            style={{ maxHeight: '75vh' }}
            blurOnSelect
            clearOnEscape
            disablePortal
            includeInputInList
            loading={fetchingAdvertisers}
            loadingText="Loading advertisers..."
            renderTags={() => null}
            noOptionsText={`No results for "${search}"`}
            groupBy={advertiser => advertiser.org_name}
            renderGroup={params => params}
            filterOptions={(options, params) =>
              options.filter(option =>
                `${option.ad_account_name} ${option.org_name}`
                  .toLowerCase()
                  .includes(params.inputValue.toLowerCase())
              )
            }
            getOptionLabel={advertiser => advertiser.ad_account_name}
            renderOption={(props, advertiser, { selected }) => (
              <AdAccountOption {...props} advertiser={advertiser} selected={selected} />
            )}
            options={advertisers || []}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
            selectOnFocus
            slotProps={{
              paper: {
                sx: {
                  boxShadow: '0px 15px 15px rgba(27,31,35,.15)',
                  margin: 0,
                  borderRadius: '0px 0px 3px 3px',
                  color: '#586069',
                  fontSize: 13,
                }
              },
            }}
            renderInput={params => (
              <StyledInputBase
                autoFocus
                color="secondary"
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                placeholder="Search advertisers"
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                }
              />
            )}
            data-testid="ad-account-switcher-menu"
          />
        </ClickAwayListener>
      </StyledPopper>
    </StyledGrid>
  );
};

AdAccountSwitcher.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.object,
  advertisers: PropTypes.array,
  currentAdvertiser: PropTypes.object,
  updateAdvertiser: PropTypes.func,
};

export default AdAccountSwitcher;
