import React, { Fragment } from 'react';
import {
  SubwayElementTitle,
  SubwayNode,
  SubwayVerticalDivider,
  sectionProps,
  subwayProps,
  useWizardSubwaySection,
} from '../../Wizard';
import { Stack } from '@mui/material';

const labels = {
  review: {
    label: 'Review & Launch',
    value: 'review',
  },
};

const nodes = [labels.review];

const ReviewSectionSubway = ({ sectionKey, disabled }) => {
  const { active, setActiveSection, setActivePane, getPaneStatus } =
    useWizardSubwaySection({
      key: sectionKey,
      defaultPaneKey: labels.review.value,
    });

  const handleUpdatePane = paneId => () => {
    setActivePane(paneId);
  };

  return (
    <Fragment>
      <SubwayElementTitle
        disabled={disabled}
        expanded={active}
        title="Review & Launch"
        onChange={() => setActiveSection(sectionKey)}
      />

      {active && (
        <Stack alignItems="flex-start" useFlexGap>
          {nodes.map(node => (
            <Fragment key={node.value}>
              <SubwayVerticalDivider length="short" />
              <SubwayNode
                label={node.label}
                onClick={handleUpdatePane(node.value)}
                status={getPaneStatus(node.value)}
              />
            </Fragment>
          ))}
        </Stack>
      )}
    </Fragment>
  );
};

ReviewSectionSubway.propTypes = {
  ...subwayProps,
  ...sectionProps,
};

export default ReviewSectionSubway;
