import React, { forwardRef } from 'react';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

export const Icon = forwardRef((props, ref) => (
  <ReportProblemOutlinedIcon
    ref={ref}
    color="error"
    fontSize="small"
    {...props}
  />
));

Icon.displayName = 'Icon';
