import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Typography
} from '@mui/material';

const StyledCheckbox = (props) => {
  const initialChecked = () =>
    props.checked !== null && props.checked !== undefined
      ? props.checked
      : false;

  const [checked, setChecked] = useState(initialChecked());

  useEffect(() => {
    setChecked(props.checked);
  }, [props.checked]);

  const handleChecked = (event) => {
    setChecked(event.target.checked);

    if (props.onChange) {
      props.onChange(event.target.name);
    }
  };

  return (
    <Box m={1} className="Button-choice">
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            name={props.name}
            onChange={handleChecked}
          />
        }
        label={
          <Typography style={{ fontSize: '.8rem' }} variant="body2">
            {props.name}
          </Typography>
        }
      />
    </Box>
  );
};

StyledCheckbox.propTypes = {
  checked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
};

export default StyledCheckbox;
