import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, styled, Typography } from '@mui/material';
import { classes } from './constants';

export const StyledBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.blue[11],
  padding: '16px 24px',
  gap: theme.spacing(2),
  borderRadius: 5,

  [`& .${classes.iconContainer}`]: {
    color: theme.palette.blue[4],
  },
}));

export const StyledPenIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.blue[6],
  cursor: 'pointer',
  marginLeft: 'auto',
}));

export const StyledPlaceholder = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[4],
}));
