import React, { useContext } from 'react';
import { PingContext } from '../../providers/PingContext';

interface AuthState {
  isAuthenticated: boolean;
  isPending: boolean;
  accessToken?: string;
  refreshToken?: string;
  expiresIn?: number;
  expiryDate?: number;
}

interface PingAuth {
  getAuthTokens: (code: string) => Promise<void>;
  revokeAccessToken: () => Promise<any>;
  getUser: () => Promise<{ email: string; name: string }>;
  signOut: () => Promise<void>;
  clear: () => void;
}

export interface PingContextType {
  authState: AuthState;
  pingAuth: PingAuth;
}

export const usePingAuth = (): PingContextType => {
  const context = useContext(PingContext);
  if (context === undefined) {
    throw new Error(
      'usePingAuth PingContext was used outside of its Provider'
    );
  }
  return context;
};
