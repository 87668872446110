import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { logger, useNotification } from '@swr';
import { defaultTheme_v2 } from '@themes';
import React, { Suspense, lazy } from 'react';
import AppHeader from '@components/AppHeader';
import { SWRConfig } from 'swr';

const WizardPageContent = lazy(() => import('./WizardPageContent'));

export const WizardPage = () => {
  return (
    <ThemeProvider theme={defaultTheme_v2}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SWRConfig
          value={{
            use: [logger, useNotification],
            revalidateOnFocus: false,
          }}
        >
          <AppHeader>
            <Suspense fallback={'Loading...'}>
              <WizardPageContent />
            </Suspense>
          </AppHeader>
        </SWRConfig>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
