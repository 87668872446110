import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { faPlusCircle, faXmark } from '@fortawesome/pro-regular-svg-icons';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Grid,
  IconButton,
  MenuItem,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';

import {
  AD_CAP_DURATION_OPTIONS as options,
  AdCapDurationLabelKeys,
} from '@v2/components/forms/AdvancedDeliveryOptionsForm/constants';
import { defaultValues } from './formConfig';
import {
  StyledAddButton,
  StyledAddLink,
  StyledAddPlusIcon,
  StyledCircledIndexNumber,
  StyledCircledIndexNumberContainer,
  StyledRightAlignedItem,
  StyledSubmitButton,
  StyledXIcon,
} from './styles';
import { isEmpty, omit } from 'lodash';

const StyledNumberField = styled(TextField)(({ theme }) => ({
  width: theme.spacing(10),

  '&[type=number]': {
    MozAppearance: 'textfield',
  },

  '&::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },

  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0,
  },
}));

const StyledAdCapCountContainer = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(-3.375),
}));

function FrequencyCapFieldsGroup({ fieldName, ...props }) {
  const { control, formState, getFieldState, getValues } = useFormContext();
  const { fields, replace, update } = useFieldArray({
    name: fieldName,
    control,
  });

  const isEditing = useMemo(
    () => fields.some(field => field.isEditing),
    [fields],
  );

  const handleAdd = useCallback(
    index => {
      const values = getValues(`${fieldName}.${index}`);

      return update(index, { ...values, isEditing: false });
    },
    [fieldName, getValues],
  );

  const remove = index => {
    replace(fields.filter((_, i) => i !== index));
  };

  useEffect(() => {
    if (fields.length === 0) {
      replace([defaultValues]);
    }
  }, [fields]);

  return (
    <Stack spacing={2} {...props}>
      {fields.map((field, index) => {
        const { error } = getFieldState(`${fieldName}.${index}`, formState);

        const invalid = !isEmpty(omit(error, ['isEditing']));
        const unit = AdCapDurationLabelKeys[field.fctype];

        if (field.isEditing) {
          return (
            <Grid key={field.id} alignItems="center" container spacing={2}>
              <StyledAdCapCountContainer item>
                <Controller
                  name={`${fieldName}.${index}.impressions`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <StyledNumberField
                      {...field}
                      data-testid="ad-cap-count"
                      error={fieldState.invalid}
                      id="ad-cap-count"
                      type="number"
                    />
                  )}
                />
              </StyledAdCapCountContainer>
              <Grid item>
                <Typography>ads every</Typography>
              </Grid>
              <Grid item>
                <Controller
                  name={`${fieldName}.${index}.duration`}
                  control={control}
                  render={({ field, fieldState }) => (
                    <StyledNumberField
                      {...field}
                      data-testid="ad-cap-duration"
                      error={fieldState.invalid}
                      id="ad-cap-duration"
                      type="number"
                    />
                  )}
                />
              </Grid>
              <Grid item>
                <Controller
                  name={`${fieldName}.${index}.fctype`}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      defaultValue="DAY"
                      data-testid="ad-cap-unit"
                      id="ad-cap-unit"
                      select
                    >
                      {options.map(({ label, value }) => (
                        <MenuItem
                          key={value}
                          data-testid={`${value}-option`}
                          value={value}
                        >
                          {label}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              </Grid>
              <StyledRightAlignedItem item>
                <StyledSubmitButton
                  color="secondary"
                  disabled={invalid}
                  onClick={() => handleAdd(index)}
                  startIcon={<AddCircleOutlineIcon />}
                  variant="contained"
                >
                  Add
                </StyledSubmitButton>
              </StyledRightAlignedItem>
            </Grid>
          );
        }

        return (
          <Grid key={field.id} container spacing={1.5}>
            <StyledCircledIndexNumberContainer item>
              <StyledCircledIndexNumber>{index + 1}</StyledCircledIndexNumber>
            </StyledCircledIndexNumberContainer>
            <Grid item>
              <Typography>
                {field.impressions} ads every {field.duration} {unit}
              </Typography>
            </Grid>
            <StyledRightAlignedItem item>
              <IconButton aria-label="delete" onClick={() => remove(index)}>
                <StyledXIcon icon={faXmark} size="xs" />
              </IconButton>
            </StyledRightAlignedItem>
          </Grid>
        );
      })}

      {!isEditing && (
        <Grid container item>
          <StyledAddButton onClick={() => replace([...fields, defaultValues])}>
            <StyledAddPlusIcon icon={faPlusCircle} size="lg" />
            <StyledAddLink>Add Frequency Cap</StyledAddLink>
          </StyledAddButton>
        </Grid>
      )}
    </Stack>
  );
}

FrequencyCapFieldsGroup.propTypes = {
  ...Stack.propTypes,
  fieldName: PropTypes.string.isRequired,
};

export default FrequencyCapFieldsGroup;
