import { Themes } from '@constants/themes';
import PropTypes from 'prop-types';
import { array, bool, string } from 'yup';
import {
  validationSchema as freqCapValidationSchema
} from '@v2/components/campaign/FrequencyCapFieldsGroup/formConfig';

export const AD_CAP_DURATION_OPTIONS = [
  {
    label: 'Minute(s)',
    value: 'MIN',
  },
  {
    label: 'Hour(s)',
    value: 'HOUR',
  },
  {
    label: 'Day(s)',
    value: 'DAY',
  },
  {
    label: 'Week(s)',
    value: 'WEEK',
  },
];

export const AdCapDurationLabelKeys = Object.values(AD_CAP_DURATION_OPTIONS).reduce(
  (acc, { label, value }) => ({
    ...acc,
    [value]: label,
  }),
  {},
);


export const AdCapUnitTypes = PropTypes.oneOf(
  AD_CAP_DURATION_OPTIONS.map(({ value }) => value),
);

export const Copies = {
  [Themes.DEFAULT]: {
    '2_WAY_SPLIT': '2-way test',
    '3_WAY_SPLIT': '3-way test',
    '4_WAY_SPLIT': '4-way test',
    experimentLabel: 'Run an A/B test',
    frequencyCapLabel: 'Configure Campaign Frequency Cap',
    incrementalityLabel: 'Test incrementality',
    title: 'Advanced Campaign Delivery Options',
  },
  [Themes.NBCU]: {
    '2_WAY_SPLIT': '2-way Test',
    '3_WAY_SPLIT': '3-way Test',
    '4_WAY_SPLIT': '4-way Test',
    experimentLabel: 'Run an A/B Test',
    incrementalityLabel: 'Test Incrementality',
  },
};

export const EXPERIMENT_OPTIONS = [
  '2_WAY_SPLIT',
  '3_WAY_SPLIT',
  '4_WAY_SPLIT',
];

export const INCREMENTAL_OPTIONS = [
  {
    label: '1%',
    value: '1PCT_HOLDOUT',
  },
  {
    label: '5%',
    value: '5PCT_HOLDOUT',
  },
  {
    label: '10%',
    value: '10PCT_HOLDOUT',
  },
  {
    label: '20%',
    value: '20PCT_HOLDOUT',
  },
  {
    label: '25%',
    value: '25PCT_HOLDOUT',
  },
  {
    label: '50%',
    value: '50PCT_HOLDOUT',
  },
];

export const IncrementalOptionsLabelKeys = Object.values(INCREMENTAL_OPTIONS).reduce(
  (acc, { label, value }) => ({
    ...acc,
    [value]: label,
  }),
  {},
);

export const fields = {
  freqCaps: {
    path: 'freqCaps',
    defaultValue: [],
    rule: array()
      .when('isCapAds', {
        is: true,
        then: array()
          .of(freqCapValidationSchema())
          .required('Frequency caps are required')
          .min(1, 'At least one frequency cap is required')
          .required('Frequency caps are required when isCapAds is true')
      }),
  },
  experimentPlan: {
    path: 'experimentPlan',
    defaultValue: '',
    rule: string()
      .when('isExperiment', {
        is: true,
        then: s => s.required()
      }),
  },
  experimentType: {
    path: 'experimentType',
    defaultValue: '',
    rule: string()
      .when('isIncremental', {
        is: true,
        then: s => s.required()
      }),
  },
  isCapAds: {
    path: 'isCapAds',
    defaultValue: false,
    rule: bool(),
  },
  isExperiment: {
    path: 'isExperiment',
    defaultValue: false,
    rule: bool(),
  },
  isIncremental: {
    path: 'isIncremental',
    defaultValue: false,
    rule: bool(),
  },
};
