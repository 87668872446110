import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

export default function Title(props) {
  return (
    <Typography
      id={props.id}
      component="h3"
      gutterBottom
      variant="h3"
      className={props.className ? props.className : ''}
    >
      {props.children}
    </Typography>
  );
}

Title.propTypes = {
  className: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  children: PropTypes.node,
  id: PropTypes.string
};
