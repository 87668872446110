export interface MinorityData<T> {
  data: T[];
  isAllSelected: boolean;
  isAllUnselected: boolean;
  isSelectedMinority: boolean;
  selected: T[];
  unselected: T[];
}

export const getMinorityData = <T extends Record<string, any>>(data: T[], key: keyof T = 'selected'): MinorityData<T> => {
  const [selected, unselected] = data.reduce<[T[], T[]]>((acc, curr) => {
    const index = curr[key] ? 0 : 1;
    acc[index].push(curr);
    return acc;
  }, [[], []]);

  const isSelectedMinority = selected.length < unselected.length || unselected.length === 0;

  return {
    data: isSelectedMinority ? selected : unselected,
    isAllSelected: data.length === selected.length,
    isAllUnselected: data.length === unselected.length,
    isSelectedMinority,
    selected,
    unselected,
  };
};
