import React from 'react';
import { AccordionDetails } from '@mui/material';
import PropTypes from 'prop-types';

export const SectionDetails = ({ children, ...props }) => {
  return <AccordionDetails {...props}>{children}</AccordionDetails>;
};

SectionDetails.propTypes = {
  ...AccordionDetails.propTypes,
  children: PropTypes.node,
};
