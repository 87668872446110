import React, { Fragment, useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import numeral from 'numeral';
import { useStateMachine } from 'little-state-machine';
import {
  Box,
  Button,
  CardMedia,
  Chip,
  Divider,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import CampaignFooter from './CampaignFooter';
import Title from './Title';
import AdvertiserContext from './AdvertiserContext';

import configureIcon from '../images/icon-config-freqcaps.png';
import reachLogos from '../images/inventory-logos/reach.png';
import bundlesLogos from '../images/inventory-logos/bundles.png';
import { getMinorityData, joinWithAnd } from '../helpers';
import { packageCopy } from '../constants';
import { useDomain } from './hooks/domain';

const PREFIX = 'Inventory';

const classes = {
  chips: `${PREFIX}-chips`,
  configBtn: `${PREFIX}-configBtn`,
  dividerSelected: `${PREFIX}-dividerSelected`,
  divider: `${PREFIX}-divider`,
  inputs: `${PREFIX}-inputs`,
  isEditing: `${PREFIX}-isEditing`,
  hover: `${PREFIX}-hover`,
  pack: `${PREFIX}-pack`,
  paper: `${PREFIX}-paper`,
  paperHeight: `${PREFIX}-paperHeight`,
  paperLast: `${PREFIX}-paperLast`,
  cpmText: `${PREFIX}-cpmText`,
  text: `${PREFIX}-text`,
  tiers: `${PREFIX}-tiers`,
  tierWrap: `${PREFIX}-tierWrap`,
  tierWrapLast: `${PREFIX}-tierWrapLast`,
  title: `${PREFIX}-title`,
  freqCap: `${PREFIX}-freqCap`,
  tooltipWidth: `${PREFIX}-tooltipWidth`,
  info: `${PREFIX}-info`,
  freqCapButton: `${PREFIX}-freqCapButton`
};

const StyledBox = styled(Box)((
  {
    theme
  }
) => ({
  [`& .${classes.chips}`]: {
    backgroundColor: '#c3e7fa',
    color: '#1dafff',
    fontSize: '.75rem',
    lineHeight: 'normal',
    letterSpacing: 'normal',
    minWidth: 78,
    maxHeight: 25,
  },

  [`& .${classes.configBtn}`]: {
    color: '#5c6b73',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.dividerSelected}`]: {
    backgroundColor: theme.palette.secondary.main,
  },

  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.inputs}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.isEditing}`]: {
    minHeight: 440,
  },

  [`& .${classes.hover}`]: {
    [`&:hover`]: {
      boxShadow: `0 5px 5px -3px rgba(74, 172, 226, 0.38), 0 3px 6px 2px rgba(74, 172, 226, 0.37), 0 4px 6px 1px rgba(74, 172, 226, 0.47)`,
    },
  },

  [`& .${classes.pack}`]: {
    backgroundColor: `rgba(29, 175, 255, 0.2)`,
  },

  [`& .${classes.paper}`]: {
    height: '100%',
    cursor: `pointer`,
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    [`& h2`]: {
      fontSize: `1.3rem`,
      fontWeight: `bold`,
      margin: 0,
    },

    [`& p`]: {
      fontSize: `0.75rem`,
    },
  },

  [`& .${classes.paperHeight}`]: {
    minHeight: 402,
  },

  [`& .${classes.paperLast}`]: {
    paddingRight: 0,
  },

  [`& .${classes.cpmText}`]: {
    ...theme.typography.paragraph,
    fontWeight: 500,
  },

  [`& .${classes.text}`]: {
    ...theme.typography.paragraph,
  },

  [`& .${classes.tiers}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'stretch',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },

  [`& .${classes.tierWrap}`]: {
    alignSelf: 'stretch',
    width: 'calc(100% / 3)',
    marginRight: theme.spacing(2),
  },

  [`& .${classes.tierWrapLast}`]: {
    marginRight: 0,
  },

  [`& .${classes.title}`]: {
    margin: 0,
  },

  [`& .${classes.freqCap}`]: {
    color: '#47505d',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.tooltipWidth}`]: {
    backgroundColor: `#f2f3f5`,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    maxWidth: 180,
  },

  [`& .${classes.info}`]: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },

  [`& .${classes.freqCapButton}`]: {
    alignItems: 'flex-start',
  }
}));

const packageTitles = ['Premium Reach', 'Premium Bundles', 'Private'];

const LOGO_PREFIX = 'LogoGridPremium';

const logoClasses = {
  media: `${LOGO_PREFIX}-media`,
  mediaBig: `${LOGO_PREFIX}-mediaBig`,
  media2: `${LOGO_PREFIX}-media2`,
};

const StyledLogoGridPremiumReach = styled("div")((() => ({
  display: 'flex',
  flexWrap: 'wrap',

  [`& .${classes.media}`]: {
    height: 53,
    backgroundSize: 'contain',
    marginTop: 10,
  },

  [`& .${classes.mediaBig}`]: {
    height: 60,
    backgroundSize: 'contain',
    marginBottom: -8,
  },

  [`& .${classes.media2}`]: {
    height: 55,
    backgroundSize: 'contain'
  },
})));

const LogoGridPremiumReach = (props) => (
  <StyledLogoGridPremiumReach>
    <Grid container spacing={2}>
      <Grid item xs={12} >
        <CardMedia
          className={props.isEditing ? logoClasses.mediaBig : logoClasses.media}
          image={reachLogos.toString()}
        />
      </Grid>
    </Grid>
  </StyledLogoGridPremiumReach>
);

LogoGridPremiumReach.propTypes = {
  isEditing: PropTypes.bool,
};

const LogoGridPremiumBundles = () => (
  <StyledLogoGridPremiumReach>
    <Grid container spacing={2}>
      <Grid item xs={12} >
        <CardMedia
          className={logoClasses.media2}
          image={bundlesLogos.toString()}
        />
      </Grid>
    </Grid>
  </StyledLogoGridPremiumReach>
);

/////////////////////////////
// INVENTORY COMPONENT
/////////////////////////////
const Inventory = props => {
  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();
  const { state } = useStateMachine();

  const { deviceTypes, operatingSystems } = state.data.inventoryStep.deviceOverlayModal;

  const {
    showAdvanced = true,
    showSubLinks = true,
    title = 'Select Inventory',
    isEditing,
    tier,
    setStep,
    setTier,
    setIsCustomTargeting,
    canNext,
    onSubmit,
    onDeviceOverlayShow,
    showGenre,
    showRunOfNetwork,
    showBrandLogos,
    showCustomApp,
    isLoading,
    advertiserHasPrivateDeals,
  } = props;

  const advancedTargetingCopy = useMemo(() => {
    const deviceTypeMinorityData = getMinorityData(deviceTypes);
    const operatingSystemMinorityData = getMinorityData(operatingSystems);

    const deviceTypesList = deviceTypeMinorityData.data.map(data => data.title);
    const operatingSystemsList = operatingSystemMinorityData.data.map(data => data.title);

    const deviceTypesListCopy = joinWithAnd(deviceTypesList);
    const operatingSystemsListCopy = joinWithAnd(operatingSystemsList);

    const deviceTypesIncludingCopy = deviceTypeMinorityData.isSelectedMinority ? 'Including' : 'Excluding';
    const operatingSystemsIncludingCopy = operatingSystemMinorityData.isSelectedMinority ? 'Including' : 'Excluding';

    if (deviceTypeMinorityData.isAllSelected && operatingSystemMinorityData.isAllSelected) {
      return 'Advanced targeting by Device Type and Operating System';
    }

    return (
      <>
        {!deviceTypeMinorityData.isAllUnselected && (
          <>
            <b>Device Type:</b>&nbsp;
            {deviceTypesIncludingCopy}&nbsp;
            {deviceTypesListCopy}
          </>
        )}

        {!deviceTypeMinorityData.isAllUnselected && !operatingSystemMinorityData.isAllUnselected && <>&nbsp;|&nbsp;</>}

        {!operatingSystemMinorityData.isAllUnselected && (
          <>
            <b>OS:</b>&nbsp;
            {operatingSystemsIncludingCopy}&nbsp;
            {operatingSystemsListCopy}
          </>
        )}
      </>
    );
  }, [deviceTypes, operatingSystems]);

  const handleBack = () => {
    if (props.hasAdvancedTargeting) {
      props.updateBreadcrumbs('targeting', 50);
      setStep('TargetingSegments');
    } else {
      props.updateBreadcrumbs('targeting', 25);
      setStep('DemoTargeting');
    }
  };

  const handleTierClick = t => {
    setTier(t);
  };

  const renderCPM = cpm => {
    const cpmStart = cpm[0] * 1.18 + 0.4;
    const cpmEnd = cpm[1] * 1.18 + 0.4;
    return `Estimated CPM: ${numeral(cpmStart).format('$0,0')} - ${numeral(
      cpmEnd
    ).format('$0,0')}`;
  };

  const renderTier = (selected, index) => {
    const isSelected = tier === selected;
    const hasCPM = packageCopy[adContext.theme][selected].cpm !== null;

    return (
      <div
        key={`${selected}-${index}`}
        className={clsx(
          classes.tierWrap,
          selected === 'private' ? classes.tierWrapLast : {}
        )}
      >
        <Paper
          className={clsx(
            classes.paper,
            !isSelected ? classes.hover : classes.pack,
            isEditing ? classes.isEditing : classes.paperHeight
          )}
          elevation={isSelected ? 0 : 5}
          onClick={() => {
            handleTierClick(selected);

            if (!isEditing) {
              if (selected === 'bundles' && showGenre) {
                props.setStep('Genres');
              }

              if (selected === 'bundles' && !showGenre) {
                props.setInventoryOverlay(true);
              }

              if (selected === 'private') {
                props.setStep('Private');
              }
            }
          }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height="100%"
            width="100%"
          >
            <Box>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                  <h2 className={classes.paperHeader}>
                    {selected === 'reach' && showRunOfNetwork
                      ? 'Run of Network'
                      : selected === 'bundles' && showGenre
                      ? 'Genres'
                      : packageTitles[index]}
                  </h2>
                </Grid>

                {isSelected && (
                  <Grid item>
                    <CheckIcon color="secondary" />
                  </Grid>
                )}
              </Grid>

              <Divider
                className={clsx(
                  classes.divider,
                  isSelected ? classes.dividerSelected : {}
                )}
              />

              <Chip className={classes.chips} label="Benefits" />

              <p className={classes.text}>{packageCopy[adContext.theme][selected].benefits}</p>

              <Divider
                className={clsx(
                  classes.divider,
                  isSelected ? classes.dividerSelected : {}
                )}
              />

              <Chip className={classes.chips} label="Best For" />

              <p className={classes.text}>{packageCopy[adContext.theme][selected].bestFor}</p>

              {hasCPM && (
                <Fragment>
                  <Divider
                    className={clsx(
                      classes.divider,
                      isSelected ? classes.dividerSelected : {}
                    )}
                  />

                  <p className={classes.cpmText}>
                    {renderCPM(packageCopy[adContext.theme][selected].cpm)}
                  </p>
                </Fragment>
              )}
            </Box>

            {showBrandLogos &&
              <Box mb={1} width="100%">
                {selected === 'reach' && (
                  <LogoGridPremiumReach isEditing={isEditing} />
                )}

                {selected === 'bundles' && (
                  <LogoGridPremiumBundles />
                )}
              </Box>
            }
          </Box>
        </Paper>
      </div>
    );
  };

  return (
    <StyledBox mt={-2} pb={0} px={props.inventoryOverlay ? 8 : 0}>
      <Grid container>
        {!isEditing && (
          <Fragment>
            <Grid
              container
              item
              xs={12}
              alignItems="center"
              justifyContent="space-between"
            >
              <Grid item xs={8}>
                <Box my={2}>
                  <Title className={classes.title}>{title}</Title>

                  {domain.peacock && (
                    <p>
                      Choose the inventory in which you’d like your campaign to run.
                    </p>
                   )}
                </Box>
              </Grid>

              {showSubLinks && (
                <Grid
                  container
                  item
                  xs={6}
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <Grid className={classes.inputs} container justifyContent='space-between'>
                    <Grid
                      container
                      item
                      justifyContent='flex-end'
                      xs={12}
                    >
                      <Grid item>
                        <Button
                          color='secondary'
                          size='small'
                          startIcon={<CloudUploadOutlinedIcon />}
                        >
                          Upload App Restriction List
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          color='secondary'
                          size='small'
                          startIcon={<SystemUpdateAltIcon />}
                        >
                          Download Template
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12}>
              <Box mt={1}>
                <Divider />
              </Box>
            </Grid>
          </Fragment>
        )}

        <Grid container item xs={12}>
          <Grid item className={classes.tiers}>
            {advertiserHasPrivateDeals ? ['reach', 'bundles',  'private'].map((inv, i) =>
              renderTier(inv, i)
            ) : ['reach', 'bundles'].map((inv, i) =>
              renderTier(inv, i)
            )}
          </Grid>

          <Grid container item className={classes.switch}>
            <Box display="flex" justifyContent="space-between" width="100%">
              {showAdvanced && (
                <Grid item>
                  <Button
                    className={clsx("freq-cap-btn", classes.freqCapButton)}
                    onClick={() => onDeviceOverlayShow(true)}
                  >
                    <img src={configureIcon} width={17} />
                    <Typography className={classes.freqCap}>{advancedTargetingCopy}</Typography>
                  </Button>

                  <Tooltip
                    classes={{ tooltip: classes.tooltipWidth }}
                    title="Manage these targeting options to show your ad to specific device types and operating systems."
                  >
                    <InfoOutlinedIcon
                      className={classes.info}
                      fontSize="small"
                      color="secondary"
                    />
                  </Tooltip>
                </Grid>
              )}

              {showCustomApp && (
                <Grid item>
                  <Button
                    className="freq-cap-btn"
                    onClick={() => setIsCustomTargeting(true)}
                    data-testid="custom-app-targeting-btn"
                    startIcon={<img src={configureIcon} width={17} />}
                  >
                    <Typography className={classes.configBtn}>
                      Custom App Targeting
                    </Typography>
                  </Button>
                </Grid>
              )}
            </Box>
          </Grid>

          <Grid container item xs={12} justfy="flex-end">
            {!props.isEditing && (
              <CampaignFooter
                isLoading={isLoading}
                isDisabled={!canNext}
                back={
                  props.hasAdvancedTargeting
                    ? 'Targeting Segments'
                    : 'Demo Targeting'
                }
                next={'Save Ad Group...'}
                onBack={handleBack}
                onNext={onSubmit}
                page={3}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </StyledBox>
  );
};

Inventory.propTypes = {
  showAdvanced: PropTypes.bool,
  showSubLinks: PropTypes.bool,
  title: PropTypes.string,
  isEditing: PropTypes.bool,
  hasAdvancedTargeting: PropTypes.bool,
  setStep: PropTypes.func,
  tier: PropTypes.string,
  setTier: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  onDeviceOverlayShow: PropTypes.func,
  canNext: PropTypes.bool,
  onSubmit: PropTypes.func,
  inventoryOverlay: PropTypes.bool,
  setInventoryOverlay: PropTypes.func,
  setIsCustomTargeting: PropTypes.func,
  showGenre: PropTypes.bool,
  showRunOfNetwork: PropTypes.bool,
  showBrandLogos: PropTypes.bool,
  showCustomApp: PropTypes.bool,
  isLoading: PropTypes.bool,
  advertiserHasPrivateDeals: PropTypes.bool,
};

export default Inventory;
