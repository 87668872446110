import React, { Fragment } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Divider, Grid, InputAdornment, TextField } from '@mui/material';
import ManageBudget from './ManageBudget'

const PREFIX = 'AdGroupBudgeting';

const classes = {
  name: `${PREFIX}-name`
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.name}`]: {
    marginTop: 6,
  }
}));

const AdGroupBudgeting = ({
  adGroup,
  campaign,
  cpm,
  isError,
  isNew,
  hasControlAdGroup,
  manageBudgetRef,
  setAdGroups,
  setCpm,
  setCampaignBudget,
  setCampaignHoldOut,
  setDailyBudget,
  setDisplays,
  setHasSaved,
  setIsBudgetError,
  shouldShowMaxCPMInput,
}) => (
  <StyledBox mt={2} width="100%">
    <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
      <Grid item xs={12}>
        <Box mt={3}>
          <ManageBudget
            ref={manageBudgetRef}
            isNew={isNew}
            hasControlAdGroup={hasControlAdGroup}
            adGroup={adGroup}
            currentCampaign={campaign}
            setAdGroups={setAdGroups}
            setCampaignBudget={setCampaignBudget}
            setCampaignHoldOut={setCampaignHoldOut}
            setDailyBudget={setDailyBudget}
            setDisplays={setDisplays}
            setIsBudgetError={setIsBudgetError}
            setHasSaved={setHasSaved}
          />
        </Box>
      </Grid>


      {shouldShowMaxCPMInput && (
        <Fragment>
          <Box my={4} mb={3} width="100%">
            <Divider />
          </Box>
          <Grid container item xs={12}>
            <Grid item xs={2}>
              <TextField
                id="cpm-bid"
                error={isError && (!cpm || cpm === '')}
                className={classes.name}
                color="secondary"
                fullWidth
                label="Max CPM Bid"
                variant="outlined"
                value={cpm}
                type="number"
                onChange={(event) => setCpm(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                  inputProps: { min: 0, step: .01 },
                }}
              />
            </Grid>
          </Grid>
        </Fragment>
      )}
    </Grid>
  </StyledBox>
);

AdGroupBudgeting.propTypes = {
  isError: PropTypes.bool,
  isNew: PropTypes.bool,
  adGroup: PropTypes.object,
  campaign: PropTypes.object,
  cpm: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  hasControlAdGroup: PropTypes.bool,
  setAdGroups: PropTypes.func,
  setCpm: PropTypes.func,
  setCampaignBudget: PropTypes.func,
  setCampaignHoldOut: PropTypes.func,
  setDailyBudget: PropTypes.func,
  setDisplays: PropTypes.func,
  setHasSaved: PropTypes.func,
  setIsBudgetError: PropTypes.func,
  shouldShowMaxCPMInput: PropTypes.bool,
  manageBudgetRef: PropTypes.object,
};

export default AdGroupBudgeting;
