export const Variant = {
  filled: 'filled',
  outlined: 'outlined',
  text: 'text',
};

export const Type = {
  success: 'success',
  info: 'info',
  error: 'error',
  warning: 'warning',
};

export const iconSize = {
  [Variant.filled]: {
    success: 2,
    info: 2,
    error: 2,
    warning: 2,
    caption: 1.71,
  },
  [Variant.outlined]: {
    success: 2,
    info: 2,
    error: 2,
    warning: 2,
    caption: 1.71,
  },
  [Variant.text]: {
    success: 1.71,
    info: 1.71,
    error: 1.71,
    warning: 1.71,
    caption: 1.71,
  },
};

export const iconColors = {
  [Variant.filled]: {
    success: 'green.4',
    info: 'blue.4',
    error: 'red.4',
    warning: 'chart.5',
    caption: 'grey.6',
  },
  [Variant.outlined]: {
    caption: 'grey.6',
    success: 'green.7',
    info: 'blue.7',
    error: 'red.1',
    warning: 'chart.5',
  },
  [Variant.text]: {
    success: 'green.4',
    info: 'blue.4',
    error: 'red.2',
    warning: 'chart.5',
    caption: 'grey.6',
  },
};

export const textColors = {
  [Variant.filled]: {
    success: 'green.7',
    info: 'blue.7',
    error: 'red.1',
    caption: 'grey.4',
  },
  [Variant.outlined]: {
    success: 'inherit',
    info: 'inherit',
    error: 'inherit',
    caption: 'grey.4',
  },
  [Variant.text]: {
    success: 'green.7',
    info: 'blue.7',
    error: 'red.2',
    caption: 'grey.4',
  },
};
