import React from "react";
import { node, string } from "prop-types";
import { styled } from '@mui/material/styles';
import clsx from 'clsx';

const PREFIX = 'Container';

const classes = {
  container: `${PREFIX}-container`,
  active: `${PREFIX}-active`
};

const Root = styled('div')(() => ({
  [`&.${classes.container}`]: {
    backgroundColor: '#FAFAFA',
  },

  [`&.${classes.active}`]: {
    width: '100%',
    borderRadius: "10px 10px 2px 2px",
    backgroundColor: '#0E5880',
    color: '#FFFFFF',
    'svg': {
      fill: '#FFFFFF'
    }
  }
}));

export const Container = ({ children, className }) => {
  return (
    <Root className={clsx(classes.container, className, classes.active)}>
      {children}
    </Root>
  );
}

Container.propTypes = {
  children: node.isRequired,
  className: string,
}
