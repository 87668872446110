export const outcomes = [
  {
    value: 'IMPRESSIONS',
    label: 'Impressions',
  },
  {
    value: 'WEB_SITE_VISITS',
    label: 'Web site visits',
  },
  {
    value: 'PURCHASES',
    label: 'Purchases',
  },
  {
    value: 'APP_INSTALLS',
    label: 'App installs',
  },
  {
    value: 'IN_APP_PURCHASES',
    label: 'In-app purchases',
  },
];
