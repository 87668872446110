import tvsApiProvider from './tvs-api-provider';

/////////////////////////////
// TVS API CONSTRUCTOR
/////////////////////////////
class TVSApi {
  get = (url, headers) => {
    return tvsApiProvider.get(url, headers);
  };

  post = (url, data, headers) => {
    return tvsApiProvider.post(url, data, headers);
  };

  put = (url, data, headers) => {
    return tvsApiProvider.put(url, data, headers);
  };

  patch = (url, data, headers) => {
    return tvsApiProvider.patch(url, data, headers);
  };

  remove = (url, headers) => {
    return tvsApiProvider.remove(url, headers);
  };

  getId = (url, id, headers) => {
    return tvsApiProvider.getId(url, id, headers);
  };

  getUrl = (url, headers) => {
    return tvsApiProvider.getUrl(url, headers);
  };

  postUrl = (url, data, headers) => {
    return tvsApiProvider.postUrl(url, data, headers);
  };
}

const api = new TVSApi();

export { api };
