import React from 'react';
import { Stack, Typography } from '@mui/material';

export const FileTypeDetails = {
  FileDropzone: {
    video: {
      accept: 'video/mp4, video/x-m4v, video/*',
      DESCRIPTION: 'Drag your video files here, or',
      specifications: (
        <Stack className="Dropzone-specs">
          <Typography>
            Recommended File Format: MOV or MP4 with Video Ad-ID | Min
            Dimension: 1920x1080 | Min Bit Rate: 15 Mbps | Max File Size: 10
            GB
          </Typography>
        </Stack>
      ),
    },
    image: {
      accept: 'image/jpeg, image/png, image/gif',
      DESCRIPTION: 'Drag your display files here, or',
      specifications: (
        <Stack className="Dropzone-specs">
          <Typography>Supported File Formats: PNG, JPEG, GIF</Typography>
          <Typography>
            Supported Dimension: 0x0, 1x1, 88x31, 120x60, 120x240, 120x600,
            125x125, 160x600, 180x150, 200x200, 234x60, 250x250, 300x50,
            300x250, 300x600, 300x1050, 320x50, 320x100, 320x480, 336x280,
            468x60, 480x320, 728x90, 768x1024, 970x90, 970x250, 1024x768
          </Typography>
          <Typography>Max File Size: 100 MB</Typography>
        </Stack>
      ),
    },
    csv: {
      DESCRIPTION: 'Drag your CSV file here, or',
    },
  },
};
