import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { Grid, Switch, Typography, FormControlLabel } from '@mui/material';
const PREFIX = 'IncludeToggle';

const classes = {
  root: `${PREFIX}-root`,
  switchBase: `${PREFIX}-switchBase`,
  track: `${PREFIX}-track`,
  item: `${PREFIX}-item`,
  itemText: `${PREFIX}-itemText`,
  switchText: `${PREFIX}-switchText`,
  disabledText: `${PREFIX}-disabledText`
};

const StyledGrid = styled(Grid)(() => ({
  [`&.${classes.item}`]: {
    padding: '12px 0',
    borderBottom: '1px solid rgba(154, 160, 166, 0.5)',

    '&:last-child': {
      borderBottom: 'none',
    },
  },

  [`& .${classes.itemText}`]: {
    color: '#fff',
    fontWeight: 'normal',
  },

  [`& .${classes.switchText}`]: {
    color: '#fff',
    fontWeight: 'normal',
  },

  [`& .${classes.disabledText}`]: {
    color: '#5c6b73',
  }
}));

const StyledFormControlLabel = FormControlLabel;

const StyledSwitch = Switch;

const IncludeToggle = ({
  title,
  name,
  control
}) => {


  return (
    <StyledGrid
      item
      container
      xs={12}
      alignItems="center"
      justifyContent="space-between"
      className={classes.item}
    >
      <Grid>
        <Typography className={classes.itemText}>
          {title}
        </Typography>
      </Grid>
      <Grid>
        <Controller
          render={({ field: { value, ...restField } }) => (
            <StyledFormControlLabel
              control={
                <Grid
                  container
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item className={clsx(classes.switchText, value && classes.disabledText)}>Exclude</Grid>
                  <Grid item>
                    <StyledSwitch
                      {...restField}
                      checked={value}
                      size="small"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                      classes={{
                        switchBase: classes.switchBase,
                        track: classes.track
                      }} />
                  </Grid>
                  <Grid item className={clsx(classes.switchText, !value && classes.disabledText)}>Include</Grid>
                </Grid>
              }
              classes={{
                root: classes.root
              }} />
          )}
          name={name}
          defaultValue="false"
          control={control}
        />
      </Grid>
    </StyledGrid>
  );
};

IncludeToggle.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
};

export default IncludeToggle;
