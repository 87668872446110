import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Box, Accordion as DefaultAccordion } from '@mui/material';

const Wrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'expanded',
})(({ theme, expanded }) => ({
  backgroundColor: theme.palette.common.offWhite,
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  margin: theme.spacing(1, 0),
  boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12)',

  '&:first-of-type': {
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
  },
  '&:last-of-type': {
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
  },
  '&:not(:first-of-type)': {
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  '&:not(:last-of-type)': {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },

  ...(expanded && {
    backgroundColor: theme.palette.blue[11],
  })
}));

const StyledAccordion = styled(DefaultAccordion)(() => ({
  backgroundColor: 'inherit',
  boxShadow: 'none',
  '&:before': {
    display: 'none',
  },
  '&.Mui-expanded': {
    margin: 0,
  },
}));

const Accordion = ({ topBar, children, expanded, ...props }) => {
  return (
    <Wrapper expanded={expanded}>
      {topBar}

      <StyledAccordion expanded={expanded} {...props}>
        {children}
      </StyledAccordion>
    </Wrapper>
  );
};

Accordion.propTypes = {
  topBar: PropTypes.node,
  expanded: PropTypes.bool,
  children: PropTypes.node,
};

export default Accordion;
