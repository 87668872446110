import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { useAuth } from './hooks';

const privateRootPath = '/';

export function withRedirectIfAuthenticated(Component) {
  // eslint-disable-next-line react/display-name
  return props => {
    const { authState } = useAuth();
    const { search } = useLocation();

    return !authState.isPending ? (
      !authState.isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={`${privateRootPath}${search}`} />
      )
    ) : (
      <></>
    );
  };
}
