import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCopy } from '@hooks/copy';
import { Stack } from '@mui/material';
import React from 'react';
import { SectionInfo } from '../../../../../components/SectionInfo';
import { Copies } from '../constants';
import { useGeography } from './hooks/useGeography';
import {
  DividerStyled,
  DividerTypographyStyled,
  SectionBoxStyled,
  SectionInnerBoxStyled,
} from '../styles';
import { ButtonContainer, StyledButton } from './styles';
import { GeographyProps } from './types';
import { getEmptyGeoItem } from './utils';
import { GroupedGeographyItem } from './GroupedGeographyItem';
import {
  faCirclePlus,
  faCloudArrowUp,
} from '@fortawesome/pro-regular-svg-icons';

export const Geography = ({ info, onAdvanced, ...props }: GeographyProps) => {
  const Copy = useCopy(Copies);

  const { fixedList, add, focused, blur, focus, remove, update } =
    useGeography();

  return (
    <SectionBoxStyled {...props}>
      <DividerStyled textAlign="left">
        <DividerTypographyStyled>Geography</DividerTypographyStyled>
      </DividerStyled>
      <SectionInnerBoxStyled>
        <SectionInfo infoBlocks={info} />
        <Stack spacing={2}>
          {fixedList.map((item, index) => (
            <GroupedGeographyItem
              isFocused={focused.has(index)}
              onBlur={blur(index)}
              onFocus={focus(index)}
              onRemove={remove(index)}
              onUpdate={update(index)}
              onAdvanced={({ data }) => {
                onAdvanced({
                  data,
                  include: !data[0].blacklist,
                  type: data[0].type,
                });
              }}
              key={item.id}
              value={item}
              index={index}
            />
          ))}

          <ButtonContainer>
            <StyledButton
              onClick={() => add(getEmptyGeoItem())}
              size="small"
              variant="text"
              startIcon={<FontAwesomeIcon icon={faCirclePlus} />}
            >
              {Copy.addGeography}
            </StyledButton>
            <StyledButton
              onClick={() =>
                onAdvanced({
                  data: [],
                  include: true,
                })
              }
              size="small"
              variant="text"
              startIcon={<FontAwesomeIcon icon={faCloudArrowUp} />}
            >
              {Copy.addZips}
            </StyledButton>
          </ButtonContainer>
        </Stack>
      </SectionInnerBoxStyled>
    </SectionBoxStyled>
  );
};
