import React from 'react';
import PropTypes from 'prop-types';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { faArrowRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const CustomListItem = ({ children }) => (
  <ListItem sx={{ padding: '5px 10px', alignItems: 'flex-start' }}>
    <ListItemIcon color="grey.1" style={{ fontSize: 18, minWidth: 32, marginTop: 8 }}>
      <FontAwesomeIcon icon={faArrowRight} color="grey.2" />
    </ListItemIcon>
    <ListItemText>
      {children}
    </ListItemText>
  </ListItem>
);

CustomListItem.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
    .isRequired,
};
