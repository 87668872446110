import { useCallback, useState } from 'react';
import Papa from 'papaparse';
import { UseGeoDropzoneArgs } from '../types';
import { useDropzone } from 'react-dropzone';

export const useGeoDropzone = ({ onChange }: UseGeoDropzoneArgs) => {
  const [showDropzone, setShowDropzone] = useState(false);
  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    if (file && file.type === 'text/csv') {
      Papa.parse(file, {
        complete: (result: { data: string[] }) => {
          onChange(result.data.map(row => row[0]).filter(v => v));
          setShowDropzone(false);
        },
      });
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDragOver: event => event.preventDefault(),
    onDragEnter: () => setShowDropzone(true),
    onDragLeave: () => setShowDropzone(false),
    accept: '.csv',
  });

  return {
    showDropzone,
    isDragActive,
    onDrop,
    setShowDropzone,
    getRootProps,
    getInputProps,
  };
};
