import React, { forwardRef } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { SnackbarContent } from 'notistack'
import { Typography } from '@mui/material';
import { LikeIcon } from '../icons';

const StyledSnackbarContent = styled(SnackbarContent)(({ theme }) => ({
  width: '310px',
  alignItems: 'center',
  flexWrap: 'nowrap',
  gap: theme.spacing(2),
  color: '#fff',
  borderRadius: 10,
  backgroundColor: '#2D3940',
  paddingTop: theme.spacing(2.25),
  paddingBottom: theme.spacing(2.25),
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
}));

export const CopySnackbar = forwardRef((props, ref) => {
  const { message, ...other } = props;

  return (
    <StyledSnackbarContent {...other} ref={ref} role="alert">
      <LikeIcon />
      <Typography variant="caption">{message}</Typography>
    </StyledSnackbarContent>
  );
})

CopySnackbar.propTypes = {
  message: PropTypes.node.isRequired,
};

CopySnackbar.displayName = 'CopySnackbar';
