import PropTypes from 'prop-types';
import { Box, Stack, Typography, Link } from '@mui/material';
import React from 'react';
import { RoutePaths } from '../../../constants';

const AppInviteError = ({ isMissingCode }) => {
  return (
    <Stack
      spacing={3}
      sx={{ maxWidth: 720, margin: '0 auto' }}
      alignItems="center"
      data-testid="accept-invite-error"
    >
      <Typography variant="h3">Oops.</Typography>
      <Box sx={{ textAlign: 'center', maxWidth: 650 }}>
        {isMissingCode ? (
          <Typography variant="body1" color="grey.5" data-testid="invite-code-required">
            An invite code is required to create an account in the platform.
            Please visit our{' '}
            <Link sx={{ textDecoration: 'none' }} href={RoutePaths.HOME}>
              request access page
            </Link>{' '}
            to get started.
          </Typography>
        ) : (
          <Typography variant="body1" color="grey.5" data-testid="invite-no-longer-valid">
            Your invite code is no longer valid, please return to the{' '}
            <Link sx={{ textDecoration: 'none' }} href={RoutePaths.HOME}>
              request access page
            </Link>{' '}
            to get started.
          </Typography>
        )}
      </Box>
      <img
        style={{ backgroundSize: 'contain', width: '100%' }}
        src={require('../../../images/accept-invitation-error.png')}
        alt=""
      />
    </Stack>
  );
};

AppInviteError.propTypes = {
  isMissingCode: PropTypes.bool,
};

export default AppInviteError;
