import { useFormContext } from 'react-hook-form';
import { fields } from '../constants';
import { useCreativeUploadContext } from './useCreativeUploadContext';
import { useState } from 'react';

export const useUpload = ({ toggleAssignCreative }) => {
  const [isUploading, setIsUploading] = useState(false);
  const form = useFormContext();
  const {
    cancelUploading,
    drop,
    createFile,
    createVastTags,
    updateCreative,
  } = useCreativeUploadContext();

  // TODO: Implement update file
  const handleUpdateCreative = async data => {
    return updateCreative(data);
  };

  const handleUpdateFile = (index, data) => {
    form.setValue(`${fields.files.path}.${index}`, data);
  };

  const handleDrop = async (files, onFilesPredefined, onFileDefined) => {
    setIsUploading(true);
    return drop(files)
      .then(files => {
        const formFiles = [
          ...files.map(({ meta }) => meta),
          ...form.getValues(fields.files.path),
        ];

        form.setValue(fields.files.path, formFiles);

        return files;
      })
      .then(async modifiedFiles => {
        onFilesPredefined?.(modifiedFiles.map(({ meta }) => meta));
        return Promise.all(
          modifiedFiles.map(async ({ file, meta }) => {
            const fileIndex = form
              .getValues(fields.files.path)
              .findIndex(({ id }) => id === meta.id);

            return createFile({ file, meta }, progressEvent => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total,
              );
              handleUpdateFile(fileIndex, {
                ...meta,
                progress: percentCompleted,
              });
            })
              .then(uploadedFileMeta => {
                const fileData = {
                  ...meta,
                  ...uploadedFileMeta,
                  progress: 100,
                };
                handleUpdateFile(fileIndex, {
                  ...fileData,
                  processing: false,
                });
                toggleAssignCreative(fileData, true);

                onFileDefined?.({
                  prev: file,
                  current: fileData,
                });
                return fileData;
              })
              .catch(() => {
                form.setValue(
                  fields.files.path,
                  form
                    .getValues(fields.files.path)
                    .filter(f => f.id !== meta.id),
                );
              });
          }),
        ).finally(() => {
          setIsUploading(false);
        });
      });
  };

  /** Replace with SWR */
  const handleCreateVASTTags = async data => {
    const newData = await createVastTags(data);
    return newData;
  };

  // TODO: Implement cancel uploading
  const handleCancelUploading = async id => {
    await cancelUploading(id);
    form.setValue(
      fields.files.path,
      form.getValues(fields.files.path).filter(f => f.id !== id),
    );
  };

  return {
    createFile,
    createVastTags: handleCreateVASTTags,
    updateCreative: handleUpdateCreative,
    cancelUploading: handleCancelUploading,
    drop: handleDrop,
    isUploading,
  };
};
