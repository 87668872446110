import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Link, useParams } from 'react-router-dom';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  Box,
} from '@mui/material';

import { useAPI } from '../hooks/api';
import { useDate } from '../hooks/date';
import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';

const PREFIX = 'DisplayDetailPage';

const classes = {
  head: `${PREFIX}-head`,
  body: `${PREFIX}-body`,
  previewThumb: `${PREFIX}-previewThumb`,
  link: `${PREFIX}-link`
};

const StyledAppHeader = styled(AppHeader)(() => ({
  [`& .${classes.previewThumb}`]: {
    height: 'auto',
    maxWidth: '100%',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: '700',
  }
}));

const StyledTableCell = TableCell;

const headCells = [
  { id: 'campaignName', label: 'Campaign', disablePadding: false },
  { id: 'adGroupName', label: 'Ad Group', disablePadding: false },
  { id: 'startDate', label: 'Start date', disablePadding: false },
  { id: 'endDate', label: 'End date', disablePadding: false },
];

function CreativeDetailTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
            classes={{
              head: classes.head,
              body: classes.body
            }}>
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </StyledTableCell>
        ))}

        <StyledTableCell
          classes={{
            head: classes.head,
            body: classes.body
          }} />
      </TableRow>
    </TableHead>
  );
}

const DisplayDetailPage = props => {


  const adContext = useContext(AdvertiserContext);
  const { useGet } = useAPI();
  const { formatEndDate } = useDate();
  const { adGroupId, campaignId, displayId } = useParams();

  const [campaign, setCampaign] = useState(null);
  const [creative, setCreative] = useState(null);

  const adGroupsLink = campaignId === 'na' ? '/campaigns' : `/campaigns/${campaignId}/adgroups/${adGroupId}`;
  const displayCreativeLink = adGroupId === 'na' ? '/displays' : `/campaigns/${campaignId}/adgroups/${adGroupId}/displays`;

  const { staticdisplaylineitem_set = [] } = creative || {};
  const displayLineItemSet = staticdisplaylineitem_set[0] || {};

  const now = moment();

  useEffect(() => {
    if (adContext.id !== null) {
      getCreative();
    }
  }, [adContext]);

  useEffect(() => {
    if (displayLineItemSet.campaign) {
      getCampaign(displayLineItemSet.campaign);
    }
  }, [creative]);

  function getCreative() {
    return useGet(`/static_display_creatives/${displayId}`)
      .then(res => {
        console.log('response from  static_display_creative', res);

        if (res) {
          setCreative(res);
        }

        return res;
      })
      .catch(err => console.error(err));
  }

  function getCampaign(url) {
    return useGet(url)
      .then(res => {
        console.log('Response from campaign', res);
        setCampaign(res);
        return res;
      })
      .catch(error => {
        console.error(error);
      });
  }

  return (
    <StyledAppHeader history={props.history}>
      <Box m={5} mb={2}>
        <Typography>
          <strong>
            <Link className={classes.link} to={`/campaigns/`}>
              Campaigns
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          <strong>
            <Link className={classes.link} to={adGroupsLink}>
              Ad Groups
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          <strong>
            <Link className={classes.link} to={displayCreativeLink}>
              Display Creative
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          <span>{creative && creative.name ? creative.name : ''}</span>
        </Typography>
      </Box>

      <Box border={1} borderColor="grey.300" p={6} m={4} borderRadius="20px">
        <Grid container>
          <Grid item container xs={5}>
            {creative != null && (
              <Grid item container spacing={3} xs={12}>
                {creative.preview_url ? (
                  <Grid alignItems="center" justifyContent="center" container>
                    <Grid item xs={12}>
                      <img
                        className={classes.previewThumb}
                        src={creative.preview_url}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}

                <Grid item container spacing={1} xs={12}>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      {creative.name ? creative.name : ''}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="body2">
                      {displayLineItemSet.start_date && `Launched ${now.diff(displayLineItemSet.start_date, 'days')} days ago | `}
                      {displayLineItemSet.start_date && moment(displayLineItemSet.start_date).format('l')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>

          <Grid item container xs={7}>
            <TableContainer>
              <Typography variant="h3" component="div">
                Ad Group Assignments
              </Typography>

              <Table>
                <CreativeDetailTableHead />

                <TableBody>
                  {campaign !== null && (
                    <TableRow tabIndex={-1} data-testid="creative-table-row">
                      <TableCell align="left">{campaign.name}</TableCell>

                      <TableCell align="left">
                        {displayLineItemSet.name}
                      </TableCell>

                      <TableCell align="left">
                        {moment(campaign.start_date).format('l')}
                      </TableCell>

                      <TableCell align="left">
                        {formatEndDate(campaign.end_date)}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </StyledAppHeader>
  );
};

DisplayDetailPage.propTypes = {
  history: PropTypes.object,
};

export default DisplayDetailPage;
