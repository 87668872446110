import React from 'react';
import { GroupedGeographyItemProps } from './types';
import { GeographyItem } from './GeographyItem';
import { GeographyPreviewSegment } from '../../GeoTargeter/GeographyPreviewSegment';
import { Box, Stack } from '@mui/material';
import { distinctGroupsNames } from '../../GeoTargeter/constants';
import { map } from 'lodash';
import { useGroupedGeographyItem } from './hooks/useGroupedGeographyItem';
import {
  StyledFAIconButton,
  StyledGroupedItemsConclude,
  StyledMoreTypography,
} from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/pro-solid-svg-icons';
import { entireUSId } from '../constants';
import { plainItems } from './constants';

export const GroupedGeographyItem = ({
  value,
  ...props
}: GroupedGeographyItemProps) => {
  const { isExpandable, expanded, setExpanded, shownValues } =
    useGroupedGeographyItem({
      value,
    });

  return (
    <Stack
      direction="row"
      gap={2}
      justifyContent="center"
      alignItems="center"
    >
      {plainItems.includes(value.type) ? (
        <GeographyItem {...props} value={value.list[0]} />
      ) : (
        <GeographyPreviewSegment
          blacklist={value.blacklist}
          onChange={({ blacklist }) => {
            props.onUpdate({
              blacklist,
            });
          }}
          label={
            <Stack direction="row" gap={1}>
              <StyledGroupedItemsConclude>
                {distinctGroupsNames[value.type]}:
              </StyledGroupedItemsConclude>
              <Stack display="inline-block">
                {map(shownValues, 'text').join(', ')}
                {isExpandable && !expanded && (
                  <StyledMoreTypography onClick={() => setExpanded(true)}>
                    {' '}
                    +{value.list.length - 3} more...
                  </StyledMoreTypography>
                )}
              </Stack>
            </Stack>
          }
          onEdit={() => {
            props.onAdvanced({
              data: value.list,
            });
          }}
        />
      )}
      <Box>
        {value.list[0]?.id !== entireUSId && (
          <StyledFAIconButton onClick={props.onRemove}>
            <FontAwesomeIcon icon={faRemove} />
          </StyledFAIconButton>
        )}
      </Box>
    </Stack>
  );
};
