import { keysToCamelCase } from '../helpers';

export const withCamelCase = (useSWRNext) => (key, fetcher, config) => {
  const modifiedFetcher = async (...args) => {
    const data = await fetcher(...args);

    return keysToCamelCase(data);
  };

  return useSWRNext(key, modifiedFetcher, config);
};
