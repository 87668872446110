import { FormEvent, KeyboardEvent, useEffect, useState } from 'react';
import { UseChipPopperArgs } from '../types';
import { getTemporaryGeo } from '../utils';

export const useChipPopper = ({
  isLoading,
  data,
  value,
  type,
  onEscape,
  onInput,
  onSelect,
}: UseChipPopperArgs) => {
  const [activeElementIndex, setActiveElementIndex] = useState(0);
  const [baseElement, setBaseElement] = useState<HTMLElement | null>(null);
  const [forceHide, setForceHide] = useState(false);
  const isOpen = forceHide
    ? false
    : value.length > 3 && !isLoading && !!data.length;

  const getTemporaryData = (data: string) => {
    const values = data.split(',');
    return values
      .map(t => getTemporaryGeo(t.trim(), type))
      .slice(0, values.length - 1);
  };

  const handleInputKeyboardEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        if (forceHide) {
          onEscape?.();
          break;
        }

        setForceHide(true);
        break;
      case 'Enter':
        e.preventDefault();
        if (data[activeElementIndex]) {
          onSelect([data[activeElementIndex]]);
          break;
        }
        onSelect([getTemporaryGeo(e.currentTarget.value.trim(), type)]);
        break;
      case 'ArrowDown':
        e.preventDefault();
        setForceHide(false);
        setActiveElementIndex(
          activeElementIndex + 1 >= data.length ? 0 : activeElementIndex + 1,
        );
        break;
      case 'ArrowUp':
        e.preventDefault();
        setForceHide(false);
        setActiveElementIndex(
          activeElementIndex - 1 < 0
            ? data.length - 1
            : activeElementIndex - 1,
        );
        break;
      default:
        break;
    }
  };

  const handleInputTypingEvent = (e: FormEvent<HTMLInputElement>) => {
    const inputEvent = e.nativeEvent as InputEvent;
    const text = e.currentTarget.value;
    const lastChar = text.charAt(text.length - 1);

    setForceHide(false);

    switch (inputEvent.inputType) {
      case 'insertFromPaste': {
        const tempPasted = getTemporaryData(text);
        onSelect(tempPasted);
        break;
      }

      default:
        if (lastChar === ',') {
          const tempTyped = getTemporaryData(text);
          onSelect(tempTyped);
          onInput('');
          return;
        }
        onInput(text);
        break;
    }
  };

  useEffect(() => {
    setActiveElementIndex(0);
  }, [data]);

  return {
    baseElement,
    setBaseElement,
    forceHide,
    setForceHide,
    isOpen,
    activeElementIndex,
    onInputKeyboardEvent: handleInputKeyboardEvent,
    onInputTypingEvent: handleInputTypingEvent,
  };
};
