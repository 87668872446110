import React from 'react';
import { useList } from './hooks/useList';
import {
  IconButton,
  LinearProgress,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { PropTypes } from 'prop-types';
import { useListElement } from './hooks/useListElement';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRemove } from '@fortawesome/pro-solid-svg-icons';
import {
  faPlayCircle,
  faPlusCircle,
  faTrashXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { LinkStyled } from '../../../styles';
import { Edit } from './Edit';
import { formatFileName } from '@components/hooks/upload';

const FileInfoStack = styled(Stack)({
  width: '100%',
});

const TitleStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
}));

const PlayCircleIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[5],
}));

const RemoveIconStyled = styled(FontAwesomeIcon)(({ theme }) => ({
  width: theme.spacing(1.75),
  height: theme.spacing(2),
}));

const ListElement = ({ field, isDisplay, progress, onRemove, onCancel }) => {
  const { isComplete, cancelUploading, stats } = useListElement({
    field,
    isDisplay,
    progress,
  });

  const filename = field.name ?? formatFileName(field.path);

  return (
    <FileInfoStack direction="column" spacing={2.5}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
      >
        <TitleStyled variant="body2">
          {!isDisplay && (
            <>
              <PlayCircleIconStyled icon={faPlayCircle} size="1x" />&nbsp;
            </>
          )}
          {filename}
        </TitleStyled>
        <Stack
          direction="row"
          justifyContent="center"
          spacing={3}
          alignItems="center"
        >
          <Typography variant="caption" color={'grey.7'}>
            {stats}
          </Typography>
          {isComplete && (
            <IconButton onClick={onRemove} size="small">
              <RemoveIconStyled icon={faTrashXmark} />
            </IconButton>
          )}

          {!isComplete && (
            <IconButton onClick={onCancel} size="small">
              <RemoveIconStyled onClick={cancelUploading} icon={faRemove} />
            </IconButton>
          )}
        </Stack>
      </Stack>
      {!isComplete && (
        <LinearProgress
          variant="determinate"
          value={progress}
          sx={{ backgroundColor: 'grey.11', width: '100%' }}
        />
      )}
    </FileInfoStack>
  );
};

ListElement.propTypes = {
  field: PropTypes.object.isRequired,
  isDisplay: PropTypes.bool.isRequired,
  progress: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export const List = ({ files, isDisplay, onCancel, onDrop, onRemove }) => {
  const {
    controlledFields,
    showUpload,
    uploaded,
    drop,
    remove,
    cancel,
    setShowUpload,
  } = useList({
    files,
    onRemove,
    onCancel,
    onDrop,
  });

  return (
    <Stack spacing={5.5} flex={1}>
      {controlledFields.map((field, idx) => (
        <ListElement
          onRemove={remove(field.id)}
          onCancel={cancel(field.id)}
          key={field.id}
          field={field}
          isDisplay={isDisplay}
          progress={field.progress}
          index={idx}
        />
      ))}
      {uploaded && (
        showUpload ? (
          <Edit isDisplay={isDisplay} onDrop={drop} />
        ) : (
          <LinkStyled onClick={() => setShowUpload(true)} underline="hover">
            <FontAwesomeIcon icon={faPlusCircle} /> Add Creative
          </LinkStyled>
        )
      )}
    </Stack>
  );
};

List.propTypes = {
  files: PropTypes.array.isRequired,
  isDisplay: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onDrop: PropTypes.func,
  onRemove: PropTypes.func.isRequired,
};
