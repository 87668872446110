import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlayCircle } from '@fortawesome/pro-regular-svg-icons';
import { faRemove } from '@fortawesome/pro-solid-svg-icons';
import { formatBytes, formatDuration } from '@v2/components/campaign/FileUploads';

const LoadingElement = ({
  name,
  size,
  duration,
  progress,
  onRemove,
}) => (
  <Stack direction="column" spacing={2.5} sx={{ width: '100%' }}>
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
    >
      <Typography variant="body2" color="text.primary" fontWeight={700}>
        <Box component="span" sx={{ color: 'grey.5' }}>
          <FontAwesomeIcon icon={faPlayCircle} size="1x" />
        </Box>
        &nbsp;{name}
      </Typography>

      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Typography variant='caption' color="grey.2" sx={{ fontWeight: 700 }}>Loading...</Typography>

        <Typography variant="caption" color="grey.3">
          {formatBytes(size)} &#8226; {formatDuration(duration)} &#8226; 1080P
        </Typography>

        <IconButton onClick={onRemove} size="small" aria-label="remove uploading file">
          <FontAwesomeIcon icon={faRemove} />
        </IconButton>
      </Stack>
    </Stack>

    <LinearProgress
      variant="determinate"
      value={progress}
      sx={{ backgroundColor: 'grey.11', width: '100%' }}
    />
  </Stack>
);

LoadingElement.propTypes = {
  name: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
  progress: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default LoadingElement;
