import { useEffect } from 'react';
import { useAuth } from '../components/hooks';
import apiInstance from '../connection/apiClient';

export const useInactiveAuthenticationTokenResponseInterceptor = () => {
  const { auth } = useAuth();

  useEffect(() => {
    const interceptor = apiInstance.interceptors.response.use(
      response => response,
      (error) => {
        const { data, status } = error.response;
        const { errorType } = data;

        if (status === 403 && errorType === 'INACTIVE_AUTHENTICATION_TOKEN') {
          auth.clearAuth();

          return;
        }

        return Promise.reject(error);
      },
    );

    return () => {
      apiInstance.interceptors.response.eject(interceptor);
    };
  }, [auth]);
};
