// MUI Switch component customization
export const switchStyles = {
  styleOverrides: {
    root: ({ theme }) => ({
      padding: theme.spacing(1.25),
      '& .MuiSwitch-switchBase': {
        '&.Mui-checked': {
          '& + .MuiSwitch-track': {
            backgroundColor: ({ ownerState }) => ownerState.color ? theme.palette[ownerState.color]?.main : undefined, // on color
            opacity: 1,
            border: 0,
          },
          '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5,
          },
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
          color: theme.palette.grey[10], // off color
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.7,
        },
      },
      '& .MuiSwitch-track': {
        borderRadius: theme.spacing(1.375),
        backgroundColor: theme.palette.grey[10], // off color
        opacity: 1,
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.primary.contrastText,
        boxShadow: 'none',
        width: theme.spacing(1.75),
        height: theme.spacing(1.75),
        margin: theme.spacing(0.375),
      },
    }),
  },
  variants: [
    {
      props: (props) =>
        props.singleColor === true,
      style: (props) => ({
        '& .MuiSwitch-track': ({ theme }) => ({
          backgroundColor: props.color
            ? theme.palette[props.color]?.main
            : theme.palette.primary.main, // fall back color if props.color isn't defined
        }),
      }),
    },
  ],
};
