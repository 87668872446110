import React from 'react';
import { Button, Divider, Stack } from '@mui/material';
import { isBetween } from './utils';
import PropTypes from 'prop-types';
import { useButtonSlider } from './hooks';
import { StyledButton, StyledButtonText } from './styles';
import { isEmpty } from 'lodash';

export const ButtonSlider = ({ onChange, value, options, multiple, highlightFirstItem = false, ...props }) => {
  const {
    all,
    change,
    edited,
    submit,
    cancel,
  } = useButtonSlider({ onChange, value, options, multiple });

  return (
    <Stack spacing={3} py={2}>
      <Stack
        {...props}
        flexWrap="wrap"
        direction="row"
        alignItems="flex-start"
        data-testid={`button-slider`}
        spacing={2}
        useFlexGap
      >
        {options.map((option, index) => {
          const key = Array.isArray(option.value)
            ? option.value.join('-')
            : option.value;

          const isFirstItem = index === 0;

          return (
            <StyledButton
              active={
                Array.isArray(option.value)
                  ? isEmpty(option.value) && isEmpty(edited)
                    ? true
                    : isBetween(option.value, edited)
                  : option.value === edited
              }
              data-testid="button-slider-option"
              variant="outlined"
              key={key}
              onClick={() => {
                option.activable === false
                  ? all(option.value)
                  : change(option.value);
              }}
            >
              <StyledButtonText sx={{ fontWeight: isFirstItem && highlightFirstItem ? 600 : 'normal' }}>
                {option.label}
              </StyledButtonText>
            </StyledButton>
          );
        })}

      </Stack>
      <Stack direction="row" spacing={2}>
        <Button
          variant="contained"
          data-testid={`button-slider-submit`}
          onClick={submit}
        >
          OK
        </Button>
        <Button
          variant="text"
          color="secondary"
          data-testid={`button-slider-cancel`}
          onClick={cancel}
        >
          Cancel
        </Button>
      </Stack>
      <Divider />
    </Stack>
  );
};

ButtonSlider.propTypes = {
  ...Stack.propTypes,
  onChange: PropTypes.func,
  chunkBy: PropTypes.number,
  value: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.number,
    PropTypes.string,
  ]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.number),
        PropTypes.number,
        PropTypes.string,
      ]),
      activable: PropTypes.bool,
    }),
  ),
  highlightFirstItem: PropTypes.bool,
};
