// MUI Slider component customization
export const sliderStyles = {
  styleOverrides:{
    root:  ({ theme }) => ({
      height: theme.spacing(1),
      '& .MuiSlider-thumb': {
        height: theme.spacing(3),
        width: theme.spacing(3),
      },
      '& .MuiSlider-rail': {
        backgroundColor: theme.palette.grey[9],
        opacity: 1,
      },
      '& .MuiSlider-track': {
        backgroundColor: theme.palette.blue[8],
        borderColor: theme.palette.blue[8],
      },
      '& .MuiSlider-markLabel': {
        top: theme.spacing(4.375),
        color: theme.palette.grey[1],
      },
      '&.Mui-disabled': {
        color: theme.palette.grey[9],
        '& .MuiSlider-thumb': {
          backgroundColor: theme.palette.grey[6],
        },
        '& .MuiSlider-rail': {
          backgroundColor: theme.palette.grey[9],
        },
        '& .MuiSlider-track': {
          backgroundColor: theme.palette.grey[9],
          borderColor: theme.palette.grey[9],
        },
        '& .MuiSlider-markLabel': {
          color: theme.palette.grey[3],
        },
      },
      '&.MuiSlider-marked': {
        '& .MuiSlider-mark': {
          display: "none",
        },
        '& .MuiSlider-markLabel': {
          color: theme.palette.grey[5],
          '&Active': {
            color: theme.palette.grey[1],
          },
        },
      },
    }),
  },
};
