import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Chip } from '@mui/material';
const PREFIX = 'StatusChip';

const classes = {
  chip: `${PREFIX}-chip`,
  active: `${PREFIX}-active`,
  deactivated: `${PREFIX}-deactivated`,
  invited: `${PREFIX}-invited`
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.chip}`]: {
    maxWidth: '300px',
    padding: '0 12px',
  },

  [`& .${classes.active}`]: {
    backgroundColor: '#c2f1e3',
    color: '#05734e',
  },

  [`& .${classes.deactivated}`]: {
    backgroundColor: '#ffa6a8',
    color: '#c1021f',
  },

  [`& .${classes.invited}`]: {
    backgroundColor: '#e0e0e0',
    color: '#000000de',
  }
}));

export const StatusChip = ({ status }) => {
  const currentStatus = status.toLowerCase();

  return (
    <StyledBox>
      {currentStatus === 'active' && (
        <Chip
          className={clsx(classes.chip, classes.active)}
          label="Active"
        />
      )}

      {currentStatus === 'deactivated' && (
        <Chip
          className={clsx(classes.chip, classes.deactivated)}
          label="Deactivated"
        />
      )}

      {currentStatus === 'invited' && (
        <Chip
          className={clsx(classes.chip, classes.invited)}
          label="Invited"
        />
      )}
    </StyledBox>
  );
};

StatusChip.propTypes = {
  status: PropTypes.string.isRequired,
};
