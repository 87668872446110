import { useAuth } from '../hooks';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';

export const Logout = () => {
  const { auth, authState } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (!authState.isPending) {
      if (authState.isAuthenticated) {
        auth.signOut();
        return;
      }

      history.push('/');
    }
  }, [auth, authState.isPending, authState.isAuthenticated]);

  return null;
};
