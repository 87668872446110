import moment from 'moment-timezone';

import React from 'react';
import { extractIdFromUrl } from '@cypress/utils/helpers';
import { accountCategoriesObjectiveMap } from '@components/accountCategories';
import { Stack } from '@mui/material';
import { GoalEventPicker } from './GoalEventPicker';
import { GoalTypeAndAmount } from './GoalTypeAndAmount';
import { objectives, objectiveValues, trackingLockedInfo } from './constants';

export const getUnlockedContent = ({
  objective,
  contentsProps,
  objectiveGoalsAllowed
}) => {
  const { control, setValue, objectiveGoalEventSet } = contentsProps;
  return (
    objectiveGoalsAllowed && (
      <Stack spacing={3} sx={{ marginBottom: 2.5 }}>
        {objective.hasGoalEvent &&
          <GoalEventPicker
            control={control}
            eventSet={objectiveGoalEventSet}
            value={objective.value}
          />
        }
        {objective.goalTypes?.length > 0 &&
          <GoalTypeAndAmount
            control={control}
            value={objective.value}
            setValue={setValue}
          />
        }
      </Stack>
    )
  );
};

const isLocked = disabledObjectiveOptions => {
  const objectiveGoalEventSet =
    disabledObjectiveOptions?.objectiveGoalEventSet;
  return !objectiveGoalEventSet || objectiveGoalEventSet.length === 0;
}

export const getLockedStatus = ({ option, disabledObjectiveOptions }) => {
  switch (option.value) {
    case objectiveValues.appInstalls:
    case objectiveValues.websiteSales:
    case objectiveValues.websiteVisits:
      return isLocked(disabledObjectiveOptions);
    default:
      return false;
  }
};

export const getLockedInfo = ({ option, lockedInfo }) => {
  switch (option.value) {
    case objectiveValues.appInstalls:
    case objectiveValues.websiteSales:
    case objectiveValues.websiteVisits:
      return lockedInfo.trackingLockedInfo;
    default:
      return null;
  }
};

export const getRecommendedCampaignObjectForAdvertiser = advertiser => {
  if (advertiser?.category) {
    const categoryId = extractIdFromUrl(advertiser.category);
    if (categoryId) {
      return Object.keys(accountCategoriesObjectiveMap).find(key => {
        return accountCategoriesObjectiveMap[key].includes(
          parseInt(categoryId, 10),
        );
      });
    }
  }
  return null;
};

export const getObjectivesOptions = ({
  advertiserObjectives,
  control,
  copies,
  objectiveGoalsAllowed,
  objectiveGoalEventSet,
  recommendedCampaignObjective,
  setValue,
}) => {
  objectiveGoalEventSet.forEach(obj => {
    obj.value = obj.id;
  });

  const contentsProps = { control, setValue, objectiveGoalEventSet };

  const actualObjectives = Object.keys(objectives)
    .filter(key => key in advertiserObjectives)
    .reduce((acc, key) => {
      acc[key] = objectives[key];
      return acc;
    }, {});

  return Object.keys(actualObjectives).map(key => ({
    ...actualObjectives[key],
    recommended: recommendedCampaignObjective === actualObjectives[key].value,
    recommendedInfo: copies.objectiveRecommendation,
    locked:
      getLockedStatus({
        option: actualObjectives[key],
        disabledObjectiveOptions: { objectiveGoalEventSet },
      }),
    lockedInfo:
      getLockedInfo({
        option: actualObjectives[key],
        lockedInfo: { trackingLockedInfo },
      }),
    unlockedContent:
      getUnlockedContent({
        objective: actualObjectives[key],
        contentsProps,
        objectiveGoalsAllowed,
      }),
  }));
};

export const defaultCampaignName = () => {
  const timestamp = moment().format('MMMM Do h:mma');
  return `${timestamp} Campaign`;
};
