import React from 'react';
import PropTypes from 'prop-types';
import { areEqual } from 'react-window';

import OrgGroup from './OrgGroup';

const Row = React.memo(({ item, style, index }) => {
  if (Object.keys(item).includes('group')) {
    return <OrgGroup item={item} index={index} style={style} />;
  }

  return (
    <div key={index} style={style}>
      {item}
    </div>
  );
}, areEqual);

Row.displayName = 'Row';
Row.propTypes = {
  item: PropTypes.object,
  style: PropTypes.object,
  index: PropTypes.number,
};

const VirtualizedListRows = ({ data, index, style }) => {
  const item = data[index];

  return <Row item={item} style={style} index={index} />;
};

VirtualizedListRows.propTypes = {
  data: PropTypes.array,
  index: PropTypes.number,
  style: PropTypes.object,
};

export default VirtualizedListRows;
