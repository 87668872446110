import React, { useEffect, useRef } from 'react';
import { SecureRoute as OktaSecureRoute } from '@okta/okta-react';
import { Route, useHistory } from 'react-router-dom';

import { useAuth } from './hooks';
import { useDomain } from './hooks/domain';

const routes = {
  ping: Route,
  okta: OktaSecureRoute,
};

const SecureRoute = props => {
  const domain = useDomain();
  const { auth, authState } = useAuth();
  const isMounted = useRef(false);
  const history = useHistory();

  useEffect(() => {
    isMounted.current = true;
    if (
      isMounted.current &&
      !authState.isPending &&
      !authState.isAuthenticated
    ) {
      if (domain.peacock) {
        return history.push('/login');
      }

      auth.oktaSignInWithRedirect({});
    }

    return () => (isMounted.current = false);
  }, [authState, domain.peacock, isMounted]);

  const RouteComponent = routes[authState?.sso];

  if (!RouteComponent) {
    return null;
  }

  return <RouteComponent {...props} />;
};

export default SecureRoute;
