import { AD_CAP_DURATION_OPTIONS as options } from '@v2/components/forms/AdvancedDeliveryOptionsForm/constants';

export const unitsToLabel = ({ unit, duration }) => {
  let unitLabel = options
    .find(({ value }) => value === unit)
    .label.toLowerCase()
    .slice(0, -3);
  if (duration > 1) unitLabel += 's';

  return unitLabel;
};
