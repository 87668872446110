import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { classes } from './constants';
import { StyledBox, StyledPenIcon, StyledPlaceholder } from './styles';
import { SelectedSegmentItemProps } from './types';
import { faPen } from '@fortawesome/pro-regular-svg-icons';
import { isString } from 'lodash';

const SelectedSegmentItem = ({
  icon,
  label,
  onEdit,
  placeholderText = '',
}: SelectedSegmentItemProps) => {
  const labelNode = useMemo(() => {
    if (!label) {
      return (
        <StyledPlaceholder onClick={onEdit}>
          {placeholderText}
        </StyledPlaceholder>
      );
    }

    if (isString(label)) {
      return <Typography>{label}</Typography>;
    }

    return label;
  }, [label]);

  return (
    <StyledBox>
      <div className={classes.iconContainer}>{icon}</div>
      {labelNode}
      {onEdit && <StyledPenIcon icon={faPen} onClick={onEdit} />}
    </StyledBox>
  );
};

export default SelectedSegmentItem;
