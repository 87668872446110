import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { useForm, useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';
import { CircularProgress, Grid, IconButton } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import { yupResolver } from '@hookform/resolvers/yup';
import { vastTagItemSchema } from './formConfig';
import { RHFTextField } from '../RHFTextField';
import { useAPI } from '../hooks/api';

const PREFIX = 'VastTagListExtension';

const classes = {
  action: `${PREFIX}-action`,
  submit: `${PREFIX}-submit`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.action}`]: {
    margin: '5px 0',
  },

  [`& .${classes.submit}`]: {
    '&:disabled': {
      color: 'gray',
    },
  }
}));

const VastTagListExtension = ({
  name,
  onEdit,
  onRemove,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const { usePatch } = useAPI()

  const { watch } = useFormContext();
  const tag = watch(name);

  const { control, handleSubmit, formState: {
    invalid,
    isDirty,
  } } = useForm({
    resolver: yupResolver(vastTagItemSchema),
    defaultValues: tag,
  });

  const handleEditVastTag = handleSubmit(async ({ id, ...tag }) => {
    setIsLoading(true);
    try {
      const { data } = await usePatch(`/creatives/${id}`, {
        ...tag,
      });

      onEdit(data)
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  });


  return (
    <StyledGrid
      container
      alignItems="flex-start"
      wrap="nowrap"
      spacing={2}
    >
      <Grid item xs={4}>
        <RHFTextField
          control={control}
          name={`name`}
          label="Creative Title"
          placeholder="Enter Creative Title"
        />
      </Grid>
      <Grid item xs={7}>
        <RHFTextField
          control={control}
          name={`vast_tag_url`}
          label="VAST Tag URL"
          placeholder="Enter VAST Tag URL"
        />
      </Grid>
      <Grid item xs container wrap="nowrap">
        <Grid item>
          <IconButton
            className={classes.action}
            disabled={invalid || !isDirty}
            onClick={handleEditVastTag}
            size="large"
          >
            {isLoading ? (
              <CircularProgress size={20} className={classes.submit} />
            ) : (
              <CheckCircleOutlineIcon
                color={invalid || !isDirty ? 'disabled' : 'primary'}
              />
            )}
          </IconButton>
        </Grid>
        <Grid item>
          <IconButton className={classes.action} onClick={onRemove} size="large">
            <DeleteIcon />
          </IconButton>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

VastTagListExtension.propTypes = {
  name: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

export default VastTagListExtension;
