import React, { useRef, useEffect } from 'react';
import { TableRow, TableCell, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

const PREFIX = 'LazyLoadingObserver';

const classes = {
  hiddenCell: `${PREFIX}-hiddenCell`,
  spinner: `${PREFIX}-spinner`,
};

const StyledTableRow = styled(TableRow)(() => ({
  [`& .${classes.hiddenCell}`]: {
    padding: 0,
    border: 'none',
  },
  [`& .${classes.spinner}`]: {
    padding: 5
  },
}));

// Place this component at the bottom of a table to trigger lazy loading
const LazyLoadingObserver = (props) => {
  const {
    handleScrollToBottom, // function to be called when the last(hidden) row is in viewport
    isLazyLoadingFinished, // whether the lazy loading process is finished
  } = props;

  if ('IntersectionObserver' in window) {
    const hiddenRowRef = useRef();

    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        const entry = entries[0];

        if (entry.isIntersecting && isLazyLoadingFinished) {
          handleScrollToBottom()
        }
      });

      if (hiddenRowRef.current) {
        observer.observe(hiddenRowRef.current);
      }

      return () => {
        if (hiddenRowRef.current) {
          observer.unobserve(hiddenRowRef.current);
        }
      };
    });

    return (
      <StyledTableRow ref={hiddenRowRef} data-testid="table-row">
        <TableCell className={classes.hiddenCell} colSpan='100%' align='center' height={200}>
          {!isLazyLoadingFinished && <CircularProgress className={classes.spinner} />}
        </ TableCell>
      </StyledTableRow>
    );
  }
  console.warn('IntersectionObserver is not supported by this browser.');
}

LazyLoadingObserver.propTypes = {
  handleScrollToBottom: PropTypes.func.isRequired,
  isLazyLoadingFinished: PropTypes.bool.isRequired,
};

export default LazyLoadingObserver;
