import { useUtil } from '@components/hooks/util';
import { GeoItem } from '../types';
import { findOption, getActualField } from '../utils';
import {
  FocusEvent,
  FocusEventHandler,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useGeoSearchV2 } from './useGeoSearch';

export const useGeoTargeter = (
  value: GeoItem,
  params: {
    disabled?: boolean;
    onBlur?: FocusEventHandler<Element>;
  },
) => {
  const { disabled, onBlur } = params;
  const textValue = getActualField(value);
  const { useDebouncedValue } = useUtil();
  const [inputValue, setInputValue] = useState(textValue);
  const debouncedInputValue = useDebouncedValue(inputValue, 50);
  const deferredValue = useDeferredValue(debouncedInputValue);

  const { data: searchResults = [], isLoading } = useGeoSearchV2(
    deferredValue,
    {
      disabled: params.disabled ?? false,
    },
  );

  const actualOptions = useMemo(
    () => [
      ...(value ? [value] : []),
      ...searchResults.filter(
        (o: GeoItem) => getActualField(o) !== getActualField(value),
      ),
    ],
    [searchResults, value],
  );

  const selectedValue = useMemo(
    () => findOption(actualOptions, value),
    [actualOptions, value],
  );

  useEffect(() => {
    setInputValue(textValue);
  }, [textValue]);

  const handleBlur = (e: FocusEvent) => {
    setInputValue('');
    onBlur?.(e);
  };

  return {
    inputValue,
    setInputValue,
    blur: handleBlur,
    selectedValue,
    isLoading,
    searchResults,
  };
};
