import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSnackbar } from 'notistack';

const CreditCardStripeForm = props => {
  const { onClose, isOpen } = props;

  const stripe = useStripe();
  const elements = useElements();
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(async event => {
    event.preventDefault();

    try {
      const { error: submitError } = await elements.submit();

      if (submitError) {
        throw new Error(submitError.message);
      }

      const { error: confirmCardSetupError } = await stripe.confirmSetup({
        elements,
        confirmParams: {},
        redirect: 'if_required',
      });

      if (confirmCardSetupError) {
        throw new Error(confirmCardSetupError.message);
      }

      onClose();
    } catch (error) {
      console.error("[CardSetup] Error", error);
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [elements, enqueueSnackbar]);

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      onClose={onClose}
      open={isOpen}
    >
      <DialogTitle>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h4">Enter a new card</Typography>

          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent>
        <form onSubmit={handleSubmit}>
          <PaymentElement />
          <button type="submit" disabled={!stripe || !elements}>
            Save
          </button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

CreditCardStripeForm.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default CreditCardStripeForm;
