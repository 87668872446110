import React, { Fragment, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { FixedSizeList } from 'react-window';

import VirtualizedGeoTargetingRow from './VirtualizedGeoTargetingRow';

const LISTBOX_PADDING = 8;
const DISPLAY_COUNT = 8;
const OVERSCAN_COUNT = 20;
const ROW_HEIGHT = 56;

const TargetsList = ({
  geoResults,
  setGeoResults,
  setTargetEntireUS,
  copy,
}) => {
  const listRef = React.createRef();
  const previousItemCountRef = useRef(geoResults.length);
  const itemCount = geoResults.length;

  const getHeight = () => {
    if (itemCount >= DISPLAY_COUNT) {
      return DISPLAY_COUNT * ROW_HEIGHT;
    }

    return itemCount * ROW_HEIGHT;
  };

  useEffect(() => {
    if (listRef.current && geoResults.length > previousItemCountRef.current) {
      listRef.current.scrollToItem(geoResults.length, 'end');
    }

    previousItemCountRef.current = geoResults.length;
  }, [geoResults]);

  return (
    <Fragment>
      {geoResults.length && (
        <FixedSizeList
          data-testid="geo-target-list"
          ref={listRef}
          itemData={{
            geoResults,
            setGeoResults,
            setTargetEntireUS,
            copy,
          }}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          itemSize={ROW_HEIGHT}
          overscanCount={OVERSCAN_COUNT}
          itemCount={itemCount}
        >
          {VirtualizedGeoTargetingRow}
        </FixedSizeList>
      )}
    </Fragment>
  );
};

TargetsList.propTypes = {
  geoResults: PropTypes.array,
  setGeoResults: PropTypes.func,
  setTargetEntireUS: PropTypes.func,
  copy: PropTypes.object,
};

export default TargetsList;
