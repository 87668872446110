import {
  faCircleCheck,
  faCircleDashed,
  faPlusCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { createContext } from 'react';

export const WizardContext = createContext({});

export const sections = {
  group: 'group',
  campaign: 'campaign',
  review: 'review',
};

export const sectionsOrdering = [
  sections.campaign,
  sections.group,
  sections.review,
];

export const urlSearchParams = {
  section: 'section',
};

export const subwayIconList = {
  complete: faCircleCheck,
  add: faPlusCircle,
  pending: faCircleDashed,
  active: faCircleArrowRight,
};
