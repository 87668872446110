import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { Grid, IconButton, ListItemText, Typography } from '@mui/material';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';
import { StyledList, StyledListItem } from '../../styledComponents';

export const Content = ({ items = [], ...rest }) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleCopy = useCallback(() => {
    const messageCopies = items.map(message => `- ${message}`).join('\n');
    const clipboardCopy = `Formatting issues being fixed:\n${messageCopies}`;
    const snackbarCopy = "The description of the formatting issues were copied to your clipboard";

    window.navigator.clipboard.writeText(clipboardCopy);

    enqueueSnackbar(snackbarCopy, {
      variant: 'copySnackbar',
      autoHideDuration: 2000,
      preventDuplicate: true,
    })
  }, [items]);

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      spacing={1}
      {...rest}
    >
      <Grid item>
        <Typography variant="subtitle2">Formatting issues being fixed</Typography>

        <StyledList dense disablePadding>
          {items.map((message) => (
            <StyledListItem key={message} dense disableGutters>
              <ListItemText primary={message} />
            </StyledListItem>
          ))}
        </StyledList>

        <Typography color="textSecondary" variant="body2">This may take up to 24 hours</Typography>
      </Grid>

      <Grid item>
        <IconButton onClick={handleCopy} aria-label="copy" size="large">
          <FileCopyOutlinedIcon color="secondary" fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

Content.propTypes = {
  items: PropTypes.array,
};
