import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

const context = createContext();

export const useUserAccountsContext = () => useContext(context);

export const UserAccountsContextProvider = ({
  children,
  users,
  setUsers,
  currentUser,
  toggleRoleAndPrivileges,
  onUserChange,
}) => {
  const userChanged = user => {
    setUsers(prev => {
      const index = prev.findIndex(item => item.id === user.id);
      const newUsers = [...prev];
      newUsers[index] = user;
      return newUsers;
    });
  };

  return (
    <context.Provider
      value={{
        users,
        currentUser,
        userChanged,
        toggleRoleAndPrivileges,
        onUserChange,
      }}
    >
      {children}
    </context.Provider>
  );
};

UserAccountsContextProvider.propTypes = {
  children: PropTypes.node,
  users: PropTypes.array,
  setUsers: PropTypes.func,
  currentUser: PropTypes.object,
  toggleRoleAndPrivileges: PropTypes.func,
  onUserChange: PropTypes.func,
};
