import { useEffect, useState } from 'react';

export const useList = ({ files, onDrop, onRemove, onCancel }) => {
  const [showUpload, setShowUpload] = useState(false);

  useEffect(() => {
    if (!files || files.length === 0) setShowUpload(true);
  }, [files]);

  const handleCancel = idx => () => {
    onCancel(idx);
  };

  const handleDrop = (...args) => {
    setShowUpload(false);
    onDrop(...args);
  };

  const handleRemove = idx => () => {
    onRemove(idx);
  };

  return {
    controlledFields: files,
    uploaded: files.every(field => field.progress === 100),
    drop: handleDrop,
    remove: handleRemove,
    cancel: handleCancel,
    showUpload,
    setShowUpload,
  };
};
