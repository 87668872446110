import { useContext } from 'react';
import { useAPI } from './api';
import { useCurrentSession } from './currentSession';
import AdvertiserContext from '../AdvertiserContext';
import AdvertisersContext from '../../providers/AdvertisersContext';
import Advertiser from 'types/advertiser';

export interface AdvertisersContextType {
  advertisers: Advertiser[] | null;
  fetchingAdvertisers: boolean;
  dispatchAdvertisers: React.Dispatch<any>;
}

interface UseAdvertisersReturn {
  advertisers: Advertiser[] | null;
  fetchingAdvertisers: boolean;
  createAdvertiser: (data: Partial<Advertiser>) => Promise<Advertiser | any>;
  updateAdvertiser: (id: number, data: Partial<Advertiser>) => Promise<Advertiser>;
  currentAdvertiser: Advertiser | null;
  setCurrentAdvertiser: React.Dispatch<React.SetStateAction<Advertiser | null>>;
  updateCurrentAdvertiser: (advertiser: Advertiser) => Promise<any> | undefined;
  formatAdvertiserUrl: (url: string) => string;
  validateAdvertiserUrl: (url: string) => boolean;
  verifyRootDomain: (url: string) => boolean;
  setDefaultPayment: (url: string) => Promise<any>;
}

//TODO: Remove //@ts-ignore when contexts and providers are converted to TS
// TODO: Create Advertisers context to keep advertisers globally and request data once from the App component

export const useAdvertisers = (): UseAdvertisersReturn => {
  //@ts-ignore
  const advertisersContext = useContext<AdvertisersContextType | null>(AdvertisersContext);
  const {
    currentAdvertiser,
    setCurrentAdvertiser,
  } = useCurrentSession();
  const adContext = useContext(AdvertiserContext);
  const { useGet, usePatch, usePost } = useAPI();

  if (!advertisersContext) {
    throw new Error(
      'useAdvertisers AdvertisersContext was used outside of its Provider',
    );
  }
  const { advertisers, fetchingAdvertisers, dispatchAdvertisers } =
    advertisersContext;

  // Adds https protocol to user inputted domain
  const formatAdvertiserUrl = (url: string): string => {
    if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
      return url.trim();
    }

    return `https://${url}`.trim();
  };

  // Checks for unsecure protocol
  const validateAdvertiserUrl = (url: string): boolean => !(url.indexOf('http://') === 0);

  // TODO: use Regex to parse url string
  const verifyRootDomain = (url: string): boolean => {
    let protocol: string | null = null;

    if (url.includes('http://')) {
      protocol = 'http://';
    } else if (url.includes('https://')) {
      protocol = 'https://';
    }

    if (protocol != null) {
      const split = url.split(protocol);
      const str = split[1] ? split[1] : null;

      if (str != null) {
        const urlArray = str.split('/');
        const filtered = urlArray.filter(
          a => a !== '' && !a.includes('http'),
        );
        return filtered.length === 1;
      }
    }

    const domain = url.split('/');
    const filtered = domain.filter(a => a !== '');
    return filtered.length === 1;
  };

  const setDefaultPayment = async (url: string): Promise<any> =>
    await usePatch(`/advertisers/${adContext.id}/`, {
      default_payment_profile: url,
    });

  // Exposed function for updating the
  // current advertiser and advertiser context

  //@ts-ignore
  const updateCurrentAdvertiser = (advertiser: Advertiser): Promise<Advertiser | undefined> => {
    //@ts-ignore
    setCurrentAdvertiser(advertiser);

    if (adContext.id === advertiser?.id) {
      return useGet('/advertisers/me/');
    }
  };

  const createAdvertiser = (data: Partial<Advertiser>): Promise<Advertiser | any> => {
    const { name } = currentAdvertiser || {};

    const dataObj = {
      name,
      ...data,
    };

    return usePost('/advertisers/', dataObj)
      .then(response => {
        if (response.statusText === 'Created') {
          updateCurrentAdvertiser(response.data);
          dispatchAdvertisers({ type: 'add', payload: [response.data] });
          return response.data;
        }

        return response;
      })
      .catch(error => {
        console.warn(error);
        return error;
      });
  };

  const updateAdvertiser = (id: number, data: Partial<Advertiser>): Promise<Advertiser> => {
    return usePatch(`/advertisers/${id}`, data).then(response => {
      dispatchAdvertisers({ type: 'replace', payload: response.data });
      return response.data;
    });
  };

  // Expose the functions/variables
  return {
    advertisers,
    fetchingAdvertisers,
    createAdvertiser,
    updateAdvertiser,
    currentAdvertiser,
    // @ts-ignore
    setCurrentAdvertiser,
    updateCurrentAdvertiser,
    formatAdvertiserUrl,
    validateAdvertiserUrl,
    verifyRootDomain,
    setDefaultPayment,
  };
};
