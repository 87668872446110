import moment from 'moment-timezone';

export const useDate = () => {
  const formatDateTime = (date: string | Date, time: string | Date): string | null => {
    if (date && time) {
      const hour = new Date(time).getHours();
      const minute = new Date(time).getMinutes();
      const dateTime = moment(date).hour(hour).minute(minute);

      return moment(dateTime).utc().format();
    }

    return null;
  };

  const formatEndDate = (endDate: string | Date): string => {
    if (Date.parse(endDate.toString())) {
      return moment(endDate).format('l');
    }

    return 'No End Date';
  };

  const getFormattedDateTimes = (
    startDate: string | Date,
    startTime: string | Date,
    endDate: string | Date,
    endTime: string | Date,
    noEndDate: boolean
  ): { utcStartDateTime: string; utcEndDateTime: string | null } => {
    const startDateTime = moment.tz(
      `${moment.tz(startDate, 'America/New_York').format('YYYY-MM-DD')} ${moment.tz(startTime, 'America/New_York').format('HH:mm')}`,
      "America/New_York"
    );

    const utcStartDateTime = startDateTime.utc().format();

    let utcEndDateTime: string | null = null;
    if (!noEndDate) {
      const endDateTime = moment.tz(
        `${moment(endDate).format('YYYY-MM-DD')} ${moment(endTime).format('HH:mm')}`,
        "America/New_York"
      );
      utcEndDateTime = endDateTime.utc().format();
    }

    return { utcStartDateTime, utcEndDateTime };
  };

  return {
    formatDateTime,
    formatEndDate,
    getFormattedDateTimes
  };
};
