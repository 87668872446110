import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { WarningIcon } from '@components/styledComponents/snackbars';
import { StyledList, } from '@components/styledComponents';
import { Icon } from './styledComponents/WrenchIcon';
import StyledTooltip from './styledComponents/StyledTooltip';
import { useIntercom } from '@helpers/intercomWrapper';
import { enqueueSnackbar } from 'notistack';

const CopyMessagesButton = ({ messages }) => {
  const handleCopyMessages = (messages) => {
    const messageCopies = messages.map(message => `- ${message}`).join('\n');
    const clipboardCopy = `Video needs to be replaced:\n${messageCopies}`;
    navigator.clipboard.writeText(clipboardCopy).then(() => {
      const snackbarMessage = "The messages have been copied to your clipboard";
      enqueueSnackbar(snackbarMessage, {
        variant: 'success',
        autoHideDuration: 2000,
        preventDuplicate: true,
      });
      console.log('QC messages copied to clipboard');
    }).catch(err => {
      console.error('Failed to copy text: ', err);
    });
  };
  return (
    <Tooltip title="Copy messages">
      <IconButton
        size="small"
        onClick={() => handleCopyMessages(messages)}
        sx={{ ml: 1, color: '#1DAFFF' }}
      >
        <ContentCopyIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}

CopyMessagesButton.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const MessagesList = ({ messages }) => (
  <StyledList dense disablePadding>
    {messages.map((message) => (
      <li key={message} style={{ fontWeight: 'lighter' }}>
        {message}
      </li>
    ))}
  </StyledList>
);

MessagesList.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const LearnMoreLink = () => {
  const { showArticle } = useIntercom();
  return (
    <Typography
      color="secondary"
      sx={{ fontSize: '12px', cursor: 'pointer' }}
      onClick={() => showArticle('8497224')}
    >
      Learn More
    </Typography>
  );
};

const ActiveCreativeContent = ({ qc }) => (
  <Box>
    <Box display="flex" alignItems="center" mb={1}>
      <Typography fontWeight="bold" sx={{ fontSize: '12px', flexGrow: 1 }}>
        {qc.messages?.length > 0 ? "Creative has formatting issues" : "Creative looks good"}
      </Typography>
      <CopyMessagesButton messages={qc.messages || []} />
    </Box>
    {qc.messages && qc.messages.length > 0 && <MessagesList messages={qc.messages} />}
    <Typography sx={{ fontSize: '12px' }}>
      {qc.messages?.length > 0
        ? "This creative may not run on premium inventory, but will still deliver on inventory with less strict creative requirements."
        : "Your creative will run on all inventory, including Premium"}
    </Typography>
    <LearnMoreLink />
  </Box>
);

ActiveCreativeContent.propTypes = {
  qc: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  handleCopyMessages: PropTypes.func,
};

const PendingCreativeContent = () => (
  <Box>
    <Typography fontWeight="bold" gutterBottom sx={{ fontSize: '12px' }}>
      Checking creative
    </Typography>
    <Typography paragraph sx={{ fontSize: '12px', fontWeight: 'light' }}>
      Checking your video specs to ensure it meets minimum quality requirements.
      Issues will be automatically fixed in most cases.
    </Typography>
    <LearnMoreLink />
  </Box>
);

const FailedCreativeContent = ({ qc }) => (
  <Box>
    <Box display="flex" alignItems="center" mb={1}>
      <Typography fontWeight="bold" sx={{ fontSize: '12px', flexGrow: 1 }}>
        Creative needs to be replaced
      </Typography>
      <CopyMessagesButton messages={qc.messages || []} />
    </Box>
    {qc.messages && <MessagesList messages={qc.messages} />}
    <LearnMoreLink />
  </Box>
);

FailedCreativeContent.propTypes = {
  qc: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const ProcessingCreativeContent = ({ qc }) => (
  <Box>
    <Box display="flex" alignItems="center" mb={1}>
      <Typography fontWeight="bold" sx={{ fontSize: '12px', flexGrow: 1 }}>
        Fixing formatting issues
      </Typography>
      <CopyMessagesButton messages={qc.messages || []} />
    </Box>
    {qc.messages && <MessagesList messages={qc.messages} />}
    <LearnMoreLink />
  </Box>
);

ProcessingCreativeContent.propTypes = {
  qc: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
};

const QCStatusCell = ({ creative, qc }) => {
  return (
    <React.Fragment>
      {creative.active && (
        <StyledTooltip
          arrow
          title={<ActiveCreativeContent qc={qc} />}
          data-testid={qc.messages?.length > 0 ? 'warning-tooltip' : 'approved-tooltip'}
        >
          {qc.messages?.length > 0 ? (
            <WarningIcon sx={{ color: '#FB9933' }} />
          ) : (
            <CheckIcon sx={{ color: '#0FBF84' }} />
          )}
        </StyledTooltip>
      )}
      {!creative.active && qc.status === 'READY' && qc.repaired && (
        <StyledTooltip
          arrow
          title={<ActiveCreativeContent qc={qc} />}
          data-testid="warning-tooltip"
        >
          <WarningIcon sx={{ color: '#FB9933' }} />
        </StyledTooltip>
      )}
      {qc.status === 'PENDING' && (
        <StyledTooltip
          title={<PendingCreativeContent />}
          data-testid="pending-tooltip"
          arrow
        >
          <CircularProgress size={24} sx={{ color: '#1DAFFF' }} />
        </StyledTooltip>
      )}
      {qc.status === 'FAILED' && (
        <StyledTooltip
          title={<FailedCreativeContent qc={qc} />}
          data-testid="failed-tooltip"
          arrow
        >
          <WarningIcon sx={{ color: '#FF003D' }} />
        </StyledTooltip>
      )}
      {qc.status === 'PROCESSING' && (
        <StyledTooltip
          title={<ProcessingCreativeContent qc={qc} />}
          data-testid="processing-tooltip"
          arrow
        >
          <Icon size={24} sx={{ color: '#1DAFFF' }} />
        </StyledTooltip>
      )}
    </React.Fragment>
  );
};

QCStatusCell.propTypes = {
  creative: PropTypes.shape({
    active: PropTypes.bool.isRequired,
  }).isRequired,
  qc: PropTypes.shape({
    status: PropTypes.string.isRequired,
    messages: PropTypes.arrayOf(PropTypes.string),
    repaired: PropTypes.bool,
  }).isRequired,
};


export default QCStatusCell;
