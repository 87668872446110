import React, { useState } from 'react';
import { AssignButtonStyled } from './styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faClose } from '@fortawesome/pro-regular-svg-icons';
import { Stack } from '@mui/material';

export const AssignButton = ({ ...props }) => {
  const [hovered, setHovered] = useState(false);
  return (
    <AssignButtonStyled
      {...props}
      assigned={true}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Stack
        display="inline-flex"
        component="span"
        direction="row"
        alignItems="center"
        gap={1}
      >
        <FontAwesomeIcon icon={hovered ? faClose : faCheck} />
        {hovered ? 'Unassign' : 'Assigned'}
      </Stack>
    </AssignButtonStyled>
  );
};

AssignButton.propTypes = {
  ...AssignButtonStyled.propTypes,
};
