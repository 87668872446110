import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { uniqueId } from 'lodash';

interface LocationState {
  key?: string;
}

export const useUniqueLocationKey = (): string => {
  const { state } = useLocation<LocationState>();
  const { key } = state || {};

  return useMemo(() => key ?? uniqueId(), [key]);
};
