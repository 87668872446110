import { generatePath, useHistory } from 'react-router';
import { RoutePaths } from '@constants';
import { defaultTheme_v2, padmanTheme_v2 } from '@themes';
import { useUniqueLocationKey } from '@components/hooks';
import { useDomain } from '@components/hooks/domain';

export const useWizardSelector = () => {
  const key = useUniqueLocationKey();
  const domain = useDomain();

  const isPeacockDomain = domain.peacock;

  const theme = isPeacockDomain ? padmanTheme_v2 : defaultTheme_v2;
  const history = useHistory();

  return {
    theme,
    key,
    select: () => {
      history.push(
        `${generatePath(RoutePaths.CAMPAIGN_REWORK, { campaignId: 'new' })}${window.location.search}`,
      );
    },
  };
};
