/**
 * @typedef {import('swr/infinite').SWRInfiniteConfiguration} SWRInfiniteConfiguration
 * @typedef {import('swr/infinite').SWRInfiniteConfiguration} SWRInfiniteResponse
 * @typedef {import('swr/mutation').SWRMutationResponse} SWRMutationResponse
 */

import { last, map, omit, set } from 'lodash';
import { useCallback } from 'react';
import { useSWRConfig } from 'swr';
import useSWRMutation from 'swr/mutation';
import { useCurrentSession } from '../currentSession';
import { useInfiniteGlobalCacheUpdate, useLoadInfinite } from './general';
import moment from 'moment';

export const GROUP_TYPE = {
  AD_GROUP: 'ad-group',
  STATIC_GROUP: 'static-group',
};

const TYPE_LINK = {
  [GROUP_TYPE.AD_GROUP]: '/lineitems/',
  [GROUP_TYPE.STATIC_GROUP]: '/staticdisplaylineitems/',
};

const updateGroup = (data, prevData = []) =>
  map(prevData, page => ({
    ...page,
    results: map(page.results, result =>
      result.id === data.id ? { ...result, ...data } : result,
    ),
  }));

const addGroup = (data, prevData = []) =>
  map(prevData, page => ({
    ...page,
    results: map(page.results, result =>
      result.id === data.temporaryId ? data : result,
    ),
  }));

export const defaultAdGroupName = () => {
  const timestamp = moment().format('MMMM Do h:mma');
  return `${timestamp} Ad Group`;
};

const findDataPage = (id, data) => {
  const index = data.findIndex(page =>
    page.results.find(result => result.id === id),
  );
  return index === -1 ? 0 : index + 1;
};

const buildAdGroupCacheKey = (
  type,
  adGroupId,
  currentAdvertiser,
  params = {},
) => {
  const url = `${TYPE_LINK[type]}${adGroupId}/`;

  return { url, advertiser: currentAdvertiser.id, ...params };
};

const buildPageCacheKey = (type, page, currentAdvertiser, params = {}) => {
  const url = TYPE_LINK[type];

  const advertiser = currentAdvertiser.id;

  return {
    url,
    advertiser,
    params: {
      advertiser,
      page,
      ...params,
    },
  };
};

/**
 * @param {typeof keyof TYPE_LINK} type - group type.
 * @param {import('swr/mutation').SWRMutationConfiguration} initOptions - mutation options.
 * @returns {import('swr/mutation').SWRMutationResponse}
 */
export const usePatchGroup = (type, initOptions) => {
  const { patch, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const updateAdGroup = ({ url }, { arg: { id, ...data } }) =>
    patch(`${url}${id}/`, data).then(res => res.data);

  return useSWRMutation(
    apiIsReady && currentAdvertiser.id ? { url: TYPE_LINK[type] } : null,
    updateAdGroup,
    {
      onSuccess: updatedAdGroup => {
        const key = buildAdGroupCacheKey(
          type,
          updatedAdGroup.id,
          currentAdvertiser,
        );
        mutate(key, updatedAdGroup, { populateCache: true });
      },
      revalidate: false,
      ...initOptions,
    },
  );
};

/**
 * @param {typeof keyof TYPE_LINK} type - group type.
 * @param {import('swr/mutation').SWRMutationConfiguration} initOptions - mutation options.
 * @returns {import('swr/mutation').SWRMutationResponse}
 */
export const useDeleteGroup = (type, options) => {
  const { delV1, del, apiIsReady, currentAdvertiser } = useCurrentSession();

  const method = type === GROUP_TYPE.STATIC_GROUP ? del : delV1;

  const deleteAdGroup = (url, { arg: id }) =>
    method(`${url}${id}/`).then(res => res.data);

  const { trigger, isMutating } = useSWRMutation(
    apiIsReady && currentAdvertiser.id ? TYPE_LINK[type] : null,
    deleteAdGroup,
    {
      revalidate: true,
      ...options,
    },
  );

  return { trigger, isMutating };
};

/**
 * @param {typeof keyof TYPE_LINK} type - group type.
 * @param {import('swr/mutation').SWRMutationConfiguration} initOptions - mutation options.
 * @returns {import('swr/mutation').SWRMutationResponse}
 */
export const useCreateGroup = (type, initOptions) => {
  const { post, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { mutate } = useSWRConfig();

  const fetcher = ({ url }, { arg: { temporaryId, ...data } }) =>
    post(url, data).then(res => ({ ...res.data, temporaryId }));

  return useSWRMutation(
    apiIsReady && currentAdvertiser.id ? { url: TYPE_LINK[type] } : null,
    fetcher,
    {
      onSuccess: newAdGroup => {
        mutate(
          buildAdGroupCacheKey(type, newAdGroup.id, currentAdvertiser),
          newAdGroup,
          { populateCache: true },
        );
      },
      revalidate: false,
      ...initOptions,
    },
  );
};

/**
 * @param {keyof typeof TYPE_LINK} type - group type.
 * @param {number} campaignId - campaign id.
 * @param {Object} [options]
 * @param {Function} [options.keyGenerator]
 * @param {Object} [options.params]
 * @param {boolean} [options.params.disabled]
 * @param {boolean} [options.params.v1]
 */
export const useGroups = (type, campaignId, options) => {
  const { patchV1, delV1, del, patch, currentAdvertiser } =
    useCurrentSession();

  const { trigger: triggerGlobalCacheUpdate } =
    useInfiniteGlobalCacheUpdate();

  const url = TYPE_LINK[type];

  const { data, error, isLoading, mutate, items } = useLoadInfinite(
    url,
    {
      params: {
        campaign: Number(campaignId),
        disabled: !campaignId,
      },
      keyGenerator: group =>
        buildAdGroupCacheKey(type, group.id, currentAdvertiser),
      ...options,
    },
    {
      revalidateOnReconnect: false,
      revalidateIfStale: false,
      revalidateOnFocus: false,
    },
  );

  const { trigger: triggerGroupUpdate, isMutating: isPatching } =
    usePatchGroup(type, {
      onSuccess: updatedGroup => {
        triggerGlobalCacheUpdate(
          buildPageCacheKey(
            type,
            findDataPage(updatedGroup.id, data),
            currentAdvertiser,
            {
              campaign: Number(campaignId),
            },
          ),
          updateGroup(updatedGroup, data),
        );
      },
    });

  const { trigger: triggerGroupCreate, isMutating: isCreating } =
    useCreateGroup(type, {
      onSuccess: newGroup => {
        triggerGlobalCacheUpdate(
          buildPageCacheKey(type, data.length, currentAdvertiser, {
            campaign: Number(campaignId),
          }),
          addGroup(newGroup, data),
        );
      },
    });

  const update = useCallback(
    updatedData => triggerGroupUpdate(updatedData),
    [triggerGroupUpdate],
  );

  const add = useCallback(
    predefinedData =>
      mutate(prevData => {
        const lastPage = { ...last(prevData) };

        const newResult = {
          isDisplay: type === GROUP_TYPE.STATIC_GROUP,
          id: (lastPage?.results?.length ?? 0) + 1,
          temporary: true,
          creatives: [],
          ...predefinedData,
        };

        return [
          ...(prevData?.slice(0, -1) ?? []),
          {
            ...lastPage,
            results: [...(lastPage?.results ?? []), newResult],
          },
        ];
      }, false),
    [mutate],
  );

  const remove = useCallback(
    id =>
      mutate(async prevData => {
        const pageWithItem = prevData.find(page =>
          page.results.find(result => result.id === id),
        );
        const { temporary = false } =
          pageWithItem?.results.find(result => result.id === id) || {};

        if (!temporary) {
          await (type === GROUP_TYPE.AD_GROUP ? patchV1 : patch)(
            `${url}${id}/`,
            { creatives: [] },
          );
          await (type === GROUP_TYPE.AD_GROUP ? delV1 : del)(`${url}${id}/`);
        }

        return prevData.map(page => {
          const updatedResults = page.results.filter(
            result => result.id !== id,
          );
          return set({ ...page }, 'results', updatedResults);
        });
      }, false),
    [mutate],
  );

  const create = useCallback(
    data =>
      triggerGroupCreate({ ...omit(data, ['id']), temporaryId: data.id }),
    [triggerGroupCreate, remove],
  );

  const duplicate = useCallback(
    id =>
      mutate(prevData => {
        return prevData.map(page => {
          const itemToDuplicate = page.results.find(
            result => result.id === id,
          );
          if (!itemToDuplicate) return page;

          const duplicatedItem = {
            ...itemToDuplicate,
            name: itemToDuplicate.name
              ? `${itemToDuplicate.name} Duplicate`
              : defaultAdGroupName(),
            id: page.results.length + 1,
            temporary: true,
          };

          return {
            ...page,
            results: [...page.results, duplicatedItem],
          };
        });
      }, false),
    [mutate],
  );

  return {
    items,
    data,
    error,
    isLoading: isLoading || isPatching || isCreating,
    add,
    create,
    remove,
    update,
    duplicate,
    mutate,
  };
};
