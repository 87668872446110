export function logger(useSWRNext) {
  return (key, fetcher, config) => {
    const extendedFetcher = (...args) => {
      if (process.env.USE_SWR_LOGGING === 'true') {
        console.log('SWR Request:', key, config);
      }
      return fetcher(...args);
    };

    return useSWRNext(key, extendedFetcher, config);
  };
}
