import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const roleNames = {
  READ_ONLY: 'Analyst',
  CAMPAIGN_MANAGER: 'Campaign Manager',
  ADMIN: 'Admin',
};

const formatRole = role => roleNames[role];

export const AdAccountsTable = ({
  adAccounts,
  handleManageAdAccount,
  handleAddAdAccount,
}) => {
  return (
    <Box mt={6}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ad Account</TableCell>
              <TableCell align="center">Budget</TableCell>
              <TableCell align="center">Advertiser</TableCell>
              <TableCell align="center">Billing Account</TableCell>
              <TableCell align="center">Ad Account Role</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {adAccounts && adAccounts.map(acct => (
              <TableRow hover key={acct.ad_account_id}>
                <TableCell component="th" scope="row">
                  {acct.ad_account_name}
                </TableCell>
                <TableCell align="center">{acct.budget ? `$${acct.budget}` : '-'}</TableCell>
                <TableCell align="center">{acct.advertiser_name}</TableCell>
                <TableCell align="center">
                  {acct.billing_account_name}
                </TableCell>
                <TableCell align="center">{formatRole(acct.role)}</TableCell>
                <TableCell align="right">
                  <Button
                    data-testid={`manage-account-${acct.ad_account_id}`}
                    onClick={() => handleManageAdAccount(acct)}
                    color="secondary"
                  >
                    Manage
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={1} ml={1}>
        <Button
          data-testid="add-ad-account-btn"
          color="secondary"
          size="small"
          startIcon={<AddCircleOutlineIcon fontSize="small" />}
          onClick={handleAddAdAccount}
        >
          Add Account
        </Button>
      </Box>
    </Box>
  );
};

AdAccountsTable.propTypes = {
  adAccounts: PropTypes.array,
  handleManageAdAccount: PropTypes.func,
  handleAddAdAccount: PropTypes.func,
};
