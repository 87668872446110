import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList } from 'react-window';

import VirtualizedListRows from './VirtualizedListRows';
import { LinearProgress, Box } from '@mui/material';
import { useAdvertisers } from '../../hooks';

const LISTBOX_PADDING = 8;
const PROGRESS_BAR_HEIGHT = 4;

const ListboxComponent = React.forwardRef(({ children }, ref) => {
  const rowItems = [];
  children?.forEach(item => {
    const { children: itemChildren, ...itemRest } = item;
    rowItems.push(itemRest);
    rowItems.push(...(itemChildren || []));
  });

  const itemCount = rowItems.length;
  const displayCount = 20;
  const itemSize = 36;

  const getHeight = () => {
    if (itemCount >= displayCount) {
      return displayCount * itemSize;
    }
    return itemCount * itemSize;
  };

  const { fetchingAdvertisers: isLoading } = useAdvertisers();

  // Renders the inner content of the virtualized list, including a
  // conditional loading indicator and the list items
  const InnerElementType = useCallback(
    ({ style, children, ...rest }) => (
      <div {...rest}>
        {isLoading && (
          <LinearProgress
            color="secondary"
            sx={{
              position: 'sticky',
              top: 0,
              zIndex: 1,
              height: PROGRESS_BAR_HEIGHT,
            }}
          />
        )}
        <Box
          sx={{
            ...style,
            position: 'relative',
            paddingTop: isLoading ? PROGRESS_BAR_HEIGHT : 0,
          }}
        >
          {children}
        </Box>
      </div>
    ),
    [isLoading],
  );

  return (
    <div ref={ref}>
      <FixedSizeList
        itemData={rowItems}
        height={getHeight() + 2 * LISTBOX_PADDING}
        width="100%"
        itemSize={itemSize}
        overscanCount={50}
        itemCount={itemCount}
        innerElementType={InnerElementType}
      >
        {VirtualizedListRows}
      </FixedSizeList>
    </div>
  );
});

ListboxComponent.displayName = 'ListboxComponent';
ListboxComponent.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object),
};

export default ListboxComponent;
