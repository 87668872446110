import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ClickableArea = styled(Box)({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  cursor: 'pointer',
});
