import { isEmpty } from 'lodash';

export const isSingleValueInBetween = (value, [first, last]) =>
  value >= first && value <= last;

export const isBetween = (value, [first, last]) => {
  if (!Array.isArray(value)) {
    return isSingleValueInBetween(value, [first, last]);
  }

  const [firstValue, lastValue] = value;

  return (
    isSingleValueInBetween(firstValue, [first, last]) &&
    isSingleValueInBetween(lastValue, [first, last])
  );
};

export const isSame = ([firstValue, lastValue], [first, last]) => {
  return firstValue === first && lastValue === last;
};

export const minMax = values => {
  return [Math.min(...values), Math.max(...values)];
};

export const buildRange = (currentValue, selectedValue) => {
  if (Array.isArray(selectedValue)) {
    if (isEmpty(currentValue) || isEmpty(selectedValue)) {
      return selectedValue;
    }

    const [firstSelected, lastSelected] = selectedValue;
    const [firstCurrent, lastCurrent] = currentValue;

    if (firstSelected < firstCurrent) {
      return [firstSelected, lastCurrent];
    }

    return [firstCurrent, lastSelected];
  }
};
