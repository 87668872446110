import PropTypes from 'prop-types';
import React from 'react';

import {
  faCircleCheck,
  faCircleDashed,
  faPlusCircle,
} from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, styled } from '@mui/material';

const iconList = {
  complete: faCircleCheck,
  add: faPlusCircle,
  pending: faCircleDashed,
  active: faCircleArrowRight,
};

export const nodeStatus = {
  add: 'add',
  pending: 'pending',
  complete: 'complete',
  active: 'active',
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'status',
})(({ theme, status }) => ({
  ...theme.typography.body1,
  padding: 0,
  color:
    status === nodeStatus.active
      ? theme.palette.common.black
      : theme.palette.grey[4],
  justifyContent: 'flex-start',
  '&.Mui-disabled': {
    color:
      status === nodeStatus.active
        ? theme.palette.common.black
        : theme.palette.grey[4],
  },
  '&:hover': {
    color: status === nodeStatus.add ? theme.palette.blue[6] : '',
    '& .MuiButton-startIcon': {
      color: status === nodeStatus.add ? theme.palette.blue[6] : '',
    },
  },
  '& .MuiButton-startIcon': {
    marginLeft: 0,
    color:
      status === nodeStatus.active
        ? theme.palette.blue[6]
        : theme.palette.grey[4],
  },
}));

export const SubwayNode = ({ label, status, onClick, ...props }) => {
  return (
    <StyledButton
      startIcon={<FontAwesomeIcon icon={iconList[status]} />}
      disableRipple
      status={status}
      onClick={onClick}
      {...props}
    >
      {label}
    </StyledButton>
  );
};

SubwayNode.propTypes = {
  ...StyledButton.propTypes,
  label: PropTypes.string,
  status: PropTypes.oneOf(Object.values(nodeStatus)).isRequired,
};
