import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Switch,
  Grid,
  InputAdornment,
  Typography,
  TextField,
  Tooltip,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckIcon from '@mui/icons-material/Check';
import numeral from 'numeral';

import { Accordion, AccordionDetails, AccordionSummary } from './Accordion';
import Info from './Info';
import { Bid } from './style';

const OptionalMaxCPMStrategy = props => {
  const {
    strategy,
    currentStrategy,
    isExpanded,
    handleExpand,
    handleFormChange,
    extraData,
  } = props;

  const { cpmRange, isInternalUser, adjustedCPM, rangeText, infoMessage } =
    extraData;

  const [showOptionalInput, setShowOptionalInput] = useState(
    !!currentStrategy.cpm
  );

  const toggleShowInput = () => {
    const nextState = !showOptionalInput;

    setShowOptionalInput(nextState);
    if (!nextState) {
      handleFormChange('cpm', null);
    }
  };

  const handleInputChange = event => {
    handleFormChange(event.target.name, event.target.value);
  };

  return (
    <Accordion
      key={strategy.url}
      square
      expanded={isExpanded}
      onChange={handleExpand}
    >
      <AccordionSummary
        aria-controls={`${strategy.url}-content`}
        id={`${strategy.url}-header`}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <RadioGroup
              row
              value={currentStrategy.bid_strategy}
              onChange={handleInputChange}
              name="bid_strategy"

            >
              <FormControlLabel
                value={strategy.url}
                control={<Radio />}
                label={strategy.display_name}
                data-testid={`radio-${strategy.name.toLowerCase().replace(' ', '-')}`}
              />
            </RadioGroup>
          </Grid>

          <Grid item>
            {isExpanded ? (
              <CheckIcon style={{ color: '#1DAFFF', fontSize: 16 }} />
            ) : (
              <Tooltip title={strategy.tooltip}>
                <InfoOutlinedIcon
                  style={{ color: '#1DAFFF', fontSize: 16 }}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body2">{strategy.description}</Typography>
          </Grid>

          <Grid item>
            <FormControlLabel
              control={
                <Switch
                  checked={showOptionalInput}
                  onChange={toggleShowInput}
                  name="maxBid"
                  color="secondary"
                />
              }
              label="Set Max CPM Bid"
            />
          </Grid>

          {showOptionalInput && (
            <Grid item container justifyContent="space-between" spacing={2}>
              <Grid item xs={5}>
                <TextField
                  color="secondary"
                  label={strategy.bidStrategyLabel}
                  value={currentStrategy.cpm || ''}
                  onChange={handleInputChange}
                  fullWidth
                  variant="outlined"
                  name="cpm"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputProps: { min: 0, step: 0.01 },
                  }}
                />
              </Grid>

              {cpmRange?.length === 2 &&
                cpmRange[0] > 0 &&
                cpmRange[1] > 0 && (
                  <Grid item container xs justifyContent="flex-start">
                    <Grid item xs={2}>
                      <Tooltip
                        title="Max bids can be up to 10% higher due to auction mechanics."
                        sx={{ maxWidth: 180 }}
                        arrow
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </Grid>

                    <Grid item xs={10}>
                      <Bid>
                        The bid range for your inventory selection is{' '}
                        <br />
                        {rangeText}
                      </Bid>
                      {isInternalUser && (
                        <Bid>
                          Max CPM bid sent to BW <br />
                          {numeral(adjustedCPM).format('$0,0')}
                        </Bid>
                      )}
                    </Grid>
                  </Grid>
                )}
            </Grid>
          )}

          <Grid item>
            <Info message={infoMessage} />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

OptionalMaxCPMStrategy.propTypes = {
  strategy: PropTypes.object.isRequired,
  currentStrategy: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  handleExpand: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  extraData: PropTypes.shape({
    adjustedCPM: PropTypes.number.isRequired,
    cpmRange: PropTypes.array.isRequired,
    infoMessage: PropTypes.string.isRequired,
    isInternalUser: PropTypes.bool.isRequired,
    rangeText: PropTypes.string.isRequired,
  }),
};

export default OptionalMaxCPMStrategy;
