import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider, Grid,
  IconButton,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import AsyncButton from './AsyncButton';
import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';

const PREFIX = 'DeleteDraftCampaign';

const classes = {
  dialog: `${PREFIX}-dialog`,
  campaign: `${PREFIX}-campaign`
};

const StyledDialog = styled(Dialog)(({
  theme: { spacing }
}) => ({
  [`&.${classes.dialog}`]: {
    paddingTop: spacing(2),
    paddingBottom: spacing(2),
  },

  [`& .${classes.campaign}`]: {
    color: '#0fbf84',
  }
}));

const DeleteDraftCampaign = props => {


  const { useGetAll, usePatch, useDelete } = useAPI();
  const { isLoading, setIsLoading } = useLoader();
  const {
    isLoading: isFetching,
    setIsLoading: setIsFetching,
  } = useLoader();
  const { enqueueSnackbar } = useSnackbar();

  const { campaign, isOpen, onClose } = props;
  const { name: campaignName } = campaign;

  const [adGroups, setAdGroups] = useState([]);
  const [displays, setDisplays] = useState([]);

  useEffect(() => {
    async function getLineItems() {
      await getDisplays();
      await getAdGroups();
    }

    getLineItems()
      .then(() => {
        setIsLoading(false);
        setIsFetching(false);
      })
      .catch(error => console.error(error));
  }, []);

  async function getAdGroups() {
    setIsFetching(true);

    await useGetAll('/lineitems', [], lineItems => {
      const filtered = lineItems.filter(
        li => li.campaign === campaign.url && li.draft === true
      );

      setAdGroups(prev => [...prev, ...filtered]);
    });
  }

  async function getDisplays() {
    setIsFetching(true);

    await useGetAll('/static_display_lineitems', [], displays => {
      const filtered = displays.filter(
        d => d.campaign === campaign.url && d.draft === true
      );

      setDisplays(prev => [...prev, ...filtered]);
    });
  }

  const handleRemoveCreatives = async adGroups => {
    const creativesPromises = adGroups.map(group =>
      usePatch(`/lineitems/${group.id}/`, {
        creatives: [],
      })
    );

    return Promise.all(creativesPromises)
      .then(responses => {
        console.log('responses from creatives', responses);
        return responses;
      })
      .catch(error => console.log(error));
  };

  const handleRemoveDisplays = async displays => {
    const displaysPromises = displays.map(display =>
      usePatch(`/static_display_lineitems/${display.id}/`, {
        creatives: [],
      })
    );

    return Promise.all(displaysPromises)
      .then(responses => {
        return responses;
      })
      .catch(error => console.log(error));
  };

  const handleDeleteLineItems = async adGroups => {
    const lineItemPromises = adGroups.map(item =>
      useDelete(`/lineitems/${item.id}/`)
    );

    return Promise.all(lineItemPromises)
      .then(responses => {
        return responses;
      })
      .catch(error => console.log(error));
  };

  const handleDeleteDisplayLineItems = async displays => {
    const lineItemPromises = displays.map(display =>
      useDelete(`/static_display_lineitems/${display.id}/`)
    );

    return Promise.all(lineItemPromises)
      .then(responses => {
        return responses;
      })
      .catch(error => console.log(error));
  };

  const handleDeleteCampaign = async campaign => {
    try {
      const response = await useDelete(`/campaigns/${campaign.id}`);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  };

  const deleteCampaign = async () => {
    setIsLoading(true);

    await handleRemoveDisplays(displays);
    await handleRemoveCreatives(adGroups);
    await handleDeleteDisplayLineItems(displays);
    await handleDeleteLineItems(adGroups);

    const campaignRes = await handleDeleteCampaign(campaign);

    props.onDelete?.(campaign);
    if (campaignRes.status !== 204) {
      setIsLoading(false);

      enqueueSnackbar('There was an error deleting the campaign', {
        autoHideDuration: null,
        variant: 'error',
      });
    }

    if (campaignRes.status === 204) {
      setIsLoading(false);

      enqueueSnackbar('Campaign successfully deleted', {
        variant: 'success',
      });

      onClose();
    }
  };

  // Render modal/modal components
  // TODO: List line items for campaign that will be deleted?
  return (
    <StyledDialog
      className={classes.dialog}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      aria-labelledby="delete-draft-dialog"
    >
      <DialogTitle id="delete-draft-dialog">
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          pt={1}
        >
          <Box>
            <Typography variant="h4">
              <span>Delete Draft:&nbsp;&nbsp;</span>

              <span style={{ color: '#0fbf84' }}>
                {campaignName && campaignName ? campaignName : ''}
              </span>
            </Typography>
          </Box>

          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Box px={3}>
        <Divider />
      </Box>

      <DialogContent className={classes.dialog}>
        <Box py={2} px={2} mb={4}>
          <Box mb={2}>
            <Typography variant="body1">
              You are about to delete a draft of a campaign. Are you sure
              <br/>
              you wish to continue?
            </Typography>
          </Box>

          <Typography variant="body1">
            <em>This operation cannot be reversed.</em>
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions disableSpacing sx={{ backgroundColor: '#e5e7eb', px: 4, py: 3 }}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          spacing={2}
        >
          <Grid item>
            <Button
              sx={{ color: 'grey.main' }}
              onClick={onClose}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <AsyncButton
              isDisabled={isFetching}
              isLoading={isLoading}
              onClick={deleteCampaign}
              textButton="Delete Campaign"
              loadingButton={isLoading ? 'Saving...' : 'Save'}
            />
          </Grid>
        </Grid>
      </DialogActions>
    </StyledDialog>
  );
};

DeleteDraftCampaign.propTypes = {
  campaign: PropTypes.object,
  isModal: PropTypes.bool,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  setAdGroups: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DeleteDraftCampaign;
