import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';

export const ConditionalTooltip = ({ title, children, ...rest }) => {
  return title ? (
    <Tooltip title={title} {...rest}>
      {children}
    </Tooltip>
  ) : children;
};

ConditionalTooltip.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};
