import React from 'react';
import { Box } from '@mui/material';

export const WrenchIcon = (props) => (
  <Box component="svg" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path fillRule="evenodd" clipRule="evenodd"
          d="M3.15995 15.0243C1.21837 13.0827 0.720561 10.2465 1.66086 7.847C1.77903 7.54546 2.04131 7.32379 2.35836 7.25755C2.67541 7.19131 3.00448 7.28942 3.23351 7.51848L6.53011 10.8151L7.94391 10.3438L8.41517 8.92998L5.11858 5.63341C4.88955 5.40435 4.79146 5.07526 4.8577 4.75817C4.92393 4.44117 5.14558 4.1789 5.44715 4.06072C7.84668 3.12039 10.6828 3.61825 12.6244 5.55977C14.4041 7.33944 14.9707 9.8706 14.3288 12.1283L19.4472 17.2466C20.7175 18.517 20.7175 20.5767 19.4472 21.847C18.1768 23.1174 16.1171 23.1174 14.8467 21.847L9.72836 16.7287C7.47074 17.3706 4.93956 16.8039 3.15995 15.0243ZM3.12261 10.1229C3.07762 11.4007 3.54328 12.6923 4.5176 13.6667C5.90033 15.0494 7.92207 15.4076 9.63668 14.7357C9.99197 14.5965 10.3959 14.6809 10.6658 14.9507L16.2045 20.4894C16.725 21.0099 17.5689 21.0099 18.0895 20.4894C18.61 19.9688 18.61 19.1249 18.0895 18.6043L12.5509 13.0656C12.281 12.7958 12.1966 12.3918 12.3358 12.0366C13.0077 10.3219 12.6495 8.30022 11.2668 6.91742C10.2925 5.94311 9.00086 5.4775 7.72299 5.52243L10.1923 7.99186C10.4495 8.24895 10.5393 8.62931 10.4243 8.97424L9.61364 11.4063C9.51802 11.693 9.29309 11.9179 9.00643 12.0134L6.57439 12.8242C6.22943 12.9392 5.84911 12.8493 5.59199 12.5922L3.12261 10.1229Z"
          fill="#1DAFFF" />
  </Box>
);
