export const toggleButtonStyles = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      borderColor: 'transparent',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      fontWeight: 400,
      '&.Mui-selected': {
        background: theme.palette.blue[11],
        boxShadow: 'none',
        '&:hover': {
          background: theme.palette.blue[11],
          boxShadow: 'none',
        },
      },
      '&:not(.Mui-selected)': {
        background: theme.palette.grey[12],
        boxShadow: theme.shadows[4],
        '&:hover': {
          background: theme.palette.grey[12],
          boxShadow: theme.blueHoverShadow,
        },
        // Target a not selected item that follows another not selected item & mask the left box shadow
        '& + &:not(.Mui-selected)': {
          overflow: 'visible',

          '&:before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: theme.spacing(-1.25),
            width: theme.spacing(1.25),
            height: '102%', // account for pad, margin, & border-radius
            background: theme.palette.grey[12],
          },

          '&:after': {
            content: '""',
            position: 'absolute',
            top: '15%',
            bottom: '15%',
            left: 0,
            width: '1px',
            height: '70%',
            background: theme.palette.grey[8],
          },
        },
      },
      '& .MuiToggleButton-label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }),
  },
};
