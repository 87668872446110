export const joinWithAnd = (array, separator = ', ') => {
  const firstPart = array.slice(0, array.length - 1);
  const secondPart = array.slice(array.length - 1, array.length);

  const gluing = [];

  if (firstPart.length === 0) {
    gluing.push(secondPart);
  }

  if (firstPart.length > 0) {
    gluing.push(firstPart.join(separator));
  }

  if (firstPart.length > 1) {
    gluing[0] += separator.trim();
  }

  if (firstPart.length > 0 && secondPart.length > 0) {
    gluing.push('and', secondPart);
  }

  return gluing.join(' ');
};
