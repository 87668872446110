import React from 'react';
import { FileDropzone } from '@v2/components/campaign/FileUploads';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

export const Edit = ({ onDrop, children, isDisplay, ...props }) => {
  const type = isDisplay ? 'image' : 'video';

  return (
    <FileDropzone
      {...props}
      onChange={onDrop}
      type={type}
    >
      {children}
    </FileDropzone>
  );
};

Edit.propTypes = {
  ...Box.propTypes,
  children: PropTypes.node,
  files: PropTypes.array,
  isDisplay: PropTypes.bool,
  onDrop: PropTypes.func,
};
