import React from 'react';
import { GeoTargeter, distinctNames } from '../../GeoTargeter';
import { GeographyPreview } from '../../GeoTargeter/GeographyPreview';
import { getActualFieldPlain } from '../../GeoTargeter/utils';
import { useGeographyItem } from './hooks/useGeographyItem';
import { GeographyItemProps } from './types';
import { includes } from 'lodash';

export const GeographyItem = ({
  index,
  onAdvanced,
  value,
  onBlur,
  onRemove,
  onUpdate,
  ...props
}: GeographyItemProps) => {
  const { change, changeSelectionType } = useGeographyItem({
    value,
    onBlur,
    onUpdate,
  });

  return !includes(
    [distinctNames.empty, distinctNames.country],
    value.type
  ) ? (
    <GeographyPreview
      onChange={change}
      value={{
        ...value,
      }}
      onEdit={() => {
        onAdvanced({
          data: [value],
        });
      }}
    />
  ) : (
    <GeoTargeter
      {...props}
      value={value}
      onBlur={onBlur}
      label={getActualFieldPlain(value)}
      onChange={change}
      onChangeSelectionType={changeSelectionType}
      selectionType={value.blacklist ? 'exclude' : 'include'}
    />
  );
};
