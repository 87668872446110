import React from 'react';
import { CircularProgress, IconButton, InputAdornment, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { Close } from '@mui/icons-material';

export const RHFSelect = ({ name, control, loading, clearable, ...rest }) => {
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <TextField
      color="secondary"
      fullWidth
      select
      margin="none"
      InputLabelProps={{ shrink: true, htmlFor: name, id: `${name}-label` }}
      variant="outlined"
      {...rest}
      {...field}
      error={!!fieldState.error}
      helperText={fieldState.error ? fieldState.error.message : null}
      FormHelperTextProps={{
        id: `${name}-helper-text`,
      }}
      SelectProps={{
        SelectDisplayProps: {
          "aria-labelledby": `${name}-label`,
          'aria-invalid': !!fieldState.invalid,
          'aria-errormessage': `${name}-helper-text`,
        }
      }}
      InputProps={{
        ...rest.InputProps,
        id: name,
        name,
        endAdornment: loading || clearable ? (
          <InputAdornment position="end" style={{ marginRight: 18 }}>
            {loading && <CircularProgress size="24px" />}
            {clearable && !loading && field.value && (
              <IconButton
                aria-label="clear"
                onClick={() => field.onChange(null)}
                onMouseDown={(e) => e.preventDefault()}
                edge="end"
                size='small'
              >
                <Close />
              </IconButton>
            )}
          </InputAdornment>
        ) : (
          rest.InputProps?.endAdornment || null
        ),
      }}
    />
  );
};

RHFSelect.propTypes = {
  name: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool,
  clearable: PropTypes.bool,
};
