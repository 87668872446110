import React, { memo } from 'react';
import moment from 'moment-timezone';
import { merge, mergeWith } from 'lodash';
import {
  LineChart as DefaultLineChart,
  LineChartProps,
  lineElementClasses
} from '@mui/x-charts/LineChart';

export const defaultMargin = {
  top: 50,
  bottom: 30,
  left: 0,
  right: 0,
};

export const defaultSeries = {
  type: 'line',
  showMark: false,
  area: true,
  baseline: 'min',
};

export const defaultXAxis = (props: LineChartProps) => ({
  scaleType: 'point',
  disableLine: true,
  disableTicks: true,
  tickNumber: 5,
  tickLabelStyle: {
    fontSize: 14,
    fontWeight: 700,
    fill: '#77858C'
  },
  sx: {
    transform: 'translate(0, 280px)',
  },
  tickInterval: (value: any, index: number) => {
    const isFirst = index === 0;
    const datasetLength = props.dataset ? props.dataset.length : 0;
    const isLast = index === datasetLength - 1;

    return !(isFirst || isLast);
  },
  valueFormatter: (value: string | Date) => moment(value).format('m[m]'),
});

export const defaultYAxis = {
  tickNumber: 5,
  disableLine: true,
  disableTicks: true,
  tickLabelStyle: {
    fontSize: 14,
    fontWeight: 700,
    fill: '#77858C'
  },
  sx: {
    transform: 'translate(50px, 0)',
  },
  valueFormatter: (value: number) => {
    if (value >= 1000) {
      const formattedValue = (value / 1000).toFixed(1);

      return formattedValue.endsWith('.0')
        ? `$${Math.round(value / 1000)}K`
        : `$${formattedValue}K`;
    }

    return `$${value}`;
  },
};

export const defaultSx = {
  [`& .${lineElementClasses.root}`]: {
    strokeWidth: 4
  },
};

const LineChart = (props: LineChartProps) => {
  const gradientStyles = props.series.reduce<Record<string, React.CSSProperties>>((acc, series) => {
    if (series.id && series.color) {
      const seriesId = String(series.id);

      acc[`& .MuiAreaElement-series-${seriesId}`] = {
        fill: `url('#${seriesId}Gradient')`
      };
    }

    return acc;
  }, {});

  const defs = props.series.reduce<React.ReactNode[]>((acc, series) => {
    if (series.id && series.color) {
      acc.push(
        <linearGradient
          key={series.id}
          id={`${series.id}Gradient`}
          gradientTransform="rotate(90)"
        >
          <stop offset='1.1%' stopColor={`${series.color}33`} />
          <stop offset='73.23%' stopColor={`${series.color}01`} />
        </linearGradient>
      );
    }

    return acc;
  }, []);

  return (
    <DefaultLineChart
      height={300}
      disableAxisListener
      {...props}
      series={mergeWith(
        [],
        props.series,
        (objValue, srcValue) => merge({}, defaultSeries, srcValue)
      )}
      xAxis={mergeWith(
        [],
        props.xAxis,
        (objValue, srcValue) => merge({}, defaultXAxis(props), srcValue)
      )}
      yAxis={mergeWith(
        [],
        props.yAxis,
        (objValue, srcValue) => merge({}, defaultYAxis, srcValue)
      )}
      margin={merge({}, defaultMargin, props.margin)}
      sx={merge({ ...gradientStyles }, defaultSx, props.sx)}
    >
      <defs>
        {defs}
      </defs>
      {props.children}
    </DefaultLineChart>
  );
};

export default memo(LineChart);
