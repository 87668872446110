import React, { Fragment, useEffect, useState, memo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import SearchIcon from '@mui/icons-material/Search';
import MiddleEllipsis from '../helpers/ellipsis';

import SortableTableHead from './ui/SortableTableHead';
import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import { useSort } from './hooks/sort';
import checkIcon from '../images/icon-check--blue.png';

const PREFIX = 'DisplayLibrary';

const classes = {
  root: `${PREFIX}-root`,
  action: `${PREFIX}-action`,
  adGroup: `${PREFIX}-adGroup`,
  adGroups: `${PREFIX}-adGroups`,
  adGroupCell: `${PREFIX}-adGroupCell`,
  adGroupHeader: `${PREFIX}-adGroupHeader`,
  assigned: `${PREFIX}-assigned`,
  assignedRow: `${PREFIX}-assignedRow`,
  check: `${PREFIX}-check`,
  expanded: `${PREFIX}-expanded`,
  fileSize: `${PREFIX}-fileSize`,
  iconCell: `${PREFIX}-iconCell`,
  libraryHeader: `${PREFIX}-libraryHeader`,
  name: `${PREFIX}-name`,
  play: `${PREFIX}-play`,
  na: `${PREFIX}-na`,
  none: `${PREFIX}-none`,
  spinner: `${PREFIX}-spinner`,
  tableCell: `${PREFIX}-tableCell`,
  table: `${PREFIX}-table`,
  unassigned: `${PREFIX}-unassigned`
};

const StyledBox = styled(Box)(({
  theme: { palette, spacing }
}) => ({
  [`& .${classes.root}`]: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  [`& .${classes.action}`]: {
    width: 115,
  },

  [`& .${classes.adGroup}`]: {
    color: '#727383',
    fontWeight: 600,
    marginTop: 0,
  },

  [`& .${classes.adGroups}`]: {
    border: 'none',
    padding: spacing(2),
    paddingTop: 0,
    width: 160,
  },

  [`& .${classes.adGroupCell}`]: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 160,
  },

  [`& .${classes.adGroupHeader}`]: {
    minWidth: 130,
  },

  [`& .${classes.assigned}`]: {
    color: palette.secondary.main,
  },

  [`& .${classes.assignedRow}`]: {
    background: '#0000000a',
    opacity: .9,
  },

  [`& .${classes.check}`]: {
    width: '100%',
  },

  [`& .${classes.expanded}`]: {
    border: 'none',
    padding: 0,
  },

  [`& .${classes.fileSize}`]: {
    minWidth: 100,
  },

  [`& .${classes.iconCell}`]: {
    maxWidth: 40,
    width: 40,
    paddingLeft: spacing(1),
    paddingRight: spacing(1),
  },

  [`& .${classes.libraryHeader}`]: {
    marginBottom: spacing(4),
  },

  [`& .${classes.name}`]: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 220,
  },

  [`& .${classes.play}`]: {
    marginTop: 4,
  },

  [`& .${classes.na}`]: {
    fontWeight: 500,
  },

  [`& .${classes.none}`]: {
    color: '#727383',
  },

  [`& .${classes.spinner}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 375,
    alignItems: 'center',
  },

  [`& .${classes.tableCell}`]: {
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.table}`]: {
    maxHeight: 375,
    minHeight: 375,
  },

  [`& .${classes.unassigned}`]: {
    color: '#e57373',
  }
}));

const columns = [
  { id: 'name', label: 'Display Creative', align: 'left', sortable: true, class: '' },
  { id: 'adGroups', label: 'Ad groups', align: 'left', sortable: false, class: 'adGroupHeader' },
  { id: 'resolution', label: 'Resolution', align: 'center', sortable: false, class: '' },
  { id: 'fileSize', label: 'File size', align: 'center', sortable: false, class: 'fileSize' },
  { id: 'media_upload_date', align: 'center', label: 'Date created', sortable: true, class: '' },
  { id: 'action', label: 'Action', align: 'center', sortable: false, class: '', disablePadding: true },
];

const DisplayLibrary = ({
  assigned,
  handleBatchAssign,
  handleUnassign,
  setCurrentPreview,
  setIsPreview,
}) => {

  const { useGetAll } = useAPI();
  const { isLoading, setIsLoading } = useLoader();
  const {
    order,
    orderBy,
    handleRequestSort,
    getComparator,
    stableSort
  } = useSort();

  const [displays, setDisplays] = useState([]);
  const [cachedDisplays, setCachedDisplays] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    getDisplays();
  }, []);

  function getDisplays() {
    setIsLoading(true);

    return useGetAll('/static_display_creatives', [], total => {
      setCachedDisplays(prev => [...prev, ...total]);
      setDisplays(prev => [...prev, ...total]);
      setIsLoading(false);
    });
  }

  const handleAssignSelected = () => {
    if (selected.length > 0) {
      handleBatchAssign(
        selected.map(s => cachedDisplays.find(c => c.id === s))
      );
      setSelected([]);
      setIsSelectAll(false);
    }
  };

  const handleToggleAssign = video => {
    const hasDisplay = assigned.some(a => a.url === video.url);

    if (hasDisplay) {
      handleUnassign(video);
    } else {
      handleBatchAssign([video]);
      setSelected(prev => prev.filter(p => p.url === video.url));
    }
  };

  const handleExpand = id => {
    if (expanded.includes(id)) {
      setExpanded(prev => prev.filter(p => p !== id));
    } else {
      setExpanded(prev => [...prev, id]);
    }
  };

  const handleSearchFilter = value => {
    setSearch(value);

    if (value !== '') {
      setDisplays(() =>
        cachedDisplays.filter(
          p => p.name.toLowerCase().indexOf(value.toLowerCase()) > -1
        )
      );
    } else {
      setDisplays(cachedDisplays);
    }
  };

  const handleSelected = id => {
    if (selected.includes(id)) {
      setSelected(prev => prev.filter(p => p !== id));
    } else {
      setSelected(prev => [...prev, id]);
    }
  };

  const handleSelectAll = event => {
    const { checked } = event.target;

    setIsSelectAll(checked);

    if (checked) {
      setSelected(() =>
        displays
          .filter(c => !assigned.some(a => a.url === c.url))
          .map(c => c.id)
      );
    } else {
      setSelected([]);
    }
  };

  const handlePreview = display => {
    setCurrentPreview(display);
    setIsPreview(true);
  };

  const renderMegaBytes = bytes => {
    return `${(parseInt(bytes, 10) / 1000000).toFixed(2)} MB`;
  };

  const renderLibraryHeader = () => (
    <Grid
      className={classes.libraryHeader}
      container
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={6}>
        <Typography variant="h2">Display Creative</Typography>
      </Grid>

      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        xs={6}
      >
        <Grid item xs={9}>
          <TextField
            color="secondary"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={event => handleSearchFilter(event.target.value)}
            size="small"
            placeholder="Search display"
            value={search}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <Button
            disabled={selected.length === 0}
            color="secondary"
            onClick={handleAssignSelected}
            startIcon={<PlayCircleFilledIcon />}
            variant="contained"
          >
            Assign
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = display => {
    const isAssigned = assigned.findIndex(a => a.url === display.url) > -1;
    const isExpanded = expanded.includes(display.id);
    const isSelected = selected.includes(display.id);
    const labelId = `display-${display.id}`;

    const { staticdisplaylineitem_set: adGroups } = display;

    return (
      <Fragment key={display.id}>
        <TableRow
          className={clsx(
            isExpanded ? classes.root : '',
            isAssigned ? classes.assignedRow : ''
          )}
        >
          <TableCell className={classes.iconCell}>
            {adGroups && adGroups.length > 0 && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => handleExpand(display.id)}
              >
                {isExpanded ? (
                  <KeyboardArrowUpIcon />
                ) : (
                  <KeyboardArrowDownIcon />
                )}
              </IconButton>
            )}
          </TableCell>

          <TableCell className={classes.height} padding="none">
            {!isAssigned ? (
              <Checkbox
                checked={isSelected}
                disabled={isAssigned}
                inputProps={{ 'aria-labelledby': labelId }}
                onChange={() => handleSelected(display.id)}
                size="small"
              />
            ) : (
              <Box
                display="flex"
                width={18}
                alignItems="center"
                justifyContent="center"
                my={0}
                mx="auto"
              >
                <img
                  src={checkIcon}
                  className={classes.check}
                />
              </Box>
            )}
          </TableCell>

          <TableCell component="th" id={labelId} padding="none" scope="row">
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <IconButton
                  onClick={() => handlePreview(display)}
                  size="small"
                >
                  <ImageSearchIcon color="secondary" fontSize="small" />
                </IconButton>
              </Grid>

              <Grid className={classes.name} item xs>
                <Tooltip title={display.name}>
                  <div>
                    <MiddleEllipsis>
                      <span>
                        {display.name}
                      </span>
                    </MiddleEllipsis>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </TableCell>

          <TableCell
            className={classes.adGroupCell}
            component="th"
            scope="row"
            align="left"
          >
            {display.staticdisplaylineitem_set.length > 0 ? (
              `${display.staticdisplaylineitem_set.length} Ad group${display.staticdisplaylineitem_set.length > 1 ? 's' : ''
              }`
            ) : (
              <span className={classes.na}>Not Assigned</span>
            )}
          </TableCell>

          <TableCell align="center">
            {display.resolution ? display.resolution : 'n/a'}
          </TableCell>

          <TableCell className={classes.fileSize} align="center">
            {display.file_size_bytes
              ? renderMegaBytes(display.file_size_bytes)
              : 'n/a'}
          </TableCell>

          <TableCell align="center">
            {display.media_upload_date ? moment(display.media_upload_date).format('l') : 'n/a'}
          </TableCell>

          <TableCell align="center" className={classes.action}>
            <Button
              className={isAssigned ? classes.unassigned : classes.assigned}
              disableRipple
              onClick={() => handleToggleAssign(display)}
            >
              {isAssigned ? 'UNASSIGN' : 'ASSIGN'}
            </Button>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell className={classes.expanded} />
          <TableCell className={classes.expanded} />
          <TableCell className={classes.expanded} />

          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <TableCell className={classes.adGroups}>
              {adGroups &&
                adGroups.length > 0 &&
                adGroups.map(a => (
                  <p key={a.id} className={classes.adGroup}>
                    {a.name}
                  </p>
                ))}
            </TableCell>
          </Collapse>
        </TableRow>
      </Fragment >
    );
  };

  return (
    <StyledBox>
      {/* Creatives Library Header */}
      {renderLibraryHeader()}

      {/* Creatives Library Table */}
      {isLoading ? (
        <Box className={classes.spinner}>
          <CircularProgress color="secondary" />
        </Box>
      ) : displays.length === 0 ? (
        <Box className={classes.spinner}>
          <Typography className={classes.none}>No results</Typography>
        </Box>
      ) : (
        <Fade in={!isLoading}>
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <SortableTableHead
                dense
                blankColumn="start"
                headCells={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                onSelectAll={handleSelectAll}
                isSelectAll={isSelectAll}
              />

              <TableBody>
                {stableSort(
                  displays,
                  getComparator(order, orderBy)
                ).map(c => renderRow(c))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fade>
      )}
    </StyledBox>
  );
};

DisplayLibrary.propTypes = {
  assigned: PropTypes.array,
  handleBatchAssign: PropTypes.func,
  handleUnassign: PropTypes.func,
  setCurrentPreview: PropTypes.func,
  setIsPreview: PropTypes.func,
};

export default memo(DisplayLibrary);
