import { CreativesView, AddCreativeView } from '../containers';

export const View = {
  VIEW_CREATIVES: 'VIEW_CREATIVES',
  ADD_CREATIVE: 'ADD_CREATIVE',
};

export const ViewComponents = {
  [View.VIEW_CREATIVES]: CreativesView,
  [View.ADD_CREATIVE]: AddCreativeView,
};
