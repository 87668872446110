import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TextField,
  InputAdornment,
  Tooltip,
  IconButton,
} from '@mui/material';
import { CopyIcon } from './icons/trackingEvents';


const TextCopyField = ({label, text, ...rest}) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    await navigator.clipboard.writeText(text);

    setCopied(true);
    const timer = setTimeout(() => setCopied(false), 2000);

    return () => clearTimeout(timer);
  };

  return (
    <TextField
      {...rest}
      label={label}
      multiline
      value={text}
      InputProps={{
        readOnly: true,
        style: { fontFamily: 'Roboto Mono, monospace', fontSize: '12px' },
        endAdornment:
          <InputAdornment>
            <Tooltip title={copied ? 'Copied!' : 'Copy'}>
              <IconButton
                size="small"
                onClick={handleCopy}
              >
                <CopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
      }}
      variant="outlined"
    />
  );
}

TextCopyField.propTypes = {
  label: PropTypes.string,
  text: PropTypes.string,
};

export default TextCopyField;
