export const getPrimaryOrgId = (orgUrl: string | null | undefined): string | null => {
  if (!orgUrl) {
    return null;
  }

  const orgArr = orgUrl.split('/');
  if (orgArr.length < 2) {
    return null;
  }

  const orgId = orgArr[orgArr.length - 2];
  return orgId || null;
};
