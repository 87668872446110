import React from 'react';
import { Button, Stack, styled, Typography } from '@mui/material';
import { useUpload } from './hooks/useUpload';
import { List } from './List';
import { CaptionBoxStyled, CaptionTypographyStyled } from '../styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoSquare } from '@fortawesome/pro-regular-svg-icons';
import { AdvancedBox } from '../../../AdvancedBox';
import { AdvancedBoxFooter } from '../../../AdvancedBoxFooter';
import PropTypes from 'prop-types';

const UploadWrapperStyled = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  flex: 1,
  overflowY: 'auto',
}));

export const Upload = ({ onClose, onSubmit, info, isDisplay, ...props }) => {
  const { files, disabled, drop, nextButtonContent, remove, cancel } =
    useUpload();

  return (
    <AdvancedBox
      {...props}
      info={info?.info}
      onClose={onClose}
      header={<Typography data-testid="creative-upload-screen-header" variant="h3">{info.label}</Typography>}
    >
      <UploadWrapperStyled data-testid="upload-drop-zone" flex={1}>
        <Stack>
          <List
            files={files}
            onRemove={remove}
            onCancel={cancel}
            isDisplay={isDisplay}
            onDrop={drop}
          />
        </Stack>
        {!isDisplay && (
          <CaptionBoxStyled>
            <CaptionTypographyStyled>
              <FontAwesomeIcon icon={faInfoSquare} /> We will check your file
              formatting to ensure it meets minimum quality requirements.
              Issues will be automatically fixed in most cases.
            </CaptionTypographyStyled>
          </CaptionBoxStyled>
        )}
      </UploadWrapperStyled>
      <AdvancedBoxFooter>
        <Button onClick={onClose} color="secondary" variant="text">
          Cancel
        </Button>
        <Button disabled={disabled} data-testid="next-manage-display" onClick={onSubmit} variant="contained">
          {nextButtonContent}
        </Button>
      </AdvancedBoxFooter>
    </AdvancedBox>
  );
};

Upload.propTypes = {
  ...AdvancedBox.propTypes,
  info: PropTypes.shape({
    info: PropTypes.node,
    label: PropTypes.string,
  }),
};
