import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, styled } from '@mui/material';

export const StyledFormContainer = styled(Box)(({ theme }) => ({
  maxWidth: theme.spacing(67), // 353px wide form using theme.spacing
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4),
}));

export const StyledLockIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[6],
}));

export const ScrollTopContainer = styled(Box)(({ theme }) => ({
  scrollMarginTop: theme.spacing(25),
}));
