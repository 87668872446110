import { useFormContext } from 'react-hook-form';
import { fields } from '../constants';
import { useState } from 'react';

export const useCreative = () => {
  const [showMore, setShowMore] = useState(false);
  const { watch } = useFormContext();

  return {
    creatives: watch(fields.creatives.path) ?? [],
    showMore,
    setShowMore,
  };
};
