import { isUndefined, omit, uniqBy, uniqueId } from 'lodash';
import { KeyboardEvent, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { GeoItem, UseChipSelectorArgs } from '../types';
import {
  getGeoWithTextByDistinct,
  getTemporaryGeo,
  mapBoxItemToGeoItem,
} from '../utils';
import { useGeoDropzone } from './useGeoDropzone';
import { useGeoSearchV2 } from './useGeoSearch';
import { useCopyNested } from '@components/hooks';
import { Copies } from '../constants';

export const useChipSelector = ({
  value,
  types,
  type,
  onChange,
  onBlur,
}: UseChipSelectorArgs) => {
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>(
    undefined,
  );
  const selectorRef = useRef<HTMLInputElement>(null);
  const [input, setInput] = useDebounce('', 50);
  const Copy = useCopyNested(Copies);
  const handleChange = (values: GeoItem[]) => {
    onChange(values);
  };

  const { data: result = [], isLoading } = useGeoSearchV2(input, {
    types,
  });

  const data = result.filter(v => v).map(item => mapBoxItemToGeoItem(item));

  const handleFocus = (index: number | undefined) => {
    setFocusedIndex(index);
  };

  const handleFocusPrev = () => {
    if (isUndefined(focusedIndex)) {
      setFocusedIndex(value.length ? value.length - 1 : undefined);
      return;
    }

    setFocusedIndex(focusedIndex === 0 ? undefined : focusedIndex - 1);
  };

  const handleFocusNext = () => {
    if (isUndefined(focusedIndex)) {
      setFocusedIndex(undefined);
    }

    setFocusedIndex(
      focusedIndex === value.length - 1
        ? undefined
        : (focusedIndex as number) + 1,
    );
  };

  const handleFocusStale = () => {
    if (focusedIndex === value.length - 1) {
      handleFocusPrev();
    }
  };

  const handleBlur = () => {
    onBlur();
  };

  const handlePopperKeyboardEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Backspace':
        if (!input.length) {
          handleFocusPrev();
        }
        break;
    }
  };

  const handleRemove = (item: GeoItem) => {
    handleChange(value.filter(v => v.id !== item.id));
  };

  const handleChangeItem = (prevValue: GeoItem, newValue: GeoItem) => {
    handleChange(
      uniqBy(
        value.map(v =>
          [v.id, v.temporaryId].includes(prevValue.id)
            ? omit(newValue, 'temporaryId')
            : v,
        ),
        'id',
      ),
    );
  };

  const handleAdd = (items: GeoItem[]) => {
    handleChange([
      ...value,
      ...items.map(item => getGeoWithTextByDistinct(item)),
    ]);
    setInput('');
  };

  const handleDrop = (items: string[]) => {
    handleAdd(items.map(item => getTemporaryGeo(item, type)));
  };

  return {
    ...useGeoDropzone({
      onChange: handleDrop,
    }),
    focusedIndex,
    focus: handleFocus,
    focusPrev: handleFocusPrev,
    focusNext: handleFocusNext,
    focusStale: handleFocusStale,
    selectorRef,
    setInput,
    errored: input.length > 3 && !isLoading && !data?.length,
    isLoading,
    input,
    data,
    Copy,
    add: handleAdd,
    onPopperKeyboardEvent: handlePopperKeyboardEvent,
    change: handleChange,
    changeItem: handleChangeItem,
    blur: handleBlur,
    remove: handleRemove,
  };
};
