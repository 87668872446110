import React, {  useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import numeral from 'numeral';
import { useHistory } from 'react-router-dom';
import { useStateMachine } from 'little-state-machine';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormControl,
  FormGroup,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import { useSaveExit } from './hooks/saveExit';
import CampaignFooter from './CampaignFooter';
import Title from './Title';
import tvsIcon from '../images/network-logos/tvs-logo-white.png';
import { getMinorityData } from '../helpers';
import { useDomain } from './hooks/domain';

const PREFIX = 'SelectDeals';

const classes = {
  boxHeader: `${PREFIX}-boxHeader`,
  boxLabel: `${PREFIX}-boxLabel`,
  dealHeader: `${PREFIX}-dealHeader`,
  divider: `${PREFIX}-divider`,
  dividerBox: `${PREFIX}-dividerBox`,
  fieldset: `${PREFIX}-fieldset`,
  inputs: `${PREFIX}-inputs`,
  inner: `${PREFIX}-inner`,
  labelName: `${PREFIX}-labelName`,
  labelCpm: `${PREFIX}-labelCpm`,
  noDeals: `${PREFIX}-noDeals`,
  saveButton: `${PREFIX}-saveButton`,
  tooltip: `${PREFIX}-tooltip`,
  white: `${PREFIX}-white`,
  wrap: `${PREFIX}-wrap`,
  buttonWrap: `${PREFIX}-buttonWrap`
};

const StyledBox = styled(Box)((
  {
    theme
  }
) => ({
  [`& .${classes.boxHeader}`]: {
    color: `#fff`,
    fontSize: 24,
    margin: 0,
  },

  [`& .${classes.boxLabel}`]: {
    display: 'flex',

    ['& .MuiFormControlLabel-root']: {
      margin: '0 auto',
    },
  },

  [`& .${classes.dealHeader}`]: {
    textAlign: `left`,
  },

  [`& .${classes.divider}`]: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(2),
  },

  [`& .${classes.dividerBox}`]: {
    backgroundColor: `#fff`,
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),
  },

  [`& .${classes.fieldset}`]: {
    width: '100%',
  },

  [`& .${classes.inputs}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.inner}`]: {
    minHeight: 340,
  },

  [`& .${classes.labelName}`]: {
    ...theme.typography.paragraph,
    fontWeight: 'bold',
    margin: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '95%',
  },

  [`& .${classes.labelCpm}`]: {
    ...theme.typography.paragraph,
    margin: 0,
    display: 'inline-flex',
  },

  [`& .${classes.noDeals}`]: {
    color: '#9aa0a6',
    fontSize: '1.0875rem',
    fontWeight: 600,
    textAlign: 'center',
  },

  [`& .${classes.saveButton}`]: {
    height: `3.688rem`,
    width: `11.563rem`,
  },

  [`& .${classes.tooltip}`]: {
    backgroundColor: `#f2f3f5`,
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    maxWidth: 180,
  },

  [`& .${classes.white}`]: {
    color: `#fff`,
  },

  [`&.${classes.wrap}`]: {
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  [`& .${classes.buttonWrap}`]: {
    maxHeight: 66,
    marginBottom: theme.spacing(1),
  }
}));

/////////////////////////////
// PRIVATE BUNDLES
/////////////////////////////
const SelectDeals = props => {
  const history = useHistory();
  const domain = useDomain();
  const { state } = useStateMachine();

  const {
    screenSize,
    save,
    canNext,
    setStep,
    currentAdGroup,
    backButton,
    triggerSave,
  } = props;

  const initialBundles = () => props.bundles && props.bundles.length > 0
    ? props.bundles
    : [];

  const [bundles, setBundles] = useState([]);
  const [totalBundles, setTotalBundles] = useState([]);

  const { useGetAll, usePatch } = useAPI();
  const { isLoading, setIsLoading } = useLoader();
  const { saveProgress } = useSaveExit();

  // TODO: refactor and lift getBundles to HOC
  useEffect(() => {
    getBundles();
  }, []);

  useEffect(() => {
    saveProgress(save, 'Private', handleSave, triggerSave);
  }, [save]);

  async function getBundles() {
    return await useGetAll('/bundles', [], total => {
      if (total && total.length > 0) {
        setTotalBundles(total.filter(p => p.private));
      }
    });
  }

  useEffect(() => {
    setBundles(initialBundles())
  }, [totalBundles])

  // Event handlers
  const handleCheck = event => {
    const { checked, name } = event.target;

    if (checked) {
      setBundles(prev => [...prev, name]);

      if (props.setBundles) {
        props.setBundles(prev => [...prev, name]);
      }
    } else {
      const filtered = bundles.filter(bundle => bundle !== name);
      setBundles(filtered);

      if (props.setBundles) {
        props.setBundles(filtered);
      }
    }
  };

  // TODO: use submit handler from parent component instead of this function to prevent duplication
  const handleSave = async () => {
    setIsLoading(true);

    const { deviceTypes, operatingSystems } = state.data.inventoryStep.deviceOverlayModal;

    const deviceTypeMinorityData = getMinorityData(deviceTypes);
    const operatingSystemMinorityData = getMinorityData(operatingSystems);

    const dataObj = {
      inventory: 'private',
      bundles,
      screen_size: screenSize,
      device: {
        blacklist: !deviceTypeMinorityData.isSelectedMinority,
        ids: deviceTypeMinorityData.data.map(item => item.id)
      },
      os: {
        blacklist: !operatingSystemMinorityData.isSelectedMinority,
        ids: operatingSystemMinorityData.data.map(item => item.id)
      },
    };

    props.handleInventoryData(dataObj);

    const data = {
      targeting: JSON.stringify({
        ...props.combinedTargetingData,
        ...dataObj,
      }),
    };

    try {
      const response = await usePatch(`/lineitems/${currentAdGroup.id}/`, data);

      setIsLoading(false);

      // Handle Save & Exit button click
      if (save && save.step && save.step === 'Private') {
        if (save.exit) {
          history.push('/');
        }
      }

      // Handle next button click
      if (!save.exit) {
        setStep('NameAdGroup');
      }

      return response;
    } catch (error) {
      console.error('Error in saving Private Deals', error);

      setIsLoading(false);

      return error;
    }
  };

  const renderLabel = label => {
    return (
      <Grid container alignItems="center">
        <Grid item xs={2}>
          <img
            style={{ width: 25 }}
            src={label.icon && label.icon.url ? label.icon.url : tvsIcon}
            alt={label.icon && label.icon.name ? label.icon.name : 'TVS'}
          />
        </Grid>

        <Grid item xs={10}>
          <p className={classes.labelName}>{label.bundle_name}</p>
          {domain.default &&
            <p className={classes.labelCpm}>
              Estimated CPM: {numeral(Math.ceil(label.estimated_cpm)).format('$0,0')} +

              &nbsp;

              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                title={label.info || 'Test'}
              >
                <InfoOutlinedIcon
                  className={classes.info}
                  fontSize="small"
                />
              </Tooltip>
            </p>}
        </Grid>
      </Grid>
    );
  };

  return (
    <StyledBox className={classes.wrap} display="flex">
      <Grid container>
        <Grid className={classes.inner} item xs={12}>
          <Box display="flex" alignItems="center">
            {backButton}
            <Title>Select Deals</Title>
          </Box>

          <Divider className={classes.divider} />

          <Box mt={2} mb={4}>
            <Typography className={classes.dealHeader} variant="h5">
              Your Deals
            </Typography>
          </Box>

          <FormControl variant="standard" className={classes.fieldset} component="fieldset">
            <FormGroup>
              <Box pb={2}>
                <Grid container>
                  {totalBundles.length > 0 &&
                    totalBundles
                      .filter(b => !b.ott)
                      .map(bundle =>
                        <Grid
                          key={bundle.id}
                          item
                          className={classes.buttonWrap}
                          xs={4}
                        >
                          <Box
                            className={clsx(
                              'Button-choice',
                              classes.boxLabel
                            )}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={
                                    bundles.includes(bundle.bundle_name)
                                  }
                                  onChange={handleCheck}
                                  name={bundle.bundle_name}
                                  value={bundle.bundle_name}
                                />
                              }
                              label={renderLabel(bundle)}
                            />
                          </Box>
                        </Grid>
                      )}

                  {totalBundles.length === 0 &&
                    <Box py={2} pt={6} width="100%">
                      <Typography className={classes.noDeals}>
                        No Private Bundles available. Please contact support
                        to add private deals.
                      </Typography>
                    </Box>
                  }
                </Grid>

              </Box>
            </FormGroup>
          </FormControl>
        </Grid>
      </Grid>

      {!props.isEditing &&
        <CampaignFooter
          isLoading={isLoading}
          isDisabled={!canNext}
          back={props.hasAdvancedTargeting
            ? 'TargetingSegments'
            : 'Demographics'
          }
          next={'Save Ad Group...'}
          onBack={() => {
            if (props.hasAdvancedTargeting) {
              props.setStep('TargetingSegments');
            } else {
              props.setStep('DemoTargeting');
            }
          }}
          onNext={() => {
            setIsLoading(true);
            triggerSave('Private', false, 'NameAdGroup');
          }}
          page={4}
        />}
    </StyledBox>
  );
};

SelectDeals.propTypes = {
  isEditing: PropTypes.bool,
  setStep: PropTypes.func,
  targetEntireUS: PropTypes.bool,
  setTargetEntireUS: PropTypes.func,
  handleInventoryData: PropTypes.func,
  hasAdvancedTargeting: PropTypes.bool,
  currentAdGroup: PropTypes.object,
  updateBreadcrumbs: PropTypes.func,
  bundles: PropTypes.array,
  setBundles: PropTypes.func,
  setInventoryOverlay: PropTypes.func,
  save: PropTypes.object,
  canNext: PropTypes.bool,
  triggerSave: PropTypes.func,
  combinedTargetingData: PropTypes.object,
  backButton: PropTypes.object,
  screenSize: PropTypes.string,
};

export default SelectDeals;
