import React from 'react';
import { Box, Grid } from '@mui/material';
import { WizardProvider } from './WizardProvider';
import { useWizard } from './hooks/useWizard';
import { wizardProps } from './types';
import { Sections } from './components';
import { flatMap } from 'lodash';

const getSectionKeys = sectionsElements => {
  return flatMap(
    sectionsElements.map(child =>
      Array.isArray(child) ? getSectionKeys(child) : child.props?.sectionKey,
    ),
  ).filter(Boolean);
};

export const Wizard = ({ children }) => {
  const sectionsWrapper = children.find(child => child.type === Sections);
  const sections = getSectionKeys(sectionsWrapper.props.children);

  const { navigation } = useWizard({ sections });

  return (
    <WizardProvider navigation={navigation}>
      <Box sx={{ px: 6, py: 6 }}>
        <Grid container spacing={4} justifyContent="space-between">
          {children}
        </Grid>
      </Box>
    </WizardProvider>
  );
};

Wizard.propTypes = {
  ...wizardProps,
};
