import React from 'react';
import PropTypes from 'prop-types';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  Tooltip,
  Box,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';

import { Accordion, AccordionDetails, AccordionSummary } from './Accordion';

const StrategyMissingEvents = props => {
  const { strategy, isExpanded, handleExpand } = props;

  return (
    <Accordion
      key={strategy.url}
      square
      expanded={isExpanded}
      onChange={handleExpand}
    >
      <AccordionSummary
        aria-controls={`${strategy.id}-content`}
        id={`${strategy.id}-header`}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          {isExpanded && (
            <Grid
              item
              xs={12}
              style={{ margin: 0, padding: 0, marginBottom: 20 }}
            >
              <Box bgcolor="#FFE38E" p={1} style={{ margin: '-16px' }}>
                <p
                  style={{
                    fontSize: 12,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <LockIcon style={{ fontSize: 14, marginRight: '10px' }} />
                  This bid strategy will become available once tracking is set
                  up.
                </p>
              </Box>
            </Grid>
          )}
          <Grid item>
            <RadioGroup row value="bidStrategy">
              <FormControlLabel
                disabled
                value=""
                control={<Radio style={{backgroundColor: 'transparent'}} />}
                label={strategy.display_name}
              />
            </RadioGroup>
          </Grid>
          <Grid item>
            {!isExpanded && (
              <Tooltip title="This bid strategy will become available once tracking is set up. You’ll have the option to come back here at the end of the campaign workflow.">
                <LockIcon style={{ color: '#1DAFFF', fontSize: 16 }} />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" xs={11} spacing={2}>
          <Grid item>
            <Box sx={{ pb: 2 }}>{strategy.description}</Box>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

StrategyMissingEvents.propTypes = {
  strategy: PropTypes.object.isRequired,
  isExpanded: PropTypes.bool.isRequired,
  handleExpand: PropTypes.func.isRequired,
};

export default StrategyMissingEvents;
