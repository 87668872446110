import { List, Stack } from '@mui/material';
import React, { forwardRef } from 'react';
import { HighlightedContent, toHighlightedContent } from '../Advanced';
import { useChipPopper } from './hooks/useChipPopper';
import {
  StyledChipSelectorItemInput,
  StyledListItem,
  StyledPopper,
} from './styles';
import { ChipPopperProps } from './types';
import { getActualFieldShorten } from './utils';

const ChipPopper = forwardRef<HTMLInputElement, ChipPopperProps>(
  (
    {
      value,
      type,
      onInput,
      onSelect,
      onBlur,
      onEscape,
      anchorEl,
      isLoading,
      data,
      InputProps,
      ...props
    },
    ref,
  ) => {
    const {
      baseElement,
      setBaseElement,
      setForceHide,
      onInputKeyboardEvent,
      onInputTypingEvent,
      isOpen,
      activeElementIndex,
    } = useChipPopper({
      onSelect,
      onInput,
      onEscape,
      isLoading,
      data,
      value,
      type,
    });

    return (
      <Stack {...props} onBlur={onBlur} ref={el => setBaseElement(el)}>
        <StyledChipSelectorItemInput
          {...InputProps}
          ref={ref}
          autoFocus={true}
          onBlur={() => setForceHide(true)}
          onFocus={() => setForceHide(false)}
          onKeyDown={onInputKeyboardEvent}
          onInput={onInputTypingEvent}
          value={value}
          data-testid="geo-input"
        />
        <StyledPopper
          sx={{ zIndex: 1000 }}
          id="advanced-geo-item-popper"
          open={isOpen}
          anchorEl={anchorEl ?? baseElement}
        >
          <List>
            {data.map((option, idx) => (
              <StyledListItem
                active={activeElementIndex === idx}
                onMouseDown={() => onSelect([option])}
              >
                <HighlightedContent
                  data={toHighlightedContent(
                    getActualFieldShorten(option),
                    value,
                  )}
                />
              </StyledListItem>
            ))}
          </List>
        </StyledPopper>
      </Stack>
    );
  },
);

ChipPopper.displayName = 'ChipPopper';

export default ChipPopper;
