import { boolean, object, string, number } from 'yup';

export const validationSchema = ({ displaySalesforceFields }) =>
  object().shape({
    name: string().required('Required'),
    advertiserName: string().required('Required'),
    website: string().url().required('Required'),
    category: string().required('Required'),
    isUncapped: boolean().required('Required'),
    budget: number().when('isUncapped', {
      is: false,
      then: s => s.required('Required'),
    }),
    understand: boolean().when('sensitive', {
      is: value => value == 'NONE',
      then: s => s.isTrue('Required'),
    }),
    purchaseOrder: string(),
    sensitive: string().required('Required'),
    outcome: string().required('Required'),
    attrModel: string().required('Required'),
    attrWindow: string().required('Required'),
    looker: string().required('Required'),
    managementModel: string().required('Required'),
    ...(displaySalesforceFields && {
      salesforceAdvertiserId: string().required('Required'),
      salesforceAgencyId: string().required('Required'),
    }),
  });

export const getInitialValues = () => {
  return {
    name: '',
    advertiserName: '',
    website: '',
    category: '',
    budget: 0,
    isUncapped: true,
    purchaseOrder: '',
    sensitive: '',
    outcome: '',
    attrModel: '',
    attrWindow: '',
    looker: '',
    managementModel: 'SELFSERVE',
    understand: false,
    salesforceId: '',
    salesforceAgencyId: '',
  };
};

export const formValuesToApi = values => {
  return {
    ad_account_name: values.name,
    name: values.advertiserName,
    domain: values.website,
    category: values.category,
    ad_account_budget: values.isUncapped ? null : values.budget,
    billing_account: values.billingAccount,
    invoice_po: values.purchaseOrder,
    regulated_brand_type: values.sensitive !== 'NONE' ? values.sensitive : '',
    primary_kpi: values.outcome,
    attribution_model: values.attrModel,
    attribution_window: values.attrWindow,
    looker_experience: values.looker,
    advertiser_external_crm_id: values.salesforceAdvertiserId,
    agency_external_crm_id: values.salesforceAgencyId,
  };
};
