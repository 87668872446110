import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StyledTooltip = styled(Tooltip)(({
  theme: { palette, spacing }
}) => ({
  fontWeight: 'regular',

  '& .MuiTooltip-tooltip': {
    backgroundColor: `#EDF1F2`,
    color: palette.text.primary,
    padding: spacing(1.5),
    maxWidth: 184,
    fontFamily: 'Noto Sans JP',
    lineHeight: '1.25rem',
    fontSize: '0.625rem',
    fontWeight: 500,
  },

  '& .MuiTooltip-arrow': {
    fontSize: '1rem',

    '&:before': {
      backgroundColor: `#EDF1F2`,
    },
  }
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)(({
  theme: { spacing }
}) => ({
  display: 'inline-block',
  verticalAlign: 'middle',
  marginLeft: spacing(1),
}));

const InfoTooltip = (props) => (
  <StyledTooltip
    title={props.title}
    placement="top"
    arrow
  >
    <StyledInfoOutlinedIcon
      fontSize="small"
      color="secondary"
    />
  </StyledTooltip>
);

InfoTooltip.propTypes = {
  title: PropTypes.string,
};

export default InfoTooltip;
