import React from 'react';
import { PropTypes } from 'prop-types';
import { contextType } from './types';
import { Typography } from '@mui/material';
import { HighlightStyled } from './styles';
import { isArray, map } from 'lodash';

export const HighlightedContent = ({ data = '' }) => (
  <Typography title={!isArray(data) ? data : map(data, 'value').join('')}>
    {!isArray(data)
      ? data
      : data.map((v, i) => (
          <HighlightStyled
            data-testid={v.stressed ? `stressed-${i}` : `non-stressed-${i}`}
            stressed={v.stressed}
            key={i}
          >
            {v.value}
          </HighlightStyled>
        ))}
  </Typography>
);

HighlightedContent.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(contextType),
  ]),
};
