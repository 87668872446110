import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionActions from '@mui/material/AccordionActions';
import { styled } from '@mui/material/styles';

export const Accordion = styled(MuiAccordion)(() => ({
  boxShadow: 'none',
  marginTop: '10px',

  '&:not(:last-child)': {
    borderBottom: 0,
  },

  '&:before': {
    display: 'none',
  },

  '&.Mui-expanded': {
    '&:first-child': {
      marginTop: '10px',
    },

    '&:last-child:has(.MuiAccordionActions-root)': {
      '& .MuiAccordionActions-root': {
        borderRadius: '0 0 10px 10px',
      },
    },

    '&:last-child:not:has(.MuiAccordionActions-root)': {
      '& .MuiAccordionDetails-root': {
        borderRadius: '0 0 10px 10px',
      },
    }
  },

  '&:not(.Mui-expanded)': {
    '&:last-child': {
      borderRadius: '0 0 10px 10px',
      '& .MuiAccordionSummary-root': {
        borderRadius: '0 0 10px 10px',
      }
    }
  },
}));

export const AccordionSummary = styled(MuiAccordionSummary)(() => ({
  borderRadius: '2px',
  boxShadow:
    '0px 5px 5px -3px rgba(24, 28, 32, 0.07), 0px 3px 14px 2px rgba(24, 28, 32, 0.12), 0px 8px 10px 1px rgba(24, 28, 32, 0.10)',
  display: 'flex',
  justifyContent: 'space-between',
  gap: '20px',
  '@media (max-width: 991px)': {
    padding: '0 20px',
  },
  backgroundColor: 'rgba(0, 0, 0, .03)',
  marginBottom: -1,
  minHeight: 56,

  '&.Mui-expanded': {
    backgroundColor: 'var(--Blue-11, #D9F1FF)',
    boxShadow: 'none',
    border: 'none',
  },

  '& .MuiAccordionSummary-content': {
    '& .Mui-expanded': {
      marginBottom: 0,
    },
    '& label .MuiFormControlLabel-label': {
      fontWeight: 'bold',
    }
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingLeft: 50,
  backgroundColor: 'var(--Blue-11, #D9F1FF)',
  fontSize: 14,
  paddingTop: 0,
}));

export const AccordionActions = styled(MuiAccordionActions)(({ theme }) => ({
  paddingY: 0,
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(2),
  backgroundColor: 'var(--Blue-11, #BFE8FF)',
  fontSize: 13,
}));
