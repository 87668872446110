import useSWR from 'swr';

import { useCurrentSession } from '../currentSession';

export const useGetAllowedTargeting = () => {
  const { get, apiIsReady } = useCurrentSession();

  const fetcher = ({ url, params }) =>
    get(url, { params }).then(res => res.data.results);

  const url = '/allowed_targeting/';

  return useSWR(apiIsReady ? { url } : null, fetcher);
};
