import { ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';

export const StyledListItem = styled(ListItem)(() => ({
  display: 'list-item',

  '& .MuiListItem-dense': {
    paddingTop: 0,
    paddingBottom: 0,
  },
}));
