import { Button, Chip, styled } from '@mui/material';

export const StatusChipStyled = styled(Chip)(({ theme }) => ({
  height: theme.spacing(3),
  cursor: 'default',
}));

export const StatusChipExistingStyled = styled(StatusChipStyled, {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme, active }) => ({
  backgroundColor: active ? theme.palette.green[10] : theme.palette.grey[7],
  color: active ? theme.palette.green[4] : theme.palette.grey[1],
}));

export const StatusChipUploadingStyled = styled(StatusChipStyled)(
  ({ theme }) => ({
    backgroundColor: theme.palette.grey[11],
    color: theme.palette.grey[4],
  }),
);

export const StatusChipFailedStyled = styled(StatusChipStyled)(
  ({ theme }) => ({
    backgroundColor: theme.palette.red[5],
    color: theme.palette.red[4],
  }),
);

export const ActionButtonStyled = styled(Button)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  height: theme.spacing(3),
  fontWeight: theme.typography.fontWeightBold,
  borderRadius: theme.spacing(0.5),
}));

export const AssignButtonStyled = styled(ActionButtonStyled, {
  shouldForwardProp: prop => prop !== 'assigned',
})(({ theme, assigned }) => ({
  textWrap: 'nowrap',
  backgroundColor: assigned ? theme.palette.blue[11] : theme.palette.blue[6],
  color: assigned ? theme.palette.blue[6] : theme.palette.common.white,

  '&:hover': {
    color: assigned ? theme.palette.red[4] : theme.palette.common.white,
    backgroundColor: assigned ? theme.palette.red[5] : theme.palette.blue[7],
  },
}));

export const UploadingAssignButtonStyled = styled(ActionButtonStyled)(
  ({ theme }) => ({
    backgroundColor: theme.palette.grey[11],
    color: theme.palette.grey[4],
  }),
);
