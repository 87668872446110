import {
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
import React from 'react';
import { UnsavedChangesPromptProps } from './types';

import { Button } from '@v2/components/ui/Button';

import ModalWrapper from '../../../../components/ModalWrapper';

const StyledModalWrapper = styled(ModalWrapper)({
  maxWidth: '800px',
  margin: 'auto',
});

const UnsavedChangesPrompt = ({
  closeModal,
  onConfirm,
  actionName
}: UnsavedChangesPromptProps) => (
  <StyledModalWrapper isOpen onClose={closeModal}>
    <DialogTitle>Unsaved Changes</DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure that you want to {actionName}?
      </Typography>
      <Typography>Your unsaved changes will be lost.</Typography>
    </DialogContent>
    <DialogActions>
      <Button
        label="Cancel"
        onClick={closeModal}
        data-testid="cancel-unsaved-changes"
      />
      <Button
        label="I'm sure"
        onClick={onConfirm}
        data-testid="confirm-unsaved-changes"
      />
    </DialogActions>
  </StyledModalWrapper>
);

export default UnsavedChangesPrompt;
