import { useEffect } from 'react';

import { useAuth } from '../hooks';

const QuietOktaLoginCallbackPage = () => {
  const { auth } = useAuth();

  useEffect(() => {
    auth.oktaGetTokensWithRedirect();
  }, []);

  return null;
};

export default QuietOktaLoginCallbackPage;
