import { object, string } from 'yup';

export const validationSchema = object().shape({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  phone: string(),
  email: string().required('Required'),
  primaryOrg: string(),
  role: string().required('Required'),
});

export const getInitialValues = ({ user, orgUser }) => {
  return {
    firstName: user?.first_name || '',
    lastName: user?.last_name || '',
    email: user?.email || '',
    phone: user?.phone || '',
    role: orgUser?.role || '',
  };
};

export const formValuesToApi = values => {
  return {
    first_name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    phone: values.phone,
  };
};
