import LogRocket from 'logrocket';

import { useAuth } from './auth';

type AuthUser = {
  sub: string;
  name: string;
  email: string;
};

//////////////////////////
// useLOGROCKET HOOK
//////////////////////////
export const useLogRocket = () => {
  const { auth } = useAuth();
  const useLogRocket = (process.env.USE_LOG_ROCKET === 'true');

  const tagUser = async (): Promise<void> => {
    if (!useLogRocket) {
      return;
    }

    const authUser: AuthUser | null = await auth.getUser();
    if (authUser) {
      LogRocket.identify(authUser.sub, {
        name: authUser.name,
        email: authUser.email,
      });
    }
  }

  return {
    tagUser,
  };
};
