import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Typography,
  Box,
  Chip,
} from '@mui/material';

import AppHeader from '../AppHeader';
import AdvertiserContext from '../AdvertiserContext';
import { useAPI } from '../hooks/api';
import { usePermissions } from '../hooks';
import { qcStatusContents, qcStatusIcons, Scopes } from '../../constants';
import { CreativeDetail } from './CreativeDetail';

const PREFIX = 'CreativeDetailPage';

const classes = {
  previewThumb: `${PREFIX}-previewThumb`,
  title: `${PREFIX}-title`,
  link: `${PREFIX}-link`,
  middleVerticalAlign: `${PREFIX}-middleVerticalAlign`,
};

const StyledAppHeader = styled(AppHeader)(() => ({
  [`& .${classes.previewThumb}`]: {
    width: '100%',
  },

  [`& .${classes.title}`]: {
    wordBreak: 'break-all',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
  },

  [`& .${classes.middleVerticalAlign}`]: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  '& .MuiTableCell-head': {
    color: 'rgba(114, 115, 131, 0.8)',
    fontSize: 12,
    lineHeight: 'normal',
  },
  '& .MuiTableCell-body': {
    fontSize: 16,
  },
}));

const NoPreview = styled(Box)(() => ({
  background: '#EDF1F2',
  color: '#B0BABF',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  aspectRatio: '2/1',
  fontSize: '20px',
  fontWeight: 700,
}));

const headCells = [
  { id: 'campaignName', label: 'Campaign', disablePadding: false },
  { id: 'adGroupName', label: 'Ad Group', disablePadding: false },
  { id: 'startDate', label: 'Start date', disablePadding: false },
  { id: 'endDate', label: 'End date', disablePadding: false },
];

function CreativeDetailTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map(headCell => (
          <StyledTableCell
            key={headCell.id}
            align="left"
            padding={headCell.disablePadding ? 'none' : 'default'}
          >
            <TableSortLabel>{headCell.label}</TableSortLabel>
          </StyledTableCell>
        ))}
        <StyledTableCell></StyledTableCell>
      </TableRow>
    </TableHead>
  );
}

export default function CreativeDetailPage(props) {
  const { useGet } = useAPI();
  const { hasPermission } = usePermissions();

  const [creative, setCreative] = useState([]);

  const adContext = useContext(AdvertiserContext);
  const creativeId = window.location.pathname.split('/creative/')[1];
  const now = moment();

  const [, , campaignId, , adGroupId] = window.location.pathname.split('/');
  const adGroupsLink =
    campaignId === 'na'
      ? '/campaigns'
      : `/campaigns/${campaignId}/adgroups/${adGroupId}`;
  const creativeLink =
    adGroupId === 'na'
      ? '/creatives'
      : `/campaigns/${campaignId}/adgroups/${adGroupId}/creative`;

  const { qc = {} } = creative || {};

  const QCStatusIcon = qcStatusIcons[qc.status] || (() => null);
  const QCStatusContent = qcStatusContents[qc.status] || (() => null);

  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  useEffect(() => {
    if (adContext.id !== null) {
      getCreativeDetails(creativeId);
    }
  }, [adContext]);

  async function getCreativeDetails(creativeId) {
    try {
      const response = await useGet(`/creatives/${creativeId}`);
      setCreative(response);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <StyledAppHeader history={props.history}>
      <Box m={5} mb={2}>
        <Typography>
          <strong>
            <Link className={classes.link} to={`/campaigns/`}>
              Campaigns
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          <strong>
            <Link className={classes.link} to={adGroupsLink}>
              Ad Groups
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          <strong>
            <Link className={classes.link} to={creativeLink}>
              Creative
            </Link>
          </strong>
          &nbsp;&gt;&nbsp;
          {creative.name ? creative.name : ''}
        </Typography>
      </Box>
      <Box border={1} borderColor="grey.300" p={6} m={4} borderRadius="20px">
        <Grid container spacing={6} alignItems="flex-start">
          <Grid item container xs={5} spacing={4}>
            <Grid item xs={12}>
              {creative.preview_url && (
                <video
                  controlsList="nodownload"
                  className={classes.previewThumb}
                  src={creative.preview_url}
                  controls
                />
              )}

              {!creative.preview_url && (
                <NoPreview>Preview not available</NoPreview>
              )}
            </Grid>

            <Grid item container>
              <Grid item xs={12}>
                <Typography className={classes.title} variant="h4">
                  {creative.name ? creative.name : ''}
                </Typography>

                <Box mt={2} />

                {/* Need to ad back in size and quality */}
                <Typography variant="body2">
                  {creative.duration ? `${creative.duration} seconds` : ''}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2">
                  {creative.lineitem_set && creative.lineitem_set.length
                    ? `Launched ${now.diff(
                        creative.lineitem_set[0].start_date,
                        'days',
                      )} days ago | `
                    : ''}

                  {creative.lineitem_set && creative.lineitem_set.length
                    ? moment(creative.lineitem_set[0].start_date).format('l')
                    : ''}
                </Typography>
              </Grid>
            </Grid>

            {showQCFunctionality && (
              <Grid
                item
                container
                direction="row"
                alignItems="baseline"
                spacing={2}
              >
                <Grid item>
                  {creative.active ? (
                    <Chip label="Active" color="primary" />
                  ) : (
                    <Chip label="Inactive" />
                  )}
                </Grid>

                <Grid item>
                  <div className={classes.middleVerticalAlign}>
                    <QCStatusIcon />
                  </div>
                </Grid>

                {qc.messages?.length > 0 && (
                  <Grid item>
                    <QCStatusContent
                      alignItems="baseline"
                      items={qc.messages}
                    />
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item container xs={7}>
            <TableContainer>
              <Typography variant="h3" component="div">
                Ad Group Assignments
              </Typography>
              <Table>
                <CreativeDetailTableHead></CreativeDetailTableHead>
                <TableBody>
                  {creative.lineitem_set?.map((detail, idx) => (
                    <CreativeDetail
                      key={`${detail.id}-${idx}`}
                      detail={detail}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </StyledAppHeader>
  );
}

CreativeDetailPage.propTypes = {
  history: PropTypes.object,
};
