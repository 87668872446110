export const FormFields = {
  ADVERTISER_ID: 'advertiserId',
  BILLING_ID: 'billingId',
  COST_MODEL: 'costModel',
  BILLING_APPROVED: 'billingApproved',
};

export const DefaultValues = {
  [FormFields.ADVERTISER_ID]: '',
  [FormFields.BILLING_ID]: '',
  [FormFields.COST_MODEL]: 'CPA',
  [FormFields.BILLING_APPROVED]: false,
};

export const costModels = [
  { value: 'CPA', label: 'CPA' },
  { value: 'CPM', label: 'CPM' },
];
