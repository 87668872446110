import React from 'react';
import { faInfoSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Stack, Typography } from '@mui/material';
import { PropTypes } from 'prop-types';
import { CancelButtonStyled } from '../../../styles';
import { useByVASTTag } from './hooks/useByVASTTag';
import {
  CaptionBoxStyled,
  CaptionTypographyStyled,
  ContentWrapperStyled,
} from '../styles';
import { Edit } from './Edit';
import { AdvancedBox } from '../../../AdvancedBox';

export const ByVASTTag = ({
  onClose,
  onCreate,
  onSubmit,
  info,
  ...props
}) => {
  const { create, disabled } = useByVASTTag({
    onCreate,
    onSubmit,
  });

  return (
    <AdvancedBox
      {...props}
      info={info.info}
      onClose={onClose}
      header={<Typography data-testid="upload-video-screen-header" variant="h3">Upload Video</Typography>}
    >
      <Stack flex={1}>
        <ContentWrapperStyled flex={1}>
          <Edit />
          <CaptionBoxStyled>
            <CaptionTypographyStyled>
              <FontAwesomeIcon icon={faInfoSquare} /> VAST Tag 2.0 or later
              supported
            </CaptionTypographyStyled>
          </CaptionBoxStyled>
        </ContentWrapperStyled>
        <Stack>
          <Stack direction="row" justifyContent="space-between">
            <Stack>
              <CancelButtonStyled variant="text" onClick={onClose}>
                Cancel
              </CancelButtonStyled>
            </Stack>
            <Button disabled={disabled} data-testid="next-manage-creative" variant="contained" onClick={create}>
              Next: Manage Creative
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </AdvancedBox>
  );
};

ByVASTTag.propTypes = {
  ...AdvancedBox.propTypes,
  tags: PropTypes.array,
};
