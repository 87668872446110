import moment from 'moment-timezone';

export const mapValuesToJoin = values => {
  return {
    organization: {
      name: values.organization_name,
      business_physical_address: `${values.street_address_1}${values.street_address_2 ? ` ${values.street_address_2}` : ''}, ${values.city} ${values.state}, ${values.zip_code}`,
      business_phone: values.phone,
      city: values.city,
      zip_code: values.zip_code,
      state: values.state,
      street_address: values.street_address_1,
      street_address_2: values.street_address_2,
    },
    advertiser: {
      name: values.advertiser_name,
      domain: values.advertiser_website_address,
      category: values.category ?? null,
      primary_kpi: values.primary_outcome,
      regulated_brand_type: values.sensitive ?? null,
    },
  };
};

export const validateJoinPlatformInvite = platformInvite => {
  // Don't allow an accepted invite
  if (platformInvite?.status !== 'ACCEPTED') {
    return false;
  }

  // If it is expired, throw an error
  if (
    platformInvite?.expiry &&
    moment(platformInvite.expiry).isBefore(new Date())
  ) {
    return false;
  }

  return true;
};
