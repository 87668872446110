import { Tab as DefaultTab, styled } from '@mui/material';

const Tab = styled(DefaultTab)(({ theme }) => ({
  alignItems: 'flex-start',

  '&.Mui-selected': {
    color: theme.palette.common.black,
  },
}));

export default Tab;
