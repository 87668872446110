import {
  Box,
  Button,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material';

export const StyledButton = styled(Button)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  ml: 0,
  pl: theme.spacing(0.5),
  pt: 0,
  color: theme.palette.grey[1],
  '& .MuiButton-startIcon': {
    color: theme.palette.primary.main,
    fontSize: '1rem',
  },
}));

// Ensure the IconButton that uses FontAwesomeIcon is styled correctly
// Adjust size if using smaller/larger icons
export const StyledFAIconButton = styled(IconButton)(({ theme }) => ({
  minWidth: theme.spacing(5),
  minHeight: theme.spacing(5),
}));

export const ButtonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledGroupedItemsConclude = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

export const StyledMoreTypography = styled('span')(({}) => ({
  cursor: 'pointer',
}));
