import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

const PREFIX = 'TableToolbar';

const classes = {
  root: `${PREFIX}-root`,
  title: `${PREFIX}-title`
};

const StyledToolbar = styled(Toolbar)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    marginBottom: 43,
  },

  [`& .${classes.title}`]: {
    flex: '1 1 60%',
    fontSize: '2rem'
  }
}));

const StyledButton = styled(Button)`
  opacity: 0.5;
  border-radius: 4px;
  background-color: #eeeeee;
`

const TableToolbar = props => {
  const {
    setIsEnterCard
  } = props
  return (
    <StyledToolbar className={classes.root}>
      <Typography className={classes.title} variant="h3" id="tableTitle">
        Payment Methods
      </Typography>

      <div>
        <Tooltip title="Add">
          <StyledButton
            variant="contained"
            startIcon={<AddIcon />}
            disableElevation
            aria-label="add"
            onClick={setIsEnterCard}
          >
            ADD PAYMENT METHOD
          </StyledButton>
        </Tooltip>
      </div>
    </StyledToolbar>
  );
}

TableToolbar.propTypes = {
  setIsEnterCard: PropTypes.func.isRequired,
}

export default TableToolbar
