export const formControlLabelStyles = {
  MuiFormControlLabel: {
    styleOverrides: {
      label: ({ theme }) => ({
        fontSize: "0.875rem",
        color: theme.palette.grey[1],
      }),
    },
  },
};
