import React from 'react';
import { Grid } from '@mui/material';
import PropTypes from 'prop-types';

export const Sections = ({ children, ...props }) => {
  return (
    <Grid {...props} item xs={9}>
      {children}
    </Grid>
  );
};

Sections.propTypes = {
  ...Grid.propTypes,
  children: PropTypes.node,
};
