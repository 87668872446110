import { Button, Typography, styled } from '@mui/material';

export const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'active',
})(({ active, theme }) => ({
  backgroundColor: active ? theme.palette.blue[11] : 'inherit',
  outline: 'none',
  border: 'none',
  '&.MuiButton-root': {
    boxShadow: active ? `${theme.shadows[0]}` : `${theme.shadows[3]}`,
    '&:hover': {
      border: 'none',
      boxShadow: 'none',
    },
  },
}));

export const StyledButtonText = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
}));
