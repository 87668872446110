import { useContext, useMemo, useRef } from 'react';
import { useCopy, useFlags, useCurrentSession } from '@hooks';
import {
  Copies as AdvancedDeliveryOptionsFormCopies,
} from '../../AdvancedDeliveryOptionsForm/constants';
import { Copies, fields } from '../constants';
import {
  getObjectivesOptions,
  getRecommendedCampaignObjectForAdvertiser,
} from '../utils';
import { getMinDate } from '@components/WizardContainers/validations';
import { Themes } from '@constants/themes';
import AdvertiserContext from '@components/AdvertiserContext';

const SCROLL_DELAY = 500;

export const useCampaignForm = ({
  campaignStaticDisplayAdGroups,
  control,
  isIncremental,
  setValue,
  startDate,
  startTime,
  trigger,
}) => {
  const ref = useRef(null);

  const {
    theme,
    advertiserevent_set = [],
    campaign_objective_types = {},
  } = useContext(AdvertiserContext);

  const { currentAdvertiser } = useCurrentSession();

  const Copy = useCopy(Copies);

  const { flags, Flags } = useFlags();

  const AdvancedDeliveryOptionsFormCopy = useCopy(
    AdvancedDeliveryOptionsFormCopies,
  );

  const recommendedCampaignObjective = useMemo(
    () => getRecommendedCampaignObjectForAdvertiser(currentAdvertiser),
    [currentAdvertiser],
  );

  const handleObjectivesChange = () => {
    setValue(
      fields.objectiveGoalEvent.path,
      fields.objectiveGoalEvent.defaultValue,
      { shouldDirty: true },
    );
    setValue(
      fields.objectiveGoalType.path,
      fields.objectiveGoalType.defaultValue,
      { shouldDirty: true },
    );
    setValue(
      fields.objectiveGoalValueMultiplier.path,
      fields.objectiveGoalValueMultiplier.defaultValue,
      { shouldDirty: true },
    );
    setValue(
      fields.objectiveGoalValueCost.path,
      fields.objectiveGoalValueCost.defaultValue,
      { shouldDirty: true },
    );
  };

  const handleValidatorAction = () => {
    trigger();
    if (ref?.current !== null) {
      window.setTimeout(() => {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'nearest',
        });
      }, SCROLL_DELAY);
    }
  };

  const dragsAllowed = flags[Flags.USER_GETS_VERTICAL_CAMPAIGN_DRAGS]
    && theme !== Themes.NBCU;

  const objectiveGoalsAllowed =
    flags[Flags.USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS];

  const objectivesOptions = useMemo(
    () => getObjectivesOptions({
      advertiserObjectives: campaign_objective_types,
      control,
      copies: Copy,
      objectiveGoalsAllowed,
      objectiveGoalEventSet: advertiserevent_set,
      recommendedCampaignObjective,
      setValue,
    }),
    [
      campaign_objective_types,
      control,
      Copy,
      objectiveGoalsAllowed,
      advertiserevent_set,
      recommendedCampaignObjective,
      setValue,
    ],
  );

  const minEndDate = getMinDate(startDate, startTime);

  const campaignHasSavedDrag =
    campaignStaticDisplayAdGroups.length > 0 &&
    !campaignStaticDisplayAdGroups[0].temporary;

  const dragToggleDisabled = campaignHasSavedDrag || isIncremental;

  const dragToggleText = isIncremental ?
    'Display retargeting is not available for incrementality tests.' :
    Copy.dragToggleText;

  const dragTooltip = campaignHasSavedDrag ?
    'Retarget exposed audience with display ads. This cannot be toggled off \
    once the display ad group is saved.' :
    Copy.dragToggleText;

  const campaignObjectiveHeader = Copy.campaignObjectiveHeader;

  const trackingIsSetUp = advertiserevent_set.length > 0;

  return {
    AdvancedDeliveryOptionsFormCopy,
    advertiserevent_set,
    campaignObjectiveHeader,
    Copy,
    dragsAllowed,
    dragToggleDisabled,
    dragToggleText,
    dragTooltip,
    handleObjectivesChange,
    minEndDate,
    objectivesOptions,
    ref,
    trackingIsSetUp,
    validatorAction: handleValidatorAction,
  };
};
