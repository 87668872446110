import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { Box, Container, Grid, Typography, Button } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { useHistory } from 'react-router-dom';

import AppHeader from '../AppHeader';
import Title from '../Title';

const PREFIX = 'RequestAccountPage';

const classes = {
  container: `${PREFIX}-container`
};

const StyledAppHeader = styled(AppHeader)(({
  theme: { spacing }
}) => ({
  [`& .${classes.container}`]: {
    paddingTop: spacing(10),
    paddingBottom: spacing(10),
  }
}));

const RequestAccountPage = () => {

  const history = useHistory();

  return (
    <StyledAppHeader hidenav="true" navSkipSaveStatus="">
      <Box className={clsx(classes.container, '')}>
        <Container maxWidth="lg">
          <Box>
            <Grid container item direction="row" display="flex" md={12}>
              <Grid item md={6} style={{ "display": "flex", "width": "50%", "height": "200px", "margin": "auto", "alignItems": "center", "justifyContent": "center" }}>
                <Title>Welcome to Peacock Ad Manager</Title>
              </Grid>
              <Grid item md={6}>
                <img
                  style={{ backgroundSize: 'contain', width: '100%' }}
                  src={require('../../images/nbcu/padman-hero.png')}
                  alt="Peacock Ad Hero logo"
                  className={classes.logo}
                />
              </Grid>
            </Grid>
            <Grid container item direction="row" justifyContent="space-evenly" display="flex">
              <Grid item md={5} xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container justifyContent="space-evenly">
                      <Box py={5}>
                        <Grid item p={2}>
                          <Typography variant="h4" className="peacockText">
                            Request Access to Peacock Ad Manager
                          </Typography>
                          <Grid container justifyContent="center">
                            <Grid item xs={6}>
                              <Box mt={2}>
                                <Button
                                  color="secondary"
                                  size="large"
                                  fullWidth
                                  disableElevation
                                  variant='outlined'
                                  disabled
                                >
                                  Coming Soon
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Box>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item md={5} xs={12}>
                <Card variant="outlined">
                  <CardContent>
                    <Grid container justifyContent="space-evenly">
                      <Grid item p={2}>
                        <Box py={5}>
                          <Typography variant="h4" className="peacockText">
                            Log into Peacock Ad Manager
                          </Typography>
                          <Grid container justifyContent="center">
                            <Grid item xs={8}>
                              <Box mt={2}>
                                <Button
                                  color="secondary"
                                  size="large"
                                  fullWidth
                                  variant='contained'
                                  onClick={() => history.push('/login')}
                                >
                                  Log In
                                </Button>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </StyledAppHeader>
  );
};

export default RequestAccountPage;
