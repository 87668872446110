import { map, values } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTargetingKeys } from '../../../../../../components/hooks/apis/targetingKeys';
import {
  customInventoryTabValues,
  inventoryCategoryName,
} from '../../constants';
import {
  mapAppBundleListToDataItem,
  mapBundleTypeToDataItem,
} from '@components/WizardContainers/GroupSection/utils';
import { transformToContext } from '../utils/transform';
import {
  customInventoryAccessibleTypes,
  customInventoryTypes,
} from '../constants';
import { useDebounce } from 'use-debounce';

const createSearch = () => ({
  [customInventoryTabValues.appName]: '',
  [customInventoryTabValues.appBundleList]: '',
  [customInventoryTabValues.dealId]: '',
});

export const useAdvancedCustomInventoryData = ({
  tab,
  selected,
  dataFieldName,
  filter,
}) => {
  const [search, setSearch] = useState(createSearch());
  const type = customInventoryTypes[tab] ?? null;

  const [debouncedFilter] = useDebounce(filter, 200);

  const { data: appNames } = useTargetingKeys({
    type: customInventoryTypes[customInventoryTabValues.appName],
    params: {
      disabled: tab !== customInventoryTabValues.appName,
      search: search[customInventoryTabValues.appName],
    },
  });

  const { data: appBundleLists } = useTargetingKeys({
    type: customInventoryTypes[customInventoryTabValues.appBundleList],
    params: {
      disabled: tab !== customInventoryTabValues.appBundleList,
      search: search[customInventoryTabValues.appBundleList],
    },
  });

  const { data: dealIds } = useTargetingKeys({
    type: customInventoryTypes[customInventoryTabValues.dealId],
    params: {
      search: search[customInventoryTabValues.dealId],
      disabled: false,
      bundle_ids: map(
        selected.filter(b => !values(inventoryCategoryName).includes(b.type)),
        'id',
      ).join(','),
    },
  });

  const dealIdsMapped = useMemo(() => values(dealIds), [dealIds]);

  const dataByType = {
    [customInventoryTabValues.appName]: appNames ?? [],
    [customInventoryTabValues.appBundleList]: appBundleLists ?? [],
    [customInventoryTabValues.dealId]: dealIdsMapped ?? [],
  };

  const fixedSelection = useMemo(() => {
    const availableSelection = selected.filter(
      s =>
        !customInventoryAccessibleTypes.includes(s.type) ||
        s.type !== inventoryCategoryName[customInventoryTabValues.dealId] ||
        (s.type === inventoryCategoryName[customInventoryTabValues.dealId] &&
          dealIdsMapped.includes(s.id)),
    );

    if (selected.length !== availableSelection.length) {
      return availableSelection;
    }

    return selected;
  }, [dealIdsMapped, selected]);

  const preparedData = useMemo(() => {
    if (!type) return [];

    switch (type) {
      case customInventoryTabValues.appBundleList:
        return dataByType[type].map(item =>
          mapAppBundleListToDataItem(item, type, {
            type: inventoryCategoryName[type],
          }),
        );

      default:
        return dataByType[type].map(item =>
          mapBundleTypeToDataItem(item, type, {
            type: inventoryCategoryName[type],
          }),
        );
    }
  }, [dataByType, selected, type]);

  useEffect(() => {
    setSearch(prev => ({
      ...prev,
      [tab]: filter,
    }));
  }, [debouncedFilter, tab]);

  useEffect(() => {
    setSearch(createSearch());
  }, [tab]);

  return type
    ? {
        selected: fixedSelection,
        data: transformToContext(
          preparedData.filter(item => {
            if (!selected) {
              return true;
            }

            return !selected.some(
              selectedItem => selectedItem.id === item.id,
            );
          }),
          debouncedFilter,
          dataFieldName,
        ),
      }
    : {
        selected: fixedSelection,
      };
};
