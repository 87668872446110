import { useWizardContext } from './useWizardContext';

export const useWizardSection = ({ key }) => {
  const { navigation } = useWizardContext();

  const active = navigation.activeSection === key;

  const { updateEntityState, activePane } = navigation;

  return {
    ...navigation,
    updateEntityState: state => updateEntityState(key, state),
    isActivePane: paneKey => activePane === paneKey,
    active,
  };
};
