import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';
import { useCurrentSession } from '../currentSession';
import { useOrg } from '../org';

export const buildNBCUAdvertiserCacheKey = id => ({ url: `/advertisers/${id}/nbcu/` });

export const useAdvertiserStripeSetupIntent = (options) => {
  const { getV1, apiIsReady, currentAdvertiser } = useCurrentSession();
  const { org } = useOrg();
  const { id } = currentAdvertiser;

  return useSWR(
    apiIsReady && org.payment_provider === 'STRIPE' && id ? { url: `/advertisers/${id}/stripe_setup_intent/` } : null,
    ({ url, params }) => getV1(url, { params }).then(res => res.data),
    options,
  );
};

export const useGetNBCUAdvertiser = (id, options) => {
  const { getV1, apiIsReady } = useCurrentSession();

  return useSWR(
    apiIsReady && id ? buildNBCUAdvertiserCacheKey(id) : null,
    ({ url, params }) => getV1(url, { params }).then(res => res.data),
    options,
  );
};

export const usePutNBCUAdvertiser = (id, options) => {
  const { putV1, apiIsReady } = useCurrentSession();

  return useSWRMutation(
    apiIsReady && id ? buildNBCUAdvertiserCacheKey(id) : null,
    ({ url }, { arg }) => putV1(url, arg).then(res => res.data),
    options,
  );
};
