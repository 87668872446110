// intercom-wrapper.js
const reactUseIntercom = require('react-use-intercom');

const {
  IntercomProvider,
  useIntercom,
  useIntercomEvents,
  useIntercomVisibility,
} = reactUseIntercom;

module.exports = {
  IntercomProvider,
  useIntercom,
  useIntercomEvents,
  useIntercomVisibility,
};
