import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import ModalWrapper from '../ModalWrapper';

const PREFIX = 'RolesAndPrivileges';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`,
  learn: `${PREFIX}-learn`,
  sub: `${PREFIX}-sub`
};

const StyledModalWrapper = styled(ModalWrapper)(({
  theme: { spacing }
}) => ({
  [`& .${classes.container}`]: {
    maxWidth: '480px',
    width: '100%',
    margin: '0 auto',
  },

  [`& .${classes.title}`]: {
    marginTop: -40,
    marginBottom: spacing(8),
    borderBottom: '1px solid #e5e7eb',
  },

  [`& .${classes.learn}`]: {
    display: 'inline',
    verticalAlign: 'bottom',
    fontSize: '0.725rem',
  },

  [`& .${classes.sub}`]: {
    color: '#5c6c73',
    fontSize: '0.725rem',
  }
}));

const RolesAndPrivileges = ({ onClose }) => {


  return (
    <StyledModalWrapper
      isOpen
      hasSubmit
      submit="Got it"
      maxWidth="md"
      onClose={onClose}
      onSubmit={onClose}
      noCancel
      noFooterBg
    >
      <DialogTitle className={classes.title}>
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          pt={1}
        >
          <Box>
            <Box mb={1}>
              <Typography variant="h3">User Roles & Privileges</Typography>
            </Box>
          </Box>

          <IconButton onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <Box
        mx={'auto'}
        display="flex"
        flexDirection="column"
        gap="18px"
        className={classes.container}
      >
        <Typography>Organizational Roles</Typography>

        <Box display="flex" flexDirection="column" gap="6px">
          <Typography variant="h5">Org Admin</Typography>

          <Typography>
            Oversees creating and inviting new members, setting global
            buisiness rules.
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap="6px">
          <Typography variant="h5">Org Member</Typography>

          <Typography>
            Manages and has access only to specific aspects of the
            organizations.
          </Typography>
        </Box>

        <Box mb={2} />

        <Typography>Ad Account Roles</Typography>

        <Box display="flex" flexDirection="column" gap="6px">
          <Typography variant="h5">Ad Account Admin</Typography>

          <Typography>
            Oversees an Ad Account to manage financial rules and specific user
            access and permissions under that Ad Account.
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap="6px">
          <Typography variant="h5">Campaign Manager</Typography>

          <Typography>
            Creates and manages ad campaigns for their Ad Account.
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap="6px">
          <Typography variant="h5">Analyst</Typography>

          <Typography>
            Read only, generates reports of all campaigns for their Ad
            Account.
          </Typography>
        </Box>
      </Box>
    </StyledModalWrapper>
  );
};

RolesAndPrivileges.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default RolesAndPrivileges;
