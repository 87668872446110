type PluralForm = 'singular' | 'plural';

type PluralCopies = {
  singular: (count: number) => string;
  plural: (count: number) => string;
};

export const usePluralCopy = (count: number) => {
  const plural: PluralForm = count === 1 ? 'singular' : 'plural';
  return (pluralCopies: PluralCopies): string => {
    if (typeof pluralCopies[plural] !== 'function') {
      throw new Error(`Missing ${plural} function in pluralCopies`);
    }
    return pluralCopies[plural](count);
  };
};
