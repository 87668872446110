import { handleResponse, handleError, handleHeaders } from './tvs-response';
import apiInstance from './apiClient';

/* TOOD:
// - retrieve okta auth token into api for authenticated requests
*/

// API CONSTANTS
const BASE_URL = `${process.env.API_URL}/`;

const VERSION = 'v1';

const BASE_HEADERS = {
  'accept': 'application/json',
  'content-type': 'application/json',
};



/////////////////////////////
// RESTFUL
/////////////////////////////
const get = (resource = '', headers = {}) => {
  return apiInstance
    .get(
      `${BASE_URL}${VERSION}${resource}`,
      handleHeaders(BASE_HEADERS, headers)
    )
    .then(handleResponse)
    .catch(handleError);
};

const post = (resource = '', data = {}, headers = {}) => {
  return apiInstance
    .post(
      `${BASE_URL}${VERSION}${resource}`,
      data,
      handleHeaders(BASE_HEADERS, headers)
    )
    .then(handleResponse)
    .catch(handleError);
};

const put = (resource = '', data = {}, headers = {}) => {
  return apiInstance
    .put(
      `${BASE_URL}${VERSION}${resource}`,
      data,
      handleHeaders(BASE_HEADERS, headers)
    )
    .then(handleResponse)
    .catch(handleError);
};

const patch = (resource = '', data = {}, headers = {}) => {
  return apiInstance
    .patch(
      `${BASE_URL}${VERSION}${resource}`,
      data,
      handleHeaders(BASE_HEADERS, headers)
    )
    .then(handleResponse)
    .catch(handleError);
};

const remove = (resource = '', headers = {}) => {
  return apiInstance
    .delete(
      `${BASE_URL}${VERSION}${resource}`,
      handleHeaders(BASE_HEADERS, headers)
    )
    .then(handleResponse)
    .catch(handleError);
};



// CUSTOMS
const getId = (resource = '', id = 0, headers = {}) => {
  return apiInstance
    .get(
      `${BASE_URL}${VERSION}${resource}${id}`,
      handleHeaders(BASE_HEADERS, headers)
    )
    .then(handleResponse)
    .catch(handleError);
};

const getUrl = (resource = '', headers = {}) => {
  return apiInstance
    .get(`${resource}`, headers ? handleHeaders(BASE_HEADERS, headers) : {})
    .then(handleResponse)
    .catch(handleError);
};

const postUrl = (resource = '', data = {}, headers = {}) => {
  return apiInstance
    .post(
      resource,
      data,
      handleHeaders({}, headers)
    )
    .then(handleResponse)
    .catch(handleError);
};



export default {
  getId,
  getUrl,
  get,
  post,
  postUrl,
  put,
  patch,
  remove,
};
