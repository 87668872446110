import React from 'react';
import { MenuItem } from '@mui/material';
import { StyledIcon, StyledSelect } from './styles';
import { IncludeExcludeToggleProps, SelectionType } from './types';
import {
  faLocationMinus,
  faLocationPlus,
} from '@fortawesome/pro-solid-svg-icons';

function IncludeExcludeToggle({
  value,
  onChange,
}: IncludeExcludeToggleProps) {
  return (
    <StyledSelect
      value={value}
      onChange={e => onChange(e.target.value as SelectionType)}
      displayEmpty
    >
      <MenuItem value="include">
        <StyledIcon icon={faLocationPlus} />
      </MenuItem>
      <MenuItem value="exclude">
        <StyledIcon icon={faLocationMinus} />
      </MenuItem>
    </StyledSelect>
  );
}

export default IncludeExcludeToggle;
