import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { NavLink } from 'react-router-dom';
import { Typography } from '@mui/material';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem';
import {
  ManagerIcon,
  TrackingIcon,
  CreativeIcon,
  ReportsIcon,
  TVIcon,
} from './icons';
import AdvertiserContext from './AdvertiserContext';
import { RoutePaths, Themes } from '../constants';
import { useFlags } from './hooks';


const StyledList = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2.5),
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));

const activeListItemStyles = {
  [Themes.DEFAULT]: {'v1': {
      background: '#02402B',
      boxShadow: '0px 1px 11px 0px rgba(0, 0, 0, 0.10) inset',
      borderRadius: 10,
      color: '#73CDFF',

      '& svg': {
        fill: '#73CDFF',
      },

      '& span': {
        color: '#73CDFF',
      },
    },
    'v2': {
      background: '#13171A',
      boxShadow: '0px 1px 11px 0px rgba(0, 0, 0, 0.10) inset',
      borderRadius: 10,
      color: 'white',

      '& svg': {
        fill: '#57D9AD',
      },

      '& p': {
        color: '#57D9AD',
      },
    }},
  [Themes.NBCU]: {
    v1: {
      background: '#646669',
      borderRadius: 5,
    },
    v2: {
    background: '#646669',
    borderRadius: 5,
  }},
};

const StyledListItem = styled(ListItem, {
  shouldForwardProp: prop => !['appTheme', 'themeVersion'].includes(prop),
})(({ theme, appTheme, themeVersion  }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: theme.spacing(1),
  width: theme.spacing(10),
  height: theme.spacing(10),
  padding: theme.spacing(1),
  fontSize: '0.75rem',

  '&.active': activeListItemStyles[appTheme][themeVersion],
}));

export const MainListItems = ({
  showCampaignsLink = false,
  showReportsLink = false,
  showCreativesLink = false,
  showTrackingLink = false,
}) => {
  const adContext = useContext(AdvertiserContext);
  const { flags, Flags } = useFlags();

  const v2AppEnabled = flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];
  const themeVersion = v2AppEnabled ? 'v2' : 'v1';

  return (
    <StyledList>
      {showCampaignsLink && (
        <StyledListItem
          component={NavLink}
          to={RoutePaths.CAMPAIGNS}
          button
          disableRipple
          activeClassName="active"
          appTheme={adContext.theme}
          themeVersion={themeVersion}
        >
          <TVIcon />
          <Typography variant="inherit" color="inherit" data-testid="campaigns-menu-tab">Campaigns</Typography>
        </StyledListItem>
      )}

      {showReportsLink && (
        <StyledListItem
          component={NavLink}
          to={RoutePaths.REPORTS}
          button
          disableRipple
          activeClassName="active"
          appTheme={adContext.theme}
          themeVersion={themeVersion}
        >
          <ReportsIcon />
          <Typography variant="inherit" color="inherit" data-testid="reports-menu-tab">Reports</Typography>
        </StyledListItem>
      )}

      {showCreativesLink && (
        <StyledListItem
          component={NavLink}
          to={{
            pathname: RoutePaths.CREATIVES,
            state: { key: uniqueId() },
          }}
          button
          disableRipple
          activeClassName="active"
          appTheme={adContext.theme}
          themeVersion={themeVersion}
        >
          <CreativeIcon />
          <Typography variant="inherit" color="inherit" data-testid="creative-menu-tab">Creative</Typography>
        </StyledListItem>
      )}

      {showTrackingLink && (
        <StyledListItem
          component={NavLink}
          to={RoutePaths.TRACKING}
          button
          disableRipple
          activeClassName="active"
          appTheme={adContext.theme}
          themeVersion={themeVersion}
        >
          <TrackingIcon />
          <Typography variant="inherit" color="inherit" data-testid="tracking-menu-tab">Tracking</Typography>
        </StyledListItem>
      )}
    </StyledList>
  );
};

export const SecondaryListItems = ({ showBusinessManagerLink }) => {
  const adContext = useContext(AdvertiserContext);
  const { flags, Flags } = useFlags();

  const v2AppEnabled = flags[Flags.USER_GETS_VERTICAL_CAMPAIGN];
  const themeVersion = v2AppEnabled ? 'v2' : 'v1';

  return (
    <StyledList
      component="nav"
      aria-labelledby="nested-list-subheader"
    >
      {showBusinessManagerLink && (
        <StyledListItem
          component={NavLink}
          to={RoutePaths.MANAGER}
          button
          disableRipple
          activeClassName="active"
          appTheme={adContext.theme}
          themeVersion={themeVersion}
        >
          <ManagerIcon />
          <Typography variant="inherit" color="inherit" data-testid="manager-menu-tab">Manager</Typography>
        </StyledListItem>
      )}
    </StyledList>
  );
};

SecondaryListItems.propTypes = {
  activeClassName: PropTypes.string,
  showBusinessManagerLink: PropTypes.bool,
};

MainListItems.propTypes = {
  showCampaignsLink: PropTypes.bool,
  showReportsLink: PropTypes.bool,
  showCreativesLink: PropTypes.bool,
  showTrackingLink: PropTypes.bool,
};
