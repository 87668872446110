import PropTypes from 'prop-types';

export const sectionProps = PropTypes.shape({
  sectionKey: PropTypes.string,
  dirty: PropTypes.bool,
  adGroup: PropTypes.object,
  campaignId: PropTypes.object,
});

export const wizardProps = PropTypes.shape({
  sections: PropTypes.arrayOf(PropTypes.string),
  campaign: PropTypes.object,
  groups: PropTypes.array,
});

export const subwayProps = PropTypes.shape({
  ...sectionProps,
  interactive: PropTypes.bool,
  disabled: PropTypes.bool,
  menuDisabled: PropTypes.bool,
});
