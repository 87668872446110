import React from "react";
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { defaults } from 'lodash';
import { Button, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField } from '@components/RHFTextField';
import { DefaultValues, FormFields } from './constants';

const AdminForm = ({ data, onSubmit }) => {
  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
  } = useForm({
    values: defaults(data, DefaultValues),
    resolver: yupResolver(
      object().shape({
        [FormFields.AGENCY_ID]: string().matches(/^\d*$/, 'Must be a number type'),
        [FormFields.BILLING_ID]: string().matches(/^\d*$/, 'Must be a number type'),
        [FormFields.DAILY_SPEND]: string().matches(/^[0-9]*\.?[0-9]*$/, 'Please enter a valid amount'),
      })
    ),
    mode: 'onChange',
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const handleCancel = () => {
    reset(data);
  };

  return (
    <Stack
      noValidate
      component="form"
      onSubmit={handleSubmit((data) => onSubmit(data, setError))}
      sx={{
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Stack spacing={5.5}>
        <RHFTextField
          control={control}
          name={FormFields.AGENCY_ID}
          sx={{ width: '50%' }}
          label="NBCU Salesforce Agency ID"
          placeholder="Enter NBCU Salesforce Agency ID"
        />

        <RHFTextField
          control={control}
          name={FormFields.BILLING_ID}
          sx={{ width: '50%' }}
          label="NBCU Agency Billing ID"
          placeholder="Enter NBCU Agency Billing ID"
        />

        <RHFTextField
          control={control}
          name={FormFields.DAILY_SPEND}
          sx={{ width: '50%' }}
          label="Daily Spend Threshold"
          placeholder="Enter Daily Spend Threshold"
        />

        <Controller
          name={FormFields.INVOICE_APPROVED}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} size="small" />}
              label={
                <Typography variant="body2">
                  Invoice Approved
                </Typography>
              }
            />
          )}
        />
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          disabled={formState.isSubmitting}
          onClick={handleCancel}
          disableElevation
          disableRipple
          type="reset"
        >
          Cancel
        </Button>

        <Button
          disabled={!formState.isValid || formState.isSubmitting}
          disableRipple
          variant="contained"
          size="large"
          type="submit"
        >
          Save Settings
        </Button>
      </Stack>
    </Stack>
  );
};

AdminForm.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AdminForm;
