import { useFieldArray, useFormContext } from 'react-hook-form';
import { fields } from '../../constants';
import { useMemo } from 'react';
import { groupBy, keys, partition } from 'lodash';
import { groupGeo } from '../../utils';
import {
  GeoItem,
  GeoItemIndexed,
  GroupedGeoItem,
} from '@v2/components/campaign/GeoTargeter';

export const useGeographyFields = () => {
  const { control, watch } = useFormContext();
  const { fields: arrayFields, ...data } = useFieldArray({
    control,
    name: fields.geography.path,
  });

  const watchFieldArray = watch(fields.geography.path);

  const list: GeoItem[] = arrayFields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray?.[index],
    };
  });

  const fixedList = useMemo(() => {
    const indexedList = list.map((item, index) => ({ ...item, index }));
    const {
      empty = [],
      country = [],
      ...groups
    } = groupBy<GeoItemIndexed>(indexedList, 'type');
    const newList: (GroupedGeoItem | undefined)[] = [];

    keys(groups).forEach(key => {
      const group = groups[key];
      const [blacklisted = [], whitelisted = []] = partition(
        group,
        'blacklist',
      );

      const items = [groupGeo(blacklisted), groupGeo(whitelisted)];

      newList.push(...items);
    });

    return [groupGeo(country), ...newList, groupGeo(empty)].filter(
      v => v,
    ) as GroupedGeoItem[];
  }, [list]);

  return {
    ...data,
    list,
    fixedList,
  };
};
