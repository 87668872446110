import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

import AdvertiserContext from '../components/AdvertiserContext';
import { useAPI } from '../components/hooks/api';
import { useAuth } from '../components/hooks';

const OrgContext = React.createContext(null);

OrgContext.displayName = 'OrgContext';

export const OrgContextProvider = ({ children }) => {
  const { authState } = useAuth();
  const adContext = useContext(AdvertiserContext);
  const { useGet } = useAPI();
  const [org, setOrg] = useState(null);

  const getOrg = async () => {
    try {
      const res = await useGet(adContext.primary_org, true);
      setOrg(res);
    } catch (error) {
      console.error('error getting org', error);
    }
  };

  useEffect(() => {
    if (!authState?.isAuthenticated || !adContext?.primary_org) return;

    getOrg();
  }, [authState?.isAuthenticated, adContext?.primary_org]);

  return (
    <OrgContext.Provider
      value={{
        org,
        setOrg,
      }}
    >
      {children}
    </OrgContext.Provider>
  );
};

OrgContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default OrgContext;
