import React, { useCallback, useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import LoadingSpinner from './ui/LoadingSpinner';

const PREFIX = 'CreditCardForm';

const classes = {
  iframe: `${PREFIX}-iframe`,
  methods: `${PREFIX}-methods`,
  cards: `${PREFIX}-cards`,
  radioGroup: `${PREFIX}-radioGroup`,
  radios: `${PREFIX}-radios`
};

const StyledBox = styled(Box)(({ theme }) => ({
  [`& .${classes.iframe}`]: {
    marginBottom: theme.spacing(6),
  },

  [`& .${classes.methods}`]: {
    fontSize: '.8rem',
  },

  [`& .${classes.cards}`]: {
    margin: theme.spacing(1),
    maxHeight: 244,
    overflow: 'auto',
  },

  [`& .${classes.radioGroup}`]: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },

  [`& .${classes.radios}`]: {
    fontSize: `1rem`,
  }
}));

const authorizeAddPayment = process.env.AUTHORIZE_NET_ADD_PAYMENT;

const CreditCardForm = props => {
  const  {
    data,
    isOpen,
    onClose,
    onMessage,
    onResize,
    onReceiveCommunication,
  } = props;
  const { advertiserId } = data;

  const iframeRef = useRef();
  const submitRef = useCallback(form => {
    // On render submit form immediately
    if (form != null) {
      form.submit();
    }
  }, []);

  const { useGet } = useAPI();
  const { isLoading, setIsLoading } = useLoader();

  const [formToken, setFormToken] = useState('');

  useEffect(() => {
    window.AuthorizeNetIFrame = {
      onReceiveCommunication: str => {
        if (onReceiveCommunication) {
          onReceiveCommunication(str);
        }

        const message = parse(str);

        console.log('message from iframe', message);
        console.log('message.action from iframe', message.action);

        if (isValidMessage(message)) {
          if (onMessage) {
            onMessage(message);
          }

          switch (message.action) {
            case 'successfulSave':
              // if a save is detected, hit the refresh
              // endpoint on payment_profiles
              break;

            case 'resizeWindow':
              console.log('resizeWindow', message);

              if (onResize) {
                onResize(message.width, message.height);
              }
              break;

            case 'cancel':
              console.log('cancel', message);

              getAuthorizeToken()
                .then(() => {
                  onClose();
                });

              if (onClose) {
                onClose();
              }
              break;
          }
        }
      },
    };
    return () => {
      delete window.AuthorizeNetIFrame;
    };
  }, []);

  useEffect(() => {
    getAuthorizeToken();
  }, []);

  async function getAuthorizeToken() {
    setIsLoading(true);

    try {
      const res = await useGet(
        `/advertisers/${advertiserId}/authorize_token`
      );
      console.log('res from token', res);
      if (res && res.status === 'Ok') {
        setFormToken(res.token);
      }

      setIsLoading(false);
      return res;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      return error;
    }
  }

  const isValidMessage = o => typeof o === 'object' && o !== null;

  const renderForm = () => (
    <>
      <form
        ref={submitRef}
        method="POST"
        action={authorizeAddPayment}
        target="new-auth-net"
      >
        <input name="token" type="hidden" value={formToken} />

        <Button style={{ display: 'none' }} type="submit">
          Add Payment
        </Button>
      </form>

      <div className={classes.iframe} style={{ height: 540 }}>
        <iframe
          ref={iframeRef}
          id="new-auth-net"
          name="new-auth-net"
          frameBorder={0}
          height="100%"
          width="100%"
        />
      </div>
    </>
  );

  return (
    <StyledBox pb={2}>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={onClose}
        open={isOpen}
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Enter a new card</Typography>

            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          {isLoading ? (
            <Box height={600}>
              <LoadingSpinner />
            </Box>
          ) : (
            renderForm()
          )}
        </DialogContent>
      </Dialog>
    </StyledBox>
  );
};

CreditCardForm.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
  onMessage: PropTypes.func,
  onResize: PropTypes.func,
  onReceiveCommunication: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default CreditCardForm;
