import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, Tab } from '@mui/material';
const PREFIX = 'TabPanel';

const classes = {
  selected: `${PREFIX}-selected`,
  root: `${PREFIX}-root`,
  wrapper: `${PREFIX}-wrapper`
};

const Root = styled('div')({
  [`& .${classes.selected}`]: {
    fontSize: "1.5rem",
    fontWeight: 'bold !important',
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 2.1,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#13171a !important",
  },
  [`& .${classes.root}`]: {
    flexGrow: 1,
    display: 'flex',
  },
  [`& .${classes.wrapper}`]: {
    alignItems: 'flex-end',
  },
});

export const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Root
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </Root>
  );
}

export const StyledTab = (Tab);

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
