import { Box, List } from '@mui/material';
import React from 'react';

import { Themes } from '@constants/themes';
import { TvSciQSvgIcon } from '@v2/components/icons/TvSciQSvgIcon';
import FromScratchImage from '@v2/images/padman/campaign-from-scratch.svg';
import QuickStartImage from '@v2/images/padman/quick-start-campaign.svg';

import { CustomListItem } from './CustomListItem';

const QUICK_START = 1;
const FROM_SCRATCH = 2;

export const CampaignTypes = {
  QUICK_START,
  FROM_SCRATCH,
};

const TvSciQLogo = () => (
  <span>
    <TvSciQSvgIcon sx={{ marginRight: '4px', marginBottom: '-4px' }} />
    <Box component="span" sx={{ color: 'blue.1', marginRight: '4px' }}>
      tvSc
      <span style={{ fontWeight: '700' }}>iQ</span>
    </Box>
  </span>
);

export const Copies = {
  [Themes.DEFAULT]: {
    fromScratchBody: (
      <List sx={{ width: '100%' }}>
        <CustomListItem>
          Advanced targeting and audience profiles.
        </CustomListItem>
        <CustomListItem>
          Add multiple Ad Groups
        </CustomListItem>
      </List>
    ),
    fromScratchHeader: 'Build a campaign from scratch',
    fromScratchCustomIcon: null,
    fromScratchIcon: 'faTools',
    quickStartBody: (
      <List sx={{ width: '100%' }}>
        <CustomListItem>
          Let <TvSciQLogo /> provide intelligent targeting, budget allocation,
          and bid strategy recommendations.
        </CustomListItem>
      </List>
    ),
    quickStartFooter: (
      <>
        <strong>Pro tip:</strong>{' '}
        Advertisers that are new to CTV, typically see a 10-15% lift
        in ROI with quick-start campaigns.
      </>
    ),
    quickStartHeader: 'Build a quick-start campaign',
    quickStartCustomIcon: <TvSciQSvgIcon sx={{ fontSize: 110 }} />,
  },
  [Themes.NBCU]: {
    fromScratchBody: (
      <List sx={{ width: '100%' }}>
        <CustomListItem>
          Traditional end-to-end campaign setup with advanced campaign and ad
          group configuration options
        </CustomListItem>
        <CustomListItem>
          Manually set targeting options, budget allocation, bid strategy, and
          more.
        </CustomListItem>
        <CustomListItem>
          You&apos;ll still have access to automated recommendations.
        </CustomListItem>
      </List>
    ),
    fromScratchCustomIcon: (
      <img
        src={FromScratchImage}
        alt="Campaign from Scratch"
        height="150px"
      />
    ),
    fromScratchIcon: null,
    quickStartBody: (
      <List sx={{ width: '100%' }}>
        <CustomListItem>
          End-to-end automated campaign setup that maximizes your delivery
          outcomes with less manual input
        </CustomListItem>
        <CustomListItem>
          Launch on NBCU streaming campaign in minutes.
        </CustomListItem>
        <CustomListItem>
          We&apos;ll make intelligent targeting, budget allocation, and bid
          strategy recommendations
        </CustomListItem>
        <CustomListItem>
          You can still fine-tune all settings.
        </CustomListItem>
      </List>
    ),
    quickStartFooter: null,
    quickStartCustomIcon: (
      <img
        src={QuickStartImage}
        alt="Quick Start Campaign"
        height='150px'
      />
    ),
  },
};
