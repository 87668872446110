// UTIL function helpers and data
import moment from 'moment-timezone';
import { TargetingTypeKeys } from '../constants';
import { getMinorityData, joinWithAnd } from '../helpers';

//////////////////////////
// HELPER FUNCTIONS
//////////////////////////
const valuetext = value => `${value}`;

// Converts a slider's value to time
const convertToTime = val => {
  if (val === -12 || val === 12) {
    return '12AM';
  }

  if (val === 0) {
    return `12PM`;
  }

  if (val > 0) {
    return `${val}PM`;
  }

  if (val < 0) {
    return `${12 + val}AM`;
  }
  return `12AM`;
};

// API HELPERS to format dates
const formatDateData = (date) => moment(date).utc().format();

// TODO: remove this function and replace with useDates
const formatDateTime = (date, time) => {
  const hour = new Date(time).getHours();
  const minute = new Date(time).getMinutes();
  const dateTime = moment(date).hour(hour).minute(minute);

  return moment(dateTime).utc().format();
};

const formatToUSD = (number) => {
  if(!number) return '-';

  const formatted =
    (number)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');

  return `$${formatted}`;
}

const formatDecimal = (num) => {
  if (num.length) {
    // Makes sure the amount is at least 0
    if (num >= 0) {
      // To make sure there are only two decimal places,
      // first check for a decimal
      if (num.indexOf('.') > -1) {
        const decimalIndex = num.indexOf('.');
        // Make a string from the part up to and including the decimal place
        const preDecimals = num.substr(0, decimalIndex + 1);
        // Make a string from everything after the decimal place
        const decimals = num.substr(decimalIndex + 1);
        // Remove everything after the first 2 characters of the second string
        const shavedDecimals = decimals.substring(0, 2);
        // Put strings back together
        const numTwoDecimals = preDecimals + shavedDecimals;

        return numTwoDecimals;
      }

      return num;
    }
  }

  // Sets the 'amount' field to blank if there's no amount
  return '';
};

const formatDemographic = (fromAge, toAge, fromIncome, toIncome, sex) => {
  if (!fromAge || !toAge || !fromIncome || !toIncome || !sex) {
    return '';
  }

  const maxAge = toAge === 65 ? '65+' : toAge;
  const minIncome = fromIncome === 30 ? '<30' : fromIncome;
  const maxIncome = toIncome === 250 ? '>250' : toIncome;

  return `${fromAge}-${maxAge}, ${sex}, HH${minIncome}K - ${maxIncome}K`;
};

//////////////////////////
// STATIC DATA
//////////////////////////
const forbiddenCharacters = [
  'a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r',
  's','t','u','v','w','x','y','z','A','B','C','D','E','F','G','H','I','J',
  'K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z','`','~',
  '!','@','#','$','%','^','&','*','(',')','_','=','+','[','{',']','}','\\',
  '|',';',':','\'','"',',','<','>','/','?'
];

const dmaData = [
  {
    "code": 501,
    "text": "New York (DMA)"
  },
  {
    "code": 803,
    "text": "Los Angeles (DMA)"
  },
  {
    "code": 602,
    "text": "Chicago (DMA)"
  },
  {
    "code": 504,
    "text": "Philadelphia (DMA)"
  },
  {
    "code": 506,
    "text": "Boston (Manchester) (DMA)"
  },
  {
    "code": 807,
    "text": "San Francisco - Oakland - San Jose (DMA)"
  },
  {
    "code": 623,
    "text": "Dallas - Fort Worth (DMA)"
  },
  {
    "code": 511,
    "text": "Washington DC (Hagerstown) (DMA)"
  },
  {
    "code": 524,
    "text": "Atlanta (DMA)"
  },
  {
    "code": 618,
    "text": "Houston (DMA)"
  },
  {
    "code": 505,
    "text": "Detroit (DMA)"
  },
  {
    "code": 539,
    "text": "Tampa - Saint Petersburg (Sarasota) (DMA)"
  },
  {
    "code": 819,
    "text": "Seattle - Tacoma (DMA)"
  },
  {
    "code": 753,
    "text": "Phoenix (DMA)"
  },
  {
    "code": 613,
    "text": "Minneapolis - Saint Paul (DMA)"
  },
  {
    "code": 510,
    "text": "Cleveland (DMA)"
  },
  {
    "code": 528,
    "text": "Miami - Fort Lauderdale (DMA)"
  },
  {
    "code": 751,
    "text": "Denver (DMA)"
  },
  {
    "code": 862,
    "text": "Sacramento - Stockton - Modesto (DMA)"
  },
  {
    "code": 534,
    "text": "Orlando - Daytona Beach - Melbourne (DMA)"
  },
  {
    "code": 609,
    "text": "Saint Louis (DMA)"
  },
  {
    "code": 508,
    "text": "Pittsburgh (DMA)"
  },
  {
    "code": 820,
    "text": "Portland, OR (DMA)"
  },
  {
    "code": 512,
    "text": "Baltimore (DMA)"
  },
  {
    "code": 527,
    "text": "Indianapolis (DMA)"
  },
  {
    "code": 825,
    "text": "San Diego (DMA)"
  },
  {
    "code": 517,
    "text": "Charlotte (DMA)"
  },
  {
    "code": 533,
    "text": "Hartford & New Haven (DMA)"
  },
  {
    "code": 560,
    "text": "Raleigh - Durham (Fayetteville) (DMA)"
  },
  {
    "code": 659,
    "text": "Nashville (DMA)"
  },
  {
    "code": 616,
    "text": "Kansas City (DMA)"
  },
  {
    "code": 535,
    "text": "Columbus, OH (DMA)"
  },
  {
    "code": 617,
    "text": "Milwaukee (DMA)"
  },
  {
    "code": 515,
    "text": "Cincinnati (DMA)"
  },
  {
    "code": 567,
    "text": "Greenville - Spartansburg - Asheville - Anderson (DMA)"
  },
  {
    "code": 770,
    "text": "Salt Lake City (DMA)"
  },
  {
    "code": 641,
    "text": "San Antonio (DMA)"
  },
  {
    "code": 548,
    "text": "West Palm Beach - Fort Pierce (DMA)"
  },
  {
    "code": 563,
    "text": "Grand Rapids - Kalamazoo - Battle Creek (DMA)"
  },
  {
    "code": 630,
    "text": "Birmingham (Anniston and Tuscaloosa) (DMA)"
  },
  {
    "code": 566,
    "text": "Harrisburg - Lancaster - Lebanon - York (DMA)"
  },
  {
    "code": 544,
    "text": "Norfolk - Portsmouth - Newport News (DMA)"
  },
  {
    "code": 622,
    "text": "New Orleans (DMA)"
  },
  {
    "code": 640,
    "text": "Memphis (DMA)"
  },
  {
    "code": 650,
    "text": "Oklahoma City (DMA)"
  },
  {
    "code": 790,
    "text": "Albuquerque - Santa Fe (DMA)"
  },
  {
    "code": 518,
    "text": "Greensboro - High Point - Winston-Salem (DMA)"
  },
  {
    "code": 839,
    "text": "Las Vegas (DMA)"
  },
  {
    "code": 514,
    "text": "Buffalo (DMA)"
  },
  {
    "code": 529,
    "text": "Louisville (DMA)"
  },
  {
    "code": 521,
    "text": "Providence - New Bedford (DMA)"
  },
  {
    "code": 561,
    "text": "Jacksonville, Brunswick (DMA)"
  },
  {
    "code": 635,
    "text": "Austin, TX (DMA)"
  },
  {
    "code": 577,
    "text": "Wilkes Barre - Scranton (DMA)"
  },
  {
    "code": 532,
    "text": "Albany - Schenectady - Troy (DMA)"
  },
  {
    "code": 866,
    "text": "Fresno - Visalia (DMA)"
  },
  {
    "code": 693,
    "text": "Little Rock - Pine Bluff (DMA)"
  },
  {
    "code": 557,
    "text": "Knoxville (DMA)"
  },
  {
    "code": 542,
    "text": "Dayton (DMA)"
  },
  {
    "code": 556,
    "text": "Richmond - Petersburg (DMA)"
  },
  {
    "code": 671,
    "text": "Tulsa (DMA)"
  },
  {
    "code": 686,
    "text": "Mobile - Pensacola (Fort Walton Beach) (DMA)"
  },
  {
    "code": 541,
    "text": "Lexington (DMA)"
  },
  {
    "code": 564,
    "text": "Charleston-Huntington (DMA)"
  },
  {
    "code": 513,
    "text": "Flint - Saginaw - Bay City (DMA)"
  },
  {
    "code": 571,
    "text": "Fort Myers - Naples (DMA)"
  },
  {
    "code": 678,
    "text": "Wichita - Hutchinson (DMA)"
  },
  {
    "code": 573,
    "text": "Roanoke - Lynchburg (DMA)"
  },
  {
    "code": 658,
    "text": "Green Bay - Appleton (DMA)"
  },
  {
    "code": 547,
    "text": "Toledo (DMA)"
  },
  {
    "code": 789,
    "text": "Tucson (Sierra Vista) (DMA)"
  },
  {
    "code": 744,
    "text": "Honolulu (DMA)"
  },
  {
    "code": 679,
    "text": "Des Moines - Ames (DMA)"
  },
  {
    "code": 500,
    "text": "Portland - Auburn (DMA)"
  },
  {
    "code": 652,
    "text": "Omaha (DMA)"
  },
  {
    "code": 555,
    "text": "Syracuse (DMA)"
  },
  {
    "code": 619,
    "text": "Springfield, MO (DMA)"
  },
  {
    "code": 881,
    "text": "Spokane (DMA)"
  },
  {
    "code": 538,
    "text": "Rochester, NY (DMA)"
  },
  {
    "code": 632,
    "text": "Paducah - Cape Girardeau - Harrisburg - Mt Vernon (DMA)"
  },
  {
    "code": 612,
    "text": "Shreveport (DMA)"
  },
  {
    "code": 648,
    "text": "Champaign & Springfield - Decatur (DMA)"
  },
  {
    "code": 546,
    "text": "Columbia, SC (DMA)"
  },
  {
    "code": 691,
    "text": "Huntsville - Decatur (Florence) (DMA)"
  },
  {
    "code": 669,
    "text": "Madison (DMA)"
  },
  {
    "code": 575,
    "text": "Chattanooga (DMA)"
  },
  {
    "code": 588,
    "text": "South Bend - Elkhart (DMA)"
  },
  {
    "code": 637,
    "text": "Cedar Rapids - Waterloo & Dubuque (DMA)"
  },
  {
    "code": 718,
    "text": "Jackson, MS (DMA)"
  },
  {
    "code": 523,
    "text": "Burlington - Plattsburgh (DMA)"
  },
  {
    "code": 531,
    "text": "Tri-Cities, TN-VA (DMA)"
  },
  {
    "code": 636,
    "text": "Harlingen - Weslaco - Brownsville - McAllen (DMA)"
  },
  {
    "code": 625,
    "text": "Waco - Temple - Bryan (DMA)"
  },
  {
    "code": 682,
    "text": "Davenport - Rock Island - Moline (DMA)"
  },
  {
    "code": 716,
    "text": "Baton Rouge (DMA)"
  },
  {
    "code": 507,
    "text": "Savannah (DMA)"
  },
  {
    "code": 574,
    "text": "Johnstown - Altoona (DMA)"
  },
  {
    "code": 765,
    "text": "El Paso (DMA)"
  },
  {
    "code": 649,
    "text": "Evansville (DMA)"
  },
  {
    "code": 519,
    "text": "Charleston, SC (DMA)"
  },
  {
    "code": 536,
    "text": "Youngstown (DMA)"
  },
  {
    "code": 722,
    "text": "Lincoln & Hastings - Kearney (DMA)"
  },
  {
    "code": 670,
    "text": "Fort Smith - Fayetteville - Springdale - Rogers (DMA)"
  },
  {
    "code": 545,
    "text": "Greenville - New Bern - Washington (DMA)"
  },
  {
    "code": 509,
    "text": "Fort Wayne (DMA)"
  },
  {
    "code": 570,
    "text": "Florence - Myrtle Beach (DMA)"
  },
  {
    "code": 543,
    "text": "Springfield - Holyoke (DMA)"
  },
  {
    "code": 530,
    "text": "Tallahassee - Thomasville (DMA)"
  },
  {
    "code": 551,
    "text": "Lansing (DMA)"
  },
  {
    "code": 709,
    "text": "Tyler - Longview (Lufkin & Nacogdoches) (DMA)"
  },
  {
    "code": 811,
    "text": "Reno (DMA)"
  },
  {
    "code": 540,
    "text": "Traverse City - Cadillac (DMA)"
  },
  {
    "code": 725,
    "text": "Sioux Falls (Mitchell) (DMA)"
  },
  {
    "code": 520,
    "text": "Augusta (DMA)"
  },
  {
    "code": 698,
    "text": "Montgomery (Selma) (DMA)"
  },
  {
    "code": 675,
    "text": "Peoria - Bloomington (DMA)"
  },
  {
    "code": 724,
    "text": "Fargo - Valley City (DMA)"
  },
  {
    "code": 757,
    "text": "Boise (DMA)"
  },
  {
    "code": 503,
    "text": "Macon (DMA)"
  },
  {
    "code": 801,
    "text": "Eugene (DMA)"
  },
  {
    "code": 855,
    "text": "Santa Barbara - Santa Maria - San Luis Obispo (DMA)"
  },
  {
    "code": 702,
    "text": "La Crosse - Eau Claire (DMA)"
  },
  {
    "code": 642,
    "text": "Lafayette, LA (DMA)"
  },
  {
    "code": 828,
    "text": "Monterey - Salinas (DMA)"
  },
  {
    "code": 810,
    "text": "Yakima - Pasco - Richland - Kennewick (DMA)"
  },
  {
    "code": 673,
    "text": "Columbus - Tupelo - West Point (DMA)"
  },
  {
    "code": 800,
    "text": "Bakersfield (DMA)"
  },
  {
    "code": 600,
    "text": "Corpus Christi (DMA)"
  },
  {
    "code": 868,
    "text": "Chico - Redding (DMA)"
  },
  {
    "code": 634,
    "text": "Amarillo (DMA)"
  },
  {
    "code": 522,
    "text": "Columbus, GA (DMA)"
  },
  {
    "code": 610,
    "text": "Rockford (DMA)"
  },
  {
    "code": 705,
    "text": "Wausau - Rhinelander (DMA)"
  },
  {
    "code": 628,
    "text": "Monroe - El Dorado (DMA)"
  },
  {
    "code": 605,
    "text": "Topeka (DMA)"
  },
  {
    "code": 676,
    "text": "Duluth - Superior (DMA)"
  },
  {
    "code": 604,
    "text": "Columbia - Jefferson City (DMA)"
  },
  {
    "code": 550,
    "text": "Wilmington (DMA)"
  },
  {
    "code": 692,
    "text": "Beaumont - Port Arthur (DMA)"
  },
  {
    "code": 813,
    "text": "Medford - Klamath Falls (DMA)"
  },
  {
    "code": 516,
    "text": "Erie (DMA)"
  },
  {
    "code": 624,
    "text": "Sioux City (DMA)"
  },
  {
    "code": 627,
    "text": "Wichita Falls & Lawton (DMA)"
  },
  {
    "code": 603,
    "text": "Joplin - Pittsburg (DMA)"
  },
  {
    "code": 651,
    "text": "Lubbock (DMA)"
  },
  {
    "code": 525,
    "text": "Albany, GA (DMA)"
  },
  {
    "code": 576,
    "text": "Salisbury (DMA)"
  },
  {
    "code": 559,
    "text": "Bluefield - Beckley - Oak Hill (DMA)"
  },
  {
    "code": 581,
    "text": "Terre Haute (DMA)"
  },
  {
    "code": 537,
    "text": "Bangor (DMA)"
  },
  {
    "code": 611,
    "text": "Rochester - Mason City - Austin (DMA)"
  },
  {
    "code": 804,
    "text": "Palm Springs (DMA)"
  },
  {
    "code": 554,
    "text": "Wheeling - Steubenville (DMA)"
  },
  {
    "code": 743,
    "text": "Anchorage (DMA)"
  },
  {
    "code": 502,
    "text": "Binghamton (DMA)"
  },
  {
    "code": 656,
    "text": "Panama City (DMA)"
  },
  {
    "code": 746,
    "text": "Biloxi - Gulfport (DMA)"
  },
  {
    "code": 633,
    "text": "Odessa - Midland (DMA)"
  },
  {
    "code": 687,
    "text": "Minot - Bismarck - Dickinson (DMA)"
  },
  {
    "code": 657,
    "text": "Sherman, TX - Ada, OK (DMA)"
  },
  {
    "code": 592,
    "text": "Gainesville (DMA)"
  },
  {
    "code": 758,
    "text": "Idaho Falls - Pocatello (DMA)"
  },
  {
    "code": 662,
    "text": "Abilene - Sweetwater (DMA)"
  },
  {
    "code": 598,
    "text": "Clarksburg - Weston (DMA)"
  },
  {
    "code": 526,
    "text": "Utica (DMA)"
  },
  {
    "code": 710,
    "text": "Hattiesburg - Laurel (DMA)"
  },
  {
    "code": 762,
    "text": "Missoula (DMA)"
  },
  {
    "code": 717,
    "text": "Quincy - Hannibal - Keokuk (DMA)"
  },
  {
    "code": 771,
    "text": "Yuma - El Centro (DMA)"
  },
  {
    "code": 756,
    "text": "Billings (DMA)"
  },
  {
    "code": 606,
    "text": "Dothan (DMA)"
  },
  {
    "code": 565,
    "text": "Elmira (DMA)"
  },
  {
    "code": 639,
    "text": "Jackson, TN (DMA)"
  },
  {
    "code": 643,
    "text": "Lake Charles (DMA)"
  },
  {
    "code": 644,
    "text": "Alexandria, LA (DMA)"
  },
  {
    "code": 764,
    "text": "Rapid City (DMA)"
  },
  {
    "code": 549,
    "text": "Watertown (DMA)"
  },
  {
    "code": 734,
    "text": "Jonesboro (DMA)"
  },
  {
    "code": 553,
    "text": "Marquette (DMA)"
  },
  {
    "code": 569,
    "text": "Harrisonburg (DMA)"
  },
  {
    "code": 647,
    "text": "Greenwood - Greenville (DMA)"
  },
  {
    "code": 736,
    "text": "Bowling Green (DMA)"
  },
  {
    "code": 711,
    "text": "Meridian (DMA)"
  },
  {
    "code": 558,
    "text": "Lima (DMA)"
  },
  {
    "code": 584,
    "text": "Charlottesville (DMA)"
  },
  {
    "code": 773,
    "text": "Grand Junction - Montrose (DMA)"
  },
  {
    "code": 749,
    "text": "Laredo (DMA)"
  },
  {
    "code": 755,
    "text": "Great Falls (DMA)"
  },
  {
    "code": 597,
    "text": "Parkersburg (DMA)"
  },
  {
    "code": 582,
    "text": "Lafayette, IN (DMA)"
  },
  {
    "code": 760,
    "text": "Twin Falls (DMA)"
  },
  {
    "code": 754,
    "text": "Butte - Bozeman (DMA)"
  },
  {
    "code": 802,
    "text": "Eureka (DMA)"
  },
  {
    "code": 759,
    "text": "Cheyenne - Scottsbluff (DMA)"
  },
  {
    "code": 821,
    "text": "Bend, OR (DMA)"
  },
  {
    "code": 661,
    "text": "San Angelo (DMA)"
  },
  {
    "code": 767,
    "text": "Casper - Riverton (DMA)"
  },
  {
    "code": 631,
    "text": "Ottumwa - Kirksville (DMA)"
  },
  {
    "code": 737,
    "text": "Mankato (DMA)"
  },
  {
    "code": 638,
    "text": "Saint Joseph (DMA)"
  },
  {
    "code": 596,
    "text": "Zanesville (DMA)"
  },
  {
    "code": 745,
    "text": "Fairbanks (DMA)"
  },
  {
    "code": 552,
    "text": "Presque Isle (DMA)"
  },
  {
    "code": 626,
    "text": "Victoria (DMA)"
  },
  {
    "code": 766,
    "text": "Helena (DMA)"
  },
  {
    "code": 747,
    "text": "Juneau (DMA)"
  },
  {
    "code": 583,
    "text": "Alpena (DMA)"
  },
  {
    "code": 740,
    "text": "North Platte (DMA)"
  },
  {
    "code": 798,
    "text": "Glendive (DMA)"
  }
];

const getTargetingList = (data, list) => {
  return list.reduce((accumulate, { type, key, value }) => {
    const typeKey = TargetingTypeKeys[type];
    const { ids = null, blacklist = false } = data[typeKey] || {};
    const included = ids ? ids.includes(key) : true;

    if (!accumulate[type]) {
      accumulate[type] = [];
    }

    accumulate[type].push({
      id: key,
      title: value,
      selected: blacklist ? !included : included,
    });

    return accumulate;
  }, []);
};

const getTargetingObject = (targeting) => {
  const { selected, data, isSelectedMinority } = getMinorityData(targeting);
  const list = data.map(data => data.title);

  return {
    selected,
    listCopy: joinWithAnd(list),
    includingCopy: isSelectedMinority ? 'Including' : 'Excluding',
  };
};

const formatStatus = (status) => {
  return status.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
}

export {
  getTargetingList,
  getTargetingObject,
  convertToTime,
  dmaData,
  forbiddenCharacters,
  formatDateData,
  formatDateTime,
  formatDecimal,
  formatDemographic,
  formatToUSD,
  valuetext,
  formatStatus
};
