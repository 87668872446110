import React from "react";
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { object, number, string } from 'yup';
import { defaults } from 'lodash';
import { Button, FormControlLabel, MenuItem, Stack, Switch, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFTextField } from '@components/RHFTextField';
import { DefaultValues, FormFields, costModels } from './constants';

const AdminForm = ({ data, onSubmit }) => {
  const {
    control,
    formState,
    handleSubmit,
    setError,
    reset,
  } = useForm({
    values: defaults(data, DefaultValues),
    resolver: yupResolver(
      object().shape({
        [FormFields.ADVERTISER_ID]: number("Must be a number type").transform((val, orig) => orig === "" ? undefined : val),
        [FormFields.BILLING_ID]: number("Must be a number type").transform((val, orig) => orig === "" ? undefined : val),
        [FormFields.COST_MODEL]: string().required("Cost Model is required"),
      })
    ),
    mode: 'onChange',
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const handleCancel = () => {
    reset(data);
  };

  return (
    <Stack
      component="form"
      onSubmit={handleSubmit((data) => onSubmit(data, setError))}
      sx={{
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      <Stack spacing={5.5}>
        <RHFTextField
          control={control}
          name={FormFields.ADVERTISER_ID}
          sx={{ width: '50%' }}
          label="NBCU Salesforce Advertiser ID"
          placeholder="Enter NBCU Salesforce Advertiser ID"
        />

        <RHFTextField
          control={control}
          name={FormFields.BILLING_ID}
          sx={{ width: '50%' }}
          label="NBCU Advertiser Billing ID"
          placeholder="Enter NBCU Advertiser Billing ID"
        />

        <RHFTextField
          select
          label="Cost Model"
          placeholder="Enter Cost Model"
          name={FormFields.COST_MODEL}
          control={control}
          sx={{ width: '50%' }}
        >
          {costModels.map(i => (
            <MenuItem key={i.value} value={i.value}>
              {i.label}
            </MenuItem>
          ))}
        </RHFTextField>

        <Controller
          name={FormFields.BILLING_APPROVED}
          control={control}
          render={({ field: { value, onChange } }) => (
            <FormControlLabel
              control={<Switch checked={value} onChange={onChange} size="small" />}
              label={
                <Typography variant="body2">
                  Billing Approved
                </Typography>
              }
            />
          )}
        />
      </Stack>

      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <Button
          disabled={formState.isSubmitting}
          onClick={handleCancel}
          disableElevation
          disableRipple
          type="reset"
        >
          Cancel
        </Button>

        <Button
          disabled={!formState.isValid || formState.isSubmitting}
          disableRipple
          variant="contained"
          size="large"
          type="submit"
        >
          Save Settings
        </Button>
      </Stack>
    </Stack>
  );
};

AdminForm.propTypes = {
  data: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default AdminForm;
