import React, { useEffect, useMemo, useState, useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Link,
  FormControl,
  InputAdornment,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TextField,
  Typography,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SearchIcon from '@mui/icons-material/Search';

import InviteUser from './InviteUser';
import LoadingSpinner from '../ui/LoadingSpinner';
import ManageUserAccount from './ManageUserAccount';
import RolesAndPrivileges from './RolesAndPrivileges';
import { StatusChip } from '../ui/StatusChip';
import { OrgChips } from '../OrgChips';
import { useAPI } from '../hooks/api';
import { useLoader } from '../hooks/loader';
import { UserAccountsContextProvider } from './UserAccountsContext';
import { usePermissions } from '../hooks';
import { OrgRoleLabelMap, Scopes } from '../../constants';
import AdvertiserContext from '../AdvertiserContext';

const PREFIX = 'UserAccounts';

const classes = {
  advertisers: `${PREFIX}-advertisers`,
  title: `${PREFIX}-title`,
  notFound: `${PREFIX}-notFound`,
  firstCell: `${PREFIX}-firstCell`,
  search: `${PREFIX}-search`,
  sub: `${PREFIX}-sub`
};

const StyledUserAccountsContextProvider = styled(UserAccountsContextProvider)(({
  theme: { palette, spacing }
}) => ({
  [`& .${classes.advertisers}`]: {
    maxWidth: 260,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },

  [`& .${classes.title}`]: {
    marginTop: -40,
    marginBottom: spacing(8),
    borderBottom: '1px solid #e5e7eb',
  },

  [`& .${classes.notFound}`]: {
    color: palette.grey.main,
  },

  [`& .${classes.firstCell}`]: {
    ['&.MuiTableCell-root']: {
      maxWidth: 240,
      minWidth: 205,
    }
  },

  [`& .${classes.sub}`]: {
    color: '#5c6c73',
    fontSize: '0.725rem',
  }
}));

const SearchTextField = styled(TextField)(() => ({
  '& .MuiInputBase-root': {
    fontSize: '0.825rem',
    borderRadius: '18px',
  },
}));

const LearnLink = styled(Link)(() => ({
  display: 'inline',
  verticalAlign: 'bottom',
}));

const UserAccounts = ({ org }) => {

  const { useGetAll } = useAPI();
  const { isLoading, setIsLoading } = useLoader();

  const [isInviteUser, setIsInviteUser] = useState(false);
  const [isViewRoles, setIsViewRoles] = useState(false);
  const [currentUserId, setCurrentUserId] = useState(null);
  const [users, setUsers] = useState([]);
  const [cachedUsers, setCachedUsers] = useState([]);
  const [search, setSearch] = useState('');
  const { hasPermission } = usePermissions();
  const adContext = useContext(AdvertiserContext);

  const allowInviteUser = hasPermission([Scopes.CAN_CREATE_USER])

  const isManaging = !!currentUserId;

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    setIsLoading(true);

    return useGetAll('/organization_users/management', [], items => {
      setUsers(items);
      setCachedUsers(items);
      setIsLoading(false);
    });
  };

  const currentUser = useMemo(() => {
    if (!currentUserId || !users) return null;

    return users.find(u => u.id === currentUserId);
  }, [currentUserId, users]);

  const handleCloseUser = async () => {
    setCurrentUserId(null);
    await fetchUsers();
  };

  const handleManageUser = usr => {
    setCurrentUserId(usr.id);
  };


  const handleUserChange = (userId, data) => {
    setUsers(users.map(u => u.id === userId ? { ...u, ...data } : u));
  }

  const handleSearchUsers = e => {
    const { value } = e.target;
    setSearch(value);

    if (!value) {
      setUsers(cachedUsers);
    }

    if (value) {
      const regex = new RegExp(value, 'i');

      const filteredUsers = cachedUsers.filter(user => {
        const fields = [
          `${user.user_first_name} ${user.user_last_name}`,
          user.user_first_name,
          user.user_last_name,
          user.user_email,
        ];

        return fields.some(field => regex.test(field));
      });

      setUsers(filteredUsers);
    }
  };

  const handleSaveUser = () => {

  };

  const handleDeleteUser = async () => {
    setCurrentUserId(null);

    await fetchUsers();
  }

  useEffect(() => {
    if (adContext && adContext.primary_org) {
      fetchUsers();
    }
  }, [adContext]);

  const renderTable = () => (
    <Box mt={6}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.firstCell}>Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Org Role</TableCell>
              <TableCell align="center">Ad Accounts</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="right">Action</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {users?.length > 0 && users.map(user => {
              const adAccountNames = (user?.advertisers || [])
                .map(a => a.ad_account)
                .filter(a => !!a)
                .join(', ');

              return (
                <TableRow hover key={user.id}>
                  <TableCell className={classes.firstCell} component="th" scope="row">
                    {user.user_first_name} {user.user_last_name}
                  </TableCell>

                  <TableCell align="center">
                    {user.user_email}
                  </TableCell>

                  <TableCell align="center">{OrgRoleLabelMap[user.role] || user.role}</TableCell>

                  <TableCell
                    title={adAccountNames}
                    className={classes.advertisers}
                    align="center"
                  >
                    {adAccountNames}
                  </TableCell>

                  <TableCell align="center">
                    <StatusChip status={user.status} />
                  </TableCell>

                  <TableCell align="right">
                    <Button
                      onClick={() => handleManageUser(user)}
                      color="secondary"
                    >
                      Manage
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>

        {!users || users.length === 0 && (
          <Box
            my={4}
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
          >
            <Typography className={classes.notFound}>
              No Users Found
            </Typography>
          </Box>
        )}
      </TableContainer>

      <Box mt={1} ml={1}>
        <Button
          color="secondary"
          size="small"
          startIcon={<AddCircleOutlineIcon fontSize="small" />}
          onClick={() => setIsInviteUser(true)}
          disabled={!allowInviteUser}
        >
          Invite User
        </Button>
      </Box>
    </Box>
  )

  return (
    <StyledUserAccountsContextProvider
      users={users}
      setUsers={setUsers}
      currentUser={currentUser}
      onUserChange={handleUserChange}
      toggleRoleAndPrivileges={() => setIsViewRoles(c => !c)}
    >
      {!isManaging && (
        <Box width="100%" height="100%" style={{ position: 'relative' }}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box width="30%">
              <FormControl
                variant="standard"
                size="small"
                fullWidth
              >
                <SearchTextField
                  name="search"
                  color="secondary"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className={classes.searchIcon} fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  placeholder="Filter users by name or email..."
                  value={search}
                  onChange={handleSearchUsers}
                  variant="outlined"
                />
              </FormControl>
            </Box>

            <Box>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box mr={2}>
                  Learn more about &nbsp;

                  <LearnLink
                    color="secondary"
                    component="button"
                    variant="body2"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsViewRoles(true);
                    }}
                    underline="hover">
                    User Roles & Privileges

                    <InfoOutlinedIcon color="secondary" fontSize="small" />
                  </LearnLink>
                </Box>

                <Box>
                  <OrgChips org={org} />
                </Box>
              </Box>
            </Box>
          </Box>

          <Box width="100%" height="100%">
            {isLoading && <LoadingSpinner />}
            {!isLoading && renderTable()}
          </Box>
        </Box>
      )}

      {isManaging && (
        <ManageUserAccount
          currentUser={currentUser}
          onCancel={handleCloseUser}
          onSubmit={handleSaveUser}
          org={org}
          onDelete={handleDeleteUser}
        />
      )}

      {isInviteUser && (
        <InviteUser
          onClose={() => setIsInviteUser(false)}
          onSuccess={() => {
            setIsInviteUser(false);
            fetchUsers();
          }}
          onViewRoles={() => {
            setIsViewRoles(true);
          }}
        />
      )}

      {isViewRoles && (
        <RolesAndPrivileges
          onClose={() => setIsViewRoles(false)}
          onSuccess={() => { }}
        />
      )}
    </StyledUserAccountsContextProvider>
  );
};

UserAccounts.propTypes = {
  org: PropTypes.object,
};


export default UserAccounts;
