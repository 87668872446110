import useSWR from 'swr';
import { useCurrentSession } from '../currentSession';

const base = '/targetingkeys';

export const useTargetingKeys = ({ type, params = {} }) => {
  const { disabled, ...otherParams } = params;
  const { get, apiIsReady } = useCurrentSession();
  const fetcher = ({ url, params }) =>
    get(url, params).then(res => {
      return res.data;
    });

  const swr = useSWR(
    apiIsReady && type && !disabled
      ? {
          url: `${base}/${type}/`,
          params: otherParams,
        }
      : null,
    fetcher,
    { suspense: false },
  );

  const { data, error, isLoading } = swr;

  return {
    data,
    count: data?.length,
    error,
    isLoading,
  };
};
