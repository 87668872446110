import { Themes } from '@constants/themes';
import { values } from 'lodash';
import { createContext } from 'react';

export const ChipPopperContext = createContext<{
  focusedIndex?: number;
  focus: (index: number | undefined) => void;
  focusPrev: () => void;
  focusNext: () => void;
  focusStale: () => void;
}>({
  focusedIndex: undefined,
  focus: () => {},
  focusPrev: () => {},
  focusNext: () => {},
  focusStale: () => {},
});

export const distinctNames = {
  place: 'cities',
  region: 'states',
  country: 'country',
  countries: 'countries',
  dma: 'dmas',
  dmas: 'dmas',
  zip: 'zipcodes',
  zipcodes: 'zipcodes',
  postcode: 'zipcodes',
  empty: 'empty',
} as const;

export const distinctGroupsNames = {
  [distinctNames.place]: 'Cities',
  [distinctNames.region]: 'States',
  [distinctNames.country]: 'Countries',
  [distinctNames.countries]: 'Countries',
  [distinctNames.dmas]: 'DMAs',
  [distinctNames.zipcodes]: 'Zip Codes',
  [distinctNames.empty]: '',
} as const;

export const mbToken = process.env.REACT_APP_MAPBOX_TOKEN;
export const geoBaseUrl = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
// TODO: Use v6 of the API if we can solve the CORS error
// const geoBaseUrlV2 = 'https://api.mapbox.com/search/geocode/v6/forward';

export const geoTypes = {
  country: 'country',
  region: 'region',
  postcode: 'postcode',
  place: 'place',
  dma: 'dma',
} as const;

export const geoTypesToDistinct = {
  [geoTypes.country]: distinctNames.country,
  [geoTypes.region]: distinctNames.region,
  [geoTypes.postcode]: distinctNames.zipcodes,
  [geoTypes.place]: distinctNames.place,
  [geoTypes.dma]: distinctNames.dmas,
} as const;

export const distinctToGeoType = {
  [distinctNames.place]: geoTypes.place,
  [distinctNames.region]: geoTypes.region,
  [distinctNames.country]: geoTypes.country,
  [distinctNames.countries]: geoTypes.country,
  [distinctNames.dmas]: geoTypes.dma,
  [distinctNames.zipcodes]: geoTypes.postcode,
  [distinctNames.empty]: '',
} as const;

export const geoSearchDefaultParams = {
  access_token: mbToken,
  autocomplete: true,
  country: 'us',
  types: values(geoTypes),
  // TODO: if we use v6 of the mapbox api, we need to include the q param here
  // q: search,
};

export const Copies = {
  [Themes.DEFAULT]: {
    upload: {
      [distinctNames.zipcodes]: 'Upload Zip Codes',
      [distinctNames.place]: 'Upload Cities',
      [distinctNames.dmas]: 'Upload DMAs',
      [distinctNames.region]: 'Upload States',
    },
  },
  [Themes.NBCU]: {
    upload: {
      [distinctNames.zipcodes]: 'Upload Zip Codes',
      [distinctNames.place]: 'Upload Cities',
      [distinctNames.dmas]: 'Upload DMAs',
      [distinctNames.region]: 'Upload States',
    },
  },
};
