import { useGetInventoryAvailability } from '@apis/targetingSeach';
import { useEffect, useMemo } from 'react';
import { transformToTargeting } from '../utils';
import { targetingFields } from '../constants';
import { fields } from '../formConfig';
import { useGetAllowedTargeting } from '@components/hooks/apis/allowedTargeting';
import { isEmpty, reduce, values } from 'lodash';
import moment from 'moment';

export const useInventoryAvailability = ({
  form,
  adGroup,
  rewriteFields = {},
}) => {
  const { data: allowedTargeting } = useGetAllowedTargeting();
  const [budget, start_date, end_date, ...targeting] = form.watch([
    fields.adGroupBudget.path,
    fields.startDate.path,
    fields.endDate.path,
    ...targetingFields,
  ]);

  const allFields = useMemo(
    () => ({
      budget,
      start_date,
      end_date,
      ...reduce(
        targetingFields,
        (acc, next, idx) => ({
          ...acc,
          [next]: targeting[idx],
        }),
        {},
      ),
      ...rewriteFields,
    }),
    [budget, start_date, end_date, ...targeting, ...values(rewriteFields)],
  );

  const { data, trigger, isMutating } = useGetInventoryAvailability();

  useEffect(() => {
    if (!isMutating) {
      trigger({
        budget: isEmpty(allFields.budget) ? '0' : allFields.budget,
        start_date: moment(allFields.start_date).format('YYYY-MM-DD'),
        end_date: allFields.end_date
          ? moment(allFields.end_date).format('YYYY-MM-DD')
          : moment(allFields.start_date).endOf('month').format('YYYY-MM-DD'),
        targeting: JSON.parse(
          transformToTargeting(allFields, {
            allowedTargeting,
            adGroup,
          }),
        ),
      });
    }
  }, [allFields, allowedTargeting, adGroup]);

  const [availability, type] = data ?? [0, ''];

  return {
    availability,
    type,
  };
};
