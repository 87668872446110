import React, { Suspense, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import AccountManage from './AccountManage';
import BillingAccountsTable from './BillingAccountsTable';
import AdvertiserContext from '../AdvertiserContext';
import { useAPI } from '../hooks/api';
import { OrgChips } from '../OrgChips';

const BillingAccounts = ({ org }) => {
  const { useGet } = useAPI();

  const adContext = useContext(AdvertiserContext);
  const [currentAccount, setCurrentAccount] = useState(null);
  const isManaging = !!currentAccount;

  const [billingAccounts, setBillingAccounts] = useState([])

  useEffect(() => {
    if (!adContext?.primary_org) return;
    getBillingAccounts();
  }, [adContext?.primary_org])

  const getBillingAccounts = async () => {
    try {
      const response = await useGet('/billing_accounts')
      setBillingAccounts(response.results)
    } catch (e) {
      console.log(e)
    }
  }

  const handleManageBillingAccount = acct => {
    setCurrentAccount(acct);
  }

  const handleCloseBillingAccount = () => {
    setCurrentAccount(null);
    getBillingAccounts();
  }

  return (
    <Suspense fallback={<div>loading...</div>}>
      {isManaging && (
        <AccountManage
          org={org}
          currentAccount={currentAccount}
          onClose={handleCloseBillingAccount}
        />
      )}

      {!isManaging && (
        <Box display="flex" flexDirection="column" height="100%">
          <OrgChips org={org} />
          <BillingAccountsTable
            billingAccounts={billingAccounts}
            handleManageBillingAccount={handleManageBillingAccount}
            handleAddBillingAccount={() => setCurrentAccount({})}
          />
        </Box>
      )}
    </Suspense>
  )
}

BillingAccounts.propTypes = {
  org: PropTypes.object.isRequired,
}

export default BillingAccounts;
