import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import { GrantAccessDialog } from './GrantAccessModal';
import { useUserAccountsContext } from '../UserAccountsContext';
import { useAPI } from '../../hooks/api';
import { AdvertiserUserRoleSelect } from '../../RHFAdvertiserUserRoleSelect';
import { usePermissions } from '../../hooks';
import { Scopes } from '../../../constants';

const PREFIX = 'UserAdAccounts';

const classes = {
  box: `${PREFIX}-box`,
  roles: `${PREFIX}-roles`
};

const StyledBox = styled(Box)(() => ({
  [`&.${classes.box}`]: {
    height: 'auto'
  },

  [`& .${classes.roles}`]: {
    '& .MuiInput-underline:before, & .MuiInput-underline:after': {
      borderBottom: 'none',
    },

    '& .MuiInput-underline:hover:before': {
      borderBottom: 'none',
    },

    '& .MuiListItemIcon-root': {
      display: 'none',
    }
  }
}));

const UserAdAccounts = () => {
  const { currentUser } = useUserAccountsContext();
  const { useGet, usePatch } = useAPI();

  const [users, setUsers] = useState([]);
  const [showGrantAccessModal, setShowGrantAccessModal] = useState(false);
  const [usersRole, setUsersRole] = useState({});
  const { hasPermission } = usePermissions();
  const allowGrantAccess = hasPermission([Scopes.CAN_UPDATE_ADVERTISER])

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    useGet('/advertiser_users/for_org/').then(items => {
      setUsers(items.filter(u => u.user === currentUser.user));
    });
  };

  useEffect(() => {
    if (!users) return;

    const entries = users.map(u => [u.id, u.role]);
    setUsersRole(Object.fromEntries(entries));
  }, [users]);

  const visibleUsers = useMemo(() => {
    if (!users) return [];

    return users.filter(u => u.status === 'ACTIVE');
  }, [users]);

  const handleChangeRole = async (event, user) => {
    const { value } = event.target;

    try {

      if (value === 'REMOVED') {
        await usePatch(`/advertiser_users/${user.id}/`, {
          status: value,
        });
      } else {
        await usePatch(`/advertiser_users/${user.id}/`, {
          role: value,
          status: 'ACTIVE',
        })

        setUsersRole(prev => ({
          ...prev,
          [user.id]: value,
        }));
      }

      fetchUsers();
    } catch (err) {
      console.error('Error updating ad account user role', err);
      throw err;
    }
  };

  return (
    <StyledBox className={classes.box}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Ad Account</TableCell>
              <TableCell align="center">Advertiser</TableCell>
              <TableCell align="right">Ad Account Role</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {visibleUsers.map(user => (
              <TableRow hover key={user.id}>
                <TableCell component="th" scope="row">
                  {user.ad_account_name}
                </TableCell>

                <TableCell align="left">{user.advertiser_name}</TableCell>

                <TableCell align="right">
                  <AdvertiserUserRoleSelect
                    value={usersRole[user.id] || user.role}
                    onChange={(e) => handleChangeRole(e, user)}
                    allowDeleted
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Box mt={1} ml={1}>
        <Button
          color="secondary"
          size="small"
          startIcon={<AddCircleOutlineIcon fontSize="small" />}
          onClick={() => setShowGrantAccessModal(true)}
          disabled={!allowGrantAccess}
        >
          Grant Access to an Ad Account
        </Button>
      </Box>
      {showGrantAccessModal && (
        <GrantAccessDialog
          users={users}
          onClose={() => setShowGrantAccessModal(false)}
          onAdded={() => {
            setShowGrantAccessModal(false);
            fetchUsers();
          }}
        />
      )}
    </StyledBox>
  );
};

UserAdAccounts.propTypes = {
  org: PropTypes.object,
  user: PropTypes.object,
};


export default UserAdAccounts;
