import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import { Box, Button, Stack, Typography, styled } from '@mui/material';

import { CloudFileUploadIcon } from '@v2/components/icons/CloudFileUploadIcon';
import { FileTypeDetails } from './constants';

const StyledDropzone = styled(Box, {
  shouldForwardProp: prop => prop !== 'isDragActive',
})(({ theme, isDragActive }) => ({
  border: `3px dashed ${isDragActive ? theme.palette.primary.main : theme.palette.grey[9]}`,
  backgroundColor: isDragActive
    ? theme.palette.blue[11]
    : theme.palette.background.paper,
  borderRadius: 5,
  padding: theme.spacing(5),
  textAlign: 'center',
  cursor: 'pointer',
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

// Define the StyledButton component
const StyledButton = styled(Button)(({ theme }) => ({
  boxShadow: 'none',
  padding: `${theme.spacing(1.625)} ${theme.spacing(4.375)} !important`,
  justifyContent: 'center',
  fontSize: '18px',
  fontWeight: 700,
  lineHeight: '18px',
  '&:hover': {
    boxShadow: 'none',
  },
}));

const FileDropzone = ({ type, onChange, children, ...props }) => {
  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept: FileTypeDetails.FileDropzone[type].accept,
    onDrop: onChange,
  });

  return (
    <StyledDropzone
      {...props}
      isDragActive={isDragActive}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Stack alignItems="center" gap={4}>
        {children}
        <CloudFileUploadIcon sx={{ width: '84px', height: '65px' }} />
      </Stack>
      <StyledTypography variant="h3" sx={{ mt: 4, fontSize: '1.2rem' }}>
        {FileTypeDetails.FileDropzone[type].DESCRIPTION}
      </StyledTypography>
      <StyledButton
        variant="outlined"
        sx={{ mt: 3 }}
      >
        Select Files
      </StyledButton>
      <Typography variant="body2" sx={{ mt: 5, color: 'grey.6' }}>
        {FileTypeDetails.FileDropzone[type].specifications}
      </Typography>
    </StyledDropzone>
  );
};

// PropType validation
FileDropzone.propTypes = {
  ...Box.propTypes,
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default FileDropzone;
