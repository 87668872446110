import { TextField, styled } from '@mui/material';

export const TextFieldStyled = styled(TextField)(
  ({ theme }) => ({
    ['& .MuiInputLabel-root:not(.MuiFormLabel-filled)']: {
      color: theme.palette.grey[5],
    },
    ['& .MuiSelect-select']: {
      color: theme.palette.text.primary,
    },
  }),
);
