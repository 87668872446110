import React, { useContext, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import BuildOutlinedIcon from '@mui/icons-material/BuildOutlined';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';
import { Alert, Box, Button, IconButton } from '@mui/material';
import { CreativeQCFailedInfo, CreativeQCFormattingIssuesInfo } from './modals';
import { qcStatuses } from '../constants';
import ModalContext from '../providers/ModalContext';

const PREFIX = 'CreativeQCAlerts';

const classes = {
  underlineButton: `${PREFIX}-underlineButton`
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.underlineButton}`]: {
    textDecoration: 'underline',
  }
}));

export const CreativeQCAlerts = ({ email, items }) => {
  const { setModal } = useContext(ModalContext);

  const formattingIssueCreatives = useMemo(
    () => items?.filter(
      ({ qc = {} }) => qc.status === qcStatuses.PROCESSING
    ) ?? [],
    [items],
  );

  const failedCreatives = useMemo(
    () => items?.filter(
      ({ qc = {} }) => qc.status === qcStatuses.FAILED
    ) ?? [],
    [items],
  );

  const handleFormattingIssueMoreInfoClick = (event) => {
    event.preventDefault();

    setModal({
      isOpen: true,
      data: {
        email,
        items: formattingIssueCreatives.map(creative => ({
          name: creative.name,
          messages: creative.qc.messages,
        })),
      },
      component: CreativeQCFormattingIssuesInfo,
    });
  };

  const handleFailedMoreInfoClick = (event) => {
    event.preventDefault();

    setModal({
      isOpen: true,
      data: {
        email,
        items: failedCreatives.map(creative => ({
          name: creative.name,
          messages: creative.qc.messages,
        })),
      },
      component: CreativeQCFailedInfo,
    });
  };

  if (formattingIssueCreatives.length === 0 && failedCreatives.length === 0) {
    return null;
  }

  return (
    <StyledBox display="flex" flexDirection="column" gap="15px" m={4} mb={2}>
      {formattingIssueCreatives.length > 0 && (
        <Alert
          icon={<BuildOutlinedIcon fontSize="inherit" />}
          severity="info"
          data-testid="formatting-issue-alert"
          action={
            <Box display="inline-flex" gap="16px">
              <Button
                size="small"
                color="inherit"
                className={classes.underlineButton}
                onClick={handleFormattingIssueMoreInfoClick}
              >
                More info
              </Button>
              <IconButton aria-label="close" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          }
        >
          <strong>{formattingIssueCreatives.length} video(s) have formatting issues that are being fixed</strong> – This may take up to 24 hours
        </Alert>
      )}

      {failedCreatives.length > 0 && (
        <Alert
          icon={<ReportProblemOutlinedIcon fontSize="inherit" />}
          severity="error"
          data-testid="failed-alert"
          action={
            <Box display="inline-flex" gap="16px">
              <Button
                size="small"
                color="inherit"
                className={classes.underlineButton}
                onClick={handleFailedMoreInfoClick}
              >
                More info
              </Button>
              <IconButton aria-label="close" size="small">
                <CloseIcon fontSize="inherit" />
              </IconButton>
            </Box>
          }
        >
          <strong>{failedCreatives.length} video(s) needs to be replaced</strong> – This video(s) have issues that our creative experts can not fix
        </Alert>
      )}
    </StyledBox>
  );
};

CreativeQCAlerts.propTypes = {
  email: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};
