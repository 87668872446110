import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider as DefaultSnackbarProvider } from 'notistack';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CopySnackbar } from '../components/snackbars';
import {
  ErrorSnackbar,
  SuccessSnackbar,
  WarningIcon,
  WarningSnackbar
} from '../components/styledComponents/snackbars';

export const SnackbarProvider = (({ children }) => {
  const notistackRef = useRef(null);

  const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
  }

  return (
    <DefaultSnackbarProvider
      ref={notistackRef}
      Components={{
        copySnackbar: CopySnackbar,
        variantWarning: WarningSnackbar,
        variantError: ErrorSnackbar,
        variantSuccess: SuccessSnackbar,
      }}
      action={(key) => (
        <IconButton
          className="notistack-action"
          onClick={onClickDismiss(key)}
          size="small"
        >
          <CloseIcon className="snackbar-close" fontSize="small" />
        </IconButton>
      )}
      iconVariant={{
        warning: <WarningIcon fontSize="small" />,
      }}
    >
      {children}
    </DefaultSnackbarProvider>
  );
});

SnackbarProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
