import { styled, Tooltip } from '@mui/material';

export const OutOfSyncTooltip = styled(Tooltip)(({ theme: { palette, spacing } }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: `#ECF0F2`,
    color: palette.text.primary,
    padding: spacing(1.5),
    boxShadow: '0px 2px 5px 0px rgba(0, 0, 0, 0.25)',
    maxWidth: 340,
  },

  '& .MuiTooltip-arrow': {
    color: `#ECF0F2`,
    '&::before': {
      filter: `drop-shadow(-1px -1px 1px rgba(0, 0, 0, 0.05))`,
    },
  },
}));
