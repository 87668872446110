import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import React from "react";
import PropTypes from "prop-types";
import CloseIcon from '@mui/icons-material/Close';

const PREFIX = 'UserDeleteDialog';

const classes = {
  actions: `${PREFIX}-actions`,
  red: `${PREFIX}-red`,
  title: `${PREFIX}-title`
};

const StyledDialog = styled(Dialog)(({
  theme: { spacing, palette }
}) => ({
  [`& .${classes.actions}`]: {
    gap: spacing(2),
    padding: spacing(1, 3, 3, 3),
  },

  [`& .${classes.red}`]: {
    color: '#fff',
    backgroundColor: palette.error.main,
    '&:hover': {
      backgroundColor: palette.error.dark,
    }
  },

  [`& .${classes.title}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: spacing(2, 3),
    borderBottom: `1px solid ${palette.divider}`,
    marginBottom: spacing(2),
    '& .MuiDialogTitle-root': {
      padding: 0,
    }
  }
}));

export const UserDeleteDialog = ({
  userName,
  orgName,
  onClose,
  onDelete
}) => {


  return (
    <StyledDialog open>
      <div className={classes.title}>
        <DialogTitle>
          Remove User
        </DialogTitle>
        <IconButton onClick={onClose} size="small">
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent>
        <Typography>
         Are you sure that you want to DEACTIVATE  <b>{userName}</b> from <b>{orgName}</b>?
         This will completely eliminate their ability to sign into the account.
         If you do this by mistake, you will need to Invite them back to the Organization.
        </Typography>
      </DialogContent>
      <DialogActions className={classes.actions}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" className={classes.red} onClick={onDelete}>
          {"I'm sure"}
        </Button>
      </DialogActions>
    </StyledDialog>
  );
}

UserDeleteDialog.propTypes = {
  userName: PropTypes.string.isRequired,
  orgName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
}
