export const NodeLabels = {
  CAMPAIGN_NAME_DATES: 'Campaign Name & Dates',
  CAMPAIGN_BUDGET: 'Campaign Budget',
  CAMPAIGN_OBJECTIVE: 'Campaign Objective',
  AD_GROUP_NAME_DATES: 'Ad Group Name & Dates',
  AD_GROUP_BUDGET: 'Ad Group Budget',
  AD_GROUP_BID_STRATEGY: 'Bid Strategy',
  AD_GROUP_GEOGRAPHY: 'Geography',
  AD_GROUP_AUDIENCE: 'Audience',
  AD_GROUP_INVENTORY: 'Inventory',
  AD_GROUP_CREATIVE: 'Creative',
  REVIEW_CAMPAIGN: 'Review Campaign',
};

export const entityStatus = {
  active: 1,
  paused: 2,
  draft: 0,
  pending: 3,
};

export const nodeKeys = {
  adGroupAudience: 'audience',
  adGroupBidStrategy: 'bid-strategy',
  adGroupBudget: 'budget',
  adGroupCreative: 'creative',
  adGroupGeography: 'geography',
  adGroupInventory: 'inventory',
  adGroupNameDates: 'adgroup-name-dates',
  addAdGroup: 'add-ad-group',
  campaignBudget: 'budget',
  campaignNameDates: 'name-dates',
  campaignObjective: 'objective',
  review: 'review',
};

export const sectionNames = {
  adGroup: 'ad-group',
  addAdGroup: 'add-ad-group',
  campaign: 'campaign',
  reviewAndLaunch: 'review-launch',
};

export const SCROLL_DELAY = 500;

export const editingStates = {
  single: 'single',
};

export const wizardGeneralErrorFields = ['non_field_errors'];
