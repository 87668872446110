import { styled } from '@mui/material/styles';

export const ErrorSnackbar = styled('div')(() => ({
  backgroundColor: '#f76a6a',
  color: '#fff',
  fontSize: '0.875rem',
  fontWeight: 400,
  letterSpacing: '.35px',
  maxWidth: 420,
}));
