import {
  Box,
  Button,
  Collapse,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  Switch,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { RHFTextField } from '../../RHFTextField';
import { categories as sensitiveCategories } from '../../../constants/sensitiveCategories';
import { useAPI } from '../../hooks/api';
import {
  formValuesToApi,
  getInitialValues,
  validationSchema,
} from './formConfig';
import { yupResolver } from '@hookform/resolvers/yup';
import { RHFCheckbox } from '../../RHFCheckbox';
import { useDomain } from '@hooks/domain';

const PREFIX = 'GeneralInfoForm';

const classes = {
  textfield: `${PREFIX}-textfield`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
  successMsg: `${PREFIX}-successMsg`
};

const Root = styled('form')(({
  theme: { spacing, typography, palette }
}) => ({
  [`& .${classes.textfield}`]: {
    marginBottom: spacing(3),
  },

  [`&.${classes.form}`]: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },

  [`& .${classes.submit}`]: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },

  [`& .${classes.successMsg}`]: {
    color: palette.primary.light,
  }
}));

export const GeneralInfoForm = ({
  advertiser,
  setAdvertiser,
  categories,
  billingAccounts,
  onClose,
}) => {

  const { usePatch } = useAPI();
  const domain = useDomain();

  const [status, setStatus] = useState('');
  const isLoading = status === 'loading';
  const isSuccess = status === 'success';

  const displaySalesforceFields = domain.peacock;
  const resolver = useMemo(() => yupResolver(validationSchema({ displaySalesforceFields })), [displaySalesforceFields])

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: getInitialValues(advertiser),
    resolver,
  });

  useEffect(() => {
    reset(getInitialValues(advertiser));
  }, [advertiser]);

  const isUncapped = watch('isUncapped');

  const onSubmit = handleSubmit(async values => {
    setStatus('loading');

    try {
      const { data } = await usePatch(
        `/advertisers/${advertiser.id}/`,
        formValuesToApi(values)
      );

      setStatus('success');
      setAdvertiser(data);
    } catch (error) {
      setStatus('error');
    }
  });

  return (
    <Root
      className={classes.form}
      onSubmit={onSubmit}
      noValidate
      onReset={() => reset(getInitialValues(advertiser))}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        justifySelf="flex-start"
      >
        <Box width="40%" display="flex" flexDirection="column">
          <RHFTextField
            className={classes.textfield}
            label="Ad Account Name"
            name="name"
            control={control}
          />

          <RHFTextField
            className={classes.textfield}
            label="Advertiser Name"
            name="advertiserName"
            control={control}
          />

          <RHFTextField
            className={classes.textfield}
            label="Advertiser Website Address"
            name="website"
            control={control}
          />

          <RHFTextField
            select
            className={classes.textfield}
            label="Advertiser Category"
            name="category"
            control={control}
            loading={!categories.length}
          >
            {categories.map(c => (
              <MenuItem key={c.id} value={c.url}>
                {c.name}
              </MenuItem>
            ))}
          </RHFTextField>

          <RHFTextField
            select
            className={classes.textfield}
            label="Sensitive Category"
            name="sensitive"
            control={control}
          >
            {sensitiveCategories.map(s => (
              <MenuItem key={s.value} value={s.value}>
                {s.label}
              </MenuItem>
            ))}
          </RHFTextField>
        </Box>

        <Box width="40%" display="flex" flexDirection="column">
          <RHFTextField
            className={classes.textfield}
            label="Billing Account"
            name="billingAccount"
            control={control}
            select
            loading={!billingAccounts.length && isLoading}
          >
            {billingAccounts.map(a => (
              <MenuItem key={a.id} value={a.url}>
                {a.name}
              </MenuItem>
            ))}
          </RHFTextField>

          <Box mb={3}>
            <Controller
              name="isUncapped"
              control={control}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={<Switch checked={value} onChange={() => onChange(!value)} size="small" />}
                  label={
                    <Typography variant="body2">
                      Uncapped Ad Account Budget
                    </Typography>
                  }
                />
              )}
            />
          </Box>

          <Collapse in={!isUncapped}>
            <RHFTextField
              fullWidth
              className={classes.textfield}
              label="Ad Account Budget"
              name="budget"
              control={control}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Collapse>

          <RHFTextField
            className={classes.textfield}
            label="Purchase Order Reference (optional)"
            name="purchaseOrder"
            control={control}
          />

          {displaySalesforceFields && (
            <>
              <RHFTextField
                label="Salesforce Advertiser ID"
                placeholder="Enter Salesforce Advertiser ID"
                name="salesforceAdvertiserId"
                control={control}
                className={classes.textfield}
              />

              <RHFTextField
                label="Salesforce Agency ID"
                placeholder="Enter Salesforce Agency ID"
                name="salesforceAgencyId"
                control={control}
                className={classes.textfield}
              />
            </>
          )}


          <Box display="flex">
            <Box mr={4}>
              <Typography variant="h4">Currency</Typography>
              <Typography>{advertiser?.currency}</Typography>
            </Box>

            <Box>
              <Typography variant="h4">Timezone</Typography>
              <Typography>{advertiser?.timezone}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        width="100%"
      >
        {isSuccess && (
          <Box mr={4}>
            <Typography component={'span'} className={classes.successMsg}>
              Your Settings have been saved!
            </Typography>
          </Box>
        )}

        <Box width="35%" minWidth={410} mr={6}>
          <RHFCheckbox
            control={control}
            name="understand"
            label={
              <Typography variant="body2">
                I understand that failure to disclose sensitive category may
                result in removal from the tvScientific platform
              </Typography>
            }
          />
        </Box>

        <Button
          data-testid="manage-cancel-btn"
          disabled={isLoading}
          disableElevation
          disableRipple
          type="reset"
          onClick={onClose}
        >
          Cancel
        </Button>

        <Button
          data-testid="manage-submit-btn"
          className={classes.submit}
          disableRipple
          color="secondary"
          variant="contained"
          type="submit"
        >
          {isLoading ? 'Saving...' : 'Save Settings'}
        </Button>
      </Box>
    </Root>
  );
};

GeneralInfoForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  advertiser: PropTypes.object.isRequired,
  setAdvertiser: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  categories: PropTypes.array,
  billingAccounts: PropTypes.array,
};
