import React from 'react';
import { Controller } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import { Stack, TextField, styled } from '@mui/material';
import { omit } from 'lodash';
import { ErrorMessage } from '../ErrorMessage';

const TextFieldStyled = styled(TextField, {
  shouldForwardProp: prop => prop !== 'type',
})({
  '& textarea': {
    resize: 'vertical',
  },
});

export const EditField = ({
  control,
  name,
  warning,
  error,
  inputProps,
  ...props
}) => {
  return (
    <Stack spacing={1} {...props}>
      <Controller
        control={control}
        name={name}
        render={({ field: { ...fieldProps } }) => (
          <TextFieldStyled {...omit(fieldProps, 'ref')} {...inputProps} />
        )}
      />
      {!!warning && <ErrorMessage>{warning}</ErrorMessage>}
      {!!error && <ErrorMessage type="error">{error}</ErrorMessage>}
    </Stack>
  );
};

EditField.propTypes = {
  ...Stack.propTypes,
  inputProps: PropTypes.shape(TextField.propTypes),
  type: PropTypes.oneOf(['text', 'url']),
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  warning: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
};
