import React from 'react';
import IncludeExcludeToggle from './IncludeExcludeToggle';
import SelectedSegmentItem from '@v2/components/ui/SelectedSegmentItem';
import { GeographyPreviewSegmentProps } from './types';

export const GeographyPreviewSegment = ({
  blacklist,
  label,
  onEdit,
  onChange,
  placeholder,
}: GeographyPreviewSegmentProps) => (
  <SelectedSegmentItem
    icon={
      <IncludeExcludeToggle
        value={blacklist ? 'exclude' : 'include'}
        onChange={type => {
          onChange({
            blacklist: type === 'exclude',
          });
        }}
      />
    }
    label={label}
    onEdit={onEdit}
    placeholderText={placeholder}
  />
);
