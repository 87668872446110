import { styled } from '@mui/material';
import { Link as DefaultLink } from 'react-router-dom';

const Link = styled(DefaultLink)(() => ({
  fontWeight: '700',
  whiteSpace: 'normal',
  wordBreak: 'break-word'
}));

export default Link;
