const USER_GETS_VERTICAL_CAMPAIGN = 'USER_GETS_VERTICAL_CAMPAIGN';
const USER_GETS_VERTICAL_CAMPAIGN_DRAGS = 'USER_GETS_VERTICAL_CAMPAIGN_DRAGS';
const USER_GETS_NBCU_BIZMAN_UI = 'USER_GETS_NBCU_BIZMAN_UI';
const USER_GETS_VERTICAL_INVENTORY_AVAILABILITY =
  'USER_GETS_VERTICAL_INVENTORY_AVAILABILITY';
const USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS =
  'USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS';
const SYNC_CHECKS_ENABLED = 'SYNC_CHECKS_ENABLED';
const USER_CAN_ARCHIVE = 'USER_CAN_ARCHIVE';
const HAS_GOOGLE_TAG_MANAGER = 'HAS_GOOGLE_TAG_MANAGER';
const USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS =
  'USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS';

export const Flags = {
  USER_GETS_VERTICAL_CAMPAIGN,
  USER_GETS_VERTICAL_CAMPAIGN_DRAGS,
  USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS,
  USER_GETS_NBCU_BIZMAN_UI,
  USER_GETS_VERTICAL_INVENTORY_AVAILABILITY,
  SYNC_CHECKS_ENABLED,
  USER_CAN_ARCHIVE,
  HAS_GOOGLE_TAG_MANAGER,
  USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS,
};

export const DefaultFlags = {
  [USER_GETS_VERTICAL_CAMPAIGN]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_DRAGS]: false,
  [USER_GETS_NBCU_BIZMAN_UI]: false,
  [USER_GETS_VERTICAL_INVENTORY_AVAILABILITY]: false,
  [USER_GETS_VERTICAL_CAMPAIGN_OBJECTIVE_GOALS]: false,
  [SYNC_CHECKS_ENABLED]: false,
  [USER_CAN_ARCHIVE]: false,
  [HAS_GOOGLE_TAG_MANAGER]: false,
  [USER_GETS_VERTICAL_INVENTORY_RECOMMENDATIONS]: false,
};
