import { useEffect, useMemo, useRef } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useCreativeUploadContext } from '../../hooks/useCreativeUploadContext';
import { fields as manageFields } from '../constants';
import { fields } from '../../constants';

export const useManageListElement = ({ field, onUpdate, index }) => {
  const prevFieldsData = useRef();
  const {
    control,
    formState: { errors },
  } = useFormContext();

  const { getAdditionalCreativeInfo } = useCreativeUploadContext();

  const trackingPixels = useFieldArray({
    control,
    name: `${fields.draftCreatives.path}.${index}.${manageFields.impression_tracking_pixel.path}`,
  });

  const { id } = field;

  useEffect(() => {
    prevFieldsData.current = field;
  }, [field]);

  useEffect(() => {
    const { weighting: savedWeighting } = prevFieldsData.current;

    getAdditionalCreativeInfo?.({ id }).then(
      ({ id: creative_id, lineitem, weighting } = {}) => {
        onUpdate({
          creative_id,
          weighting: weighting ?? savedWeighting,
          lineitem,
        });
      },
    );
  }, [id]);

  const showWarningGeneral = useMemo(
    () =>
      field.lineitem_set?.length > 1 ||
      field.lineitem_set?.[0]?.id !== field.lineitem,
    [field],
  );

  const showWarning = {
    clickUrl: showWarningGeneral,
    trackingPixel: showWarningGeneral,
  };

  return {
    trackingPixels,
    showWarning,
    field,
    control,
    errors,
  };
};
