import React, { useState, useRef, useCallback } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { areEqual } from 'react-window';

import {
  ButtonBase,
  Fade,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Menu,
  Paper,
} from '@mui/material';

import ClearIcon from '@mui/icons-material/Clear';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import excludeIcon from '../../images/icons/exclude-location-icon.svg';
import includeIcon from '../../images/icons/include-location-icon.svg';

const PREFIX = 'InnerGeoTargetingRow';

const classes = {
  dropdownArrow: `${PREFIX}-dropdownArrow`,
  filter: `${PREFIX}-filter`,
  filterIconWrap: `${PREFIX}-filterIconWrap`,
  filterImg: `${PREFIX}-filterImg`,
  filterLabel: `${PREFIX}-filterLabel`,
  locations: `${PREFIX}-locations`,
  result: `${PREFIX}-result`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.dropdownArrow}`]: {
    color: '#727383',
  },

  [`& .${classes.filter}`]: {
    cursor: 'pointer',
  },

  [`& .${classes.filterIconWrap}`]: {
    width: 16,
    ['& img']: {
      width: '100%',
    },
  },

  [`& .${classes.filterImg}`]: {
    transition: 'all',
  },

  [`& .${classes.filterLabel}`]: {
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.locations}`]: {
    backgroundColor: `#e5e7eb`,
    padding: `0 1.125rem`,
    width: `100%`,
  },

  [`& .${classes.result}`]: {
    marginBottom: theme.spacing(1),
  }
}));

const InnerGeoTargetingRow = React.memo(
  ({
    target,
    index,
    style,
    copy,
    handleDeleteTarget,
    handleToggleTargetExcluded,
  }) => {

    const menuAnchorRef = useRef();
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const name = target.place_name || target.name || target.text;
    const nameCopy = name.replace('US', copy.US);

    const openMenu = useCallback(() => {
      setMenuIsOpen(true);
    }, []);

    const closeMenu = useCallback(() => {
      setMenuIsOpen(false);
    }, []);

    const toggleTargetExcluded = useCallback(excluded => {
      handleToggleTargetExcluded(excluded);
      setMenuIsOpen(false);
    }, []);

    return (
      <Root style={style}>
        <Grid data-testid="panel-geo-target-list-item" className={classes.result} key={index} item xs={12}>
          <Paper className={classes.locations} elevation={0} square>
            <Grid alignItems="center" container justifyContent="center">
              <Grid
                className={classes.filter}
                container
                item
                justifyContent="flex-start"
                xs={2}
              >
                <Grid item xs={12}>
                  <ButtonBase
                    ref={menuAnchorRef}
                    onClick={openMenu}
                    disableRipple
                  >
                    <Grid container alignItems="baseline">
                      <Grid item xs={6}>
                        <div className={classes.filterIconWrap}>
                          {target.blacklist ? (
                            <Fade in={target.blacklist} timeout={100}>
                              <img
                                className={classes.filterImg}
                                src={excludeIcon}
                              />
                            </Fade>
                          ) : (
                            <Fade in={!target.blacklist} timeout={100}>
                              <img
                                className={classes.filterImg}
                                src={includeIcon}
                              />
                            </Fade>
                          )}
                        </div>
                      </Grid>

                      <Grid className={classes.dropdownArrow} item xs={6}>
                        <ExpandMoreIcon style={{ fontSize: '1rem' }} />
                      </Grid>
                    </Grid>
                  </ButtonBase>
                  <FormControl variant="standard">
                    <Menu
                      anchorEl={menuAnchorRef.current}
                      elevation={2}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                      open={menuIsOpen}
                      onClose={closeMenu}
                    >
                      <MenuItem onClick={() => toggleTargetExcluded(false)}>
                        <div className={classes.filterIconWrap}>
                          <img src={includeIcon} />
                        </div>

                        <span className={classes.filterLabel}>Include</span>
                      </MenuItem>

                      <MenuItem onClick={() => toggleTargetExcluded(true)}>
                        <div className={classes.filterIconWrap}>
                          <img src={excludeIcon} />
                        </div>

                        <span className={classes.filterLabel}>Exclude</span>
                      </MenuItem>
                    </Menu>
                  </FormControl>
                </Grid>
              </Grid>

              <Grid item xs={9}>
                {nameCopy}
              </Grid>

              <Grid item xs={1}>
                <IconButton aria-label="delete" onClick={handleDeleteTarget} size="large">
                  <ClearIcon />
                </IconButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Root>
    );
  },
  areEqual
);

InnerGeoTargetingRow.displayName = 'InnerGeoTargetingRow';
InnerGeoTargetingRow.propTypes = {
  target: PropTypes.object,
  style: PropTypes.object,
  index: PropTypes.number,
  copy: PropTypes.object,
  handleDeleteTarget: PropTypes.func,
  handleToggleTargetExcluded: PropTypes.func,
};

export default InnerGeoTargetingRow;
