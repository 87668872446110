import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Backdrop, Box, Container, Fade, Modal } from '@mui/material';
import AdvertiserContext from './AdvertiserContext';

const PREFIX = 'OverlayWrapper';

const classes = {
  container: `${PREFIX}-container`,
  inner: `${PREFIX}-inner`,
  modal: `${PREFIX}-modal`,
  overlay: `${PREFIX}-overlay`
};

const StyledModal = styled(Modal)(({
  theme: { spacing }
}) => ({
  maxHeight: '80%',

  [`& .${classes.container}`]: {
    marginTop: 64,
    paddingBottom: spacing(2),
    minHeight: '80%',
    height: '100%',
  },

  [`& .${classes.inner}`]: {
    overflow: 'auto',
    height: '100%',
    width: '100%',
    maxHeight: '100%',
    zIndex: 2,
    position: 'relative',
  },

  [`& .${classes.overlay}`]: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 18,
    right: 0,
    zIndex: 12,
  }
}));

const OverlayWrapper = props => {
  const adContext = useContext(AdvertiserContext);

  const { isOpen, onClose, children } = props;

  return (
    <StyledModal
      className={clsx(`${adContext.theme}-theme`)}
      disableScrollLock
      open={isOpen}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        style: {
          top: 64,
        },
        timeout: 200,
      }}
    >
      <Fade in={isOpen} timeout={350}>
        <Container
          className={classes.container}
          disableGutters
          maxWidth="lg"
        >
          <Box display="flex" className={classes.inner}>
            <Box className={classes.overlay}>{children}</Box>
          </Box>
        </Container>
      </Fade>
    </StyledModal>
  );
};

OverlayWrapper.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default OverlayWrapper;
