import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Button, Divider, Grid, Paper } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import Title from './Title';
import { SetupWizardSteps } from '../constants';

const PREFIX = 'TrackingSetup';

const classes = {
  backBtn: `${PREFIX}-backBtn`,
  divider: `${PREFIX}-divider`,
  selectPaper: `${PREFIX}-selectPaper`,
  skip: `${PREFIX}-skip`,
  trackingSelected: `${PREFIX}-trackingSelected`
};

const StyledGrid = styled(Grid)((
  {
    theme
  }
) => ({
  [`& .${classes.backBtn}`]: {
    color: `rgba(0, 0, 0, 0.1)`,
  },

  [`& .${classes.divider}`]: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.selectPaper}`]: {
    height: `100%`,
    cursor: `pointer`,
    [`&:hover`]: {
      backgroundColor: `rgba(29, 175, 255, 0.2)`,
    },
  },

  [`& .${classes.skip}`]: {
    color: theme.palette.secondary.main,
    fontSize: '0.9375rem',
    paddingLeft: theme.spacing(2),
    paddingTop: 7,
    paddingBottom: 7,
    paddingRight: theme.spacing(2),
  },

  [`& .${classes.trackingSelected}`]: {
    backgroundColor: `rgba(29, 175, 255, 0.2)`,
  }
}));

const TrackingSetup = ({
  handleBreadcrumbsStatus,
  handleCampaignPanel,
  handleCurrentAdGroup,
  handleEditAdGroup,
  handleRemoveLastAdGroup,
  isDisplay,
  setIsAdGroup,
  setStep,
  setTrackingMethod,
  trackingMethod,
}) => {


  useEffect(() => {
    handleBreadcrumbsStatus('tracking', 0);
  }, []);

  return (
    <StyledGrid container>
      <Grid container>
        <Grid item xs={12}>
          <Title>Set Up Performance Tracking</Title>
          <Divider className={classes.divider} />
        </Grid>
      </Grid>

      <Grid container item justifyContent="center" xs={12}>
        <Grid item xs={10}>
          <p>
            When you run ads with tvScientific, you will want to measure how
            frequently users take actions on your site that you decide are
            valuable such as making a purchase, downloading a game, or
            requesting more information about your products and services.
            Choose from the below for the best method for your business.
          </p>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={8}
        justifyContent="center"
        alignItems="stretch"
        style={{ marginTop: '-10px' }}
      >
        <Grid item xs={5}>
          <Paper
            elevation={trackingMethod === 'pixel' ? 0 : 8}
            className={clsx(
              classes.selectPaper,
              trackingMethod === 'pixel' ? classes.trackingSelected : {}
            )}
            onClick={() => {
              setStep(SetupWizardSteps.TRACKING_EVENTS);
              handleBreadcrumbsStatus('tracking', 25);
              setTrackingMethod('pixel');
            }}
          >
            <Box p={4}>
              <Title>Implement on a website</Title>

              <Divider className={classes.divider} />

              <p>
                Pixel tracking involves placing a snippet of (HTML) code on
                your website to identify and capture key information about
                user activity.
              </p>

              <p>
                Pixel-based tracking is ideal for use on a website. For most
                advertisers this is the best choice. It’s versatile, easy to
                use, and is generally familiar to anyone who has set up a
                search or social advertising campaign.
              </p>
            </Box>
            <Box pb={4} px={4} display="flex" justifyContent="flex-end">
              <Button
                color="secondary"
                endIcon={<ArrowForwardIcon />}
                variant="outlined"
                disableElevation
              >
                continue
              </Button>
            </Box>
          </Paper>
        </Grid>
        <Grid container style={{ width: '90%' }}>
          <Grid container item xs={6} justifyContent="flex-start">
            <Box pb={2} mt={4}>
              <Button
                color="secondary"
                className="Button-campaign-nav"
                onClick={() => {
                  const adg = handleRemoveLastAdGroup();
                  handleCurrentAdGroup(adg);
                  handleEditAdGroup(adg);

                  setIsAdGroup(true);
                  handleCampaignPanel(true);
                  handleBreadcrumbsStatus('tracking', -1, 'creative', 75);

                  if (isDisplay) {
                    setStep('DisplayUpload');
                  } else {
                    setStep('CreativeUpload');
                  }
                }}
                startIcon={<ArrowBackIcon />}
                variant="outlined"
                disableElevation
              >
                {isDisplay ? 'Upload Display' : 'Upload Creatives'}
              </Button>
            </Box>
          </Grid>

          <Grid container item xs={6} justifyContent="flex-end">
            <Box pb={4} mt={4}>
              <Button
                color="secondary"
                className={classes.skip}
                onClick={() => {
                  handleBreadcrumbsStatus('tracking', 100, 'launch', 0);
                  setStep('LaunchCampaign');
                }}
                endIcon={<ArrowForwardIcon />}
                variant="outlined"
                disableElevation
              >
                Skip
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </StyledGrid>
  );
};

TrackingSetup.propTypes = {
  handleBreadcrumbsStatus: PropTypes.func,
  handleCampaignPanel: PropTypes.func,
  handleCurrentAdGroup: PropTypes.func,
  handleEditAdGroup: PropTypes.func,
  handleRemoveLastAdGroup: PropTypes.func,
  isDisplay: PropTypes.bool,
  setIsAdGroup: PropTypes.func,
  setStep: PropTypes.func,
  setTrackingMethod: PropTypes.func,
  trackingMethod: PropTypes.string,
};

export default TrackingSetup;
