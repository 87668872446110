import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Box, DialogTitle, IconButton, Typography, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ModalWrapper from '../ModalWrapper';
import { useCopy } from '../hooks';
import { Themes } from '../../constants';

const PREFIX = 'CreativeQuantityControl';

const classes = {
  container: `${PREFIX}-container`,
  title: `${PREFIX}-title`
};

const StyledModalWrapper = styled(ModalWrapper)({
  [`& .${classes.container}`]: {
    maxWidth: '780px',
    width: '100%',
    margin: '0 auto',
  },
  [`& .${classes.title}`]: {
    padding: 0,
    borderBottom: '1px solid #e5e7eb',
  },
});

const Copies = {
  [Themes.DEFAULT]: {
    HOW_IT_WORKS: (
      <>
        The QC process checks the volume level, bitrate and other file format standards.<br/><br/>
        If there are quality issues, they will be <b>fixed automatically</b> in most cases. If there are issues that can’t be fixed – such as missing frames – you will be notified and asked to re-upload a new creative.<br/><br/>
        <b>Please note:</b> Your creative never leaves tvScientific custody. We have integrated with a third party service via API, so that the QC process can run while your files stay safe and secure on tvScientific infrastructure.
      </>
    ),
    INFO: <>If you want to opt-out of this free service, you can do so by contacting <b>tvScientific client services</b>.</>,
  },
  [Themes.NBCU]: {
    HOW_IT_WORKS: (
      <>
        The QC process checks the volume level, bitrate and other file format standards.<br/><br/>
        If there are quality issues, they will be <b>fixed automatically</b> in most cases. If there are issues that can’t be fixed – such as missing frames – you will be notified and asked to re-upload a new creative.<br/><br/>
        <b>Please note:</b> Your creative never leaves Peacock Ad Manager custody. We have integrated with a third party service via API, so that the QC process can run while your files stay safe and secure on Peacock Ad Manager infrastructure.
      </>
    ),
    INFO: <>If you want to opt-out of this free service, you can do so by contacting <b>Peacock Ad Manager client services</b>.</>,
  }
}

const CreativeQuantityControl = ({ onClose }) => {
  const Copy = useCopy(Copies);

  return (
    <StyledModalWrapper
      isOpen
      hasSubmit
      submit="Got it"
      maxWidth="md"
      onClose={onClose}
      onSubmit={onClose}
      noCancel
      noFooterBg
    >
      <Box
        mx={'auto'}
        display="flex"
        flexDirection="column"
        gap="18px"
        className={classes.container}
      >
        <DialogTitle className={classes.title}>
          <Box
            display="flex"
            alignItems="flex-start"
            justifyContent="space-between"
          >
            <Typography variant="h3">Creative Quality Control</Typography>

            <IconButton onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <Box display="flex" flexDirection="column" gap="6px">
          <Typography variant="h4">What it is</Typography>

          <Typography variant="body2">
            An automated quality control (QC) process that analyzes your creative to make sure it meets minimum requirements.
          </Typography>
        </Box>

        <Box display="flex" flexDirection="column" gap="6px">
          <Typography variant="h4">How it works</Typography>
          <Typography variant="body2">{Copy.HOW_IT_WORKS}</Typography>
        </Box>

        <Alert severity="info">{Copy.INFO}</Alert>
      </Box>
    </StyledModalWrapper>
  );
};

CreativeQuantityControl.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default CreativeQuantityControl;
