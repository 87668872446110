import { useContext, useEffect } from 'react';
import apiInstance from '../connection/apiClient';
import DialogContext from '../providers/DialogContext';
import { termsAndConditionsDialog, useOrg, useTenant, useUser } from '../components/hooks';
import { useGetLatestTenantTermAndCondition } from '../components/hooks/apis/termsAndConditions';
import { usePostUserAgreement } from '../components/hooks/apis/userAgreements';

export const useTermsAndConditionsResponseInterceptor = () => {
  const { setDialog } = useContext(DialogContext);
  const { user, getUser } = useUser();
  const { org } = useOrg();
  const tenant = useTenant();
  const { data: latestTenantTermAndCondition } = useGetLatestTenantTermAndCondition(tenant.id);
  const { trigger: postUserAgreementTrigger } = usePostUserAgreement();

  useEffect(() => {
    const interceptor = apiInstance.interceptors.response.use(
      response => response,
      (error) => {
        const { data, status } = error.response;
        const { errorType } = data;

        if (
          status === 403
          && errorType === 'NOT_AGREED_TO_TERMS_AND_CONDITIONS'
          && user?.url
          && org?.url
          && latestTenantTermAndCondition.url
        ) {
          setDialog(termsAndConditionsDialog({
            onSubmit: async () => {
              try {
                await postUserAgreementTrigger({
                  terms: latestTenantTermAndCondition.url,
                  user: user.url,
                  organization: org.url,
                });

                await getUser();

                setDialog(null);
              } catch (error) {
                console.error('error in saving user agreement ', error);
              }
            }
          }));

          return;
        }

        return Promise.reject(error);
      },
    );

    return () => {
      apiInstance.interceptors.response.eject(interceptor);
    };
  }, [user, org, latestTenantTermAndCondition]);
};
