import PropTypes from 'prop-types';

export const audienceType = PropTypes.shape({
  id: PropTypes.number,
  included: PropTypes.bool,
  featured: PropTypes.bool,
  audience_name: PropTypes.string,
  first_party_data: PropTypes.bool,
  icon_url: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(null),
  ]),
  info: PropTypes.string,
  private: PropTypes.bool,
  third_party_data: PropTypes.bool,
  type: PropTypes.string,
  url: PropTypes.string,
});

export const inventoryType = PropTypes.shape({
  key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  value: PropTypes.string,
  id: PropTypes.number,
  included: PropTypes.bool,
  featured: PropTypes.bool,
});

export const customInventoryType = PropTypes.shape({
  id: PropTypes.number,
  included: PropTypes.bool,
  featured: PropTypes.bool,
  adjusted_cpm: PropTypes.number,
  bundle_name: PropTypes.string,
  estimated_cpm: PropTypes.number,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(null)]),
  info: PropTypes.string,
  ott: PropTypes.bool,
  private: PropTypes.bool,
  type: PropTypes.string,
  url: PropTypes.string,
});
