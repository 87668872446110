import React, {useRef, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as d3Lib from 'd3';
import * as d3Sankey from 'd3-sankey';

const d3 = { ...d3Lib, ...d3Sankey };

const SankeyChart = props => {
  const sankeyRef = useRef(null)
  console.log('sankey data: ', props.data);

  useEffect(() => {

    const margin = { top: 25, right: 25, bottom: 25, left: 5 };
    const width = 900;
    const height = 450 - margin.top - margin.bottom;

    const _sankey = d3
      .sankey()
      .nodeWidth(45)
      .nodePadding(5)
      .extent([
        [0, 0],
        [width - 10, height - 50],
      ]);
    const sankey = ({ nodes, links }) =>
      _sankey({
        nodes: nodes.map(d => ({ ...d })),
        links: links.map(d => ({ ...d })),
      });

    const f = d3.format(',.2f');
    const format = d => `${f(d)}`;
    const formatNodeTitle = d => {
      let title;
      if (d.value === 0) return ''
      if (d.name === "Impressions") {
        title = `${d.name}`;
      } else {
        title = `${d.name} ${format(d.value)}%`;
      }
      return title;
    };

    const svg = d3
    .select(sankeyRef.current)
    .attr('viewBox', `0 0 ${width} ${height}`)

    const { nodes, links } = sankey(props.data);
    const nodeTitles = [{title: 'Ad Exposure', x0: 1, x1: 46, y0: 1, y1: 8}, {title: 'Last Click Visit', x0: 282.33, x1: 327.33, y0: 1, y1: 8}, {title: 'Conversions/Actions', x0: 563.66, x1: 608.66, y0: 1, y1: 8}, {title: 'Total Actions', x0: 830, x1: 860, y0: 1, y1: 8}];

    svg
    .append('g')
    .attr("transform", `translate(${margin.left},${margin.top})`)
    .selectAll('rect')
    .data(nodes)
    .join('rect')
    .attr('x', d => d.x0)
    .attr('y', d => d.y0)
    .attr('rx', 5)
    .attr('height', function(d) {
        return d.y1 - d.y0;
      })
      .attr('width', 45)
      .attr('fill', d => d.color)
    .append('title')
      .text(d => formatNodeTitle(d));

    const link = svg
      .append('g')
      .attr("transform", `translate(${margin.left},${margin.top})`)
        .attr('fill', 'none')
      .selectAll('g')
      .data(links)
      .join('g')

    link
    .append('path')
      .attr('d', d3.sankeyLinkHorizontal())
      .style('stroke-width', d => d.width)
      .style('stroke', '#e5e7eb')

    link
      .append('title')
      .text(d => `${d.source.name} → ${d.target.name}\n${format(d.value)}`);

    svg
      .append('g')
      .style('font', '7px sans-serif')
      .selectAll('text')
      .data(nodes)
      .join('text')
        .attr('x', d => (d.index < 9 ? d.x1 + 11 : d.x0 - 60))
        .attr('y', d => ((d.y1 + d.y0) / 2) + margin.top)
        .attr('dy', '0.35em')
        .text(d => `${formatNodeTitle(d)}`)

    svg
      .append('g')
      .selectAll('text')
      .data(nodeTitles)
      .join('text')
        .attr('x', d => d.x0 + margin.left)
        .attr('y', margin.top / 2)
        .text(d => `${d.title}`)
        .style('font-size', '8px')

  }, [props.data, sankeyRef.current]);

  return <svg ref={sankeyRef} />
};

SankeyChart.propTypes = {
  data: PropTypes.object,
};

export default SankeyChart;
