export const textFieldStyles = {
  styleOverrides: {
    root: ({ theme, fullWidth }) => ({
      '& .MuiOutlinedInput-root': {
        borderRadius: theme.shape.borderRadius, // Border radius
        borderColor: theme.palette.grey[5], // Normal state border color
        borderWidth: 1, // Border width for normal state
        ...(fullWidth && {
          width: '100%', // Ensure fullWidth prop extends the input width
        }),
      },
      '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.primary.light, // Custom color on hover
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline, & .MuiInputBase-root.Mui-focused fieldset.MuiOutlinedInput-notchedOutline':
        {
          borderColor: theme.palette.primary.dark, // Custom color on focus
          borderWidth: 1, // Custom border width on focus
        },
      // Apply custom text styles to the input
      '& .MuiInputBase-input': {
        border: 'none',
        color: theme.palette.grey[3], // Input color
        fontFamily: theme.typography.fontFamily, // Font family
        fontSize: '1rem', // Font size
        fontStyle: 'normal', // Font style
        fontWeight: theme.typography.fontWeight, // Font weight
        lineHeight: '26px', // Line height
        ...(fullWidth && {
          width: '100%', // Ensure fullWidth prop extends the input width
        }),
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
        {
          borderColor: theme.palette.primary.light, // Active state border color
          borderWidth: 1, // Active state border width
        },
      '& .MuiInputLabel-root.Mui-focused': {
        color: theme.palette.primary.light, // Label color when input is active
      },
    }),
  },
};
