import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Switch,
} from '@mui/material/';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CheckIcon from '@mui/icons-material/Check';
import numeral from 'numeral';

import { Accordion, AccordionDetails, AccordionSummary } from './Accordion';
import StrategyMissingEvents from './StrategyMissingEvents';
import Info from './Info';
import { Bid } from './style';

const RequiredEventOptionalMaxCPMStrategy = props => {
  const {
    strategy,
    currentStrategy,
    events,
    isExpanded,
    handleExpand,
    handleFormChange,
    extraData,
  } = props;

  const { cpmRange, isInternalUser, adjustedCPM, rangeText, infoMessage } =
    extraData;

  const [showOptionalInput, setShowOptionalInput] = useState(
    !!currentStrategy.cpm
  );

  useEffect(() => {
    if (currentStrategy?.bid_strategy !== strategy.url) {
      setShowOptionalInput(false);
    }
  }, [currentStrategy?.bid_strategy]);

  const toggleShowInput = () => {
    const nextState = !showOptionalInput;

    setShowOptionalInput(nextState);
    if (!nextState) {
      handleFormChange('cpm', null);
    }
  };

  const handleInputChange = event => {
    handleFormChange(event.target.name, event.target.value);
  };

  return events.length === 0 ? (
    <StrategyMissingEvents
      strategy={strategy}
      isExpanded={isExpanded}
      handleExpand={handleExpand}
    />
  ) : (
    <Accordion
      key={strategy.url}
      square
      expanded={isExpanded}
      onChange={handleExpand}
    >
      <AccordionSummary
        aria-controls={`${strategy.url}-content`}
        id={`${strategy.url}-header`}
      >
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <RadioGroup
              row
              value={currentStrategy.bid_strategy}
              onChange={handleInputChange}
              name="bid_strategy"
            >
              <FormControlLabel
                value={strategy.url}
                control={<Radio />}
                label={strategy.display_name}
              />
            </RadioGroup>
          </Grid>
          <Grid item>
            {isExpanded ? (
              <CheckIcon style={{ color: '#1DAFFF', fontSize: 16 }} />
            ) : (
              <Tooltip title={strategy.tooltip}>
                <InfoOutlinedIcon
                  style={{ color: '#1DAFFF', fontSize: 16 }}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container direction="column" xs={11} spacing={2}>
          <Grid item>
            <Box sx={{ pb: 2 }}>{strategy.description}</Box>
          </Grid>
          <Box mt={2}>
            <Grid item container>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="event-label">Conversion Event</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={currentStrategy.bid_strategy_event || ''}
                  onChange={handleInputChange}
                  label="Options"
                  name="bid_strategy_event"
                >
                  {events?.map(event => (
                    <MenuItem key={event.id} value={event.url}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Box>
          <Box mt={2}>
            <Grid item>
              <FormControlLabel
                control={
                  <Switch
                    checked={showOptionalInput}
                    onChange={toggleShowInput}
                    color="secondary"
                  />
                }
                label="Set Max CPM Bid"
              />
            </Grid>
          </Box>
          {showOptionalInput && (
            <Box mt={2}>
              <Grid container justifyContent="space-between">
                <Grid item xs={5}>
                  <TextField
                    color="secondary"
                    label={strategy.bidStrategyLabel}
                    value={currentStrategy.cpm || ''}
                    onChange={handleInputChange}
                    fullWidth
                    variant="outlined"
                    name="cpm"
                    type="number"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputProps: { min: 0, step: 0.01 },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Grid container justifyContent="flex-start">
                    {cpmRange?.length === 2 &&
                      cpmRange[0] > 0 &&
                      cpmRange[1] > 0 && (
                        <>
                          <Grid item xs={2}>
                            <Tooltip
                              title="Max bids can be up to 10% higher due to auction mechanics."
                              sx={{ maxWidth: 180 }}
                              arrow
                            >
                              <InfoOutlinedIcon fontSize="small" />
                            </Tooltip>
                          </Grid>

                          <Grid item xs={10}>
                            <Bid>
                              The bid range for your inventory selection is{' '}
                              <br />
                              {rangeText}
                            </Bid>
                            {isInternalUser && (
                              <Bid>
                                Max CPM bid sent to BW <br />
                                {numeral(adjustedCPM).format('$0,0')}
                              </Bid>
                            )}
                          </Grid>
                        </>
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          <Info message={infoMessage} />
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

RequiredEventOptionalMaxCPMStrategy.propTypes = {
  strategy: PropTypes.object.isRequired,
  currentStrategy: PropTypes.object.isRequired,
  events: PropTypes.array,
  isExpanded: PropTypes.bool.isRequired,
  handleExpand: PropTypes.func.isRequired,
  handleFormChange: PropTypes.func.isRequired,
  extraData: PropTypes.shape({
    adjustedCPM: PropTypes.number.isRequired,
    cpmRange: PropTypes.array.isRequired,
    infoMessage: PropTypes.string.isRequired,
    isInternalUser: PropTypes.bool.isRequired,
    rangeText: PropTypes.string.isRequired,
  }),
};

export default RequiredEventOptionalMaxCPMStrategy;
