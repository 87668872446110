import React from 'react';
import LogRocket from 'logrocket';
import { init } from '@fullstory/browser';

import App from './App';
import Modal from './Modal';
import Dialog from './Dialog';
import { AppProvider } from '../providers/AppContext';

// Convert ENV var to boolean
// TODO: leave console logs to validate on production. Remove once we're good.
const useLogRocket = (process.env.USE_LOG_ROCKET === 'true');
console.log('use logrocket', useLogRocket)

if (useLogRocket) {
  LogRocket.init('zorsti/prod', {
    network: {
      requestSanitizer: request => {
        // request.body can be a string or array buffer
        if (request && request.body && typeof request.body === 'string' && request.body.toLowerCase().indexOf('password') !== -1) {
          // scrub out the body
          request.body = null;
        }

        // scrub header value from request
        if (request && request.headers && request.headers['Authorization']) {
          request.headers['Authorization'] = '';
        }

        // make sure you return the modified request
        return request;
      },
    },
  });

  console.log('log rocket active');
} else {
  console.log('log rocket inactive');
}

//FullStory init
init({ orgId: 'o-1NXAMX-na1' });

const Root = () => (
  <AppProvider>
    {({ modal, dialog }) => (
      <>
        <App />
        {modal && modal.isOpen && <Modal {...modal} />}
        {dialog && dialog.isOpen && <Dialog {...dialog} />}
      </>
    )}
  </AppProvider>
);

export default Root;
