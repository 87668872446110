import { camelCase } from 'lodash';

const isObject = (obj) => obj && typeof obj === 'object' && !Array.isArray(obj);

export const keysToCamelCase = (obj) => {
  if (isObject(obj)) {
    const n = {};

    Object.keys(obj).forEach((k) => {
      n[camelCase(k)] = keysToCamelCase(obj[k]);
    });

    return n;

  } else if (Array.isArray(obj)) {
    return obj.map((i) => keysToCamelCase(i));
  }

  return obj;
};
