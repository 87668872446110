import React, { useContext, useState, useEffect, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Collapse,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSnackbar } from 'notistack';

import AdvertiserContext from './AdvertiserContext';
import AsyncButton from './AsyncButton';
import InfoTooltip from './InfoTooltip';
import { useLoader } from './hooks/loader';
import { useAdvertisers } from './hooks/advertisers';
import { accountCategories } from './accountCategories';
import dashboardIcon from '../images/general-dashboard.png';
import cartIcon from '../images/cart-dashboard.png';
import downloadIcon from '../images/download-dashboard.png';

const PREFIX = 'AdvertiserWizard';

const classes = {
  card: `${PREFIX}-card`,
  selectedCard: `${PREFIX}-selectedCard`,
  cardContent: `${PREFIX}-cardContent`,
  cardLabel: `${PREFIX}-cardLabel`,
  cardTooltip: `${PREFIX}-cardTooltip`,
  centeredTooltip: `${PREFIX}-centeredTooltip`,
  container: `${PREFIX}-container`,
  subtitle: `${PREFIX}-subtitle`,
  dashboard: `${PREFIX}-dashboard`,
  general: `${PREFIX}-general`,
  cart: `${PREFIX}-cart`,
  download: `${PREFIX}-download`,
  uncappedBudgetLabel: `${PREFIX}-uncappedBudgetLabel`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.card}`]: {
    width: 150,
    height: 150,
    cursor: 'pointer',

    '&:hover': {
      boxShadow:
        '0px 5px 5px -3px rgb(29 175 255 / 20%), 0px 8px 10px 1px rgb(29 175 255 / 14%), 0px 3px 14px 2px rgb(29 175 255 / 12%)',
    },
  },

  [`& .${classes.selectedCard}`]: {
    backgroundColor: 'rgba(29, 175, 255, 0.2)',

    '&:hover': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },

  [`& .${classes.cardContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingBottom: '16px !important',
    position: 'relative',
  },

  [`& .${classes.cardLabel}`]: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },

  [`& .${classes.cardTooltip}`]: {
    position: 'absolute',
    top: 12,
    right: 12,
  },

  [`& .${classes.centeredTooltip}`]: {
    display: 'flex',
    alignItems: 'center',
  },

  [`&.${classes.container}`]: {
    minHeight: 710,
  },

  [`& .${classes.subtitle}`]: {
    fontSize: '0.75rem',
    color: '#47505d',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.dashboard}`]: {
    fontSize: '1.125rem',
  },

  [`& .${classes.general}`]: {
    width: 58,
    height: 58,
    margin: '0 auto',
  },

  [`& .${classes.cart}`]: {
    width: 68,
    height: 58,
    margin: '0 auto',
  },

  [`& .${classes.download}`]: {
    width: 35,
    height: 58,
    margin: '0 auto',
  },

  [`& .${classes.uncappedBudgetLabel}`]: {
    marginLeft: '0.5rem',
  }
}));

const kpiOptions = [
  {
    value: 'IMPRESSIONS',
    label: 'Impressions',
  },
  {
    value: 'WEB_SITE_VISITS',
    label: 'Web site visits',
  },
  {
    value: 'PURCHASES',
    label: 'Purchases',
  },
  {
    value: 'APP_INSTALLS',
    label: 'App installs',
  },
  {
    value: 'IN_APP_PURCHASES',
    label: 'In-app purchases',
  },
];

const errorMessages = {
  name: 'This advertiser already exists. Please contact your account owner or change your advertiser name.',
  domain: 'Please enter a valid website.',
  path: 'Websites cannot contain an additional path (ex: https://website.com/path)',
  secure:
    'We currently only support secure domains. Please enter a valid "https://" domain.',
};

const attribution = {
  model: [
    { label: 'Last Touch', value: 'LAST_TOUCH' },
    { label: 'First Touch', value: 'FIRST_TOUCH' },
    { label: 'Linear', value: 'LINEAR' },
  ],
  window: [
    { label: '30 days', value: '30' },
    { label: '14 days', value: '14' },
    { label: '7 days', value: '7' },
    { label: '3 days', value: '3' },
    { label: '2 days', value: '2' },
    { label: '1 day', value: '1' },
  ],
};

const tooltips = {
  accountName: `The name of your Ad Account. Ad Accounts are used to manage access and budget in your campaigns.`,
  advertiserWebsite: `The public web URL to the Advertiser’s home page.`,
  advertiserCategory: <>The IAB category is used by supply partners to evaluate your suitability for their streaming service. <br/>Choose the category that best describes the product or service promoted by this ad account.</>,
  uncappedAdAccountBudget: `Disable this option if you want to cap the budget for this Ad Account.`,
  currency: `The currency of this Ad Account.`,
  timezone: `The default time zone used in reporting for this Ad Account.`,
  ecommerceDashbaord: `Chose E-Commerce if this ad account promotes online retail. Your reports will focus on metrics such as Cost per Visit, etc.`,
  appDashboard: `Choose App Install if this ad account promotes a mobile app. Your reports will focus on metrics such as Cost per Install, etc.`,
  generalDashboard: `Choose General if neither E-Commerce nor App Install applies. Your reports will focus on metrics such as eCPM and Conversion Rate, etc.`,
  primaryOutcome: `The primary event you use to measure success, such as install, sale, or lead.`,
  attributionWindow: `Maximum time period that an outcome can be attributed to an impression.`,
}

const AdvertiserWizard = props => {

  const adContext = useContext(AdvertiserContext);
  const { enqueueSnackbar } = useSnackbar();
  const { isLoading, setIsLoading } = useLoader();

  const {
    formatAdvertiserUrl,
    validateAdvertiserUrl,
    verifyRootDomain,
  } = useAdvertisers();

  const {
    adAccount,
    onNext,
    showSalesforce,
    showKPI,
    showModel,
    showWindow,
  } = props;

  const {
    aaName: adAcctName,
    setAAName: setAdAcctName,
    adName: name,
    setAdName: setName,
    domain,
    setDomain,
    category,
    setCategory,
    aaKpi,
    setAAKpi,
    aaBudget,
    setAABudget,
    looker,
    setLooker,
    aaAttrModel,
    setAAAttrModel,
    aaAttrWindow,
    setAAAttrWindow,
    sfAdvertiserId,
    setSfAdvertiserId,
    sfAgencyId,
    setSfAgencyId,
  } = adAccount;

  const [isUncapped, setIsUncapped] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    return () => {
      setIsLoading(false);
    };
  }, []);

  useEffect(() => {
    if (adContext) {
      if (adContext.name) {
        setName(adContext.name);
      }

      if (adContext.domain) {
        setDomain(adContext.domain);
      }
    }
  }, [adContext]);

  useEffect(() => {
    if (errorMessage != null) {
      enqueueSnackbar(errorMessage, {
        autoHideDuration: 7000,
        preventDuplicate: true,
        variant: 'error',
      });
    }
  }, [errorMessage]);

  // Input validation with useMemo
  const isNextDisabled = useMemo(
    () =>
      (showSalesforce &&
        !showKPI &&
        !showModel &&
        !showWindow &&
        (!domain || !sfAdvertiserId || !sfAgencyId || !name)) ||
      (showKPI &&
        showModel &&
        showWindow &&
        (!domain ||
          !looker ||
          !aaKpi ||
          !aaAttrModel ||
          !aaAttrWindow ||
          (adContext.userType === 'agency' && name === ''))),
    [
      showKPI,
      showModel,
      showWindow,
      showSalesforce,
      sfAdvertiserId,
      sfAgencyId,
      name,
      domain,
      looker,
      aaKpi,
      aaAttrModel,
      aaAttrWindow,
    ]
  );

  const handleName = event => {
    setName(event.target.value);
    adContext.updateAdvertiser({ name: event.target.value });
  };

  const handleSaveAdvertiser = async () => {
    setErrorMessage(null);
    setIsLoading(true);

    if (!verifyRootDomain(domain)) {
      setIsLoading(false);
      setErrorMessage(errorMessages.path);
      return;
    }

    if (!validateAdvertiserUrl(domain)) {
      setIsLoading(false);
      setErrorMessage(errorMessages.secure);
      return;
    }

    const dataObj = {
      domain: formatAdvertiserUrl(domain),
    };

    if (adContext.userType === 'agency') {
      dataObj.name = name;
    }

    if (adContext.userType === 'advertiser') {
      dataObj.name = adContext.name;
    }

    if (showKPI && showModel && showWindow) {
      dataObj.primary_kpi = aaKpi;
      dataObj.looker_experience = looker;
      dataObj.attribution_model = aaAttrModel;
      dataObj.attribution_window = aaAttrWindow;
    }

    if (showSalesforce) {
      dataObj.advertiser_external_crm_id = sfAdvertiserId;
      dataObj.agency_external_crm_id = sfAgencyId;
    }

    if(showWindow){
      dataObj.attribution_window = aaAttrWindow;
    }

    if (adContext && adContext.id) {
      // need to account for when user goes back and makes an edit
      try {
        const res = await props.handleSaveAdAccount(dataObj)

        if (res) {
          onNext();
        }

        return res;
      } catch (err) {
        console.log('Error in AdvertiserWizard', err);
        setIsLoading(false);
        return err;
      }
    } else {
      try {
        const res = await props.handleSaveAdAccount(dataObj)

        // if a bad request is made or we get an error response
        // from the api, prevent user from advancing
        if (res && res.response) {
          setIsLoading(false);
          return res;
        }

        localStorage.removeItem('company');
        localStorage.removeItem('userType');

        setIsLoading(false);
        onNext();

        return res;
      } catch (error) {
        console.log('error in AdvertiserWizard', error);
        setIsLoading(false);
        return error;
      }
    }
  };

  return (
    <StyledContainer className={classes.container}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100%"
        minHeight={710}
        p={6}
      >
        <Box>
          <Typography variant="h3">
            {adContext.userType === 'agency' &&
              `Set up your first ad account's profile`}

            {adContext.userType === 'advertiser' &&
              `Set up ${adContext.name}'s ad account profile`}
          </Typography>

          <Box mt={2}>
            <Typography className={classes.subtitle}>
              This information will inform how we configure the dashboard and
              performance reports for this advertiser. These settings can be
              modified at any time in the Ad Account Settings.
            </Typography>
          </Box>

          <Divider />

          {showSalesforce && !showKPI && !showModel && !showWindow && (
            <Box display="flex" justifyContent="center" pt={4}>
              <Grid container item xs={5} spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    autoFocus
                    fullWidth
                    color="secondary"
                    label="Advertiser Name"
                    onChange={handleName}
                    placeholder="Enter name of advertiser"
                    value={name}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    color="secondary"
                    label="Advertiser Website Address"
                    onChange={event => setDomain(event.target.value)}
                    placeholder="Enter advertiser website address"
                    value={domain}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    color="secondary"
                    label="Salesforce Advertiser ID"
                    onChange={event => setSfAdvertiserId(event.target.value)}
                    placeholder="Enter Salesforce Advertiser ID"
                    value={sfAdvertiserId}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    color="secondary"
                    label="Salesforce Agency ID"
                    onChange={event => setSfAgencyId(event.target.value)}
                    placeholder="Enter Salesforce Agency ID"
                    value={sfAgencyId}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </Box>
          )}

          {showKPI && showModel && showWindow && (
            <Box display="flex" alignItems="baseline" justifyContent="space-between" pt={4}>
              <Grid item container xs={6} spacing={3}>
                <Box mb={2} ml={2}>
                  <Typography className={classes.dashboard} variant="h4">
                    General Information
                  </Typography>
                </Box>

                <Grid item container spacing={0}>
                  <Grid item xs={10}>
                    <TextField
                      autoFocus
                      fullWidth
                      color="secondary"
                      label="Ad Account Name"
                      onChange={event => setAdAcctName(event.target.value)}
                      placeholder="Enter name of ad account"
                      value={adAcctName}
                      variant="outlined"
                      xs={10}
                    />
                  </Grid>
                  <Grid item className={classes.centeredTooltip}>
                    <InfoTooltip title={tooltips.accountName}/>
                  </Grid>
                </Grid>

                {adContext.userType === 'agency' && (
                  <Grid item xs={10}>
                    <Box mt={4} />

                    <TextField
                      autoFocus
                      fullWidth
                      color="secondary"
                      label="Advertiser Name"
                      onChange={event => setName(event.target.value)}
                      placeholder="Enter name of advertiser"
                      value={name}
                      variant="outlined"
                    />
                  </Grid>
                )}

                <Grid item container spacing={0}>
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      color="secondary"
                      label="Advertiser Website Address"
                      onChange={event => setDomain(event.target.value)}
                      placeholder="Enter advertiser website address"
                      value={domain}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item className={classes.centeredTooltip}>
                    <InfoTooltip title={tooltips.advertiserWebsite}/>
                  </Grid>
                </Grid>

                <Grid item container spacing={0}>
                  <Grid item xs={10}>
                    <TextField
                      select
                      fullWidth
                      color="secondary"
                      label="Advertiser Category"
                      onChange={(event) => setCategory(event.target.value)}
                      placeholder="Select Advertiser Category"
                      value={category}
                      variant="outlined"
                    >
                      {accountCategories.map((category, index) => (
                        <MenuItem key={index} value={category.url}>
                          {category.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item className={classes.centeredTooltip}>
                    <InfoTooltip title={tooltips.advertiserCategory}/>
                  </Grid>
                </Grid>

                <Grid item xs={10}>
                  <FormControlLabel
                    control={
                      <Switch
                        size="small"
                        checked={isUncapped}
                        onChange={evt => setIsUncapped(evt.target.checked)}
                      />
                    }
                    label={
                      <Typography variant="text" className={classes.uncappedBudgetLabel}>
                        Uncapped Ad Account Budget
                        <InfoTooltip title={tooltips.uncappedAdAccountBudget}/>
                      </Typography>
                    }
                  />
                  <Box mt={1} />

                  <Collapse in={!isUncapped}>
                    <TextField
                      autoFocus
                      type="number"
                      fullWidth
                      color="secondary"
                      label="Ad Account Budget"
                      onChange={event => setAABudget(event.target.value)}
                      placeholder="Select Industry Vertical"
                      value={aaBudget}
                      variant="outlined"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            $
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Collapse>
                </Grid>

                <Grid item xs={10}>
                  <Box display="flex">
                    <Box mr={4}>
                      <Typography variant="h4">
                        Currency
                        <InfoTooltip title={tooltips.currency}/>
                      </Typography>
                      <Typography>USD</Typography>
                    </Box>

                    <Box>
                      <Typography variant="h4">
                        Timezone
                        <InfoTooltip title={tooltips.timezone}/>
                      </Typography>
                      <Typography>EST</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              <Grid item container xs={6} spacing={3} >
                <Box mb={2} ml={2}>
                  <Typography className={classes.dashboard} variant="h4">
                    Choose a dashboard that fits this advertiser
                  </Typography>
                </Box>

                <Grid item container xs={12} spacing={0}>
                  <Grid item xs={4}>
                    <Card
                      raised={looker !== 'DEFAULT'}
                      className={clsx(
                        classes.card,
                        looker === 'DEFAULT' ? classes.selectedCard : {}
                      )}
                      onClick={() => setLooker('DEFAULT')}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.cardTooltip}>
                          <InfoTooltip title={tooltips.generalDashboard}/>
                        </div>
                        <div className={classes.general}>
                          <img
                            src={dashboardIcon}
                            alt="dashboard"
                            width="100%"
                          />
                        </div>

                        <div className={classes.cardLabel}>
                          <Typography>General</Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={4}>
                    <Card
                      raised={looker !== 'D2C'}
                      className={clsx(
                        classes.card,
                        looker === 'D2C' ? classes.selectedCard : {}
                      )}
                      onClick={() => setLooker('D2C')}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.cardTooltip}>
                          <InfoTooltip title={tooltips.ecommerceDashbaord}/>
                        </div>
                        <div className={classes.cart}>
                          <img src={cartIcon} alt="dashboard" width="100%" />
                        </div>

                        <div className={classes.cardLabel}>
                          <Typography>E-commerce</Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>

                  <Grid item xs={4}>
                    <Card
                      raised={looker !== 'GAMING'}
                      className={clsx(
                        classes.card,
                        looker === 'GAMING' ? classes.selectedCard : {}
                      )}
                      onClick={() => setLooker('GAMING')}
                    >
                      <CardContent className={classes.cardContent}>
                        <div className={classes.cardTooltip}>
                          <InfoTooltip title={tooltips.appDashboard}/>
                        </div>
                        <div className={classes.download}>
                          <img
                            src={downloadIcon}
                            alt="dashboard"
                            width="100%"
                          />
                        </div>

                        <div className={classes.cardLabel}>
                          <Typography>App install</Typography>
                        </div>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>

                <Grid item container spacing={0}>
                  <Grid container item xs={10} spacing={1}>
                    <TextField
                      fullWidth
                      select
                      color="secondary"
                      label="Primary Outcome"
                      onChange={event => setAAKpi(event.target.value)}
                      value={aaKpi}
                      variant="outlined"
                    >
                      {kpiOptions.map(k => (
                        <MenuItem key={k.value} value={k.value}>
                          {k.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item className={classes.centeredTooltip}>
                    <InfoTooltip title={tooltips.primaryOutcome}/>
                  </Grid>
                </Grid>

                <Grid item container spacing={0}>
                  <Grid item container xs={10} spacing={1}>
                    <Grid container item xs>
                      <TextField
                        fullWidth
                        select
                        color="secondary"
                        label="Attribution Model"
                        onChange={event => setAAAttrModel(event.target.value)}
                        value={aaAttrModel}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      >
                        {attribution.model.map(m => (
                          <MenuItem key={m.value} value={m.value}>
                            {m.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid container item xs>
                      <TextField
                        fullWidth
                        select
                        color="secondary"
                        label="Attribution Window"
                        onChange={event => setAAAttrWindow(event.target.value)}
                        value={aaAttrWindow}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                      >
                        {attribution.window.map(w => (
                          <MenuItem key={w.value} value={w.value}>
                            {w.label}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                  <Grid item className={classes.centeredTooltip}>
                    <InfoTooltip title={tooltips.attributionWindow}/>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>

        <Box
          mt={9}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <Button
            onClick={props.onBack}
            color="secondary"
            startIcon={<ArrowBackIcon />}
          >
            Back
          </Button>

          <AsyncButton
            color="secondary"
            isDisabled={isNextDisabled}
            isLoading={isLoading}
            onClick={handleSaveAdvertiser}
            size="medium"
            textButton="Next"
            variant="contained"
          />
        </Box>
      </Box>
    </StyledContainer>
  );
};

AdvertiserWizard.propTypes = {
  adAccount: PropTypes.object,
  onBack: PropTypes.func,
  onNext: PropTypes.func,
  handleSaveAdAccount: PropTypes.func,
  showKPI: PropTypes.bool,
  showModel: PropTypes.bool,
  showWindow: PropTypes.bool,
  showSalesforce: PropTypes.bool,
};

export default AdvertiserWizard;
