export const OrganizationRoles = {
  ADMIN: 'ADMIN',
  AD_OPS: 'AD_OPS',
  READ_ONLY: 'READ_ONLY',
  MEMBER: 'MEMBER',
  GUEST: 'GUEST',
  TENANT_ADMIN: 'TENANT_ADMIN',
  TENANT_AD_OPS: 'TENANT_AD_OPS',
  TENANT_ANALYST: 'TENANT_ANALYST',
};

export const AdvertiserRoles = {
  ADMIN: 'ADMIN',
  READ_ONLY: 'READ_ONLY',
  CAMPAIGN_MANAGER: 'CAMPAIGN_MANAGER',
  GUEST_ADMIN: 'GUEST_ADMIN',
  GUEST_ANALYST: 'GUEST_ANALYST',
  GUEST_CAMPAIGN_MANAGER: 'GUEST_CAMPAIGN_MANAGER',
};

export const OrgRoleLabelMap = {
  [OrganizationRoles.ADMIN]: 'Organization Admin',
  [OrganizationRoles.AD_OPS]: 'Ad Ops',
  [OrganizationRoles.READ_ONLY]: 'Read Only',
  [OrganizationRoles.MEMBER]: 'Member',
  [OrganizationRoles.GUEST]: 'Guest',
  [OrganizationRoles.TENANT_ADMIN]: 'Tenant Admin',
  [OrganizationRoles.TENANT_AD_OPS]: 'Tenant Ad Ops',
  [OrganizationRoles.TENANT_ANALYST]: 'Tenant Analyst',
}

export const AdvertiserRoleLabelMap = {
  [AdvertiserRoles.ADMIN]: 'Admin',
  [AdvertiserRoles.READ_ONLY]: 'Analyst',
  [AdvertiserRoles.CAMPAIGN_MANAGER]: 'Campaign Manager',
  [AdvertiserRoles.GUEST_ADMIN]: 'Admin',
  [AdvertiserRoles.GUEST_ANALYST]: 'Analyst',
  [AdvertiserRoles.GUEST_CAMPAIGN_MANAGER]: 'Campaign Manager',
}
