import React from 'react';
import PropTypes from 'prop-types';
import { EventPicker } from '@v2/components/ui/EventPicker';
import { fields } from './constants';
import { Controller } from 'react-hook-form';

export const GoalEventPicker = ({
  control,
  eventSet: objectiveGoalEventSet,
  value,
  ...props
}) => {
  return (
    <Controller
      control={control}
      name={fields.objectiveGoalEvent.path}
      render={({ field, fieldState }) => {
        return (
          <EventPicker
            {...field}
            {...fieldState}
            {...props}
            id={value}
            InputLabelProps={{ shrink: !!field.value }}
            label="Goal Event"
            helperText={fieldState.error?.message}
            eventSet={objectiveGoalEventSet}
          />
        );
      }}
    />
  );
};

GoalEventPicker.propTypes = {
  control: PropTypes.any.isRequired,
  eventSet: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
};
