import { useFormContext } from 'react-hook-form';
import {
  formatBytes,
  formatDuration,
} from '@v2/components/campaign/FileUploads';
import { fields } from '../../constants';

const generateStats = ({ field, isComplete, isDisplay }) => {
  if (isComplete) {
    return isDisplay && field.processing ?
      'Processing...' :
      generateImageOrVideoStats(field, isDisplay);
  }
  return 'Uploading...';
};

const generateImageOrVideoStats = (field, isDisplay) =>
  isDisplay ? generateImageStats(field) : generateVideoStats(field);

const generateImageStats = (field) =>
  `${field.width}x${field.height} • ${formatBytes(field.file_size_bytes)}`;

const generateVideoStats = (field) =>
  `${formatBytes(field.size)} • ${formatDuration(field.duration)} • 1080P`;

export const useListElement = ({ field, isDisplay, progress }) => {
  const form = useFormContext();
  const isComplete = parseInt(progress, 10) === 100; // Determine if upload is complete

  const stats = generateStats({ field, isComplete, isDisplay });

  return {
    isComplete,
    form,
    stats,
    weightedRotation: form.watch(fields.weightingRotation.path),
  };
};
