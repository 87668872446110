import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { parse } from 'query-string';
import { Box, Button, Dialog, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { useAPI } from './hooks/api';
import AdvertiserContext from './AdvertiserContext';
import EnhancedTable from './PaymentMethodsTable'

import amexIcon from '../images/american-express-dark.svg';
import discoverIcon from '../images/discover-dark.svg';
import mastercardIcon from '../images/master-card-dark.svg';
import visaIcon from '../images/visa-dark.svg';

const PREFIX = 'PaymentMethods';

const classes = {
  iframe: `${PREFIX}-iframe`,
  methods: `${PREFIX}-methods`,
  paymentListItem: `${PREFIX}-paymentListItem`,
  payments: `${PREFIX}-payments`,
  radioGroup: `${PREFIX}-radioGroup`,
  radios: `${PREFIX}-radios`
};

const Root = styled('div')((
  {
    theme
  }
) => ({
  [`& .${classes.iframe}`]: {
    marginBottom: theme.spacing(2),
  },

  [`& .${classes.methods}`]: {
    fontSize: '.8rem',
  },

  [`& .${classes.paymentListItem}`]: {
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),

    '&.Mui-selected': {
      backgroundColor: '#d3f0ff',
    },

    '&.Mui-selected:hover': {
      backgroundColor: '#d3f0ff',
    },
  },

  [`& .${classes.payments}`]: {
    margin: theme.spacing(1),
    maxHeight: 244,
    overflow: 'auto',
  },

  [`& .${classes.radioGroup}`]: {
    marginBottom: theme.spacing(2),
    width: '100%',
  },

  [`& .${classes.radios}`]: {
    fontSize: `1rem`,
  }
}));

const paymentIcons = {
  americanexpress: amexIcon,
  discover: discoverIcon,
  visa: visaIcon,
  mastercard: mastercardIcon,
};

const authorizeAddPayment = process.env.AUTHORIZE_NET_ADD_PAYMENT;

const formatLastFour = digits => digits.split('XXXX')[1];

const CardType = (props) => {
  const type = props.cell.row.original.card_type
  return (
    <Root style={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}>
      <img src={paymentIcons[type.toLowerCase()]} />
      <span style={{ verticalAlign: 'top', paddingLeft: 5 }}>{type} ending in {formatLastFour(props.value)}</span>
    </Root>
  );
}


const PaymentMethods = ({
  onCancel,
  onMessage,
  onReceiveCommunication,
  onResize,
}) => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Payment Method',
        accessor: 'card_number',
        Cell: CardType
      },
      {
        Header: 'Expiration Date',
        accessor: 'expiration_date',
      },
    ],
    []
  )


  const adContext = useContext(AdvertiserContext);
  const { useGet } = useAPI();
  const iframeRef = useRef();

  const submitRef = useCallback(form => {
    // On render submit form immediately
    if (form != null) {
      form.submit();
    }
  }, []);

  const [isEnterCard, setIsEnterCard] = useState(false);
  const [formToken, setFormToken] = useState('');
  const [paymentMethods, setPaymentMethods] = useState([]);

  useEffect(() => {
    window.AuthorizeNetIFrame = {
      onReceiveCommunication: str => {
        if (onReceiveCommunication) {
          onReceiveCommunication(str);
        }

        const message = parse(str);

        console.log('message from iframe', message);
        console.log('message.action from iframe', message.action);

        if (isValidMessage(message)) {
          if (onMessage) {
            onMessage(message);
          }

          switch (message.action) {
            case 'successfulSave':
              // if a save is detected, hit the refresh
              // endpoint on payment_profiles
              getProfilesRefresh()
                .then(() => getAuthorizeToken())
              break;

            case 'resizeWindow':
              console.log('resizeWindow', message);

              if (onResize) {
                onResize(message.width, message.height);
              }
              break;

            case 'cancel':
              console.log('cancel', message);

              getProfilesRefresh()
                .then(() => getAuthorizeToken())
                .then(() => {
                  handleCloseForm();
                });

              if (onCancel) {
                onCancel();
              }
              break;
          }
        }
      },
    };
    return () => {
      delete window.AuthorizeNetIFrame;
      getProfilesRefresh()
        .then(() => getAuthorizeToken())
    };
  }, []);

  useEffect(() => {
    getProfilesRefresh();
    getAuthorizeToken();
  }, []);

  function getAuthorizeToken() {
    return useGet(`/advertisers/${adContext.id}/authorize_token`)
      .then(res => {
        console.log('res from token', res);

        if (res.status === 'Ok') {
          setFormToken(res.token);
        }

        return res;
      })
      .catch(err => console.error(err));
  }

  function getProfilesRefresh() {
    console.log('getting profiles')
    return useGet(`/payment_profiles/refresh`)
      .then(res => {
        console.log('res from refreshed profiles', res);

        if (res && res.results && res.results.length > 0) {
          const reversed = res.results.reverse();
          setPaymentMethods(reversed);
        }

        console.log(res)
        return res;
      })
      .catch(err => console.error(err));
  }

  const isValidMessage = o => typeof o === 'object' && o !== null;

  const handleCloseForm = () => {
    setIsEnterCard(false);
    getProfilesRefresh()
      .then(() => getAuthorizeToken())
  };

  return (
    <div>
      <EnhancedTable
        columns={columns}
        data={paymentMethods}
        setData={setPaymentMethods}
        setIsEnterCard={setIsEnterCard}
      />
      <Dialog
        fullWidth
        className={classes.dialog}
        maxWidth="md"
        onClose={handleCloseForm}
        open={isEnterCard}
      >
        <DialogTitle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Enter a new card</Typography>
            <IconButton onClick={handleCloseForm} size="large">
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>

        <DialogContent>
          <form
            ref={submitRef}
            method="POST"
            action={authorizeAddPayment}
            target="new-auth-net"
          >
            <input name="token" type="hidden" value={formToken} />

            <Button
              style={{ display: 'none' }}
              type="submit"
            >
              Add Payment
            </Button>
          </form>

          <div className={classes.iframe} style={{ height: 540 }}>
            <iframe
              ref={iframeRef}
              id="new-auth-net"
              name="new-auth-net"
              frameBorder={0}
              height="100%"
              width="100%"
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}


CardType.propTypes = {
  card_type: PropTypes.string,
  cell: PropTypes.object,
  row: PropTypes.object,
  original: PropTypes.object,
  value: PropTypes.string
}

PaymentMethods.propTypes = {
  onCancel: PropTypes.func,
  onMessage: PropTypes.func,
  onReceiveCommunication: PropTypes.func,
  onResize: PropTypes.func,
  onSelectPayment: PropTypes.func,
  onPaymentType: PropTypes.func,
};

export default PaymentMethods
