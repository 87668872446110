import { object, string } from 'yup';

export const validationSchema = object().shape({
  firstName: string().required('Required'),
  lastName: string().required('Required'),
  email: string().email().required('Required'),
  phone: string(),
  role: string().required('Required'),
});

export const getInitialValues = () => {
  return {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
  };
};

export const formValuesToApi = values => {
  return {
    first_name: values.firstName,
    last_name: values.lastName,
    email: values.email,
    phone: values.phone,
    role: values.role,
  };
};
