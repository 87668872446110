// Response handlers for ajax requests

//////////////////////////////////////////
// TODO perform sanity checks to
// clean success/error response data
//////////////////////////////////////////
export function handleResponse(response) {
  return response;
}

export function handleError(error) {
  return error;
}

export function handleHeaders(baseHeaders, customHeaders) {
  return ({
    headers: {
      ...baseHeaders,
      ...customHeaders,
    }
  });
}
