import { useCallback } from 'react';
import { nodeStatus } from '../components';
import { useWizardContext } from './useWizardContext';

export const useWizardSubwaySection = ({ key, defaultPaneKey }) => {
  const {
    navigation: { activeSection, activePane, goTo, ...other },
  } = useWizardContext();

  const handleSetActivePane = paneId => {
    goTo({
      sectionId: key,
      paneId,
    });
  };

  const handleSetActiveSection = sectionId => {
    goTo({
      sectionId,
    });
  };

  const handleGetPaneStatus = useCallback(
    paneKey => {
      if (
        paneKey === activePane ||
        (!activePane && paneKey === defaultPaneKey)
      ) {
        return nodeStatus.active;
      }

      return nodeStatus.pending;
    },
    [activePane, defaultPaneKey],
  );

  return {
    active: key === activeSection,
    setActiveSection: handleSetActiveSection,
    setActivePane: handleSetActivePane,
    getPaneStatus: handleGetPaneStatus,
    ...other,
  };
};
