import { uniqueId } from 'lodash';
import { distinctNames } from '../../GeoTargeter';

export const getEmptyGeoItem = () => {
  return {
    id: uniqueId(),
    name: '',
    text: '',
    type: distinctNames.empty,
  };
};
