import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import dashboardIcon from '../../../images/general-dashboard.png';
import cartIcon from '../../../images/cart-dashboard.png';
import downloadIcon from '../../../images/download-dashboard.png';
import {
  Box,
  Card,
  CardContent,
  FormControl,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useController } from 'react-hook-form';
import clsx from 'clsx';

const PREFIX = 'LookerTypeSelect';

const classes = {
  general: `${PREFIX}-general`,
  card: `${PREFIX}-card`,
  selectedCard: `${PREFIX}-selectedCard`,
  cardContent: `${PREFIX}-cardContent`,
  cardLabel: `${PREFIX}-cardLabel`
};

const StyledFormControl = styled(FormControl)(({
  theme: { spacing }
}) => ({
  [`& .${classes.general}`]: {
    height: 58,
    margin: '0 auto',
    '& > img': {
      objectFit: 'scale-down',
      height: '100%',
    },
  },

  [`& .${classes.card}`]: {
    width: 150,
    height: 150,
    cursor: 'pointer',

    '&:hover': {
      boxShadow:
        '0px 5px 5px -3px rgb(29 175 255 / 20%), 0px 8px 10px 1px rgb(29 175 255 / 14%), 0px 3px 14px 2px rgb(29 175 255 / 12%)',
    },
  },

  [`& .${classes.selectedCard}`]: {
    backgroundColor: 'rgba(29, 175, 255, 0.2)',

    '&:hover': {
      boxShadow:
        '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
    },
  },

  [`& .${classes.cardContent}`]: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    paddingBottom: '16px !important',
  },

  [`& .${classes.cardLabel}`]: {
    marginTop: spacing(1),
    textAlign: 'center',
  }
}));

const lookerOptions = [
  {
    value: 'DEFAULT',
    label: 'General',
    icon: dashboardIcon,
  },
  {
    value: 'D2C',
    label: 'E-commerce',
    icon: cartIcon,
  },
  {
    value: 'GAMING',
    label: 'App install',
    icon: downloadIcon,
  },
];

export const LookerTypeSelect = ({ control, name }) => {
  const { field: lookerField, fieldState } = useController({
    control,
    name,
  });

  return (
    <StyledFormControl
      error={!!fieldState.error}
      variant="standard"
      role="radiogroup"
      aria-label="looker type"
      aria-invalid={!!fieldState.invalid}
      aria-errormessage={`${name}-helper-text`}
    >
      <Box py={2} display="flex" gap="16px">
        {lookerOptions.map(({ value, label, icon }, i) => (
          <Card
            key={value}
            raised={lookerField.value !== value}
            className={clsx(classes.card, {
              [classes.selectedCard]: lookerField.value === value,
            })}
            onClick={() => lookerField.onChange(value)}
            role="radio"
            aria-checked={lookerField.value === value}
            aria-labelledby={`${name}-${i}-label`}
          >
            <CardContent className={classes.cardContent}>
              <div className={classes.general}>
                <img src={icon} alt="dashboard" width="100%" />
              </div>

              <div className={classes.cardLabel}>
                <Typography id={`${name}-${i}-label`}>{label}</Typography>
              </div>
            </CardContent>
          </Card>
        ))}
      </Box>

      {fieldState.error && (
        <FormHelperText id={`${name}-helper-text`}>
          {fieldState.error?.message}
        </FormHelperText>
      )}
    </StyledFormControl>
  );
};

LookerTypeSelect.propTypes = {
  control: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
