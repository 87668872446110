import { useState, useMemo } from 'react';

function useLockedStates(campaignOptions) {
  const initialLockedOptionsExpandedStates = useMemo(() => {
      return campaignOptions.reduce((acc, option) => {
          if (option.lock === true) {
              acc[option.value] = false;
          }
          return acc;
      }, {});
  }, [campaignOptions]);

  const [lockedOptionsExpandedStates, setLockedOptionsExpandedStates] = useState(initialLockedOptionsExpandedStates);

  return [lockedOptionsExpandedStates, setLockedOptionsExpandedStates];
}

export default useLockedStates;
