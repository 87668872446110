import React, { useState, useEffect, useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Grid, TextField, Tooltip, InputAdornment, Button } from '@mui/material';
import clsx from 'clsx';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import infoIcon from '../images/icons/info-icon.svg';
import { useAPI } from './hooks/api';
import { useAdvertisers } from './hooks/advertisers';
import AdvertiserContext from './AdvertiserContext';
import Title from './Title';


const PREFIX = 'AdvertiserTab';

const classes = {
  root: `${PREFIX}-root`,
  tab: `${PREFIX}-tab`,
  helperTextWrap: `${PREFIX}-helperTextWrap`,
  info: `${PREFIX}-info`,
  paper: `${PREFIX}-paper`,
  submit: `${PREFIX}-submit`,
  subtitle: `${PREFIX}-subtitle`,
  success: `${PREFIX}-success`,
  successMsg: `${PREFIX}-successMsg`,
  title: `${PREFIX}-title`,
  tooltip: `${PREFIX}-tooltip`,
  wrap: `${PREFIX}-wrap`,
  headerWrapper: `${PREFIX}-headerWrapper`,
  tabWrapper: `${PREFIX}-tabWrapper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({
  theme: { palette, spacing, typography }
}) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.tab}`]: {
    fontSize: "1.5rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#5c6b73",
  },

  [`& .${classes.helperTextWrap}`]: {
    display: 'inline-block',
  },

  [`& .${classes.info}`]: {
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'text-top',
    width: 14,
    height: 14,
  },

  [`& .${classes.paper}`]: {
    paddingTop: spacing(5),
    paddingBottom: spacing(5),
    paddingLeft: spacing(9),
    paddingRight: spacing(9),
    maxWidth: 900,
    margin: 'auto',
  },

  [`& .${classes.submit}`]: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },

  [`& .${classes.subtitle}`]: {
    ...typography.subtitle1,
    fontSize: '1.125rem',
  },

  [`& .${classes.success}`]: {
    backgroundColor: palette.primary.light,
  },

  [`& .${classes.successMsg}`]: {
    color: palette.primary.light,
  },

  [`& .${classes.title}`]: {
    ...typography.h3,
  },

  [`& .${classes.tooltip}`]: {
    backgroundColor: `#f2f3f5`,
    color: palette.text.primary,
    padding: spacing(2),
    maxWidth: 180,
  },

  [`& .${classes.wrap}`]: {
    height: 'calc(100% - 64px)',
  },

  [`& .${classes.headerWrapper}`]: {
    marginTop: 70,
    marginBottom: 100,
    textAlign: 'right'
  },

  [`& .${classes.tabWrapper}`]: {
    marginTop: 35,
  }
}));

const lockedTooltip = `These fields can only be edited if there aren’t currently active campaigns. If you’d like to edit these fields, pause all campaigns first.`

const AdvertiserTab = () => {

  const adContext = useContext(AdvertiserContext);
  const { useGet, usePatch } = useAPI();
  const { updateCurrentAdvertiser } = useAdvertisers();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [name, setName] = useState('');
  const [website, setWebsite] = useState('');
  const [category, setCategory] = useState('');

  useEffect(() => {
    if (adContext.id) {
      if (category === '') {
        getCategory();
      }

      if (name === '') {
        setName(adContext.name);
      }

      if (website === '') {
        setWebsite(adContext.domain);
      }
    }
  }, [adContext.category]);

  const getCategory = () => {
    if (adContext.category) {
      return useGet(adContext.category)
        .then(response => {
          if (response) {
            setCategory(response.name);
          }
          return response;
        })
        .catch(err => console.error(err));
    }
    return setCategory(`Uncategorized`)
  };

  const handleSubmit = () => {
    const { id } = adContext;

    setIsLoading(true);
    setIsSuccess(false);

    return usePatch(`/advertisers/${id}/`, { name })
      .then(response => {
        setIsLoading(false);
        setIsSuccess(true);

        if (response) {
          updateCurrentAdvertiser(response);
        }

        return response;
      })
      .catch(error => {
        setIsLoading(false);
        setIsSuccess(false);
        console.error(error);
      });
  };

  const renderHelperText = () => (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item>
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          title={lockedTooltip}
        >
          <span className={classes.info}>
            <img src={infoIcon} />
          </span>
        </Tooltip>
      </Grid>

      <Grid item>
        Why is this field locked?
      </Grid>
    </Grid>
  );

  return (
    <Root>
      <Title className={classes.title}>Advertiser Settings</Title>

      <Box mt={3} mb={5}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              color="secondary"
              margin="none"
              fullWidth
              label="Advertiser Name"
              onChange={event => setName(event.target.value)}
              value={name}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              color="secondary"
              disabled
              margin="none"
              fullWidth
              label="Advertiser Website"
              value={website}
              InputLabelProps={{ shrink: true }}
              variant="outlined"
            />
            {adContext.active && renderHelperText()}
          </Grid>

          <Grid item xs={6}>
            <TextField
              color="secondary"
              disabled
              fullWidth
              // helperText={}
              label="Company Category"
              margin="none"
              onChange={(event) => setCategory(event.target.value)}
              value={category}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                ),
              }}
              variant="outlined"
            />
            {adContext.active && renderHelperText()}
          </Grid>
        </Grid>
      </Box>

      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        style={{ textAlign: 'right' }}
        width="100%"
      >
        {isSuccess && (
          <Typography component={'span'} className={classes.successMsg}>
            Your settings have been saved!
          </Typography>
        )}

        <Button
          className={clsx(classes.submit)}
          color="secondary"
          disabled={isLoading}
          disableElevation={isSuccess}
          disableRipple
          onClick={handleSubmit}
          variant="contained"
        >
          {isLoading
            ? 'Saving...'
            : isSuccess
              ? 'Save Settings'
              : 'Save Settings'}
        </Button>
      </Box>
    </Root>
  );
};

export default AdvertiserTab;
