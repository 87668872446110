import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Button,
  FormControlLabel,
  Switch,
  Typography,
} from '@mui/material';
import clsx from 'clsx';

const PREFIX = 'SupportTab';

const classes = {
  textfield: `${PREFIX}-textfield`,
  submit: `${PREFIX}-submit`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({
  theme: { spacing, typography }
}) => ({
  [`& .${classes.textfield}`]: {
    marginBottom: spacing(3),
  },

  [`& .${classes.submit}`]: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  }
}));

export const SupportTab = () => {

  const [status] = useState('');
  const isLoading = status === 'loading';
  const isSuccess = status === 'success';

  const [isSupportAccess, setIsSupportAccess] = useState(false);

  return (
    (<Root>
      <Box width="65%">
        <Box mb={4}>
          <Typography>
            If you need help, please use Intercom (click the button in the
            bottom right of the screen) to request support, and then activate
            Support Access below.
          </Typography>
        </Box>

        <Box width="78%">
          <Box mb={3}>
            <Typography variant="h4">Activate Support Access</Typography>
          </Box>

          <FormControlLabel
            control={
              <Switch
                checked={isSupportAccess}
                onChange={e => setIsSupportAccess(e.target.checked)}
                size="small"
              />
            }
            label={
              <Box ml={2}>
                <Typography className={classes.supportAccess}>
                  I grant [tenant-name] access to the [ad-account-name] Ad
                  Account, and I understand that the [tenant-name] Ad Ops team
                  will have full admin privileges.
                </Typography>
              </Box>
            }
          />
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        mt={10}
        width="100%"
      >
        {isSuccess && (
          <Typography component={'span'} className={classes.successMsg}>
            Your Settings have been saved!
          </Typography>
        )}

        <Button
          color="secondary"
          disabled={isLoading}
          disableElevation
          disableRipple
        >
          Cancel
        </Button>

        <Button
          className={clsx(classes.submit)}
          color="secondary"
          disableElevation={isSuccess}
          disableRipple
          variant="contained"
        >
          {isLoading ? 'Saving...' : 'Save Settings'}
        </Button>
      </Box>
    </Root>)
  );
};
