import { useContext, useEffect } from 'react';

import { useAuth } from './auth';
import { useErrors } from './errors';
import { useLoader } from './loader';
import apiInstance from '../../connection/apiClient';
import AdvertiserContext from '../AdvertiserContext';

interface ApiResponse<T = any> {
  results?: T[];
  next?: string | null;
  [key: string]: any;
}

/////////////////
// useAPI HOOK
/////////////////
export const useAPI = () => {
  const adContext = useContext(AdvertiserContext);
  const { authState } = useAuth();
  const { isLoading, setIsLoading } = useLoader();
  const { handleError } = useErrors();


  function refreshToken(): void {
    if (authState?.isAuthenticated) {
      apiInstance.defaults.headers[
        'Authorization'
      ] = `Bearer ${authState.accessToken}`;
    }
  }

  refreshToken();

  if (adContext && adContext.id) {
    apiInstance.defaults.headers['X-TVS-AdvertiserContext'] = adContext.id;
  }

  if (adContext && adContext.primary_org) {
    const orgArr = adContext.primary_org.split('/');
    const orgId = orgArr[orgArr.length - 2];
    apiInstance.defaults.headers['X-TVS-OrganizationContext'] = orgId;
  }

  useEffect(() => {
    return () => setIsLoading(false);
  }, []);

  const unhandledGet = (url: string, params?: any) => {
    return apiInstance.get(url, {params});
  }

  // Rest API Service
  const useGet = (url: string, config?: any) => {
    setIsLoading(true);

    return apiInstance
      .get(url, config)
      .catch(error => {
        setIsLoading(false);
        if (error.response) {
          console.error('Error in useGet', error);
          handleError(error, 'error', refreshToken);
          throw error;
        }
      })
      .then(response => {
        setIsLoading(false);
        if (
          response &&
          response.status &&
          [200, 201].indexOf(response.status) > -1 &&
          response.data
        ) {
          return response.data;
        }

        return response;
      });
  };

  const usePost = (url: string, data: any, config?: any) => {
    setIsLoading(true);

    return apiInstance
      .post(url, data, config)
      .then(response => {
        setIsLoading(false);
        console.log('Response from usePost', response);
        return response;
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error in usePost', error);
        handleError(error, 'error', refreshToken);
        throw error;
      });
  };

  const usePatch = (url: string, data: any, config?: any) => {
    setIsLoading(true);

    return apiInstance
      .patch(url, data, config)
      .then(response => {
        setIsLoading(false);
        console.log('Response from usePatch', response);
        return response;
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error in usePatch', error);
        handleError(error, 'error', refreshToken);
        throw error;
      });
  };

  const usePut = (url: string, data: any, config?: any) => {
    setIsLoading(true);

    return apiInstance
      .put(url, data, config)
      .then(response => {
        setIsLoading(false);
        console.log('Response from usePut', response);
        return response;
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error in usePut', error);
        handleError(error, 'error', refreshToken);
        return error;
      });
  };

  const useDelete = (url: string, config?: any) => {
    setIsLoading(true);

    return apiInstance
      .delete(url, config)
      .then(response => {
        setIsLoading(false);
        console.log('Response from useDelete', response);
        return response;
      })
      .catch(error => {
        setIsLoading(false);
        console.error('Error in useDelete', error);
        handleError(error, 'error', refreshToken);
        throw error;
      });
  };

  /////////////////
  // CUSTOM APIs
  /////////////////

  // Handles collection of results from recursive requests
  const useGetAll = <T = any>(
    url: string,
    total: T[],
    callback: (items: T[]) => void,
    config?: any
  ): Promise<ApiResponse<T>> => {
    setIsLoading(true);

    return useGet(url, config)
      .then((response: ApiResponse<T>) => {
        setIsLoading(false);

        const totalItems = total && response.results
          ? [...total, ...response.results]
          : total || [];

        if (response && response.next && response.next != null) {
          return useGetAll(response.next, totalItems, callback);
        }

        if (response && !response.next) {
          callback(totalItems);
        }

        return response;
      })
      .catch((error: Error) => {
        console.error('Error in useGetAll', error);

        setIsLoading(false);
        handleError(error, 'error', refreshToken);

        throw error;
      });
  };

  const useGetPaginated = async (url: string, callback: (results: any[], isFirstPage: boolean) => Promise<void>, config?: any) => {
    setIsLoading(true);

    let requestUrl = url;
    let hasNext = true;

    while (hasNext) {
      const response = await apiInstance.get(requestUrl, config);
      const { previous, next, results } = response.data;

      const isFirstPage = !previous;

      await callback(results, isFirstPage);

      if (next) {
        requestUrl = next;
      } else {
        hasNext = false;
        setIsLoading(false);
      }
    }
  }

  const useGetBlob = (url: string, config?: any) => {
    setIsLoading(true);

    return apiInstance
      .get(url, { ...config, responseType: 'blob' })
      .then(response => {
        setIsLoading(false);

        if (
          response &&
          response.status &&
          [200, 201].indexOf(response.status) > -1 &&
          response.data
        ) {
          return response.data;
        }

        return response;
      })
      .catch(error => {
        setIsLoading(false);
        handleError(error, 'error', refreshToken);
        console.error('Error in useGetBlob', error);
        return error;
      });
  };

  return {
    isLoading,
    setIsLoading,
    useGet,
    usePatch,
    usePost,
    usePut,
    useDelete,
    useGetAll,
    useGetPaginated,
    useGetBlob,
    unhandledGet,
  };
};
