import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import { sectionProps } from '@components/Wizard';

const ReviewAndLaunchInfoPane = lazy(
  () => import('./panes/ReviewAndLaunchInfoPane'),
);

const ReviewSection = ({ adGroups, campaignId, retargetingAdGroups }) => {
  return (
    <ReviewAndLaunchInfoPane
      key="info"
      adGroups={adGroups}
      campaignId={campaignId}
      retargetingAdGroups={retargetingAdGroups}
    />
  );
};

ReviewSection.propTypes = {
  ...sectionProps,
  adGroups: PropTypes.arrayOf(PropTypes.object),
  retargetingAdGroups: PropTypes.arrayOf(PropTypes.object),
};

export default ReviewSection;
