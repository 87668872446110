import React from 'react';
import { styled } from '@mui/material/styles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Grid,
  Fade,
  List,
  ListItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import LocationOffOutlinedIcon from '@mui/icons-material/LocationOffOutlined';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

import { formatDemographic, getTargetingObject } from './util';
import { useGeo } from './hooks/geo';
import { useCopy } from './hooks';
import AsyncButton from './AsyncButton';
import TargetingIcon from '../images/icons/targeting.svg';
import DevicesIcon from '../images/icons/devices.svg';
import OSIcon from '../images/icons/os.svg';
import { GenderSelection, Themes } from '../constants';

const PREFIX = 'CampaignPanel';

const classes = {
  root: `${PREFIX}-root`,
  forecast: `${PREFIX}-forecast`,
  green: `${PREFIX}-green`,
  gridWrap: `${PREFIX}-gridWrap`,
  households: `${PREFIX}-households`,
  icon: `${PREFIX}-icon`,
  iconWrap: `${PREFIX}-iconWrap`,
  customIconWrap: `${PREFIX}-customIconWrap`,
  listItem: `${PREFIX}-listItem`,
  section: `${PREFIX}-section`,
  sectionLast: `${PREFIX}-sectionLast`,
  saveBtn: `${PREFIX}-saveBtn`,
  subheaderWrapper: `${PREFIX}-subheaderWrapper`,
  subheader: `${PREFIX}-subheader`
};

const StyledBox = styled(Box)((
  {
    theme
  }
) => ({
  [`&.${classes.root}`]: {
    minHeight: `100%`,
    width: `100%`,
  },

  [`& .${classes.forecast}`]: {
    [`& h2`]: {
      fontSize: `2rem`,
      fontWeight: `bold`,
      margin: 0,
    },

    [`& sub`]: {
      fontSize: `0.75rem`,
      fontWeight: 500,
    },
  },

  [`& .${classes.green}`]: {
    color: `#0fbf84`,
  },

  [`& .${classes.gridWrap}`]: {
    display: `flex`,
    flexDirection: `column`,
    height: `100%`,
    justifyContent: `space-between`,
  },

  [`& .${classes.households}`]: {
    fontSize: `0.75rem`,
    fontWeight: `normal`,
    margin: 0,
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.icon}`]: {
    width: `0.6em`,
  },

  [`& .${classes.iconWrap}`]: {
    marginTop: 3,
    minWidth: 24,
  },

  [`& .${classes.customIconWrap}`]: {
    marginTop: '8px',
  },

  [`& .${classes.listItem}`]: {
    alignItems: 'flex-start',
    padding: 0,
  },

  [`& .${classes.section}`]: {
    flex: `0 0 auto`,
    width: `100%`,
    marginBottom: theme.spacing(1),
    minHeight: 100,
  },

  [`& .${classes.sectionLast}`]: {
    width: `100%`,
  },

  [`& .${classes.saveBtn}`]: {
    marginTop: theme.spacing(2),
    fontSize: '1.05rem',
  },

  [`& .${classes.subheaderWrapper}`]: {
    flex: 1,
    minWidth: 0,
  },

  [`& .${classes.subheader}`]: {
    color: `#181c20`,
    fontSize: `1.2rem`,
    fontWeight: `bold`,
    margin: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  }
}));

const formatDateRange = (startDate, endDate, noEndDate) => {
  let campaignStart = '';
  let campaignEnd = '';

  if (noEndDate) {
    // If there is no end date at all
    campaignStart = moment(startDate).format('MMM D');

    return `${campaignStart}-onward`;
  } else if (moment(startDate).isSame(endDate, 'day')) {
    // If selectedStartDate day is equal to selectedEndDate day
    campaignStart = moment(startDate).format('MMM D, YYYY');

    return `${campaignStart}`;
  } else if (
    moment(startDate).isSame(endDate, 'month') &&
    moment(startDate).isSame(endDate, 'year')
  ) {
    // If selectedStartDate month is equal to selectedEndDate month
    campaignStart = moment(startDate).format('MMM D');
    campaignEnd = moment(endDate).format('D, YYYY');

    return `${campaignStart}-${campaignEnd}`;
  } else if (moment(startDate).isSame(endDate, 'year')) {
    // If selectedStartDate month is not equal to selectedEndDate month, but years are same
    campaignStart = moment(startDate).format('MMM D');
    campaignEnd = moment(endDate).format('MMM D, YYYY');

    return `${campaignStart}-${campaignEnd}`;
  }

  // Otherwise, selectedStartDate year is not equal to selectedEndDate year
  campaignStart = moment(startDate).format('MMM D, YYYY');
  campaignEnd = moment(endDate).format('MMM D, YYYY');

  return `${campaignStart}-${campaignEnd}`;
};

const Copies = {
  [Themes.DEFAULT]: {
    genderSelect: GenderSelection[Themes.DEFAULT],
    DAYPARTING: 'Day-parting',
    US: 'US',
  },
  [Themes.NBCU]: {
    genderSelect: GenderSelection[Themes.NBCU],
    DAYPARTING: 'dayparting',
    US: 'U.S.',
  },
};

const ZIP_CODE_RESULTS_LIMIT = 10;

//////////////////////////////////
// CAMPAIGN PANEL COMPONENT
//////////////////////////////////
const CampaignPanel = props => {


  const {
    adGroups,
    budget,
    demoData,
    deviceTypes,
    operatingSystems,
    formatGeoName,
    geoResults,
    hasDayParting,
    isAdGroup,
    isDisplay,
    isSubmitDisabled,
    name,
    isLoading,
    showDisplay,
    targetingData,
    currentAdGroup,
    selectedStartDate,
    selectedEndDate,
    noEndDate,
    removeCurrentAdGroup,
    onSubmit,
  } = props;

  const { formatGeoForClient } = useGeo();
  const Copy = useCopy(Copies);

  // Render Functions
  const renderDemo = data => {
    if (!data) {
      return;
    }

    const { age, income, gender } = data;

    const demoText = formatDemographic(
      age[0],
      age[1],
      income[0],
      income[1],
      Copy.genderSelect[gender]
    );

    return (
      <Fade in={!!data}>
        <ListItem className={classes.listItem} disableGutters>
          <ListItemIcon className={classes.iconWrap}>
            <PersonOutlinedIcon className={classes.icon} />
          </ListItemIcon>

          <ListItemText primary={demoText} />
        </ListItem>
      </Fade>
    );
  };

  const renderTargeting = data => {
    const { device = [], os = [], segments, type } = data;
    const devicesObject = getTargetingObject(device);
    const operatingSystemsObject = getTargetingObject(os);

    return (
      <>
        {(segments || type) && (
          <ListItem className={classes.listItem} disableGutters>
            <ListItemIcon className={classes.iconWrap}>
              <MyLocationIcon className={classes.icon} />
            </ListItemIcon>

            <ListItemText primary={`${data.segments.length} Targeting Segments`} />
          </ListItem>
        )}

        {devicesObject.selected.length > 0 &&
          operatingSystemsObject.selected.length > 0 && (
            <ListItem className={classes.listItem} disableGutters>
              <ListItemIcon
                className={clsx(classes.iconWrap, classes.customIconWrap)}
              >
                <img src={TargetingIcon} />
              </ListItemIcon>

              <ListItemText
                primary={`${
                  devicesObject.selected.length +
                  operatingSystemsObject.selected.length
                } targeting segments`}
              />
            </ListItem>
          )}

        {devicesObject.selected.length > 0 && (
          <ListItem className={classes.listItem} disableGutters>
            <ListItemIcon
              className={clsx(classes.iconWrap, classes.customIconWrap)}
            >
              <img src={DevicesIcon} />
            </ListItemIcon>

            <ListItemText
              primary={`${devicesObject.includingCopy} ${devicesObject.listCopy}`}
            />
          </ListItem>
        )}

        {operatingSystemsObject.selected.length > 0 && (
          <ListItem className={classes.listItem} disableGutters>
            <ListItemIcon
              className={clsx(classes.iconWrap, classes.customIconWrap)}
            >
              <img src={OSIcon} />
            </ListItemIcon>

            <ListItemText
              primary={`${operatingSystemsObject.includingCopy} ${operatingSystemsObject.listCopy}`}
            />
          </ListItem>
        )}
      </>
    );
  };

  const renderGeo = (group, index) => {
    const g = group.place_name ? group.place_name : group.text;
    const formattedName = formatGeoName(group);
    const nameCopy = formattedName.replace('US', Copy.US);

    return (
      <Fade key={`${g}-${index}`} in={!!g}>
        <ListItem className={classes.listItem} disableGutters data-testid="panel-geo-target-list-item">
          <ListItemIcon className={classes.iconWrap}>
            {group.blacklist ? (
              <LocationOffOutlinedIcon className={classes.icon} />
            ) : (
              <LocationOnOutlinedIcon className={classes.icon} />
            )}
          </ListItemIcon>

          <ListItemText
            primary={`${group.blacklist ? 'Exclude:' : ''} ${nameCopy}`}
          />
        </ListItem>
      </Fade>
    );
  };

  const renderCurrentGroup = () => {
    const geo = !Array.isArray(geoResults)
      ? formatGeoForClient(geoResults)
      : geoResults;

    const topGeoResults = geo.slice(0, ZIP_CODE_RESULTS_LIMIT);
    if (geo.length > topGeoResults.length) {
      topGeoResults.push({
        text: `and ${geo.length - topGeoResults.length} more`,
        type: 'postcode',
      })
    }

    return (
      <div className={classes.section}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item className={classes.subheaderWrapper}>
            <h3 className={classes.subheader}>
              {currentAdGroup && currentAdGroup.name
                ? currentAdGroup.name
                : `Ad Group ${adGroups.length + 1}`}
            </h3>
          </Grid>
          {adGroups.length > 0 && (
            <Grid item>
              <IconButton onClick={removeCurrentAdGroup} size="large">
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Grid>
          )}
        </Grid>

        <List component="div" dense disablePadding data-testid="panel-geo-target-list">
          {topGeoResults && topGeoResults.length > 0 && topGeoResults.map((g, i) => renderGeo(g, i))}

          {demoData && demoData.age && renderDemo(demoData)}

          {targetingData && renderTargeting(targetingData)}
        </List>
      </div>
    );
  };

  // Renders all single group's data
  const renderAdGroup = (group, index) => {
    const targetData = group.targeting;

    // Grab parsed JSON from stringified data
    const {
      advanced,
      age = [],
      gender,
      geo: rawGeo,
      income = [],
      segments,
      type,
      os,
      device,
    } = targetData;

    // Restructure data
    const demo = {
      advanced,
      age,
      gender,
      income,
    };

    const targeting = {
      segments,
      type,
      os,
      device,
    };

    const name = group.name ? group.name : `Ad Group ${index + 1}`;
    const fadeName = group.name ? true : false;

    const geo = !Array.isArray(rawGeo) ? formatGeoForClient(rawGeo) : rawGeo;

    return (
      <div key={`${name}-${index}`} className={classes.section}>
        <Fade in={fadeName} timeout={300}>
          <h3 className={classes.subheader}>{name}</h3>
        </Fade>

        <List component="div" dense disablePadding>
          {geo && geo.length > 0 && geo.map((g, i) => renderGeo(g, i))}

          {demo && renderDemo(demo)}

          {targeting && renderTargeting(targeting)}
        </List>
      </div>
    );
  };

  // Render the details for the campaign
  const renderDetails = () => {
    const devicesObject = getTargetingObject(deviceTypes);
    const operatingSystemsObject = getTargetingObject(operatingSystems);

    return (
      <List component="div" dense disablePadding>
        <ListItem className={classes.listItem} disableGutters>
          <ListItemIcon className={classes.iconWrap}>
            <BusinessOutlinedIcon className={classes.icon} />
          </ListItemIcon>

          <ListItemText
            primaryTypographyProps={{
              style: {
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              },
            }}
            primary={name}
          />
        </ListItem>

        <ListItem className={classes.listItem} disableGutters>
          <ListItemIcon className={classes.iconWrap}>
            <DateRangeOutlinedIcon className={classes.icon} />
          </ListItemIcon>

          <ListItemText
            primary={formatDateRange(
              selectedStartDate,
              selectedEndDate,
              noEndDate
            )}
          />
        </ListItem>

        {hasDayParting && (
          <ListItem className={classes.listItem} disableGutters>
            <ListItemIcon className={classes.iconWrap}>
              <AccessTimeOutlinedIcon className={classes.icon} />
            </ListItemIcon>

            <ListItemText primary={Copy.DAYPARTING} />
          </ListItem>
        )}

        <ListItem className={classes.listItem} disableGutters>
          <ListItemIcon className={classes.iconWrap}>
            <AttachMoneyOutlinedIcon className={classes.icon} />
          </ListItemIcon>

          <ListItemText primary={`${budget}/day`} />
        </ListItem>

        {showDisplay && (
          <ListItem className={classes.listItem}>
            <ListItemText
              primary={`Display Retargeting: ${isDisplay ? 'On' : 'Off'}`}
            />
          </ListItem>
        )}

        {devicesObject.selected.length > 0 &&
          operatingSystemsObject.selected.length > 0 && (
            <ListItem className={classes.listItem} disableGutters>
              <ListItemIcon
                className={clsx(classes.iconWrap, classes.customIconWrap)}
              >
                <img src={TargetingIcon} />
              </ListItemIcon>

              <ListItemText
                primary={`${
                  devicesObject.selected.length +
                  operatingSystemsObject.selected.length
                } targeting segments`}
              />
            </ListItem>
          )}

        {devicesObject.selected.length > 0 && (
          <ListItem className={classes.listItem} disableGutters>
            <ListItemIcon
              className={clsx(classes.iconWrap, classes.customIconWrap)}
            >
              <img src={DevicesIcon} />
            </ListItemIcon>

            <ListItemText
              primary={`${devicesObject.includingCopy} ${devicesObject.listCopy}`}
            />
          </ListItem>
        )}

        {operatingSystemsObject.selected.length > 0 && (
          <ListItem className={classes.listItem} disableGutters>
            <ListItemIcon
              className={clsx(classes.iconWrap, classes.customIconWrap)}
            >
              <img src={OSIcon} />
            </ListItemIcon>

            <ListItemText
              primary={`${operatingSystemsObject.includingCopy} ${operatingSystemsObject.listCopy}`}
            />
          </ListItem>
        )}
      </List>
    );
  };

  return (
    <StyledBox
      style={{ backgroundColor: 'white' }}
      borderLeft={1}
      borderColor="grey.300"
      className={classes.root}
      pl={4}
      pr={3}
      py={4}
    >
      <form onSubmit={onSubmit} className={classes.gridWrap}>
        {isAdGroup && renderCurrentGroup()}

        {adGroups &&
          adGroups.length > 0 &&
          adGroups
            .slice(0)
            .reverse()
            .map((g, i) => renderAdGroup(g, i))}

        <div className={classes.sectionLast}>
          <div>
            <Box mb={2}>
              <h3 className={classes.subheader}>Campaign Details</h3>

              {renderDetails()}
            </Box>

            {/* <h3 className={classes.subheader}>Forecast</h3>

            <Grid
              alignItems="center"
              container
              className={classes.forecast}
              justify="flex-start"
            >
              <Grid item xs={6}>
                <h2>5.23 M</h2>
              </Grid>

              <Grid item xs>
                <p className={classes.households}>Households <br />with CTV</p>
              </Grid>
            </Grid>

            <Grid container className={classes.forecast}>
                <h2 className={classes.green}>37.2 - 41.9 K</h2>
                <sub>Estimated Daily Reach</sub>
              </Grid> */}

            {/* <Box p={2} /> */}

            <AsyncButton
              nativeType="submit"
              color="secondary"
              className={classes.saveBtn}
              disableElevation
              fullWidth
              isDisabled={isSubmitDisabled}
              isLoading={isLoading}
              variant="contained"
            >
              Save &amp; Continue
            </AsyncButton>
          </div>
        </div>
      </form>
    </StyledBox>
  );
};

CampaignPanel.propTypes = {
  adGroups: PropTypes.array,
  budget: PropTypes.string,
  currentAdGroup: PropTypes.object,
  demoData: PropTypes.object,
  deviceTypes: PropTypes.array,
  operatingSystems: PropTypes.array,
  formatGeoName: PropTypes.func,
  geoResults: PropTypes.array,
  handleCampaignPanel: PropTypes.func,
  handleSaveAdGroup: PropTypes.func,
  isAdGroup: PropTypes.bool,
  isDisplay: PropTypes.bool,
  isSubmitDisabled: PropTypes.bool,
  showDisplay: PropTypes.bool,
  setIsAdGroup: PropTypes.func,
  updateBreadcrumbs: PropTypes.func,
  hasDayParting: PropTypes.bool,
  name: PropTypes.string,
  selectedEndDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  selectedEndTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  selectedStartDate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  selectedStartTime: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  setStep: PropTypes.func,
  noEndDate: PropTypes.bool,
  isLoading: PropTypes.bool,
  targetingData: PropTypes.object,
  removeCurrentAdGroup: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default CampaignPanel;
