const accountCategories = [
  {
    id: 6,
    iab_id: 'IAB5_2',
    name: 'Adult Education',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/6/'
  },
  {
    id: 7,
    iab_id: 'IAB20_1',
    name: 'Adventure Travel',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/7/'
  },
  {
    id: 8,
    iab_id: 'IAB3_1',
    name: 'Advertising',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/8/'
  },
  {
    id: 12,
    iab_id: 'IAB20_3',
    name: 'Air Travel',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/12/'
  },
  {
    id: 14,
    iab_id: 'IAB7_5',
    name: 'Alternative Medicine',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/14/'
  },
  {
    id: 19,
    iab_id: 'IAB21_1',
    name: 'Apartments',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/19/'
  },
  {
    id: 27,
    iab_id: 'IAB1',
    name: 'Arts & Entertainment',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/27/'
  },
  {
    id: 33,
    iab_id: 'IAB2_1',
    name: 'Auto Parts',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/33/'
  },
  {
    id: 35,
    iab_id: 'IAB2_2',
    name: 'Auto Repair',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/35/'
  },
  {
    id: 36,
    iab_id: 'IAB2',
    name: 'Automotive',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/36/'
  },
  {
    id: 41,
    iab_id: 'IAB18_1',
    name: 'Beauty',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/41/'
  },
  {
    id: 44,
    iab_id: 'IAB17_3',
    name: 'Bicycling',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/44/'
  },
  {
    id: 46,
    iab_id: 'IAB3_3',
    name: 'Biotech/Biomedical',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/46/'
  },
  {
    id: 53,
    iab_id: 'IAB1_1',
    name: 'Books & Literature',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/53/'
  },
  {
    id: 58,
    iab_id: 'IAB20_6',
    name: 'Budget Travel',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/58/'
  },
  {
    id: 60,
    iab_id: 'IAB3_4',
    name: 'B2B Software',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/60/'
  },
  {
    id: 62,
    iab_id: 'IAB2_3',
    name: 'Buying/Selling Cars',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/62/'
  },
  {
    id: 63,
    iab_id: 'IAB21_3',
    name: 'Buying/Selling Homes',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/63/'
  },
  {
    id: 67,
    iab_id: 'IAB19_5',
    name: 'Cameras & Camcorders',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/67/'
  },
  {
    id: 68,
    iab_id: 'IAB20_9',
    name: 'Camping',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/68/'
  },
  {
    id: 72,
    iab_id: 'IAB17_6',
    name: 'Canoeing/Kayaking',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/72/'
  },
  {
    id: 92,
    iab_id: 'IAB9_9',
    name: 'Cigars',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/92/'
  },
  {
    id: 94,
    iab_id: 'IAB18_5',
    name: 'Clothing',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/94/'
  },
  {
    id: 95,
    iab_id: 'IAB8_5',
    name: 'Cocktails/Beer',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/95/'
  },
  {
    id: 99,
    iab_id: 'IAB4_2',
    name: 'Higher Ed',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/99/'
  },
  {
    id: 115,
    iab_id: 'IAB13_2',
    name: 'Credit/Debt & Loans',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/115/'
  },
  {
    id: 118,
    iab_id: 'IAB20_12',
    name: 'Cruises',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/118/'
  },
  {
    id: 122,
    iab_id: 'IAB14_1',
    name: 'Dating',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/122/'
  },
  {
    id: 123,
    iab_id: 'IAB6_3',
    name: 'Daycare/Pre School',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/123/'
  },
  {
    id: 133,
    iab_id: 'IAB8_9',
    name: 'Restaurants',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/133/'
  },
  {
    id: 146,
    iab_id: 'IAB19_29',
    name: 'Entertainment',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/146/'
  },
  {
    id: 153,
    iab_id: 'IAB6',
    name: 'Family & Parenting',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/153/'
  },
  {
    id: 155,
    iab_id: 'IAB18_3',
    name: 'Fashion',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/155/'
  },
  {
    id: 159,
    iab_id: 'IAB13_4',
    name: 'Financial Planning',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/159/'
  },
  {
    id: 162,
    iab_id: 'IAB8',
    name: 'Food & Drink',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/162/'
  },
  {
    id: 174,
    iab_id: 'IAB15_8',
    name: 'Geography',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/174/'
  },
  {
    id: 178,
    iab_id: 'IAB17_15',
    name: 'Golf',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/178/'
  },
  {
    id: 180,
    iab_id: 'IAB5_9',
    name: 'Graduate School',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/180/'
  },
  {
    id: 188,
    iab_id: 'IAB7',
    name: 'Health & Fitness',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/188/'
  },
  {
    id: 194,
    iab_id: 'IAB9',
    name: 'Hobbies & Interests',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/194/'
  },
  {
    id: 196,
    iab_id: 'IAB10',
    name: 'Home & Garden',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/196/'
  },
  {
    id: 198,
    iab_id: 'IAB10_5',
    name: 'Home Repair',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/198/'
  },
  {
    id: 206,
    iab_id: 'IAB20_18',
    name: 'Hotels',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/206/'
  },
  {
    id: 220,
    iab_id: 'IAB13_6',
    name: 'Insurance',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/220/'
  },
  {
    id: 223,
    iab_id: 'IAB19_18',
    name: 'Internet Technology',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/223/'
  },
  {
    id: 224,
    iab_id: 'IAB13_7',
    name: 'Investing',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/224/'
  },
  {
    id: 233,
    iab_id: 'IAB18_4',
    name: 'Jewelry',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/233/'
  },
  {
    id: 243,
    iab_id: 'IAB11',
    name: "Law, Gov't & Politics",
    url: 'https://api.thefinstore.com/v1/advertiser_categories/243/'
  },
  {
    id: 262,
    iab_id: 'IAB1_6',
    name: 'Music',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/262/'
  },
  {
    id: 271,
    iab_id: 'IAB12',
    name: 'News',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/271/'
  },
  {
    id: 307,
    iab_id: 'IAB21',
    name: 'Real Estate',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/307/'
  },
  {
    id: 308,
    iab_id: 'IAB23',
    name: 'Religion & Spirituality',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/308/'
  },
  {
    id: 348,
    iab_id: 'IAB18',
    name: 'Style & Fashion',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/348/'
  },
  {
    id: 354,
    iab_id: 'IAB19',
    name: 'Technology & Computing',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/354/'
  },
  {
    id: 361,
    iab_id: 'IAB20',
    name: 'Travel',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/361/'
  },
  {
    id: 374,
    iab_id: 'IAB9_30',
    name: 'Gaming',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/374/'
  },
  {
    id: 387,
    iab_id: 'IAB7_44',
    name: 'Weight Loss',
    url: 'https://api.thefinstore.com/v1/advertiser_categories/387/'
  },
];

const accountCategoriesObjectiveMap = {
  audienceReach: [27, 53, 81, 160, 208, 260, 262, 357, 196, 20, 145, 147, 171, 198, 199, 221, 239, 309, 243, 214, 244, 364, 296, 103, 290, 43, 312, 346, 353, 115, 159, 191, 220, 224, 263, 277, 337, 322, 29, 382, 45, 85, 175, 284, 294, 339, 174, 54, 291, 21, 48, 80, 136, 241, 310, 373, 343, 34, 156, 161, 164, 169, 170, 178, 204, 205, 209, 219, 39, 252, 259, 264, 276, 280, 299, 302, 303, 314, 316, 44, 317, 318, 319, 325, 332, 333, 336, 350, 351, 352, 52, 358, 377, 379, 381, 392, 55, 72, 84, 93, 116, 105, 106, 141, 185, 210, 247, 257, 258, 275, 289, 295, 313, 326, 363, 375, 378, 62, 73, 83, 111, 113, 117, 132, 22, 308, 15, 279, 30, 57, 79, 89, 193, 226, 237, 242, 366, 272, 370, 152, 297, 304, 186, 367, 215, 212, 213, 380, 344, 112, 59, 8, 246, 250, 254, 10, 46, 60, 109, 165, 179, 183, 207, 153, 5, 37, 123, 154, 285, 286, 300, 342, 162, 16, 163, 168, 189, 227, 230, 255, 371, 372, 389, 38, 66, 87, 95, 96, 119, 130, 133, 194, 24, 98, 102, 137, 167, 173, 177, 197, 225, 234, 26, 249, 267, 281, 292, 306, 315, 321, 323, 324, 345, 40, 391, 49, 50, 71, 74, 86, 92],
  websiteVisits: [271, 222, 265, 245, 158, 122, 135, 172, 251, 327, 355, 386, 149, 354, 1, 108, 120, 121, 128, 129, 142, 181, 200, 223, 231, 17, 232, 248, 261, 268, 269, 270, 282, 287, 298, 146, 18, 330, 369, 376, 383, 384, 385, 388, 65, 67, 36, 33, 35, 361, 7, 69, 78, 118, 138, 150, 166, 182, 203, 228, 9, 229, 256, 266, 338, 340, 362, 368, 31, 64, 68, 307, 19, 63, 110, 114, 104, 143, 77, 76, 365, 75, 99, 157, 235, 236, 311, 273, 320, 356, 139, 2, 201, 202, 238, 301, 341, 347, 6, 23, 100, 101, 134, 144, 240, 180, 140, 188, 151, 56, 70, 88, 90, 91, 97, 124, 125, 126, 127, 3, 131, 148, 176, 187, 190, 192, 195, 211, 216, 217, 11, 218, 253, 274, 278, 283, 288, 293, 305, 328, 329, 13, 334, 335, 349, 360, 387, 390, 14, 25, 28, 32, 47],
  websiteSales: [348, 41, 51, 155, 233, 94, 4, 82, 107, 206, 359, 12, 42, 58, 61, 331, 184],
  appInstalls: [374]
};

export { accountCategories, accountCategoriesObjectiveMap };
