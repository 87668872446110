import React from 'react';
import Container from '@mui/material/Container';
import background from '../images/00a_Dashboard.png';

const Dashboard = () => {
  return (
    <Container maxWidth={false} disableGutters>
      <img className={`temporary-dashboard-page-preview`} src={background} />
    </Container>
  )
}

export default Dashboard;
