import { useWizardContext } from './useWizardContext';

export const useWizardPane = ({ key }) => {
  const { navigation } = useWizardContext();

  const active = navigation.activePane === key;

  const { updateEntityState } = navigation;

  return {
    ...navigation,
    updateEntityState: state => updateEntityState(key, state),
    active,
  };
};
