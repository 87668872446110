import React from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Button } from '@mui/material';
import uploadIconSvg from '../images/icon-upload.svg';

const FileDropzone = props => {
  const {
    accept,
    handleDrop,
    handleDropRejected,
    maxSize,
    uploadIcon,
    description,
    specifications
  } = props;

  const { getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: handleDrop,
    onDropRejected: handleDropRejected,
    maxSize,
  });

  return (
    <div className="Dropzone" {...getRootProps()}>
      <input data-testid="file-dropzone" {...getInputProps()} />

      <img src={uploadIcon || uploadIconSvg} alt="Upload icon" />

      <p className="Dropzone-description">{description}</p>

      <Button color="secondary" variant="outlined" disableElevation>
        Select Files
      </Button>

      {specifications}

    </div>
  );
};

FileDropzone.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  handleDrop: PropTypes.func.isRequired,
  handleDropRejected: PropTypes.func.isRequired,
  maxSize: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  specifications: PropTypes.element.isRequired,
  uploadIcon: PropTypes.any,
};

export default FileDropzone;
