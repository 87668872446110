import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import ModalContext from '../providers/ModalContext';

const Modal = ({
  isOpen,
  data,
  component: Component,
  onClose = close => () => { close(); },
  onSuccess = () => {},
}) => {
  const { setModal } = useContext(ModalContext);

  if (!isOpen) {
    return null;
  }

  return (
    <Component
      isOpen={isOpen}
      data={data}
      onClose={(event) => onClose(() => setModal(null))(event)}
      onSuccess={onSuccess}
    />
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  data: PropTypes.any,
  component: PropTypes.any.isRequired,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default Modal;
