import { defaults, isUndefined, omit } from 'lodash';
import { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { GeoItem, UseChipSelectoItemArgs } from '../types';
import { getGeoWithTextByDistinct, mapBoxItemToGeoItem } from '../utils';
import { useGeoSearchV2 } from './useGeoSearch';
import { useChipPopperContext } from './useChipPopperContext';
import { useDebounce } from 'use-debounce';

export const useChipSelectorItem = ({
  value,
  onChange,
  onRemove,
  types,
  type,
  index,
}: UseChipSelectoItemArgs) => {
  const { focusedIndex, focus, focusStale } = useChipPopperContext();
  const [baseRef, setBaseRef] = useState<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState(value.text);
  const [debouncedInput] = useDebounce(inputValue, 200);
  const [isEditing, setIsEditing] = useState(false);

  const { data: result = [], isLoading } = useGeoSearchV2(debouncedInput, {
    types,
  });

  const data = result.filter(v => v).map(item => mapBoxItemToGeoItem(item));

  const handleChange = useCallback(
    (newValue: GeoItem) => {
      onChange(newValue);
    },
    [onChange, value],
  );

  const handleRemove = () => {
    onRemove();
    focusStale();
  };

  const handleEscape = () => {
    setIsEditing(false);
    setInputValue(value.text);
    baseRef?.focus();
  };

  const handleEnter = () => {
    setIsEditing(true);
  };

  const handleKeyboardEvent = (e: KeyboardEvent<HTMLInputElement>) => {
    switch (e.key) {
      case 'Enter':
        e.preventDefault();
        if (!isEditing) {
          handleEnter();
        }
        break;
      case 'Backspace':
      case 'Delete':
        if (!isEditing) {
          e.preventDefault();
          handleRemove();
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (value.temporary && !isLoading) {
      handleChange({
        ...omit(
          getGeoWithTextByDistinct(defaults(data[0], value)),
          'temporary',
        ),
        temporaryId: value.id,
        ...(!data.length && { errored: true }),
      });
    }
  }, [isLoading, data, value, type, handleChange]);

  useEffect(() => {
    if (baseRef && !isUndefined(focusedIndex) && focusedIndex === index) {
      baseRef.focus();
    }
  }, [baseRef, focusedIndex, index]);

  return {
    isEditing,
    data,
    isLoading,
    inputValue,
    errored: value.errored ?? false,
    setInputValue,
    onKeyboardEvent: handleKeyboardEvent,
    baseRef,
    setBaseRef,
    edit: handleEnter,
    escape: handleEscape,
    change: handleChange,
    remove: handleRemove,
    focus,
  };
};
