import { useWizardContext } from './useWizardContext';

export const useWizardPreview = ({ key }) => {
  const {
    navigation: { activeSection },
  } = useWizardContext();
  return {
    active: key === activeSection,
  };
};
