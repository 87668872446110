import { useFormContext } from 'react-hook-form';
import { AdvancedContext, fields } from '../constants';
import { useContext } from 'react';

export const useSection = () => {
  const { inventory, audience, customInventory } =
    useContext(AdvancedContext);
  const { setValue } = useFormContext();

  const handleSetZipCodesToEdit = zipCodesElement => {
    setValue(fields.zipcodes.path, zipCodesElement);
  };

  return {
    setZipCodesToEdit: handleSetZipCodesToEdit,
    inventory,
    audience,
    customInventory,
  };
};
