import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  useForm,
  useFieldArray,
  FormProvider,
} from 'react-hook-form';
import { Box, Divider, Grid, InputAdornment, Switch, TextField, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ModalWrapper from './ModalWrapper';
import AlertBox from './AlertBox';
import Title from './Title';

const PREFIX = 'EditWeighting';

const classes = {
  container: `${PREFIX}-container`,
  panelHeader: `${PREFIX}-panelHeader`,
  close: `${PREFIX}-close`,
  row: `${PREFIX}-row`,
  relative: `${PREFIX}-relative`,
  label: `${PREFIX}-label`,
  divider: `${PREFIX}-divider`,
  toggleInfo: `${PREFIX}-toggleInfo`,
  toggleTextActive: `${PREFIX}-toggleTextActive`
};

const Root = styled("div")(() => ({
  [`& .${classes.container}`]: {
    position: 'relative',
    height: 'auto',
  },

  [`& .${classes.panelHeader}`]: {
    backgroundColor: '#e5e7eb',
  },

  [`& .${classes.close}`]: {
    paddingLeft: 10,
    width: '40px',
  },

  [`& .${classes.row}`]: {
    paddingTop: '30px',
  },

  [`& .${classes.relative}`]: {
    position: 'relative',
  },

  [`& .${classes.label}`]: {
    position: 'absolute',
    top: '-10px',
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
  },

  [`& .${classes.divider}`]: {
    margin: '20px 0 20px',
  },

  [`& .${classes.toggleInfo}`]: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },

  [`& .${classes.toggleTextActive}`]: {
    color: '#181C20',
  }
}));

const EditWeighting = ({
  data,
  isOpen,
  onSubmit,
  onClose,
}) => {


  const formMethods = useForm({
    defaultValues: data,
    shouldUnregister: true,
    mode: 'onBlur'
  });

  const { register, formState, control, watch, handleSubmit } = formMethods;
  const { isSubmitting, errors } = formState;

  const creatives = watch('creatives');
  const weightedDelivery = watch('weightedDelivery');

  const { fields } = useFieldArray({
    name: "creatives",
    keyName: 'internalId',
    control,
  });

  return (
    <FormProvider {...formMethods}>
      <Root>
        <ModalWrapper
          fullWidth
          maxWidth="lg"
          isOpen={isOpen}
          onClose={onClose}
          isLoading={isSubmitting}
          onSubmit={handleSubmit(onSubmit)}
          isDisabled={formState.isDirty && !formState.isValid}
          submit="Update Weighting"
          hasSubmit
          aria-labelledby="edit-ad-group-dialog"
        >
          <Box className={classes.container}>
            <Grid
              container
              item
              display="flex"
              direction="column"
              justifyContent="space-between"
              px={0}
              pb={22}
            >
              <Grid
                container
                item
                justifyContent="space-between"
              >
                <Grid item xs={11}>
                  <Title>
                    Edit Creative Weighting
                  </Title>

                  <Divider className={classes.divider} />

                  {errors.creatives && errors.creatives.length > 0 && (
                    <AlertBox
                      {...alert.activeAlert}
                      isAlertOpen
                      type="MAJOR"
                      message={errors.creatives.filter(weighting => weighting)[0].weighting.message}
                    />
                  )}

                  <Box py="25px">
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}
                    >
                      <Grid
                        item
                        className={clsx(!weightedDelivery && classes.toggleTextActive)}
                      >
                        Random Delivery
                      </Grid>
                      <Grid item>
                        <Tooltip title="With Random Delivery the creative will be served at random, which translates to approximately even distribution">
                          <InfoOutlinedIcon
                            className={classes.toggleInfo}
                            fontSize="small"
                            color="secondary"
                          />
                        </Tooltip>
                      </Grid>

                      <Grid item>
                        <Switch
                          {...register('weightedDelivery')}
                          checked={weightedDelivery}
                          inputProps={{ 'aria-label': 'secondary checkbox' }}
                          size="small"
                        />
                      </Grid>
                      <Grid
                        item
                        className={clsx(weightedDelivery && classes.toggleTextActive)}
                      >
                        Weighted Delivery
                      </Grid>
                      <Grid item>
                        <Tooltip title="Weighted Delivery allows you to determine the max allocation of the ad group budget that can go to each creative by assigning a value between 1 and 100 (%) to determine the relative weight with which the creative will be served">
                          <InfoOutlinedIcon
                            className={classes.toggleInfo}
                            fontSize="small"
                            color="secondary"
                          />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Box>

                  {fields.map((field, index) => {
                    register(`creatives.${index}.creativeAdGroupId`);

                    return (
                      <Box key={field.id} flexGrow={1} className={classes.row}>
                        <Grid
                          container
                          item
                          alignItems="center"
                          justifyContent="space-between"
                          xs={12}
                          spacing={3}
                        >
                          <Grid item xs={2}>
                            <TextField
                              {...register(`creatives.${index}.weighting`, {
                                required: {
                                  value: true,
                                  message: 'The field must not be empty',
                                },
                                max: {
                                  value: 100,
                                  message: 'The field value must not exceed 100',
                                },
                                min: {
                                  value: 0,
                                  message: 'The field value must not be less than 0',
                                },
                                pattern: {
                                  value: /^\d+$/,
                                  message: 'The field must contain only numbers',
                                },
                              })}
                              color="secondary"
                              label="Weighting"
                              variant="outlined"
                              fullWidth
                              error={errors?.[index]?.weighting !== undefined}
                              disabled={!weightedDelivery}
                              value={weightedDelivery ? creatives[index].weighting : 'n/a'}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">%</InputAdornment>
                                ),
                                inputProps: { min: 0 }
                              }}
                            />
                          </Grid>

                          <Grid item xs={4} className={classes.relative}>
                            {index === 0 && <p className={classes.label}>Creative Title</p>}
                            <p>{field.name}</p>
                          </Grid>

                          <Grid item xs={2} className={classes.relative}>
                            {index === 0 && <p className={classes.label}>File Size</p>}
                            <p>{field.file_size_bytes}</p>
                          </Grid>

                          <Grid item xs={2} className={classes.relative}>
                            {index === 0 && <p className={classes.label}>Duration</p>}
                            <p>{field.duration}</p>
                          </Grid>

                          <Grid item xs={2} className={classes.relative}>
                            {index === 0 && <p className={classes.label}>Resolution</p>}
                            <p>{field.resolution}</p>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  )}
                </Grid>

                <Grid item xs={1}>
                  <CloseIcon className={classes.close} onClick={onClose}/>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </ModalWrapper>
      </Root>
    </FormProvider>
  );
};

EditWeighting.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

export default EditWeighting;
