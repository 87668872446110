import { map, uniqBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { distinctNames } from '../../../GeoTargeter/constants';
import { GeoItem } from '../../../GeoTargeter/types';
import { entireUSValue } from '../../constants';
import { getActualGeoNameField } from '../../utils';
import { useGeographyFields } from './useGeographyFields';
import { plainItems } from '../constants';

export const useGeography = () => {
  const [focused, setFocused] = useState(new Set());
  const { list, fixedList, remove, append, update } = useGeographyFields();

  const handleRemoveEntireIfOverlaps = useCallback(() => {
    const entireUSIndex = list.findIndex(
      field => field.id === entireUSValue.id || field.name === 'Entire US',
    );

    if (entireUSIndex === -1) return;

    const { blacklist: entireUSBlacklist } = list[entireUSIndex];

    if (list.length > 1) {
      const isOverlapping = list.some(
        (value, index) =>
          index !== entireUSIndex &&
          (getActualGeoNameField(value) !== '' ||
            value.type === distinctNames.zipcodes) &&
          value.blacklist === entireUSBlacklist,
      );
      if (isOverlapping) {
        remove(entireUSIndex);
      }
    }
  }, [list]);

  const handleRemoveDuplicates = useCallback(() => {
    const uniqFields = uniqBy(list, 'id');

    list.forEach((field, idx) => {
      if (!uniqFields.includes(field)) {
        remove(idx);
      }
    });
  }, [list]);

  const handleAddGeography = useCallback(
    (value: GeoItem) => {
      if (
        plainItems.includes(value.type) &&
        list.some(v => v.type === value.type)
      )
        return;

      append({
        ...value,
        blacklist: false,
      });
    },
    [append, list],
  );

  const handleRemove = (index: number) => () => {
    const ids = map(fixedList[index].list, 'id');
    remove(
      list
        .map(({ id }, index) => (ids?.includes(id) ? index : -1))
        .filter(index => index !== -1),
    );
  };

  const handleUpdate = (index: number) => (value: Partial<GeoItem>) => {
    const ids = map(fixedList[index].list, 'id');
    const updatedIndexes = list
      .map(({ id }, index) => (ids?.includes(id) ? index : -1))
      .filter(index => index !== -1);

    updatedIndexes.forEach(index => {
      update(index, {
        ...list[index],
        ...value,
      });
    });
  };

  const handleFocus =
    (index: number) =>
    (isFocused = true) => {
      setFocused(prev => {
        const newSet = new Set(prev);

        if (isFocused) {
          newSet.add(index);
          return newSet;
        }

        newSet.delete(index);
        return newSet;
      });
    };

  const handleBlur = (index: number) => () => {
    handleFocus(index)(false);
  };

  useEffect(() => {
    if (!list.length || !list.some(v => !v.blacklist)) {
      handleAddGeography(entireUSValue);
    }

    handleRemoveEntireIfOverlaps();
    handleRemoveDuplicates();
  }, [
    handleAddGeography,
    handleRemoveEntireIfOverlaps,
    handleRemoveDuplicates,
    list,
  ]);

  return {
    list,
    fixedList,
    focused,
    update: handleUpdate,
    blur: handleBlur,
    focus: handleFocus,
    remove: handleRemove,
    add: handleAddGeography,
  };
};
