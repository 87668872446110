export const cssBaselineStyles = {
  MuiCssBaseline: {
    styleOverrides: {
      body: {
        fontSize: '0.875rem',
      },
    },
    a: ({ theme }) => ({
      color: theme.palette.text.primary,
      textDecoration: 'none',
      '&:hover, &:focus': {
        color: theme.palette.grey[1],
        textDecoration: 'underline',
      },
    }),
  },
};
