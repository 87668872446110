export const alertStyles = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius / 2,
    }),
    standardError: ({ theme }) => ({
      backgroundColor: theme.palette.red[3],
      color: theme.palette.red[4],
    }),
    standardInfo: ({ theme }) => ({
      backgroundColor: theme.palette.blue[10],
      color: theme.palette.blue[1],
    }),
    standardSuccess: ({ theme }) => ({
      backgroundColor: theme.palette.green[10],
      color: theme.palette.green[1],
    }),
    // custom Alert severity "tip"
    standardTip: ({ theme }) => ({
      backgroundColor: theme.palette.tip.light,
      color: theme.palette.tip.dark,
    }),
  },
};
