import { useFieldArray, useFormContext } from 'react-hook-form';
import { fields } from '../../constants';
import { useEffect } from 'react';

export const useEdit = () => {
  const form = useFormContext();

  const { control, trigger, getFieldState } = form;

  const tags = useFieldArray({
    control,
    name: fields.vastTags.path,
    rules: {
      minLength: 1,
    },
  });

  useEffect(() => {
    trigger();
  }, [tags.fields.length]);

  return {
    disabled: getFieldState(fields.vastTags.path).invalid,
    tags,
    form,
  };
};
