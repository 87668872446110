import { useContext, useEffect, useState } from 'react';
import { useAPI } from './api';
import AdvertiserContext from '../AdvertiserContext';
import moment from 'moment-timezone';
import { useGetCampaigns } from '@apis/campaigns';
import { useDomain } from './domain';
import { Campaign } from 'types/campaign';

interface FreqCap {
}

export const useCampaigns = () => {
  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();
  const { useGet, usePatch } = useAPI();

  const [isCapAds, setIsCapAds] = useState(false);
  const [isDisplay, setIsDisplay] = useState(false);
  const [isExperiment, setIsExperiment] = useState(false);
  const [isGoals, setIsGoals] = useState(false);
  const [isIncremental, setIsIncremental] = useState(false);
  const [freqCaps, setFreqCaps] = useState<FreqCap[]>([]);
  const [adCapCount, setAdCapCount] = useState(0);
  const [adCapFreq, setAdCapFreq] = useState(0);
  const [adCapUnit, setAdCapUnit] = useState<'DAY' | 'WEEK' | 'MONTH'>('DAY');
  const [holdOut, setHoldOut] = useState<string | null>(null);
  const [percent, setPercent] = useState('5');
  const [plan, setPlan] = useState('2_WAY_SPLIT');
  const [selectedGoal, setSelectedGoal] = useState<'CPA' | 'CPC' | 'CPM'>('CPA');
  const [cpa, setCPA] = useState('');
  const [displayBudget, setDisplayBudget] = useState(0);
  const { update: updateCampaignCache } = useGetCampaigns();

  useEffect(() => {
    const nextHoldOut = domain.default ? '1PCT_HOLDOUT' : null;

    setHoldOut(nextHoldOut);
  }, [domain.default]);

  const getCampaignData = async (campaignId: number): Promise<Campaign | undefined> => {
    if (campaignId && adContext.id) {
      try {
        const campaignData: Campaign = await useGet(`/campaigns/${campaignId}`);

        if (campaignData.display_pct != null) {
          handleDisplay();
          setPercent(campaignData.display_pct);
        }

        if (campaignData.freq_caps && campaignData.freq_caps.length > 0) {
          setFreqCaps(campaignData.freq_caps);
        }

        if (campaignData.experiment_type && campaignData.experiment_type !== '') {
          handleIncremental();
          setHoldOut(campaignData.experiment_type);
        }

        if (campaignData.goal_amt && campaignData.goal_type) {
          handleGoals();
          handleCPA(campaignData.goal_amt);
          handleSelectedGoal(campaignData.goal_type as 'CPA' | 'CPC' | 'CPM');
        }

        return campaignData;
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleCapAds = (value: boolean) => {
    setIsCapAds(value);
  };

  const handleDisplay = () => {
    setIsDisplay(prev => !prev);
  };

  const handleGoals = () => {
    setIsGoals(prev => !prev);
  };

  const handleExperiment = (value: boolean) => {
    setIsExperiment(value);
  };

  const handleIncremental = () => {
    if (isGoals || isCapAds || isDisplay) {
      setIsCapAds(false);
      setIsDisplay(false);
      setIsGoals(false);
    }
    setIsIncremental(prev => !prev);
  };

  const handleSelectedGoal = (value: 'CPA' | 'CPC' | 'CPM') => {
    setSelectedGoal(value);
  };

  const handleCPA = (value: string) => {
    setCPA(value);
  };

  const updateCampaign = (id: number, data: Partial<Campaign>) => {
    return usePatch(`/campaigns/${id}/`, data)
      .then(response => {
        if (response.data) {
          updateCampaignCache(response.data);
        }
        console.log('response from edit campaign: ', response);
        return response;
      })
      .catch(error => {
        console.error('error in edit campaign', error);
        return error;
      });
  };

  const saveCampaignProgress = async (save: { step: string; exit?: boolean; next?: string }, id: number) => {
    if (save && save.step) {
      if (!save.exit && save.next && id) {
        return await usePatch(`/campaigns/${id}`, {
          progress: save.next,
        });
      }

      if (save.exit) {
        return await usePatch(`/campaigns/${id}/`, {
          progress: save.step,
        });
      }
    }
  };

  const launchCampaign = async ({
    active,
    campaignId,
    daily_budget,
    day_parts,
    endDate,
    endTime,
    name,
    noEndDate,
    payment_profile,
    nbcu_purchase_order,
    startDate,
    startTime,
  }: {
    active: boolean;
    campaignId: number;
    daily_budget: string;
    day_parts: any;
    endDate: string;
    endTime: string;
    name: string;
    noEndDate: boolean;
    payment_profile: string | null;
    nbcu_purchase_order: string;
    startDate: string;
    startTime: string;
  }) => {
    const adUrl = adContext.url;

    const startDateTime = moment.tz(
      `${moment.tz(startDate, 'America/New_York').format('YYYY-MM-DD')} ${moment.tz(startTime, 'America/New_York').format('HH:mm')}`,
      'America/New_York',
    );
    const utcStartDateTime = startDateTime.utc().format();

    let utcEndDateTime = null;
    if (!noEndDate) {
      const endDateTime = moment.tz(
        `${moment(endDate).format('YYYY-MM-DD')} ${moment(endTime).format('HH:mm')}`,
        'America/New_York',
      );
      utcEndDateTime = endDateTime.utc().format();
    }

    const dataObj: Partial<Campaign> = {
      active,
      advertiser: adUrl,
      daily_budget,
      day_parts,
      draft: false,
      end_date: utcEndDateTime,
      freq_cap_duration: null,
      freq_cap_impressions: null,
      freq_cap_type: null,
      experiment_type: null,
      goal_amt: null,
      goal_type: null,
      name,
      payment_profile,
      nbcu_purchase_order,
      start_date: utcStartDateTime,
    };

    if (isCapAds) {
      dataObj.freq_caps = [{
        duration: adCapFreq,
        impressions: adCapCount,
        type: adCapUnit
      }];
    }

    if (isIncremental) {
      dataObj.experiment_type = holdOut;
    }

    if (isGoals) {
      dataObj.goal_amt = cpa;
      dataObj.goal_type = selectedGoal;
    }

    if (isDisplay) {
      dataObj.display_pct = percent;
    }

    console.log('dataObj in campaign', dataObj);

    try {
      const response = await usePatch(`/campaigns/${campaignId}/`, dataObj);
      console.log('Response in launch campaign', response);
      return response;
    } catch (error) {
      console.error('Error in launching Campaign', error);
      return error;
    }
  };

  return {
    campaign: {
      isCapAds,
      isDisplay,
      isExperiment,
      isGoals,
      isIncremental,
      handleCapAds,
      handleDisplay,
      handleExperiment,
      handleGoals,
      handleIncremental,
      adCap: {
        count: adCapCount,
        freq: adCapFreq,
        unit: adCapUnit,
        setCount: setAdCapCount,
        setFreq: setAdCapFreq,
        setUnit: setAdCapUnit,
        freqCaps,
        setFreqCaps,
      },
      incremental: {
        holdOut,
        setHoldOut,
      },
      display: {
        percent,
        setPercent,
        displayBudget,
        setDisplayBudget,
      },
      experiment: {
        plan,
        setPlan,
      },
      goals: {
        optimize: selectedGoal,
        cpa,
        handleOptimize: handleSelectedGoal,
        handleCPA,
      },
    },
    getCampaignData,
    launchCampaign,
    saveCampaignProgress,
    updateCampaign,
  };
};
