import React, { useContext, useEffect, useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AdvertiserContext from './AdvertiserContext';
import AsyncButton from './AsyncButton';
import LoadingSpinner from './ui/LoadingSpinner';
import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import fbIcon from '../images/logo-facebook.png';
import googleIcon from '../images/logo-google-ads.png';
import igIcon from '../images/logo-instagram.png';
import scIcon from '../images/logo-snapchat.png';

const PREFIX = 'AdvertiserCostPerClick';

const classes = {
  channelRow: `${PREFIX}-channelRow`,
  footer: `${PREFIX}-footer`,
  iconWrap: `${PREFIX}-iconWrap`,
  label: `${PREFIX}-label`,
  noWorries: `${PREFIX}-noWorries`,
  subtitle: `${PREFIX}-subtitle`
};

const StyledContainer = styled(Container)((
  {
    theme
  }
) => ({
  [`& .${classes.channelRow}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  [`& .${classes.footer}`]: {
    height: 150,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },

  [`& .${classes.iconWrap}`]: {
    width: 32,
    height: 32,
    marginRight: theme.spacing(2),
  },

  [`& .${classes.label}`]: {
    fontSize: '.875rem',
  },

  [`& .${classes.noWorries}`]: {
    fontSize: '0.625rem',
    color: '#47505d',
  },

  [`& .${classes.subtitle}`]: {
    fontSize: '0.75rem',
    color: '#47505d',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  }
}));

const channelIcons = {
  'Facebook Ads': fbIcon,
  'Google Ads': googleIcon,
  'Instagram Ads': igIcon,
  Snapchat: scIcon,
};

// TODO: IDs for referral sources from current comps only
// Facebook, Google, Instagram and Snapchat in that order
// Will need a fast follow
const referralIds = [
  'Facebook Ads',
  'Google Ads',
  'Instagram Ads',
  'Snapchat',
];

const AdvertiserCostPerClick = props => {

  const adContext = useContext(AdvertiserContext);

  const { useGetAll, usePost } = useAPI();
  const { isLoading: isFetching, setIsLoading: setIsFetching } =
    useLoader(true);
  const { isLoading, setIsLoading } = useLoader();

  const [isLater, setIsLater] = useState(false);
  const [channels, setChannels] = useState([]);
  const [costs, setCosts] = useState({});
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    getReferrals();
  }, []);

  function getReferrals() {
    return useGetAll('/referral_sources', [], total => {
      if (total && total.length > 0) {
        const referrals = total.filter(
          r => r && r.display_name && referralIds.includes(r.display_name)
        );

        referrals.forEach(r => {
          setCosts(prev => ({
            ...prev,
            [r.display_name]: '',
          }));
        });

        setChannels(referrals);
        setIsFetching(false);
      }
    });
  }

  // Handles disabled state of next button
  const isNextDisabled = useMemo(() => {
    if (isLater) {
      return false;
    }

    if (selected && selected.length === 0) {
      return true;
    }

    return selected.some(s => costs[s] === '' || costs[s] === '0');
  }, [selected, costs, isLater]);

  const handleChannelSelected = id => {
    if (!selected.includes(id)) {
      setSelected(prev => [...prev, id]);
    } else {
      setSelected(prev => prev.filter(p => p !== id));
    }
  };

  const handleChannelCost = (id, value) => {
    setCosts(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  // Submits a request for each source selected and with a cost
  const handleSourceData = id => {
    const source = channels.find(r => r.id === id);

    return usePost('/referral_source_costs', {
      advertiser: adContext.url,
      referral_source: source.url,
      cost_per_click: costs[id],
    });
  };

  const handleNext = () => {
    setIsLoading(true);

    const requests = selected.map(s => handleSourceData(s));

    return Promise.all(requests)
      .then(res => {
        console.log('responses from last click cost', res);

        setIsLoading(false);

        if (res) {
          props.onNext();
        }

        return res;
      })
      .catch(error => {
        console.error(error);

        setIsLoading(false);

        throw error;
      });
  };

  const renderChannel = channel => {
    return (
      <Box
        key={channel.id}
        className={classes.channelRow}
        mb={4}
        width="100%"
      >
        <Box width="10%">
          <Checkbox
            checked={selected.includes(channel.id)}
            onChange={() => handleChannelSelected(channel.id)}
            size="small"
          />
        </Box>

        <Box display="flex" alignItems="center" width="45%">
          <div className={classes.iconWrap}>
            <img src={channelIcons[channel.display_name]} width="100%" />
          </div>

          <Typography className={classes.label}>
            {channel.display_name}
          </Typography>
        </Box>

        <Box width="45%">
          <TextField
            fullWidth
            disabled={!selected.includes(channel.id)}
            color="secondary"
            type="number"
            label="Avg. Last Click Cost"
            placeholder="Enter cost"
            variant="outlined"
            value={costs[channel.id]}
            onChange={event =>
              handleChannelCost(channel.id, event.target.value)
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <AttachMoneyIcon fontSize="small" />
                </InputAdornment>
              ),
              inputProps: { min: 0 },
            }}
          />
        </Box>
      </Box>
    );
  };

  return (
    <StyledContainer>
      <Box p={6}>
        <Typography variant="h3">
          Select {adContext.name}&apos;s Last Click Channels and enter Average
          Cost Per Click
        </Typography>

        <Typography className={classes.subtitle}>
          This information will enrich the dashboard and performance reports.
          If you don&apos;t have the info on hand, you can enter it later, or
          modify it at any time in the Advertiser Settings.
        </Typography>

        <Divider />

        <Grid container justifyContent="center">
          <Grid item xs={6}>
            {isFetching ? (
              <Box mt={6} minHeight={418} maxHeight={420}>
                <LoadingSpinner />
              </Box>
            ) : (
              <Box mt={6} minHeight={418} maxHeight={420}>
                {channels.map(c => renderChannel(c))}
              </Box>
            )}
          </Grid>
        </Grid>

        <Box className={classes.footer}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            width="100%"
          >
            <Box>
              <Button
                onClick={props.onBack}
                color="secondary"
                startIcon={<ArrowBackIcon />}
              >
                Back
              </Button>
            </Box>

            <Box display="flex" alignItems="center">
              <Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={isLater}
                      onChange={event => setIsLater(event.target.checked)}
                      name="later"
                      size="small"
                    />
                  }
                  label="I'll do this later"
                />
              </Box>

              <Box
                display="flex"
                justifyContent="center"
                flexDirection="column"
                alignItems="center"
              >
                <Box mb={1} mt={2}>
                  <AsyncButton
                    isDisabled={isNextDisabled}
                    isLoading={isLoading}
                    onClick={handleNext}
                    color="secondary"
                    size="medium"
                    textButton="Next"
                    variant="contained"
                  />
                </Box>

                <Box ml={2}>
                  <Typography className={classes.noWorries}>
                    No worries. You can change this later.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </StyledContainer>
  );
};

AdvertiserCostPerClick.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};

export default AdvertiserCostPerClick;
