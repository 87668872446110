import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, styled } from '@mui/material';

export const TriangleExclamationIconStyled = styled(FontAwesomeIcon)(
  ({ theme }) => ({
    color: theme.palette.warning.main,
    height: '1.25em',
    marginRight: theme.spacing(),
  }),
);

export const ValidationButtonStyled = styled(Button)(
  ({ theme }) => ({
    color: theme.palette.blue[5],
    fontSize: 12,
    fontWeight: 400,
  }),
);
