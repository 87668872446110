import React from 'react';
import { PlaceholderCell } from './PlaceholderCell';
import { TableLeftHeader } from './TableLeft';
import { TableLeft } from './TableLeft/TableLeft';
import { TableRightHeader } from './TableRight';
import { TableRight } from './TableRight/TableRight';
import { addAction, addType, defaultTableSettings } from './constants';
import { useTable } from './hooks/useTable';
import {
  AdvancedListBoxStyled,
  AdvancedSelectedBoxStyled,
  AdvancedWrapperStyled,
} from './styles';
import { transformFromGroupedData, transformToIncludable } from './utils';
import { isNull } from 'lodash';
import { TableLeftVirtualized } from './TableLeft/TableLeftVirtualized';
import { PlaceholderGroupVirtualized } from './PlaceholderGroupVirtualized';
import { PlaceholderGroup } from './PlaceholderGroup';

import 'react-virtualized/styles.css';
import { TableRightVirtualized } from './TableRight/TableRightVirtualized';
import { tableType } from './types';

export const Table = ({
  data,
  onChange,
  dataName,
  selected,
  selectedFilter,
  onFilter,
  minRowsAmount,
  renderCell,
  settings = defaultTableSettings,
  renderGroup,
  transform,
  virtualized = false,
  onIncludeAll,
  onExcludeAll,
  filter,
  ...props
}) => {
  const {
    actualAmount,
    filteredSelected,
    isAddAllHovered,
    setIsAddAllHovered,
    isRemoveAllHovered,
    setIsRemoveAllHovered,
  } = useTable({ data, minRowsAmount, selected, selectedFilter });

  const TableLeftComponent = virtualized ? TableLeftVirtualized : TableLeft;
  const TableRightComponent = virtualized
    ? TableRightVirtualized
    : TableRight;

  return (
    <AdvancedWrapperStyled direction="row" {...props}>
      <AdvancedListBoxStyled flex={1}>
        <TableLeftHeader
          data={data}
          filter={filter}
          onFilter={onFilter}
          onAddAllHovered={setIsAddAllHovered}
          onAddAll={v =>
            onChange(
              [
                ...filteredSelected,
                ...transformFromGroupedData(data, selected, settings),
              ].filter(v => !isNull(v)),
              {
                type: addType.All,
                portion: v,
                action: addAction.Add,
                selected,
              },
            )
          }
        />
        <TableLeftComponent
          data={(transform?.left ? transform.left(data) : data) ?? []}
          actualAmount={actualAmount}
          dataName={dataName}
          renderCell={renderCell}
          renderGroup={renderGroup}
          PlaceholderCell={PlaceholderCell}
          PlaceholderGroup={
            virtualized ? PlaceholderGroupVirtualized : PlaceholderGroup
          }
          isTargeted={isAddAllHovered}
          onChange={v =>
            onChange(
              [transformToIncludable(v, selected, settings), ...selected],
              {
                type: addType.Single,
                portion: [v],
                action: addAction.Add,
                selected,
              },
            )
          }
        />
      </AdvancedListBoxStyled>
      <AdvancedSelectedBoxStyled flex={1}>
        <TableRightHeader
          settings={settings}
          data={selected}
          onRemoveAll={() =>
            onChange([], {
              portion: [],
              type: addType.All,
              action: addAction.Remove,
              selected,
            })
          }
          onIncludeAll={onIncludeAll}
          onExcludeAll={onExcludeAll}
          onRemoveAllHovered={setIsRemoveAllHovered}
        />
        <TableRightComponent
          actualAmount={actualAmount}
          settings={settings}
          data={
            (transform?.right
              ? transform.right(filteredSelected)
              : filteredSelected) ?? []
          }
          PlaceholderCell={PlaceholderCell}
          PlaceholderGroup={
            virtualized ? PlaceholderGroupVirtualized : PlaceholderGroup
          }
          renderCell={renderCell}
          renderGroup={renderGroup}
          isTargeted={isRemoveAllHovered}
          onUpdate={v =>
            onChange(
              selected.map(s => (s.id === v.id ? v : s)),
              {
                type: addType.Single,
                portion: [v],
                action: addAction.Update,
                selected,
              },
            )
          }
          onChange={v =>
            onChange(
              selected.filter(s => s.id !== v.id),
              {
                type: addType.Single,
                portion: [v],
                action: addAction.Remove,
                selected,
              },
            )
          }
          isRemoveAllHovered={isRemoveAllHovered}
        />
      </AdvancedSelectedBoxStyled>
    </AdvancedWrapperStyled>
  );
};

Table.propTypes = {
  ...tableType,
};
