import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog as MuiDialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const StyledDialog = styled(MuiDialog)(({ theme: { spacing } }) => ({
  '& .MuiDialog-paper': {
    padding: spacing(2),
    color: '#13171a',

    '& .MuiTypography-colorTextSecondary': {
      color: 'inherit',
    },

    '& .MuiDialogActions-spacing > :not(:first-child)': {
      marginLeft: spacing(2.5),
    },
  }
}));

const CloseButton = styled(IconButton)(({ theme: { spacing } }) => ({
  position: 'absolute',
  right: spacing(1),
  top: spacing(1),
  color: '#435159',
}));

const StyledDialogContent = styled(DialogContent)(({ theme: { spacing } }) => ({
  paddingTop: spacing(2),
  paddingBottom: spacing(2),
}));

const Dialog = ({
  isOpen,
  title,
  content,
  actions,
  onClose,
}) => (
  <StyledDialog
    fullWidth
    maxWidth="sm"
    open={isOpen}
    aria-labelledby="terms-dialog"
  >
    {onClose && (
      <CloseButton
        aria-label="close"
        onClick={onClose}
        size="large"
      >
        <CloseIcon />
      </CloseButton>
    )}

    <DialogTitle id="terms-dialog">{title}</DialogTitle>

    <Box mx={3}>
      <Divider/>
    </Box>

    <StyledDialogContent>
      {content}
    </StyledDialogContent>

    <DialogActions>
      {actions}
    </DialogActions>
  </StyledDialog>
);

Dialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.element.isRequired,
  actions: PropTypes.element.isRequired,
  onClose: PropTypes.func,
};

export default Dialog;
