import React from 'react';
import PropTypes from 'prop-types';

import InSync from './InSync';
import OutOfSync from './OutOfSync';

const BWSyncStatus = ({ bwStatus, diff, isDisabled }) => {
  if (bwStatus == true && !isDisabled) {
    return <InSync />;
  }

  return <OutOfSync diff={diff} isDisabled={isDisabled}/>;
};

BWSyncStatus.propTypes = {
  bwStatus: PropTypes.bool.isRequired,
  diff: PropTypes.object,
  isDisabled: PropTypes.bool,
};

export default BWSyncStatus;
