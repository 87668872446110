import { Themes } from './themes';

const ProgressesDefault = {
  100: {
    icon: 'Breadcrumb-progress progress--100',
    label: 'Completed',
    status: 'has-progress',
    step: 'completed',
    value: 100,
    completed: true,
  },
  75: {
    icon: 'Breadcrumb-progress progress--75',
    label: 'In Progress',
    status: 'in-progress',
    step: 'in-progress',
    value: 75,
    completed: false,
  },
  50: {
    icon: 'Breadcrumb-progress progress--50',
    label: 'In Progress',
    status: 'in-progress',
    step: 'in-progress',
    value: 50,
    completed: false,
  },
  25: {
    icon: 'Breadcrumb-progress progress--25',
    label: 'In Progress',
    status: 'in-progress',
    step: 'in-progress',
    value: 25,
    completed: false,
  },
  0: {
    icon: 'Breadcrumb-progress progress--0',
    label: 'In Progress',
    status: 'in-progress',
    step: 'in-progress',
    value: 0,
    completed: false,
  },
  '-1': {
    icon: '',
    label: 'Not started',
    status: 'not-started',
    step: '',
    value: -1,
    completed: false,
  },
  default: {
    icon: '',
    label: 'Pending',
    status: 'not-started',
    step: 'pending',
    value: -2,
    completed: false,
  }
};

const ProgressesNbcu = {
  ...ProgressesDefault,
  default: {
    icon: '',
    label: 'Not started',
    status: 'not-started',
    step: '',
    value: -1,
    completed: false,
  }
};

export const BreadcrumbProgressesThemes = {
  [Themes.DEFAULT]: ProgressesDefault,
  [Themes.NBCU]: ProgressesNbcu
}
