import React, { memo, useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useFieldArray, useForm } from 'react-hook-form';
import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { RHFTextField } from '../RHFTextField';
import { yupResolver } from '@hookform/resolvers/yup';
import { vastTagListSchema } from './formConfig';
import { useAPI } from '../hooks/api';
import AdvertiserContext from '../AdvertiserContext';
import { useClearSwrCache } from '../../swr';

const PREFIX = 'VastTagAdding';

const classes = {
  tooltip: `${PREFIX}-tooltip`,
  tooltipText: `${PREFIX}-tooltipText`,
  info: `${PREFIX}-info`,
  deleteButton: `${PREFIX}-deleteButton`,
  actions: `${PREFIX}-actions`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(({
  theme: { spacing }
}) => ({
  [`& .${classes.tooltip}`]: {
    marginRight: '4px',
  },

  [`& .${classes.tooltipText}`]: {
    fontSize: '0.8125rem',
  },

  [`& .${classes.info}`]: {
    color: '#069de0',
  },

  [`& .${classes.deleteButton}`]: {
    margin: '12px 0',
  },

  [`& .${classes.actions}`]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    gap: spacing(2),
  }
}));

const createTagItem = () => ({ name: '', vast_tag_url: '' });

const VastTagAdding = ({ onCreate, showCLose, onClose }) => {
  const adContext = useContext(AdvertiserContext);
  const { clearCache } = useClearSwrCache();

  const { usePost } = useAPI()
  const { control, formState: { isDirty, invalid, isSubmitting }, handleSubmit } = useForm({
    resolver: yupResolver(vastTagListSchema),
    defaultValues: {
      vastTags: [createTagItem()],
    },
  });


  const {
    fields,
    append: appendTagRow,
    remove: removeTagRow,
  } = useFieldArray({
    keyName: 'internalId',
    control,
    name: 'vastTags',
  });

  const onSubmit = handleSubmit(async ({ vastTags }) => {
    const requests = vastTags.map(tag =>
      usePost('/creatives', {
        ...tag,
        advertiser: adContext.url,
      })
    );

    const responses = await Promise.all(requests);
    const data = responses.map(response => response.data);

    clearCache(['/creatives']);
    onCreate(data);
  })

  return (
    (<Root>
      <Box
        border={1}
        borderColor="grey.300"
        p={4}
        mt={2}
        borderRadius="20px"
        maxHeight="380px"
        overflow="auto"
      >
        {fields.map((field, index) => {
          return (
            <Grid
              key={field.internalId}
              container
              alignItems="flex-start"
              spacing={2}
            >
              <Grid item xs={4}>
                <RHFTextField
                  control={control}
                  name={`vastTags.${index}.name`}
                  placeholder="Enter Creative Title"
                  label="Creative Title"
                />
              </Grid>
              <Grid item xs={7}>
                <RHFTextField
                  control={control}
                  name={`vastTags.${index}.vast_tag_url`}
                  placeholder="Enter VAST Tag URL"
                  label="VAST Tag URL"
                />
              </Grid>
              {fields.length > 1 && (
                <Grid item>
                  <IconButton
                    className={classes.deleteButton}
                    onClick={() => removeTagRow(index)}
                    size="small"
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Grid>
              )}
            </Grid>
          );
        })}

        <Grid container alignItems="center" spacing={1}>
          <Grid item>
            <Button
              color="secondary"
              onClick={() => appendTagRow(createTagItem())}
              size="small"
              startIcon={<AddIcon />}
            >
              Add another VAST Tag
            </Button>
          </Grid>

          <Grid container item alignItems="center" xs>
            <Tooltip
              className={classes.tooltip}
              title="VAST Tag 2.0 or later supported"
            >
              <InfoOutlinedIcon fontSize="inherit" className={classes.info} />
            </Tooltip>

            <p className={classes.tooltipText}>
              VAST Tag 2.0 or later supported
            </p>
          </Grid>
        </Grid>

        <div className={classes.actions}>
          {showCLose && (
            <Button
              size="large"
              color="secondary"
              variant="text"
              disabled={isSubmitting}
              onClick={onClose}
            >
              Close
            </Button>
          )}

          <Button
            size="large"
            color="secondary"
            variant="contained"
            disabled={invalid || !isDirty || isSubmitting}
            onClick={onSubmit}
          >
            {isSubmitting ? 'Loading...' : 'Next: Verify Meta Data'}
          </Button>
        </div>
      </Box>
    </Root>)
  );
};

VastTagAdding.propTypes = {
  name: PropTypes.string,
  onCreate: PropTypes.func.isRequired,
  showCLose: PropTypes.bool,
  onClose: PropTypes.func,
};

export default memo(VastTagAdding);
