import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';

// Custom Accordion Components
export const Accordion = styled(MuiAccordion)(() => ({
  boxShadow: 'none',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
  '&$expanded': {
    margin: 'auto',
  },
}));

export const AccordionSummary = styled(MuiAccordionSummary)(() => ({
  backgroundColor: '#e5e7eb',
  borderRadius: 8,
  marginBottom: -1,
  minHeight: 56,

  '&$expanded': {
    minHeight: 56,
  },

  '& .MuiAccordionSummary-content': {
    '&$expanded': {
      margin: '12px 0',
    },
  },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));
