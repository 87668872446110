import { Box, styled } from '@mui/material';

const ModalWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  width: 400,
  background: theme.palette.background.paper,
  borderRadius: `0 0 ${theme.spacing(3.75)} ${theme.spacing(3.75)}`,
  boxShadow: `${theme.spacing(1.25)} ${theme.spacing(10)} ${theme.spacing(8)} ${theme.spacing(1.125)} rgba(0, 0, 0, 0.25)`,
}));

export default ModalWrapper;
