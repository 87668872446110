import { Themes } from '@constants/themes';

export const Copies = {
  [Themes.DEFAULT]: {
    title: 'Review and launch',
  },
  [Themes.NBCU]: {
    title: 'Review and Launch',
  },
};
