import { faRocket } from '@fortawesome/pro-regular-svg-icons';
import React, { Fragment } from 'react';
import {
  SectionPreviewIcon,
  SectionPreviewTypography,
  SectionSubTitle,
  sectionProps,
} from '../../Wizard';
import { useWizardPreview } from '../../Wizard/hooks/useWizardPreview';
import { useCampaignSectionTitles } from './hooks/useCampaignSectionTitles';

const CampaignSectionPreview = ({ sectionKey, campaignId }) => {
  const { active } = useWizardPreview({ key: sectionKey });
  const { title, subtitle } = useCampaignSectionTitles({ campaignId });
  return (
    <Fragment>
      <SectionPreviewIcon isActive={active} icon={faRocket} />
      <SectionPreviewTypography isActive={active}>
        {subtitle && (
          <SectionSubTitle>{subtitle}</SectionSubTitle>
        )}
        {title}
      </SectionPreviewTypography>
    </Fragment>
  );
};

CampaignSectionPreview.propTypes = {
  ...sectionProps,
};

export default CampaignSectionPreview;
