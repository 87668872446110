import { useEffect, useRef } from 'react';

export const useScrollTo = delay => {
  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        ref.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, delay);
    }
  }, []);

  return {
    ref,
  };
};
