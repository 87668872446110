import { useEffect, useMemo } from 'react';

export const useAdvancedTable = ({
  selected,
  selectedTab,
  tabs,
  onFilter,
}) => {
  const tab = useMemo(() => {
    return tabs.find(tab => tab.value === selectedTab) ?? tabs[0];
  }, [selectedTab, tabs]);

  const actionType = useMemo(() => {
    if (!tab.includable) return 'Save';

    let word = '';

    const variants = new Map([
      [true, 'Include'],
      [false, 'Exclude'],
    ]);
    for (const value of selected) {
      if (
        (value.included && word === variants.get(false)) ||
        (!value.included && word === variants.get(true))
      ) {
        word = 'Save';
        break;
      } else {
        word = variants.get(value.included);
      }
    }
    return word;
  }, [selected, tab]);

  useEffect(() => {
    onFilter('');
  }, [selectedTab, onFilter]);

  return {
    actionType,
  };
};
