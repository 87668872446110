import { styled } from '@mui/material/styles';

export const WarningSnackbar = styled('div')(({ theme }) => ({
  backgroundColor: '#fff4e5',
  color: '#663c00',
  fontSize: '0.875rem',
  fontWeight: 400,
  letterSpacing: '.35px',
  maxWidth: 400,

  '& > .notistack-action': {
    color: theme.palette.grey.main,
  },
}));
