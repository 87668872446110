export const updateDeviceOverlay = (state, payload) => {
  return {
    ...state,
    data: {
      ...state.data,
      inventoryStep: {
        ...state.data.inventoryStep,
        deviceOverlayModal: payload,
      },
    },
  }
};

export const updateDeviceTypes = (state, payload) => {
  return {
    ...state,
    data: {
      ...state.data,
      inventoryStep: {
        ...state.data.inventoryStep,
        deviceOverlayModal: {
          ...state.data.inventoryStep.deviceOverlayModal,
          deviceTypes: payload
        },
      },
    },
  }
};

export const updateOperatingSystems = (state, payload) => {
  return {
    ...state,
    data: {
      ...state.data,
      inventoryStep: {
        ...state.data.inventoryStep,
        deviceOverlayModal: {
          ...state.data.inventoryStep.deviceOverlayModal,
          operatingSystems: payload
        },
      },
    },
  }
};
