import * as yup from 'yup';

const phoneRegExp = /^[\d().\-+ ]+$/;
export const joinSchema = yup.object().shape({
  organization_name: yup.string().required('Company Name is required'),
  street_address_1: yup.string().required('Street Address is required'),
  street_address_2: yup.string().optional(),
  city: yup.string().required('City is required'),
  state: yup.string().required('State is required').nullable(),
  zip_code: yup
    .string()
    .length(5, 'Please enter a valid Zip Code')
    .matches(/^[0-9]{5}/, 'Please enter a valid Zip Code')
    .required('Zip Code is required'),
  phone: yup
    .string()
    .required('Phone is required')
    .min(10, 'Phone is not valid')
    .matches(phoneRegExp, 'Phone is not valid'),

  advertiser_name: yup.string().required('Advertiser Name is required'),
  advertiser_website_address: yup
    .string()
    .url('Please enter a valid website address')
    .required('Advertiser Website Address is required'),
  category: yup
    .string()
    .nullable()
    .required('Advertiser Category is required'),
  primary_outcome: yup.string().nullable(),
  sensitive: yup.string().nullable(),
  accepted_terms: yup
    .boolean()
    .isTrue(
      'In order to use the tvScientific Platform you must agree to the Terms and Conditions',
    ),
});
