import React from 'react';
import PropTypes from 'prop-types';
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import amexIcon from '../../images/american-express-dark.svg';
import discoverIcon from '../../images/discover-dark.svg';
import mastercardIcon from '../../images/master-card-dark.svg';
import visaIcon from '../../images/visa-dark.svg';

const formatLastFour = digits => digits.split('XXXX')[1];

const paymentIcons = {
  amex: amexIcon,
  discover: discoverIcon,
  visa: visaIcon,
  mastercard: mastercardIcon,
};

const PaymentMethodCard = ({
  method,
  setSelectedMethod,
  selectedMethod,
  onDelete,
}) => {
  const cardType = method.card_type === 'AmericanExpress' ? 'amex' : method.card_type.toLowerCase();

  return (
    <ListItem
      button
      selected={selectedMethod === method.url}
      onClick={() => setSelectedMethod('selectedCard', method.url)}
    >
      <ListItemIcon>
        <img src={paymentIcons[cardType]} alt={`${cardType} card`} />
      </ListItemIcon>

      <ListItemText
        primary={
          <Typography variant="body2">
            {`${cardType.toUpperCase()} ending in `}
            {`${formatLastFour(method.card_number)}`}
            &nbsp;|&nbsp; {`Expiration ${method.expiration_date}`}
          </Typography>
        }
      />

      <IconButton size="small" aria-label="delete" onClick={(event) => {
        event.stopPropagation();
        onDelete(method);
      }}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </ListItem>
  );
};

PaymentMethodCard.propTypes = {
  method: PropTypes.shape({
    card_type: PropTypes.string.isRequired,
    card_number: PropTypes.string.isRequired,
    expiration_date: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }).isRequired,
  setSelectedMethod: PropTypes.func.isRequired,
  selectedMethod: PropTypes.string,
  onDelete: PropTypes.func.isRequired,
};

export default PaymentMethodCard;
