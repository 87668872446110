import { Stack } from '@mui/material';
import React, { Fragment } from 'react';
import {
  SubwayElementTitle,
  SubwayNode,
  SubwayVerticalDivider,
  sectionProps,
  subwayProps,
  useWizardSubwaySection,
} from '../../Wizard';
import { labels, nodes } from './constants';
import { useCampaignSectionTitles } from './hooks/useCampaignSectionTitles';
import { useGetCampaign } from '@apis/campaigns';

const CampaignSectionSubway = ({ campaignId, sectionKey }) => {
  const { campaign } = useGetCampaign(campaignId);
  const { active, setActiveSection, setActivePane, getPaneStatus } =
    useWizardSubwaySection({
      key: sectionKey,
      defaultPaneKey: labels.nameDates.value,
    });
  const { subtitle } = useCampaignSectionTitles({ campaignId });

  const handleUpdatePane = paneId => () => {
    setActivePane(paneId);
  };

  return (
    <Fragment>
      <SubwayElementTitle
        expanded={active}
        title={campaign?.name ?? 'Campaign'}
        subtitle={subtitle}
        onChange={() => setActiveSection(sectionKey)}
      />

      {active && (
        <Stack alignItems="flex-start" useFlexGap>
          {nodes.map(node => (
            <Fragment key={node.value}>
              <SubwayVerticalDivider length="short" />
              <SubwayNode
                label={node.label}
                disabled={node.disabled}
                onClick={handleUpdatePane(node.value)}
                status={getPaneStatus(node.value)}
              />
            </Fragment>
          ))}
        </Stack>
      )}
    </Fragment>
  );
};

CampaignSectionSubway.propTypes = {
  ...subwayProps,
  ...sectionProps,
};

export default CampaignSectionSubway;
