import React, { Suspense, useEffect } from 'react';
import AppHeader from '../../AppHeader';
import { Box, Container, Stack, Typography } from '@mui/material';
import LoadingSpinner from '../../ui/LoadingSpinner';
import { useHistory, useParams } from 'react-router-dom';
import {
  useGetPlatformInvite,
  useJoinPlatformInvite,
} from '@apis/platformInvite';
import PropTypes from 'prop-types';
import { ErrorBoundary } from '@v2/components/ui/ErrorBoundary';
import AppInviteError from '../AcceptInvitePage/AppInviteError';
import JoinForm from './JoinForm';
import { mapValuesToJoin, validateJoinPlatformInvite } from './utils';
import { RoutePaths } from '@constants/routes';
import { useErrors } from '@hooks/errors';
import { useAuth } from '@hooks/auth';

const JoinDetails = ({ inviteId }) => {
  const { platformInvite } = useGetPlatformInvite(inviteId);
  const { trigger } = useJoinPlatformInvite(inviteId);
  const history = useHistory();
  const { handleError } = useErrors();

  // If they haven't accepted the invite yet, redirect them back to the accept page
  if (platformInvite?.status === 'INVITED') {
    history.replace(`/accept-invite/${inviteId}`);
    return null;
  }

  if (!validateJoinPlatformInvite(platformInvite)) {
    return <AppInviteError />;
  }

  const handleSubmit = async values => {
    try {
      const result = await trigger(mapValuesToJoin(values));
      if (result?.next_step_url) {
        window.location.href = result.next_step_url;
      } else {
        history.push(RoutePaths.HOME);
      }
    } catch (e) {
      handleError(e);
    }
  };

  if (!platformInvite) {
    return null;
  }

  const { legal_company_name } = platformInvite?.context ?? {};
  return (
    <Stack spacing={8}>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        width="100%"
        sx={{ borderBottom: '1px solid #B0BABF', paddingBottom: 2 }}
      >
        <Stack spacing={2}>
          <Typography variant="h3">
            Tell us about{' '}
            {legal_company_name ? legal_company_name : 'your company'}
          </Typography>
          <Typography variant="body1">
            Please review and verify the information below, which is used to
            set up your account.
          </Typography>
        </Stack>
      </Stack>

      <JoinForm onSubmit={handleSubmit} platformInvite={platformInvite} />
    </Stack>
  );
};

JoinDetails.propTypes = {
  inviteId: PropTypes.string.isRequired,
};

const JoinPage = () => {
  const { inviteId } = useParams();

  // Clear the advertiser context if it exists and reload the page so we don't have
  // bad data being sent to the api server
  useEffect(() => {
    if (localStorage.getItem('AdvertiserContext')) {
      localStorage.removeItem('AdvertiserContext');
      window.location.reload();
    }
  }, []);

  const {
    auth,
    authState: { isAuthenticated, isPending },
  } = useAuth();

  useEffect(() => {
    if (!isPending && !isAuthenticated) {
      auth.oktaGetTokensWithRedirect(`/join/${inviteId}`);
    }
  }, [isAuthenticated, isPending]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <AppHeader hidenav="true">
      <Container maxWidth="lg">
        <Box paddingTop={5}>
          <ErrorBoundary
            fallback={<AppInviteError isMissingCode={!inviteId} />}
          >
            <Suspense fallback={<LoadingSpinner />}>
              <JoinDetails inviteId={inviteId} />
            </Suspense>
          </ErrorBoundary>
        </Box>
      </Container>
    </AppHeader>
  );
};

export default JoinPage;
