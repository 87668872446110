import React, { forwardRef } from 'react';

import { styled } from '@mui/material/styles';
import { Switch, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const IncludeExcludeSwitch = styled(Switch)(
  ({ theme, onColor, offColor }) => ({
    '& .MuiSwitch-switchBase': {
      '&.Mui-checked': {
        transform: 'translateX(0%)', // Move the thumb to the left when checked
        '& .MuiSwitch-thumb:before': {
          backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8"><path fill="${encodeURIComponent(
            onColor || theme.palette.success.dark, // on color
          )}" d="M3.18182 5.93284L0.795455 3.58209L0 4.36567L3.18182 7.5L10 0.783582L9.20455 0L3.18182 5.93284Z"/></svg>')`,
        },
        '& + .MuiSwitch-track': {
          backgroundColor: onColor || theme.palette.success.main, // on color
          opacity: 1,
          border: 0,
        },
      },
      '&:not(.Mui-checked)': {
        transform: 'translateX(55%)', // Move the thumb to the right when disabled
      },
    },
    '& .MuiSwitch-thumb': {
      '&::before': {
        content: "''",
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8"><path fill="${encodeURIComponent(
          offColor || theme.palette.red[4],
        )}" d="M8 0.805714L7.19429 0L4 3.19429L0.805714 0L0 0.805714L3.19429 4L0 7.19429L0.805714 8L4 4.80571L7.19429 8L8 7.19429L4.80571 4L8 0.805714Z"/></svg>')`,
      },
    },
    '& .MuiSwitch-track': {
      borderRadius: theme.spacing(1.375),
      backgroundColor: offColor || theme.palette.warning.main, // off color
      opacity: 1,
    },
  }),
);

Switch.propTypes = {
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'success',
    'error',
    'info',
    'warning',
  ]),
};

export const DoubleLabelSwitch = forwardRef(
  (
    {
      leftLabel = 'Include',
      rightLabel = 'Exclude',
      checked,
      onChange,
      children,
      ...rest
    },
    ref,
  ) => (
    <Stack direction="row" alignItems="center" spacing={0.05}>
      <Typography
        variant="body2"
        sx={{
          color: checked ? 'grey.3' : 'grey.1',
          cursor: checked ? 'pointer' : 'inherit',
        }}
        onClick={() => {
          if (checked) {
            onChange({ target: { checked: false } });
          }
        }}
      >
        {leftLabel}
      </Typography>
      {React.cloneElement(children, {
        control: React.cloneElement(children.props.control, {
          checked,
          onChange,
          ref,
          ...rest,
        }),
      })}
      <Typography
        variant="body2"
        sx={{
          color: checked ? 'grey.1' : 'grey.3',
          cursor: checked ? 'inherit' : 'pointer',
        }}
        onClick={() => {
          if (!checked) {
            onChange({ target: { checked: true } });
          }
        }}
      >
        {rightLabel}
      </Typography>
    </Stack>
  ),
);

DoubleLabelSwitch.displayName = 'DoubleLabelSwitch';

DoubleLabelSwitch.propTypes = {
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};
