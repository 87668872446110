import { bundleTypes } from '@components/WizardContainers/GroupSection/constants';
import { hiddenCategories } from '@components/WizardContainers/GroupSection/panes/AdGroupCustomInventoryPane/constants';
import { useMemo } from 'react';
import { useDomain } from '../domain';
import { useLoadInfinite } from './general';
import { useGetRecommendedInventory } from './recommendedInventory';
import { mapInventoryItemToDataItem } from '@components/WizardContainers/GroupSection/utils';
import Bundle from '../../../types/bundle';

export const useGetBundles = ({ campaignId }: { campaignId?: number }) => {
  const domain = useDomain();

  const { items } = useLoadInfinite('bundles/');

  const { data: recommendedInventory } =
    useGetRecommendedInventory(campaignId);

  const bundlesFilter = (bundle: Bundle) =>
    !bundle.ott && bundle.type && !hiddenCategories.includes(bundle.type);

  const peacockFilter = (bundle: Bundle) =>
    bundle.type === bundleTypes.PEACOCK || bundle.private;

  const privateBundlesMap = (bundle: Bundle) => ({
    ...bundle,
    type: bundle.private ? bundleTypes.PRIVATE : bundle.type,
  });

  const bundles = useMemo<Bundle[]>(() => {
    const filter = domain.peacock ? peacockFilter : bundlesFilter;

    return items
      .map(privateBundlesMap)
      .map(i => mapInventoryItemToDataItem(i, recommendedInventory))
      .filter(filter);
  }, [domain.peacock, items, recommendedInventory]);

  return { items: bundles };
};
