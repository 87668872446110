import { useFormContext } from 'react-hook-form';
import { fields } from '../../constants';
import { useCreativeContext } from '../../hooks/useCreativeContext';

export const useByVASTTag = ({ onCreate, onSubmit }) => {
  const form = useFormContext();
  const { toggleAssignCreative } = useCreativeContext();

  const { watch, getFieldState, formState, getValues, resetField } = form;

  const tags = watch(fields.vastTags.path);

  const state = getFieldState(fields.vastTags.path);

  const handleCreate = async () => {
    const created = await onCreate(getValues(fields.vastTags.path));
    created.forEach(item => {
      toggleAssignCreative(item);
    });
    resetField(fields.vastTags.path);
    onSubmit();
  };

  return {
    disabled: state.invalid || formState.isSubmitting,
    create: handleCreate,
    creativeByVastTagDraft: tags,
  };
};
