import { Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';

export const HtmlTooltip = styled(Tooltip)(({ theme }) => ({
  '& .MuiTooltip-tooltip': {
    backgroundColor: '#f2f3f5',
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
  }
}));
