import React from 'react';
import { List, ListItem, Stack, Typography, styled } from '@mui/material';
import PropTypes from 'prop-types';

const GeneralItemTypographyStyled = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
}));

const GeneralListStyled = styled('ul')(() => ({
  listStyleType: 'disc',
  margin: 0,
}));

const GeneralListItemStyled = styled('li')(() => ({
  padding: 0,
}));

const SubListItemStyled = styled(ListItem)(({ theme }) => ({
  alignItems: 'flex-start',
  boxAlign: 'start',
  padding: theme.spacing(0, 1),
}));

export const DataList = ({ data, title, render, singleSubField = false }) => {
  return (
    <GeneralListStyled disablePadding>
      {!!data.length && (
        <GeneralListItemStyled>
          <Stack direction="row">
            <GeneralItemTypographyStyled variant="body2">
              {title}
            </GeneralItemTypographyStyled>
            <List disablePadding>
              {singleSubField ? (
                <SubListItemStyled>{render(data)}</SubListItemStyled>
              ) : (
                data.map((c, idx) => (
                  <SubListItemStyled key={idx}>
                    {render(c, idx)}
                  </SubListItemStyled>
                ))
              )}
            </List>
          </Stack>
        </GeneralListItemStyled>
      )}
    </GeneralListStyled>
  );
};

DataList.propTypes = {
  singleSubField: PropTypes.bool,
  title: PropTypes.string,
  data: PropTypes.array,
  render: PropTypes.func,
};
