import { useEffect } from 'react';

import { useLocation, useHistory } from 'react-router-dom';

import { usePingAuth } from '../hooks/ping';

const NBCULoginCallbackPage = () => {
  const { pingAuth } = usePingAuth();
  const { search } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const urlParams = new URLSearchParams(search);

    const code = urlParams.get('code');
    if (code) {
      pingAuth
        .getAuthTokens(code)
        .then(() => {
          history.push('/');
        })
        .catch(e => {
          console.log('Getting tokens failed', e);
          pingAuth.signOut();
          history.push('/');
        });
    } else {
      // TODO: handle exceptions. redirect?
      console.log('no code...');
    }
  }, []);

  return null;
};

export default NBCULoginCallbackPage;
