import { Themes } from '../constants';

const NBCU_TENANT_ID = '2'


export const getTheme = ({
  orgTenant,
  isNbcuSubdomain
}) => {

  let is_nbcu_tenant;

  if (orgTenant) {
    const [, tenantId] = orgTenant.split('/').reverse();
    is_nbcu_tenant = tenantId === NBCU_TENANT_ID;
  }

  const isAdvertiserLoaded = is_nbcu_tenant !== undefined;

  if (!isAdvertiserLoaded) {
    return isNbcuSubdomain ? Themes.NBCU : Themes.DEFAULT;
  }

  return is_nbcu_tenant ? Themes.NBCU : Themes.DEFAULT;
};

const _domainSubdomainStrategy = (currentUrl) => ({
  inNbcuSubdomain: () => currentUrl.hostname.includes('peacock'),
  getForDefault: () => {
    const nextUrl = new URL(currentUrl);
    nextUrl.hostname = nextUrl.hostname.replace('peacock.', '');
    return nextUrl.toString();
  },
  getForNbcu: () => {
    const nextUrl = new URL(currentUrl);
    nextUrl.hostname = `peacock.${nextUrl.hostname}`;
    return nextUrl.toString();
  }
})

export const getDomainForTheme = ({ theme, currentUrl }) => {
  const nextUrl = new URL(currentUrl);
  const isNbcuTheme = theme === Themes.NBCU;

  const strategy = _domainSubdomainStrategy(nextUrl);

  const inNbcuSubdomain = strategy.inNbcuSubdomain();

  if (isNbcuTheme && !inNbcuSubdomain) {
    return strategy.getForNbcu();
  }

  if (!isNbcuTheme && inNbcuSubdomain) {
    return strategy.getForDefault();
  }
  return null
}
