import { useGetAllowedTargeting } from '@apis/allowedTargeting';
import { useGetAudiences } from '@apis/audiences';
import { useGetAllBidStrategies } from '@apis/bidStrategy';
import { useGetBundles } from '@apis/bundles';
import { useGetCampaign } from '@apis/campaigns';
import { useGetAllCreatives } from '@apis/creatives';
import { useAllDisplays } from '@apis/displays';
import { useTargetingKeys } from '@components/hooks/apis/targetingKeys';
import { weightingRotationOptions } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/AdvancedCreative';
import { customInventoryTypes } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedSubflows/constants';
import {
  customInventoryTabValues,
  zipCodesEmptyValue,
} from '@v2/components/campaign/CampaignAdGroupSection/constants';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo } from 'react';
import { useWizardSection } from '../../../Wizard/hooks/useWizardSection';
import { SCROLL_DELAY } from '../../constants';
import { useScrollTo } from '../../hooks/useScrollTo';
import { fields } from '../formConfig';
import { useGroupSectionForm } from './useGroupSectionForm';
import { useGroupSectionSubmit } from './useGroupSectionSubmit';
import { useGroupSectionsInfo } from './useGroupSectionsInfo';
import { useInventoryAvailability } from './useInventoryAvailabilty';
import { map, uniq } from 'lodash';

export const useGroupSection = ({
  campaignId,
  adGroup,
  sectionKey,
  isDisplay,
}) => {
  const { ref } = useScrollTo(SCROLL_DELAY);
  const { enqueueSnackbar } = useSnackbar();
  const { updateEntityState, activePane } = useWizardSection({
    key: sectionKey,
  });

  const { items: audiences } = useGetAudiences();
  const { campaign } = useGetCampaign(campaignId);
  const { data: bidStrategies } = useGetAllBidStrategies();

  const { data: appBundleList } = useTargetingKeys({
    type: customInventoryTypes[customInventoryTabValues.appBundleList],
  });

  const { data: targeting } = useGetAllowedTargeting();

  const { items: bundles } = useGetBundles({ campaignId });

  const { items: displayLib } = useAllDisplays({
    disabled: !isDisplay,
  });

  const { items: lib } = useGetAllCreatives({
    disabled: !!isDisplay,
  });

  const library = useMemo(
    () => (isDisplay ? displayLib : lib),
    [isDisplay, displayLib, lib],
  );

  const { form } = useGroupSectionForm({
    adGroup,
    bidStrategies,
    bundles,
    library,
    appBundleList,
    audiences,
    targeting,
    campaign,
  });

  const { availability: inventoryAvailability } = useInventoryAvailability({
    form,
    adGroup,
  });

  const { info } = useGroupSectionsInfo({ form });

  const { submit, mutating } = useGroupSectionSubmit({
    campaign,
    adGroup,
    form,
    sectionKey,
    isDisplay,
  });

  const handleSubmitCreatives = ({ creatives, weightingRotation }) => {
    form.setValue(fields.creatives.path, creatives, { shouldDirty: true });
    form.setValue(
      fields.weightingRotation.path,
      weightingRotation
        ? weightingRotationOptions.weighted
        : weightingRotationOptions.random,
      { shouldDIrty: true },
    );
  };
  const handleSubmitCustomInventory = ({ items }) => {
    form.setValue(
      fields.advancedCustomInventory.path,
      items,
      { shouldDirty: true },
    );
  };

  const handleSubmitZipCodes = ({ prevData, data }) => {
    const geo = form.getValues(fields.geography.path);

    const providedIds = uniq([
      ...map(data, 'id'),
      ...map(prevData.data, 'id'),
    ]).filter(v => v);

    const newGeo = geo.filter(({ id }) => !providedIds.includes(id));
    form.setValue(
      fields.geography.path,
      [...newGeo, ...data],
      { shouldDirty: true },
    );
  };

  const handleSubmitAudiences = ({ items }) => {
    form.setValue(fields.advancedAudience.path, items, { shouldDirty: true });
  };

  const handleSubmitAdvancedInventory = items => {
    form.setValue(fields.advancedInventory.path, items, { shouldDirty: true });
  };

  const handleSubmitAdvancedDelivery = data => {
    form.setValue(
      fields.configureAdGroupFrequencyCap.path,
      data[fields.configureAdGroupFrequencyCap.path],
      { shouldDirty: true },
    );
    form.setValue(
      fields.configureAdGroupDayparting.path,
      data[fields.configureAdGroupDayparting.path],
      { shouldDirty: true },
    );
  };

  const handleGeneralError = message => {
    enqueueSnackbar(message, { variant: 'error' });
  };

  const handleResetZipCodes = () => {
    form.setValue(fields.zipcodes.path, zipCodesEmptyValue);
  };

  const {
    formState: { isDirty },
  } = form;

  useEffect(() => {
    return () => {
      lib.forEach(({ preview_url }) => {
        URL.revokeObjectURL(preview_url);
      });
    };
  }, [lib]);

  useEffect(() => {
    updateEntityState(sectionKey, {
      dirty: isDirty,
    });
  }, [isDirty, updateEntityState]);

  return {
    bundles,
    ref,
    form,
    activePane,
    campaign,
    sectionsInfo: info,
    mutating,
    submit,
    submitCreatives: handleSubmitCreatives,
    submitZipCodes: handleSubmitZipCodes,
    submitCustomInventory: handleSubmitCustomInventory,
    submitAdvancedInventory: handleSubmitAdvancedInventory,
    submitAudiences: handleSubmitAudiences,
    submitAdvancedDelivery: handleSubmitAdvancedDelivery,
    inventoryAvailability,
    resetZipCodes: handleResetZipCodes,
    generalError: handleGeneralError,
    isActivePane: paneKey => activePane === paneKey,
  };
};
