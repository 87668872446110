import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Box, Chip } from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { formatStatus } from './util';
import AdvertiserContext from './AdvertiserContext';
import { OrgRoleLabelMap } from '../constants';

const PREFIX = 'OrgChips';

const classes = {
  active: `${PREFIX}-active`,
  closed: `${PREFIX}-closed`,
  disabled: `${PREFIX}-disabled`,
  draft: `${PREFIX}-draft`,
  under_review: `${PREFIX}-under_review`,
  admin: `${PREFIX}-admin`,
  chip: `${PREFIX}-chip`,
  type: `${PREFIX}-type`
};

const StyledBox = styled(Box)(({
  theme: { spacing }
}) => ({
  [`& .${classes.active}`]: {
    backgroundColor: '#C2F2E2',
    color: '#0FBF84',
  },

  [`& .${classes.closed}`]: {
    backgroundColor: '#FFDEE2',
    color: '#AC0029',
  },

  [`& .${classes.disabled}`]: {
    backgroundColor: '#EDF1F2',
    color: '#77858C',
  },

  [`& .${classes.draft}`]: {
    backgroundColor: '#ff9800',
    color: '#fff',
  },

  [`& .${classes.under_review}`]: {
    backgroundColor: '#D9F1FF',
    color: '#1DAFFF',
  },

  [`& .${classes.admin}`]: {
    backgroundColor: '#bfe7ff',
    color: '#2592cf',
  },

  [`& .${classes.chip}`]: {
    marginLeft: spacing(2),
    padding: spacing(1),
  },

  [`& .${classes.type}`]: {
    textTransform: 'capitalize',
  }
}));

export const OrgChips = ({ org }) => {
  const adContext = useContext(AdvertiserContext);

  return (
    <StyledBox width="100%" display="flex" justifyContent="flex-end">
      {org?.type && (
        <Chip
          className={clsx(classes.chip, classes.type)}
          label={org.type.toLowerCase()}
        />
      )}

      <Chip
        className={clsx(classes.chip, classes.admin)}
        icon={<PersonOutlineIcon fontSize="small" />}
        label={OrgRoleLabelMap[adContext?.org_role]}
      />

      {org?.status && (
        <Chip
          className={clsx(classes.chip, classes[org.status.toLowerCase()])}
          label={formatStatus(org.status)}
        />
      )}
    </StyledBox>
  );
};

OrgChips.propTypes = {
  org: PropTypes.object,
};
