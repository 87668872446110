import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, Tabs } from '@mui/material';
import { useHistory } from 'react-router-dom';

import AdvertiserContext from '../AdvertiserContext';
import AppHeader from '../AppHeader';
import BillingTable from '../BillingAccounts';
import PaymentMethods from '../PaymentMethods';
import LoadingSpinner from '../ui/LoadingSpinner';
import { useLoader } from '../hooks/loader';
import { StyledTab, TabPanel } from '../TabPanel';
import AdvertiserTab from '../AdvertisterTab';
import { useDomain } from '@hooks/domain';

const PREFIX = 'Settings';

const classes = {
  root: `${PREFIX}-root`,
  tab: `${PREFIX}-tab`,
  helperTextWrap: `${PREFIX}-helperTextWrap`,
  info: `${PREFIX}-info`,
  paper: `${PREFIX}-paper`,
  submit: `${PREFIX}-submit`,
  subtitle: `${PREFIX}-subtitle`,
  success: `${PREFIX}-success`,
  successMsg: `${PREFIX}-successMsg`,
  title: `${PREFIX}-title`,
  tooltip: `${PREFIX}-tooltip`,
  wrap: `${PREFIX}-wrap`,
  headerWrapper: `${PREFIX}-headerWrapper`,
  tabWrapper: `${PREFIX}-tabWrapper`
};

const StyledAppHeader = styled(AppHeader)(({
  theme: { palette, spacing, typography }
}) => ({
  [`& .${classes.root}`]: {
    flexGrow: 1,
  },

  [`& .${classes.tab}`]: {
    fontSize: "1.5rem",
    fontWeight: "normal",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.33,
    letterSpacing: "normal",
    textAlign: "right",
    color: "#5c6b73",
  },

  [`& .${classes.helperTextWrap}`]: {
    display: 'inline-block',
  },

  [`& .${classes.info}`]: {
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'text-top',
    width: 14,
    height: 14,
  },

  [`& .${classes.paper}`]: {
    paddingTop: spacing(5),
    paddingBottom: spacing(5),
    paddingLeft: spacing(9),
    paddingRight: spacing(9),
    maxWidth: 900,
    margin: 'auto',
  },

  [`& .${classes.submit}`]: {
    ...typography.h4,
    color: '#fff',
    marginLeft: spacing(2),
    padding: spacing(2),
    width: 175,
  },

  [`& .${classes.subtitle}`]: {
    ...typography.subtitle1,
    fontSize: '1.125rem',
  },

  [`& .${classes.success}`]: {
    backgroundColor: palette.primary.light,
  },

  [`& .${classes.successMsg}`]: {
    color: palette.primary.light,
  },

  [`& .${classes.title}`]: {
    ...typography.h3,
  },

  [`& .${classes.tooltip}`]: {
    backgroundColor: `#f2f3f5`,
    color: palette.text.primary,
    padding: spacing(2),
    maxWidth: 180,
  },

  [`& .${classes.wrap}`]: {
    height: 'calc(100% - 64px)',
  },

  [`& .${classes.headerWrapper}`]: {
    marginTop: 70,
    marginBottom: 100,
    textAlign: 'right'
  },

  [`& .${classes.tabWrapper}`]: {
    marginTop: 35,
  }
}));

const Settings = (props) => {

  const [value, setValue] = React.useState(0);
  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();
  const { setIsLoading } = useLoader()
  const history = useHistory();

  const [name, setName] = useState('')

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (adContext && adContext.id) {
      setIsLoading(false);
      setName(adContext.name)
    }
  }, [adContext])

  return (
    <StyledAppHeader>
      <div className={classes.root}>
        <Grid container alignItems="center" justifyContent="center" style={{ minHeight: '100vh' }}>
          <Grid item xs={10}>
            {!adContext.id ? <LoadingSpinner /> : (<Paper elevation={0} style={{ paddingLeft: 40, paddingRight: 40, minHeight: '80vh' }}>
              <Grid container>
                <Grid item xs={3}>
                  <div className={classes.headerWrapper}>
                    <Typography component={'h3'} >BUSINESS MANAGER</Typography>
                    <Typography>{name}</Typography>
                  </div>
                  <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="secondary"
                    className={classes.tabs}
                  >
                    <StyledTab className={classes.tab} label="Advertiser Settings" />
                    <StyledTab className={classes.tab} label="Billing Accounts" onClick={() => { history.push('/settings') }} />
                    {domain.default && (
                      <StyledTab className={classes.tab} label="Payment Methods" />
                    )}
                  </Tabs>
                </Grid>
                <Grid item xs={9} className={classes.tabWrapper}>
                  <TabPanel value={value} index={0}>
                    <AdvertiserTab {...props} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <BillingTable handleChange={handleChange} />
                  </TabPanel>
                  {domain.default && (
                    <TabPanel value={value} index={2}>
                      <PaymentMethods />
                    </TabPanel>
                  )}
                </Grid>
              </Grid>
            </Paper>)}
          </Grid>
        </Grid>
      </div>
    </StyledAppHeader>
  );
}


Settings.propTypes = {
  history: PropTypes.object,
};

export default Settings;
