import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';

const PrivateRoute = ({
  route: CustomRoute = Route,
  component: Component,
  redirectTo,
  isAllowed,
  isLoading,
  ...rest
}) => {
  if (isLoading) {
    return null;
  }
  return (
    <CustomRoute
      {...rest}
      render={props => {
        if(isAllowed) {
          return <Component {...props} />;
        }

        return <Redirect to={{ pathname: redirectTo }} />;
      }}
    />
  );
};

PrivateRoute.propTypes = {
  route: PropTypes.func,
  component: PropTypes.func.isRequired,
  redirectTo: PropTypes.string.isRequired,
  isAllowed: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
};
export default PrivateRoute;
