import { useContext } from 'react';
import CurrentSessionContext from '../../providers/CurrentSessionContext';
import Advertiser from 'types/advertiser';

type CurrentSessionContextType = {
  apiIsReady: boolean;
  get: (url: string, params?: any) => Promise<any>;
  getV1: (url: string, params?: any) => Promise<any>;
  post: (url: string, data: any) => Promise<any>;
  postV1: (url: string, data: any, options?: any) => Promise<any>;
  patch: (url: string, data: any) => Promise<any>;
  patchV1: (url: string, data: any) => Promise<any>;
  del: (url: string, params?: any) => Promise<any>;
  delV1: (url: string, params?: any) => Promise<any>;
  currentAdvertiser: Advertiser;
  setCurrentAdvertiser: React.Dispatch<React.SetStateAction<Advertiser>>;
  updateCurrentAdvertiser: (data: Partial<Advertiser>) => void;
};

export const useCurrentSession = (): CurrentSessionContextType => {
  const currentSessionContext = useContext(CurrentSessionContext);

  if (!currentSessionContext) {
    throw new Error(
      'useCurrentSession CurrentSessionContext was used outside of its Provider',
    );
  }

  return currentSessionContext;
};
