import { OrganizationRoles } from '@constants';
import { useOrg } from './org';
import { keys, reduce } from 'lodash';

/**
 * @returns {Record<keyof typeof OrganizationRoles, boolean>}
 */
export const useRoles = () => {
  const { org } = useOrg();

  return reduce(
    keys(OrganizationRoles),
    (acc, key) => ({
      ...acc,
      [key]: org?.organization_role === OrganizationRoles[key],
    }),
    {},
  );
};
