import { Box, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import PropTypes from 'prop-types';

import { RHFSelect } from '../../RHFSelect';
import { outcomes } from '../../../constants/outcomes';

const PREFIX = 'AttributionRoleFieldset';

const classes = {
  dropdown: `${PREFIX}-dropdown`
};

const StyledBox = styled(Box)(() => ({
  [`& .${classes.dropdown}`]: {
    width: '100%',
    maxWidth: '300px',
  }
}));

const attribution = {
  model: [
    { label: 'Last Touch', value: 'LAST_TOUCH' },
    { label: 'First Touch', value: 'FIRST_TOUCH' },
    { label: 'Linear', value: 'LINEAR' },
  ],
  window: [
    { label: '30 days', value: '30' },
    { label: '14 days', value: '14' },
    { label: '7 days', value: '7' },
    { label: '3 days', value: '3' },
    { label: '2 days', value: '2' },
    { label: '1 day', value: '1' },
  ],
};


export const AttributionRoleFieldset = ({
  control,
  primaryOutcomeName,
  attModelName,
  attWindowName,
}) => (
  <StyledBox>
    <RHFSelect
      label="Primary Outcome"
      className={classes.dropdown}
      name={primaryOutcomeName}
      control={control}
    >
      {outcomes.map(k => (
        <MenuItem key={k.value} value={k.value}>
          {k.label}
        </MenuItem>
      ))}
    </RHFSelect>

    <Box width="100%" mt={3} display="flex" gap="16px">
      <RHFSelect
        className={classes.dropdown}
        label="Attribution Model"
        name={attModelName}
        control={control}
      >
        {attribution.model.map(m => (
          <MenuItem key={m.value} value={m.value}>
            {m.label}
          </MenuItem>
        ))}
      </RHFSelect>

      <RHFSelect
        className={classes.dropdown}
        label="Attribution Window"
        name={attWindowName}
        control={control}
      >
        {attribution.window.map(w => (
          <MenuItem key={w.value} value={w.value}>
            {w.label}
          </MenuItem>
        ))}
      </RHFSelect>
    </Box>
  </StyledBox>
);

AttributionRoleFieldset.propTypes = {
  control: PropTypes.object.isRequired,
  primaryOutcomeName: PropTypes.string.isRequired,
  attWindowName: PropTypes.string.isRequired,
  attModelName: PropTypes.string.isRequired,
};
