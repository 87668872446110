import React from 'react';
import PropTypes from 'prop-types';
import CheckIcon from '@mui/icons-material/Check';

/////////////////////////////
// BREADCRUMBS COMPONENT
/////////////////////////////
const Breadcrumbs = ({
  breadcrumbsStatus,
  lastStepElement,
  title,
  ...rest
}) => {
  const {
    createCampaign,
    targeting,
    creative,
    tracking,
    launch,
  } = breadcrumbsStatus;

  const stepNames = Object.getOwnPropertyNames(breadcrumbsStatus).map(n => {
    if (n === `createCampaign`) {
      return title;
    }

    return n.replace(/^\w/, (c) => c.toUpperCase());
  });

  // Render functions
  const renderStep = (progress, index) => {
    const isLastStep = index === 4;
    const showLastStepElement = isLastStep && lastStepElement;

    return (
      <div className={`Breadcrumb-step ${progress.step}`} key={index}>
        <div className={`Breadcrumb-icon ${progress.icon}`}>
          <div className="Breadcrumb-number">
            {!progress.completed && showLastStepElement}
            {!progress.completed && !showLastStepElement && <span>{index + 1}</span>}
            {progress.completed && <CheckIcon />}
          </div>
        </div>

        <div className="Breadcrumb-content">
          <h3 className="Breadcrumb-title">{stepNames[index]}</h3>

          <div className={`Breadcrumb-status ${progress.status}`}>
            {progress.label}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div {...rest}>
      {[
        createCampaign,
        targeting,
        creative,
        tracking,
        launch
      ].map((step, index) => renderStep(step, index))}
    </div>
  )
};

Breadcrumbs.propTypes = {
  title: PropTypes.string,
  breadcrumbsStatus: PropTypes.object,
  breadcrumbs: PropTypes.object,
  lastStepElement: PropTypes.element,
};

export default Breadcrumbs;
