import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, styled } from '@mui/material';
import Typography from '@mui/material/Typography';

const ENTER_TOOLTIP_DELAY = 600;

const TruncatedTextTooltip = ({
  text,
  subtitle,
  isExpanded,
}) => {
  const textRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const StyledTypography = styled(Typography)(({ theme }) => ({
    color: isExpanded ? theme.palette.common['black'] : theme.palette.grey[4],
    lineHeight: 'normal',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: '1.125rem',
    textAlign: 'left',
  }));

  const StyledSubTitle = styled(Typography)(({ theme }) => ({
    color: isExpanded ? theme.palette.common['black'] : theme.palette.grey[4],
    fontSize: '0.75rem',
    fontWeight: 400,
    textAlign: 'left',
  }));

  // Determine whether the text is truncated
  // ref: https://stackoverflow.com/a/69968815
  const checkTruncation = () => {
    const element = textRef.current;
    if (element && element.scrollWidth > element.clientWidth) {
      setIsTruncated(true);
    } else {
      setIsTruncated(false);
    }
  };

  useEffect(() => {
    checkTruncation();
  }, [text]);

  return (
    <Tooltip
      title={text}
      placement="bottom-end"
      disableHoverListener={!isTruncated}
      enterNextDelay={ENTER_TOOLTIP_DELAY}
    >
      {subtitle && (
        <StyledSubTitle noWrap variant="subtitle2">
          {subtitle}
        </StyledSubTitle>
      )}
      <StyledTypography noWrap ref={textRef} variant="h4">
        {text}
      </StyledTypography>
    </Tooltip>
  );
};

TruncatedTextTooltip.propTypes = {
  text: PropTypes.string,
  subtitle: PropTypes.string,
  isExpanded: PropTypes.bool.isRequired,
};

export default TruncatedTextTooltip;
