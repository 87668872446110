import moment from 'moment-timezone';

export const validateAcceptPlatformInvite = platformInvite => {
  // Don't allow an accepted invite
  if (platformInvite?.status !== 'INVITED') {
    return false;
  }

  // If it is expired, throw an error
  if (
    platformInvite?.expiry &&
    moment(platformInvite.expiry).isBefore(new Date())
  ) {
    return false;
  }

  return true;
};
