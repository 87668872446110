import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import wrenchAnimation from '../../../images/animation/wrench.json';

export const Icon = forwardRef(({ style, ...props }, ref) => (
  <span ref={ref}>
    <Lottie
      style={{
        width: '40px',
        height: '40px',
        margin: '0 auto',
        ...style,
      }}
      animationData={wrenchAnimation}
      loop
      {...props}
    />
  </span>
));

Icon.displayName = 'Icon';
Icon.propTypes = {
  style: PropTypes.object,
};
