import { Button, Stack, styled } from '@mui/material';
import { omit } from 'lodash';
import { PropTypes } from 'prop-types';
import React from 'react';
import { Advanced } from '../../Advanced';
import { useAdvancedTable } from './hooks/useAdvancedTable';
import { FormProvider } from 'react-hook-form';
import { AdvancedBoxFooter } from '@v2/components/campaign/CampaignAdGroupSection/AdvancedBoxFooter';

const ContentWrapperStyled = styled(Stack)(() => ({
  flex: 1,
  overflowY: 'auto',
}));

const FormStyled = styled('form')(({ style }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  maxHeight: '100%',
  ...style,
}));

const TableWrapperStyled = styled(Stack)(() => ({
  flex: 1, // Ensures the table takes up available space
}));

export const AdvancedTable = ({
  tabs,
  onClose,
  onSubmit,
  selected,
  selectedFilter,
  total,
  form,
  onChangeTab,
  selectedTab,
  transform,
  onFilter,
  formProps,
  ...props
}) => {
  const { actionType } = useAdvancedTable({
    selected,
    selectedTab,
    tabs,
    onFilter,
  });

  return (
    <FormStyled {...formProps} onSubmit={form.handleSubmit(onSubmit)}>
      <FormProvider {...form}>
        <ContentWrapperStyled spacing={6}>
          <TableWrapperStyled>
            <Advanced
              {...props}
              selectedTab={selectedTab}
              minRowsAmount={10}
              selected={selected}
              selectedFilter={selectedFilter}
              tabs={tabs}
              transform={transform}
              onFilter={onFilter}
              onChangeTab={v => {
                onChangeTab(tabs.find(tab => tab.value === v));
              }}
            />
          </TableWrapperStyled>
        </ContentWrapperStyled>

        <AdvancedBoxFooter>
          <Button color="secondary" variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button
            type="submit"
            disabled={!form.formState.isValid}
            variant="contained"
            data-testid="save-button"
          >
            {actionType} {total ?? selected.length} results
          </Button>
        </AdvancedBoxFooter>
      </FormProvider>
    </FormStyled>
  );
};

AdvancedTable.propTypes = {
  ...omit(Advanced.propTypes, ['selectedTab', 'onChangeTab']),
  onClose: PropTypes.func.isRequired,
  total: PropTypes.number,
  transform: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
  onChangeTab: PropTypes.func.isRequired,
  form: PropTypes.object.isRequired,
  formProps: PropTypes.object,
};
