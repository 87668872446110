export const buttonStyles = {
  styleOverrides: {
    root: {},
    // button sizes
    sizeSmall: ({ theme }) => ({
      padding: theme.spacing(0.5, 2),
      fontSize: '1rem',
      borderRadius: theme.spacing(.75),
    }),
    sizeMedium: ({ theme }) => ({
      padding: theme.spacing(1, 3),
      fontSize: '1.25rem',
      // make the icons slightly bigger than the text
      '& .MuiButton-startIcon>*:nth-of-type(1), & .MuiButton-endIcon>*:nth-of-type(1)':
        {
          fontSize: '24px !important',
        },
      '& .MuiButton-startIcon': {
        marginRight: theme.spacing(1.5),
      },
      '& .MuiButton-endIcon': {
        marginLeft: theme.spacing(1.5),
      },
      // override the padding for outlined buttons
      '&.MuiButton-outlined': {
        padding: theme.spacing(1.375, 3), //'11px 24px',
        borderWidth: theme.spacing(0.25),
      },
    }),
    sizeLarge: ({ theme }) => ({
      padding: theme.spacing(2, 4),
      fontSize: '1.5rem',
    }),
    // contained buttons
    contained: ({ theme }) => ({
      boxShadow:
        '3px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
      '&.Mui-disabled': {
        color: theme.palette.primary.contrast,
        backgroundColor: theme.palette.grey[12],
      },
      '&:active': {
        boxShadow: 'none',
      },
    }),
    containedPrimary: ({ theme }) => ({
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.light,
      },
      '&:active': {
        backgroundColor: theme.palette.primary.dark,
      },
    }),
    containedWarning: ({ theme }) => ({
      backgroundColor: theme.palette.warning.main,
      '&:hover': {
        backgroundColor: theme.palette.warning.light,
      },
      '&:active': {
        backgroundColor: theme.palette.warning.dark,
      },
    }),
    outlinedWarning: ({ theme }) => ({
      borderColor: theme.palette.grey[3],
      color: theme.palette.grey[3],
      '&:hover': {
        borderColor: theme.palette.red[1],
        color: theme.palette.red[1],
        backgroundColor: 'transparent',
      },
      '&:active': {
        borderColor: theme.palette.red[4],
        color: theme.palette.red[4],
      },
    }),
    // outlined buttons
    outlined: ({ theme }) => ({
      '&:hover': {
        borderColor: theme.palette.blue[11],
        backgroundColor: theme.palette.blue[11],
        color: theme.palette.blue[4],
      },
      '&:active': {
        color: theme.palette.blue[4],
      },
    }),
    // text buttons
    textPrimary: ({ theme }) => ({
      color: theme.palette.grey[1],
      '&:hover': {
        color: theme.palette.grey[2],
        backgroundColor: 'transparent',
      },
    }),
  },
};
