import React from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { FormControlLabel, Grid, Switch, Typography } from '@mui/material';
const PREFIX = 'ToggleSwitch';

const classes = {
  switchBase: `${PREFIX}-switchBase`,
  checked: `${PREFIX}-checked`,
  track: `${PREFIX}-track`,
  label: `${PREFIX}-label`
};

const StyledGrid = styled(Grid)(() => ({
  [`& .${classes.label}`]: {
    marginLeft: 0,
    marginRight: 0,
  }
}));

const CustomSwitch = Switch;

const ToggleSwitch = props => {


  return (
    <StyledGrid component="label" container alignItems="center" item xs={12}>
      <Grid item>
        <Typography
          variant="body2"
          style={{
            fontSize: '.75rem',
            color: '#47505d',
            opacity: props.checked ? .6 : 1,
          }}
        >
          {props.leftLabel}
        </Typography>
      </Grid>

      <Grid item>
        <FormControlLabel
          className={classes.label}
          control={
            <CustomSwitch
              disableRipple
              edge={props.edge}
              checked={props.checked}
              size={props.size}
              onChange={props.onChange}
              classes={{
                switchBase: classes.switchBase,
                checked: classes.checked,
                track: classes.track
              }} />
          }
          label={
            <Typography
              style={{ fontSize: '.75rem', color: '#47505d'}}
              variant="body2"
            >
              {props.label}
            </Typography>
          }
          labelPlacement={props.labelPlacement ? props.labelPlacement : 'end'}
        />
      </Grid>

      <Grid item>
        <Typography
          variant="body2"
          style={{
            fontSize: '.75rem',
            color: '#47505d',
            opacity: props.checked ? 1 : .6,
          }}
        >
          {props.rightLabel}
        </Typography>
      </Grid>
    </StyledGrid>
  );
};

ToggleSwitch.propTypes = {
  checked: PropTypes.bool,
  edge: PropTypes.string,
  label: PropTypes.string,
  labelPlacement: PropTypes.string,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
  onChange: PropTypes.func,
  size: PropTypes.string,
};

export default ToggleSwitch;
