import React from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import { useDimensions } from './hooks/dimensions';
const PREFIX = 'ImageSpecifications';

const classes = {
  dimensions: `${PREFIX}-dimensions`,
  specs: `${PREFIX}-specs`,
  supported: `${PREFIX}-supported`
};

const Root = styled('div')(({
  theme: { spacing }
}) => ({
  [`& .${classes.dimensions}`]: {
    margin: '0 auto',
    maxWidth: '60%',
    fontSize: '0.825rem',
  },

  [`&.${classes.specs}`]: {
    marginTop: spacing(1),
  },

  [`& .${classes.supported}`]: {
    marginBottom: 6,
  }
}));

const ImageSpecifications = () => {

  const { dimensions } = useDimensions();

  return (
    <Root className={clsx("Dropzone-specs", classes.specs)}>
      <p>Supported file formats: PNG, JPEG, GIF</p>

      <p className={classes.supported}>Supported dimensions:</p>

      <p className={classes.dimensions}>
        {dimensions && dimensions.length > 0 &&
          dimensions.map((d, i) =>
            i === dimensions.length - 1
              ? d.creative_size_name
              : `${d.creative_size_name}, `
          )}
      </p>

      <p>Max File Size: 100MB</p>
    </Root>
  );
};

export default ImageSpecifications;
