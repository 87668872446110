import { useState } from 'react';

export const useTabs = tabs => {
  const [tab, setTab] = useState(tabs[0].value);

  return {
    tab,
    tabs,
    setTab: v => setTab(v.value),
  };
};
