import {
  DistinctNamesValues,
  distinctNames,
} from '@v2/components/campaign/GeoTargeter';
import { useGroupedGeographyItemArgs } from '../types';
import { useState } from 'react';

export const useGroupedGeographyItem = ({
  value,
}: useGroupedGeographyItemArgs) => {
  const [expanded, setExpanded] = useState(false);
  const plainItems = [
    distinctNames.countries,
    distinctNames.empty,
    distinctNames.country,
  ] as DistinctNamesValues[];

  const shownValues = expanded ? value.list : value.list.slice(0, 3);

  return {
    plainItems,
    expanded,
    isExpandable: value.list.length > 3,
    shownValues,
    setExpanded,
  };
};
