import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography } from '@mui/material';

import FileDropzone from './FileDropzone';
import FileUploadPreview from './FileUploadPreview';
import { faSquareInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FileUploader = ({
  files,
  onRemove,
  weightedRotation = false,
  ...props
}) => {
  return files.length === 0 ? (
    <FileDropzone {...props} />
  ) : (
    <Box sx={{ mt: 2 }}>
      {files.map((file, index) => (
        <FileUploadPreview
          key={index}
          index={index}
          filesLength={files.length}
          file={file}
          onDelete={onRemove}
          progress={file.progress || 0}
          weightedRotation={weightedRotation}
        />
      ))}
      <Typography
        variant="body2"
        sx={{ display: 'flex', alignItems: 'center', mt: 3, ml: 2, mb: 4 }}
      >
        <FontAwesomeIcon icon={faSquareInfo} />
        &nbsp;We will check your file formatting to ensure it meets minimum
        quality requirements. Issues will be automatically fixed in most
        cases.
      </Typography>
    </Box>
  );
};

// PropType validation
FileUploader.propTypes = {
  accept: PropTypes.string,
  type: PropTypes.string,
  files: PropTypes.array.isRequired,
  onDrop: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  weightedRotation: PropTypes.bool,
};

// Default props
FileUploader.defaultProps = {
  type: 'video',
};

export default FileUploader;
