import { useState } from 'react';

export const useTable = ({
  minRowsAmount = 0,
  data,
  selected,
  selectedFilter,
}) => {
  const [isAddAllHovered, setIsAddAllHovered] = useState(false);
  const [isRemoveAllHovered, setIsRemoveAllHovered] = useState(false);

  const dataLength = !data.length
    ? 0
    : data[0].groupName
      ? data.reduce((acc, curr) => acc + curr.data.length, 0)
      : data.length;

  const filteredSelected = selectedFilter
    ? selectedFilter(selected)
    : selected;

  const actualAmount = Math.max(
    dataLength,
    filteredSelected.length,
    minRowsAmount,
  );

  return {
    filteredSelected,
    actualAmount,
    isAddAllHovered,
    setIsAddAllHovered,
    isRemoveAllHovered,
    setIsRemoveAllHovered,
  };
};
