import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { useCurrentSession } from '../currentSession';

interface TermsAndConditionsResponse {
  id: string;
  content: string;
  version: string;
  createdAt: string;
  updatedAt: string;
}

export const useGetLatestTenantTermAndCondition = (
  id: string | number,
  options?: SWRConfiguration
): SWRResponse<TermsAndConditionsResponse, Error> => {
  const { getV1 } = useCurrentSession();

  return useSWR<TermsAndConditionsResponse, Error>(
    { url: `/terms_and_conditions/latest/${id}/` },
    ({ url, params }) => getV1(url, { params })
      .then(res => res.data),
    options,
  );};
