import {
  WrenchQCTooltip,
  Content as WrenchQCTooltipContent,
  Icon as WrenchQCTooltipIcon,
} from '../components/tooltips/WrenchQCTooltip';
import {
  WarningQCTooltip,
  Content as WarningQCTooltipContent,
  Icon as WarningQCTooltipIcon,
} from '../components/tooltips/WarningQCTooltip';

const PROCESSING = 'PROCESSING';
const FAILED = 'FAILED';
const PENDING = 'PENDING';

export const qcStatuses = {
  PENDING,
  PROCESSING,
  FAILED,
};

export const qcStatusTooltips = {
  [PENDING]: WrenchQCTooltip,
  [PROCESSING]: WrenchQCTooltip,
  [FAILED]: WarningQCTooltip,
};

export const qcStatusIcons = {
  [PENDING]: WrenchQCTooltipIcon,
  [PROCESSING]: WrenchQCTooltipIcon,
  [FAILED]: WarningQCTooltipIcon,
};

export const qcStatusContents = {
  [PENDING]: WrenchQCTooltipContent,
  [PROCESSING]: WrenchQCTooltipContent,
  [FAILED]: WarningQCTooltipContent,
};
