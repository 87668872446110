import { Themes } from '@constants/themes';

export const Copies = {
  [Themes.DEFAULT]: {
    addAll: 'Add all',
    excludeAll: 'Exclude all',
    includeAll: 'Include all',
    removeAll: 'Remove all',
  },
  [Themes.NBCU]: {
    addAll: 'Add All',
    excludeAll: 'Exclude All',
    includeAll: 'Include All',
    removeAll: 'Remove All',
  },
};

export const defaultTableSettings = {
  includable: false,
  separateInclExcl: false,
};

export const addType = {
  Single: 'single',
  All: 'all',
};

export const addAction = {
  Add: 'add',
  Remove: 'remove',
  Update: 'update',
};
