export const BASE_PING_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const PING_CONFIG = {
  redirectURL: `${window.location.origin}/${process.env.REACT_APP_PING_REDIRECT_PATH}`,
  clientID: process.env.REACT_APP_PING_CLIENT_ID,
  baseAuthPath: process.env.REACT_APP_PING_BASE_URL,
  scopeName: process.env.REACT_APP_PING_SCOPE_NAME,
  authorizeGrantType: 'code',
};

export const PING_AUTH_BASE_URL = `${process.env.API_URL}/v1/tvs-ping-nbcu/`;

export const PING_TOKEN_STORAGE_KEY = 'ping-token-storage';

export const nbcuPingAuthorizeUrl = `${PING_CONFIG.baseAuthPath}/authorization.oauth2?client_id=${PING_CONFIG.clientID}&response_type=${PING_CONFIG.authorizeGrantType}&redirect_uri=${PING_CONFIG.redirectURL}&scope=${PING_CONFIG.scopeName}`;
