import moment from 'moment-timezone';
import { convertToStatus, removeNullValues } from '../../../utils';
import { extractIdFromUrl } from '@cypress/utils/helpers';
import { accountCategoriesObjectiveMap } from '@components/accountCategories';
import { fields } from '@v2/components/forms/CampaignForm/constants';
import { fields as advancedOptionsFields } from '@v2/components/forms/AdvancedDeliveryOptionsForm/constants';
import { useCurrentSession } from '@components/hooks';

export const parseApiDataToFormValues = values => {
  const { currentAdvertiser } = useCurrentSession();
  const recommendedCampaignObjective =
    getRecommendedCampaignObjectForAdvertiser(currentAdvertiser);

  const {
    active,
    name,
    startDate,
    endDate,
    budget,
    dailyBudget,
    dailyBudgetCap,
    draft,
    experimentType,
    experimentPlan,
    freqCaps = [],
    objective,
    objectiveGoal,
    objectiveGoalEvent,
    objectiveValueCost,
    objectiveValueMultiplier,
    displayPct,
    budgetType,
  } = values || {};

  return {
    [fields.name.path]: name,
    [fields.advertiserName.path]: currentAdvertiser.name,
    [fields.draft.path]: draft,
    [fields.active.path]: active,
    [fields.status.path]: convertToStatus(values),
    [fields.startDate.path]: startDate ? moment(startDate) : undefined,
    [fields.startTime.path]: startDate ? moment(startDate) : undefined,
    [fields.endDate.path]: endDate ? moment(endDate) : undefined,
    [fields.endTime.path]: endDate ? moment(endDate) : undefined,
    [fields.budgetType.path]: budgetType,
    [fields.lifetimeBudget.path]: budget,
    [fields.dailyBudget.path]: dailyBudget,
    [fields.dailyBudgetCap.path]: dailyBudgetCap,
    [fields.displayPct.path]: Number(displayPct) ? true : undefined,
    [fields.objective.path]: objective || recommendedCampaignObjective,
    [fields.objectiveGoalEvent.path]: objectiveGoalEvent,
    [fields.objectiveGoalType.path]: objectiveGoal,
    [fields.objectiveGoalValueCost.path]: objectiveValueCost,
    [fields.objectiveGoalValueMultiplier.path]: objectiveValueMultiplier,
    [fields.advancedOptions.path]: {
      isIncremental: !!experimentType,
      isExperiment: !!experimentPlan,
      isCapAds: freqCaps.length > 0,
      freqCaps,
      experimentPlan: experimentPlan ?? undefined,
      experimentType: experimentType ?? undefined,
    },
  };
};

const mustIncludeFields = [
  'objective_goal',
];

const filter = (key, value) => {
  if (mustIncludeFields.includes(key)) {
    return true;
  }
  return value;
}

export const parseFormValuesToApiData = (updatedValues, formValues, campaignId) => {

  // if daily budget, lifetime budget or daily budget cap  is in form values, make sure
  // budget type is as well
  if (
    fields.dailyBudget.path in updatedValues ||
    fields.lifetimeBudget.path in updatedValues ||
    fields.dailyBudgetCap.path in updatedValues
  ) {
    updatedValues.budgetType = formValues.budgetType;
  }

  const {
    name,
    advertiser,
    budgetType,
    dailyBudget,
    lifetimeBudget,
    objective,
    objectiveGoalEvent,
    objectiveGoalType,
    objectiveGoalValueCost,
    objectiveGoalValueMultiplier,
    advancedOptions,
    active,
    draft,
  } = updatedValues || {};

  if (
    fields.endDate.path in updatedValues ||
    fields.endTime.path in updatedValues
  ) {
    updatedValues.endTime = updatedValues.endTime || formValues.endTime;
    updatedValues.endDate = updatedValues.endDate || formValues.endDate;
  }

  if (
    fields.startDate.path in updatedValues ||
    fields.startTime.path in updatedValues
  ) {
    updatedValues.startTime = updatedValues.startTime || formValues.startTime;
    updatedValues.startDate = updatedValues.startDate || formValues.startDate;
  }

  const campaignBudgetType = budgetType || formValues.budgetType;

  const data = removeNullValues({
    name,
    budget:
      campaignBudgetType === 'lifetime_budget' ? lifetimeBudget : dailyBudget,
    budget_type: budgetType,
    start_date: combineDateAndTime(
      updatedValues.startDate,
      updatedValues.startTime,
    ),
    end_date:
      combineDateAndTime(updatedValues.endDate, updatedValues.endTime) ??
      undefined,
    advertiser,
    active,
    draft,
    objective,
    objective_goal_event: objectiveGoalEvent,
    objective_goal: objectiveGoalType,
    objective_value_cost: objectiveGoalValueCost,
    objective_value_multiplier: objectiveGoalValueMultiplier,
  }, filter);

  // setting nullable, empty string fields here
  if (fields.displayPct.path in updatedValues) {
    data.display_pct = updatedValues.displayPct ? 0.1 : 0.0;
  }

  if (fields.dailyBudgetCap.path in updatedValues) {
    data.daily_budget_cap =
      campaignBudgetType === 'lifetime_budget'
        ? updatedValues.dailyBudgetCap
        : null;
  }

  // if the campaign exists, include all goals-related fields in the request
  // that has changed in case some of the fields need to be reset to null
  if (campaignId) {
    if (fields.objectiveGoalEvent.path in updatedValues) {
      data.objective_goal_event = updatedValues.objectiveGoalEvent;
    }

    if (fields.objectiveGoalValueCost.path in updatedValues) {
      data.objective_value_cost = updatedValues.objectiveGoalValueCost;
    }

    if (fields.objectiveGoalValueMultiplier.path in updatedValues) {
      data.objective_value_multiplier = updatedValues.objectiveGoalValueMultiplier;
    }
  }

  if (fields.advancedOptions.path in updatedValues) {
    const {
      isIncremental,
      isExperiment,
      experimentPlan,
      experimentType,
      freqCaps,
    } = advancedOptions || {};

    if (advancedOptionsFields.freqCaps.path in advancedOptions) {
      data.freq_caps = removeNullValuesFromFreqCaps(freqCaps);
    }

    if (advancedOptionsFields.experimentPlan.path in advancedOptions) {
      data.experiment_plan = isExperiment ? experimentPlan : null;
    }

    if (advancedOptionsFields.experimentType.path in advancedOptions) {
      data.experiment_type = isIncremental ? experimentType : null;
    }
  }

  return data;
};

export const getRecommendedCampaignObjectForAdvertiser = advertiser => {
  if (advertiser?.category) {
    const categoryId = extractIdFromUrl(advertiser.category);
    if (categoryId) {
      return Object.keys(accountCategoriesObjectiveMap).find(key => {
        return accountCategoriesObjectiveMap[key].includes(
          parseInt(categoryId, 10),
        );
      });
    }
  }
  return null;
};

const combineDateAndTime = (date, time) => {
  if (!date || !time) return null;
  const dateMoment = moment(date);
  const timeMoment = moment(time);

  const combinedMoment = moment({
    year: dateMoment.year(),
    month: dateMoment.month(),
    day: dateMoment.date(),
    hour: timeMoment.hour(),
    minute: timeMoment.minute(),
    second: timeMoment.second(),
  });

  return combinedMoment;
};

const removeNullValuesFromFreqCaps = freqCaps => {
  if (!freqCaps) return;

  return freqCaps.map(freqCap => {
    return removeNullValues(freqCap);
  });
};
