import { useQuery } from '@hooks';
import { editingStates } from '../constants';
import { generatePath, useHistory } from 'react-router-dom';
import { RoutePaths } from '@constants/routes';

export const useGeneralNavigation = () => {
  const query = useQuery();
  const history = useHistory();

  const handleToCampaignDetails = ({ campaign }) => {
    history.push({
      pathname: generatePath(RoutePaths.AD_GROUPS, {
        campaignId: campaign.id,
      }),
    });
  };

  return {
    toCampaignDetails: handleToCampaignDetails,
    state: {
      singleEdit: query.get('editing') === editingStates.single,
    },
  };
};
