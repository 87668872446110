import React, { useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Box, Button, Grid, Typography, Alert } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import VastTagAdding from './VastTagAdding';
import VastTagList from './VastTagList';

const PREFIX = 'VastTag';

const classes = {
  libraryHeader: `${PREFIX}-libraryHeader`,
  title: `${PREFIX}-title`
};

const StyledBox = styled(Box)(({
  theme: { spacing }
}) => ({
  [`& .${classes.libraryHeader}`]: {
    marginBottom: spacing(4),
  },

  [`& .${classes.title}`]: {
    marginBottom: spacing(2),
  }
}));

export const VastTag = ({ onRemove, onEdit, onAdd, name }) => {
  const { watch } = useFormContext();

  const tags = watch(name);

  const [showEdit, setShowEdit] = useState(false);

  const createdTags = useMemo(() => {
    return (tags || []).filter(tag => tag.id !== undefined);
  }, [tags]);

  useEffect(() => {
    if (createdTags.length > 0) return;
    setShowEdit(true);
  }, [createdTags]);

  const handleVastTagsCreate = async (data) => {
    onAdd(data);
    setShowEdit(false);
  };

  return (
    <StyledBox>
      <Grid
        className={classes.libraryHeader}
        container
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={6} className={classes.title}>
          <Typography variant="h2">Creative</Typography>
        </Grid>

        <Alert severity="info">
          tvScientific recommends using one creative per VAST tag. Linking
          multiple creatives will make it hard to understand which creative
          drives what performance.
        </Alert>

        {createdTags.length > 0 && (
          <Grid item xs={12}>
            <VastTagList
              data={createdTags}
              onRemove={onRemove}
              onEdit={onEdit}
            />
            {!showEdit && (
              <Box mt={1} ml={1}>
                <Button
                  color="secondary"
                  size="small"
                  startIcon={<AddCircleOutlineIcon fontSize="small" />}
                  onClick={() => setShowEdit(true)}
                >
                  Add Vast Tag
                </Button>
              </Box>
            )}
          </Grid>
        )}

        {showEdit && (
          <Grid item xs={12}>
            <VastTagAdding
              onCreate={handleVastTagsCreate}
              showCLose={createdTags.length > 0}
              onClose={() => setShowEdit(false)}
            />
          </Grid>
        )}
      </Grid>
    </StyledBox>
  );
};

VastTag.propTypes = {
  name: PropTypes.string.isRequired,
  onEdit: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};
