import React, { Fragment } from 'react';
import {
  SubwayElementTitle,
  SubwayMenu,
  SubwayNode,
  SubwayVerticalDivider,
  sectionProps,
  subwayProps,
  useWizardSubwaySection,
} from '../../Wizard';

import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { labels, menuLabels, nodes } from './constants';
import { useGroupSectionTitles } from './hooks/useGroupSectionTitles';

const GroupSectionSubway = ({
  group,
  sectionKey,
  onDuplicate,
  onDelete,
  disabled,
  isDisplay,
  menuDisabled,
}) => {
  const { active, setActiveSection, setActivePane, getPaneStatus } =
    useWizardSubwaySection({
      key: sectionKey,
      defaultPaneKey: labels.nameDates.value,
    });

  const { subtitle, testId } = useGroupSectionTitles({ group });

  const handleUpdatePane = paneId => () => {
    setActivePane(paneId);
  };

  return (
    <Fragment>
      <SubwayVerticalDivider length="long" />
      <SubwayElementTitle
        expanded={active}
        disabled={disabled}
        title={group.name ?? `Ad Group ${group.id}`}
        buttonProps={{ 'data-testid': testId }}
        subtitle={subtitle}
        menu={
          !isDisplay ? (
            <SubwayMenu
              disabled={menuDisabled}
              options={[
                {
                  label: menuLabels.duplicate,
                  onClick: onDuplicate,
                },
                {
                  label: menuLabels.delete,
                  onClick: onDelete,
                  type: 'warning',
                },
              ]}
            />
          ) : null
        }
        onChange={() => setActiveSection(sectionKey)}
      />
      {active && (
        <Stack alignItems="flex-start" useFlexGap>
          {nodes.map(node => (
            <Fragment key={node.value}>
              <SubwayVerticalDivider length="short" />
              <SubwayNode
                disabled={node.disabled}
                label={node.label}
                onClick={handleUpdatePane(node.value)}
                status={getPaneStatus(node.value)}
              />
            </Fragment>
          ))}
        </Stack>
      )}
    </Fragment>
  );
};

GroupSectionSubway.propTypes = {
  ...subwayProps,
  ...sectionProps,
  onDuplicate: PropTypes.func,
  onDelete: PropTypes.func,
  isDisplay: PropTypes.bool,
};

export default GroupSectionSubway;
