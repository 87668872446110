import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Check } from '@mui/icons-material';

import CampaignIcon from '../../../images/icons/campaign.svg';

const Root = styled("li")(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: spacing(1),
  padding: spacing(0, 4),
  width: '100%',
  minHeight: 'auto',
  fontSize: '0.9rem',
  cursor: 'pointer',

  '&[aria-selected="true"]': {
    backgroundColor: 'transparent',
  },

  '&[data-focus="true"]': {
    backgroundColor: '#d9f1ff',
  },
}));

const StyledCheck = styled(Check)(() => ({
  marginLeft: 'auto',
}));

const AdAccountOption = ({ advertiser, selected, ...rest }) => (
  <Root key={advertiser.id} {...rest}>
    <img src={CampaignIcon} />
    {advertiser.ad_account_name}
    {selected && <StyledCheck fontSize="small" />}
  </Root>
);

AdAccountOption.propTypes = {
  advertiser: PropTypes.object,
  selected: PropTypes.bool,
};

export default AdAccountOption;
