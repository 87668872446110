import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useForm } from 'react-hook-form';
import { object, array, string, number } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Link, Stack, Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import EditElement from './EditElement';
import LoadingElement from './LoadingElement';
import { FormFields } from '../constants';

const List = ({
  data,
  onLearnMoreClick,
  onCancel,
  onSubmit,
}) => {
  const { control, formState, handleSubmit } = useForm({
    values: {
      [FormFields.items.NAME]: data,
    },
    resolver: yupResolver(
      object().shape({
        [FormFields.items.NAME]: array().of(
          object().shape({
            [FormFields.items.fields.NAME]: string().required('Required'),
            [FormFields.items.fields.PROGRESS]: number()
              .test('is-100', 'Progress must be 100', value => value === 100),
            // The asset will have a valid url when it is processed by the backend, don't allow submitting until the url
            // is set by the backend upload response
            [FormFields.items.fields.URL]: string().required('Required')
          })
        ).required('Required'),
      })
    ),
    mode: 'onChange',
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  const { fields, remove } = useFieldArray({
    control,
    name: FormFields.items.NAME,
  });

  const handleRemove = useCallback(index => () => {
    const { cancel } = fields[index];

    cancel();
    remove(index);
  }, [fields]);

  return (
    <Stack
      component="form"
      spacing={5.5}
      sx={{ flex: 1 }}
      onSubmit={handleSubmit(onSubmit)}
    >
      {fields.map((item, index) => {
        const Element = item.progress === 100 ? EditElement : LoadingElement;

        return (
          <Element
            key={item.id}
            name={item.name}
            size={item.size}
            duration={item.duration}
            progress={item.progress}
            formName={`${FormFields.items.NAME}.${index}`}
            control={control}
            onRemove={handleRemove(index)}
          />
        )
      })}

      <Stack
        direction="row"
        spacing={2}
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack
          direction="row"
          spacing={1}
          sx={{ alignItems: 'center' }}
        >
          <InfoOutlinedIcon fontSize="small" color="secondary" /> &nbsp;

          <Typography variant="body2">
            We will check your file formatting to ensure it meets minimum
            quality requirements. Issues will be automatically fixed in most
            cases.
          </Typography>

          <Link
            size="small"
            color="inherit"
            component={Button}
            onClick={onLearnMoreClick}
            underline="hover">
            Learn more
          </Link>
        </Stack>

        <Stack direction="row" spacing={1}>
          <Button
            type="reset"
            color="secondary"
            disableElevation
            disableRipple
            size="small"
            onClick={onCancel}
          >
            Cancel
          </Button>

          <Button
            type="submit"
            variant="contained"
            disabled={!formState.isValid || formState.isSubmitting}
          >
            Continue
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

List.propTypes = {
  data: PropTypes.array.isRequired,
  onLearnMoreClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default List;
