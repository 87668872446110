import React from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Slider,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  Typography,
  IconButton,
} from '@mui/material';
import { Controller, useFormContext, useFieldArray } from 'react-hook-form';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Stack } from '@mui/system';
import { faPlusCircle, faXmark } from '@fortawesome/pro-regular-svg-icons';
import {
  StyledAddButton,
  StyledAddLink,
  StyledAddPlusIcon,
  StyledCircledIndexNumber,
  StyledCircledIndexNumberContainer,
  StyledRightAlignedItem,
  StyledSubmitButton,
  StyledXIcon,
} from '../FrequencyCapFieldsGroup/styles';
import {
  convertTimeRangeToAmPM,
  convertTimeToExtended,
  daysOfWeek,
  toDaysList,
  valueToTime,
} from './utils';
import { getDays, getEmptyValue } from './constants';
import { isEmpty, omit } from 'lodash';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
  '&:hover': {
    backgroundColor: theme.palette.common.white,
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.blue[11],
    color: theme.palette.common.black,
    boxShadow: `0 3px 14px 2px ${theme.palette.blue[12]}`,
    '&:hover': {
      backgroundColor: theme.palette.grey[11],
    },
  },
}));

const Dayparting = ({ item, index, onRemove }) => {
  return (
    <Grid key={item.id} container spacing={1.5}>
      <StyledCircledIndexNumberContainer item>
        <StyledCircledIndexNumber>{index + 1}</StyledCircledIndexNumber>
      </StyledCircledIndexNumberContainer>
      <Grid item>
        <Typography>
          {toDaysList(item.days)}
          {convertTimeRangeToAmPM(convertTimeToExtended(item.time))}
        </Typography>
      </Grid>
      <StyledRightAlignedItem item>
        <IconButton aria-label="delete" onClick={onRemove}>
          <StyledXIcon icon={faXmark} size="xs" />
        </IconButton>
      </StyledRightAlignedItem>
    </Grid>
  );
};

const DaypartingEditing = ({ fieldName, onUpdate, onRemove }) => {
  const { control, watch, getFieldState } = useFormContext();
  const field = watch(fieldName);
  const fieldState = getFieldState(fieldName);
  const { days } = field;
  const sliderDisabled = !days || Object.values(days).every(d => !d.selected);

  return (
    <Grid container spacing={2} alignItems="baseline">
      <Grid
        container
        item
        xs={10}
        gap={2}
        alignItems="center"
        justifyContent="space-around"
      >
        <Grid item xs={6}>
          <Controller
            control={control}
            name={`${fieldName}.days`}
            render={({ field: { onChange, value, ...rest } }) => {
              return (
                <ToggleButtonGroup
                  {...rest}
                  value={Object.entries(value).reduce(
                    (acc, [key, val]) => [
                      ...acc,
                      ...(val.selected ? [key] : []),
                    ],
                    [],
                  )}
                  name={`${fieldName}.days`}
                  onChange={(_, newValue) => {
                    const days = getDays();
                    onChange({
                      ...days,
                      ...newValue.reduce(
                        (acc, v) => ({
                          ...acc,
                          [v]: {
                            day: days[v].day,
                            selected: true,
                          },
                        }),
                        {},
                      ),
                    });
                  }}
                  aria-label="days of the week"
                >
                  {daysOfWeek.map(({ label, value }, index) => (
                    <StyledToggleButton
                      key={index}
                      value={value}
                      aria-label={label.toLowerCase()}
                      data-testid="add-dayparting-toggle-btn"
                    >
                      {label}
                    </StyledToggleButton>
                  ))}
                </ToggleButtonGroup>
              );
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <Controller
            control={control}
            name={`${fieldName}.time`}
            render={({ field }) => (
              <Slider
                {...field}
                getAriaLabel={() => 'Time Range'}
                min={-12}
                max={12}
                valueLabelFormat={v => {
                  return valueToTime(v + 12);
                }}
                valueLabelDisplay="on"
                disabled={sliderDisabled}
                data-testid="add-dayparting-slider"
              />
            )}
          />
        </Grid>
      </Grid>
      <StyledRightAlignedItem display="flex" flexDirection="row" gap={3} item>
        <StyledSubmitButton
          color="secondary"
          disabled={!isEmpty(omit(fieldState.error, 'isEditing'))}
          onClick={() => {
            onUpdate({
              isEditing: false,
            });
          }}
          startIcon={<AddCircleOutlineIcon />}
          variant="contained"
        >
          Add
        </StyledSubmitButton>
        <IconButton aria-label="delete" onClick={onRemove}>
          <StyledXIcon icon={faXmark} size="xs" />
        </IconButton>
      </StyledRightAlignedItem>
    </Grid>
  );
};

DaypartingEditing.propTypes = {
  fieldName: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
};

Dayparting.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  onRemove: PropTypes.func.isRequired,
};

function DaypartingFieldsGroup({ fieldName }) {
  const { control, watch, trigger } = useFormContext();
  const { append, fields, remove, update } = useFieldArray({
    control,
    name: fieldName,
  });

  const dynamicFields = watch(fieldName);

  const controlledFields = fields.map((field, idx) => ({
    ...field,
    ...dynamicFields[idx],
  }));

  return (
    <Stack spacing={2}>
      {controlledFields.map((field, idx) =>
        field.isEditing ? (
          <DaypartingEditing
            key={field.id}
            fieldName={`${fieldName}.${idx}`}
            onUpdate={data => update(idx, { ...field, ...data })}
            onRemove={() => remove(idx)}
          />
        ) : (
          <Dayparting
            key={field.id}
            item={field}
            index={idx}
            onRemove={() => remove(idx)}
          />
        ),
      )}
      {!controlledFields.some(f => f.isEditing) && (
        <Grid container item>
          <StyledAddButton
            onClick={() => {
              {
                append({
                  ...getEmptyValue(),
                  isEditing: true,
                });

                trigger();
              }
            }}
          >
            <StyledAddPlusIcon icon={faPlusCircle} size="lg" />
            <StyledAddLink>Add Dayparting</StyledAddLink>
          </StyledAddButton>
        </Grid>
      )}
    </Stack>
  );
}

DaypartingFieldsGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default DaypartingFieldsGroup;
