import { useEffect, useMemo } from 'react';
import { Geo } from '../../constants';

interface GeoItem {
  place_name?: string;
  name?: string;
  blacklist?: boolean;
}

const isEntireUS = (item: GeoItem): boolean => {
  const name = item.place_name || item.name;
  return name === Geo.entireUS.place_name;
};

const isNotEntireUS = (item: GeoItem): boolean => {
  const name = item.place_name || item.name;
  return name !== Geo.entireUS.place_name;
};

const isBlacklisted = (item: GeoItem): boolean => !isEntireUS(item) && !!item.blacklist;
const isNotBlacklisted = (item: GeoItem): boolean => !isEntireUS(item) && !item.blacklist;

interface UseSelectEntireUSProps {
  geo: GeoItem[];
  setGeo: React.Dispatch<React.SetStateAction<GeoItem[]>>;
}

export const useSelectEntireUS = ({ geo, setGeo }: UseSelectEntireUSProps) => {
  useEffect(() => {
    if (geo.length) {
      const hasEntireUS = geo.some(isEntireUS);
      if (hasEntireUS && geo.some(isNotBlacklisted)) {
        setSelectEntireUS(false);
      }
      if (!hasEntireUS && !geo.some(isNotBlacklisted)) {
        setSelectEntireUS(true);
      }
    } else {
      setSelectEntireUS(true);
    }
  }, [geo]);

  const selectEntireUS = useMemo(() => (geo || []).some(isEntireUS), [geo]);

  const setSelectEntireUS = (entireUS: boolean) => {
    if (entireUS !== selectEntireUS) {
      setGeo((prev) => {
        let nextGeoResults = [...prev.filter(isNotEntireUS)];
        if (entireUS) {
          nextGeoResults = [...nextGeoResults.filter(isBlacklisted)];
          nextGeoResults.unshift(Geo.entireUS);
        }
        return nextGeoResults;
      });
    }
  };

  return {
    selectEntireUS,
    setSelectEntireUS,
  };
};
