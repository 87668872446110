import { useCallback } from 'react';
import { useRBACContext } from '../../providers/RBACContext';

type Permissions = Record<string, boolean>;

/*
* @todo replace with useRBACContext
* @deprecated use useRBACContext instead
*
* @example
* const { permissions } = useRBACContext();
* ...
* {permissions.CAN_VIEW_NBCU_ORGANIZATION && <Component />}
* */
export const usePermissions = () => {
  const { permissions } = useRBACContext() as { permissions: Permissions };

  const hasPermission = useCallback(
    (scopes: string[], defaultValue: boolean = false): boolean =>
      permissions ? scopes.some(scope => permissions[scope]) : defaultValue,
    [permissions],
  );

  return { hasPermission, arePermissionsLoaded: !!permissions };
};
