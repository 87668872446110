import React from 'react';
import PropTypes from 'prop-types';
import { Box, Container } from '@mui/material';

import GeoTargeting from './GeoTargeting';
import UploadGeo from './UploadGeo';

const AdGroupGeo = ({
  geo,
  includeUS,
  overlayShown,
  setGeo,
  setIncludeUS,
  showOverlay,
}) => (
  <Box
    style={{ backgroundColor: overlayShown ? '#262f3c' : '' }}
    mt={2}
    width="100%"
  >
    <Container disableGutters maxWidth={false}>
      <GeoTargeting
        geoResults={geo}
        isEditing
        setGeoResults={setGeo}
        setTargetEntireUS={setIncludeUS}
        showOverlay={showOverlay}
        targetEntireUS={includeUS}
      />
    </Container>
    {overlayShown && (
      <UploadGeo
        showOverlay={showOverlay}
        setGeoResults={setGeo}
        targetEntireUS={includeUS}
        setTargetEntireUS={setIncludeUS}
      />
    )}
  </Box>
);

AdGroupGeo.propTypes = {
  geo: PropTypes.array,
  includeUS: PropTypes.bool,
  overlayShown: PropTypes.bool,
  setGeo: PropTypes.func,
  setIncludeUS: PropTypes.func,
  showOverlay: PropTypes.func,
};

export default AdGroupGeo;
