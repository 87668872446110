import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Icon,
  Grid,
  Card,
  Typography,
  CardContent,
  CardActionArea,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ModalWrapper from './ModalWrapper';

const IconStyled = styled(Icon)(() => ({
  fill: '#A1ACB3',

  '& .MuiIcon-fontSizeLarge': {
    fontSize: '12rem',
  },
}));

const TypographyStyled = styled(Typography)(() => ({
  '& .MuiTypography-body2': {
    fontWeight: 'bold',
  },
}));

const ManageTracking = ({ isOpen, data, onClose }) => {
  const { options } = data;

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <Box flexGrow={1}>
        <Box mb={4}>
          <Typography variant="h3">Add Tracking Event</Typography>
        </Box>

        <Box mb={1}>
          <Typography variant="body1">First, where does the event take place?</Typography>
        </Box>

        <Grid container spacing={3}>
          {options.map((place) => {
            const { name, icon: Icon, onClick } = place;

            return (
              <Grid key={name} item xs={3}>
                <Card>
                  <CardActionArea onClick={onClick}>
                    <CardContent>
                      <Grid container direction="column">
                        <Grid item>
                          <Box textAlign="center">
                            <IconStyled component={Icon} fontSize="large" />
                          </Box>
                        </Grid>
                        <Grid item>
                          <TypographyStyled variant="body2">{name}</TypographyStyled>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </ModalWrapper>
  );
};

ManageTracking.propTypes = {
  isOpen: PropTypes.bool,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ManageTracking;
