import React from 'react';
import PropTypes from 'prop-types';
import { useController } from 'react-hook-form';
import { Stack, Typography } from '@mui/material';

const TypographyField = ({ control, name, ...props }) => {
  const controller = useController({
    name,
    control,
  });

  const {
    field,
    fieldState: { invalid, error },
  } = controller;

  return (
    <Stack direction='row'>
      <Typography {...props} />

      <input type='hidden' {...field} />

      {invalid && (
        <Typography variant="body2" color="error">
          {error.message}
        </Typography>
      )}
    </Stack>
  );
}

TypographyField.propTypes = {
  control: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
};

export default TypographyField;
