import { object, number, string, boolean } from 'yup';

const compareTo30days = (value, unit) => {
  const day = 30;
  const week = 4.28571;
  const min = 43200;
  const hour = 720;

  switch (unit) {
    case 'DAY':
      return value <= day;
    case 'WEEK':
      return value <= week;
    case 'MIN':
      return value <= min;
    case 'HOUR':
      return value <= hour;
  }
};

export const defaultValues = {
  impressions: 1,
  duration: 1,
  fctype: 'DAY',
  isEditing: true,
};

export const validationSchema = () => {
  return object().shape({
    impressions: number()
      .required('Required')
      .moreThan(0, 'Ad count must be greater than zero.'),
    duration: number()
      .required('Required')
      .moreThan(0, 'Duration must be greater than zero.')
      .when('fctype', (unit, schema) => {
        return schema.test({
          message: 'Duration must be less than or equal to 30 days',
          test: (value) => {
            return compareTo30days(value, unit);
          }
        });
      }),
    fctype: string().required('Required'),
    isEditing: boolean().isFalse(),
  });
};
