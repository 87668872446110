import { useWizardNavigation } from './useWizardNavigation';
import { useWizardQuery } from './useWizardQuery';

export const useWizard = ({ sections }) => {
  const query = useWizardQuery();

  const navigation = useWizardNavigation({
    sections,
  });

  return {
    navigation,
    query,
    sections,
  };
};
