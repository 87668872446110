import {
  GeoItem,
  SelectionType,
} from '@v2/components/campaign/GeoTargeter/types';
import { omit, pick } from 'lodash';
import {
  fromGeoItemResultToZip,
  getActualFieldPlain,
  getGeoWithTextByDistinct,
} from '../../../GeoTargeter/utils';

export const useGeographyItem = ({
  value,
  onBlur,
  onUpdate,
}: {
  value: GeoItem;
  onBlur: () => void;
  onUpdate: (value: Partial<GeoItem>) => void;
}) => {
  const handleChange = (newValue: GeoItem) => {
    const actualValue = newValue ?? {
      id: '',
      place_name: '',
    };

    onUpdate(
      fromGeoItemResultToZip({
        ...pick(actualValue, ['id', 'code', 'place_name', 'type']),
        ...omit(getGeoWithTextByDistinct(actualValue), 'index'),
        name: getActualFieldPlain(actualValue),
        blacklist: newValue?.blacklist ?? false,
      }),
    );

    if (newValue) {
      onBlur();
    }
  };

  const handleChangeSelectionType = (selectionType: SelectionType) => {
    onUpdate({
      ...value,
      blacklist: selectionType === 'exclude',
    });
  };

  return {
    changeSelectionType: handleChangeSelectionType,
    change: handleChange,
  };
};
