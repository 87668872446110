import React, {
  Fragment,
  useEffect,
  useState,
  memo,
  useContext,
  useMemo,
} from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment-timezone';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
  Tooltip,
} from '@mui/material';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import SearchIcon from '@mui/icons-material/Search';
import MiddleEllipsis from '../helpers/ellipsis';

import AdvertiserContext from './AdvertiserContext';
import { CreativeQCAlerts } from './CreativeQCAlerts';
import SortableTableHead from './ui/SortableTableHead';
import { useAPI } from './hooks/api';
import { useLoader } from './hooks/loader';
import { useSort } from './hooks/sort';
import { usePermissions } from './hooks';
import checkIcon from '../images/icon-check--blue.png';
import { useDomain } from './hooks/domain';
import { Scopes } from '../constants';
import QCStatusCell from './Creatives/QCStatusComponents';

const PREFIX = 'CreativeLibrary';

const classes = {
  root: `${PREFIX}-root`,
  action: `${PREFIX}-action`,
  adGroup: `${PREFIX}-adGroup`,
  adGroupCell: `${PREFIX}-adGroupCell`,
  adGroupHeader: `${PREFIX}-adGroupHeader`,
  assigned: `${PREFIX}-assigned`,
  assignedRow: `${PREFIX}-assignedRow`,
  check: `${PREFIX}-check`,
  displayButton: `${PREFIX}-displayButton`,
  fileSize: `${PREFIX}-fileSize`,
  libraryHeader: `${PREFIX}-libraryHeader`,
  name: `${PREFIX}-name`,
  play: `${PREFIX}-play`,
  na: `${PREFIX}-na`,
  none: `${PREFIX}-none`,
  spinner: `${PREFIX}-spinner`,
  tableCell: `${PREFIX}-tableCell`,
  table: `${PREFIX}-table`,
  unassigned: `${PREFIX}-unassigned`,
};

const StyledBox = styled(Box)(({ theme: { palette, spacing } }) => ({
  [`& .${classes.root}`]: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

  [`& .${classes.action}`]: {
    width: 115,
  },

  [`& .${classes.adGroup}`]: {
    color: '#727383',
    fontWeight: 600,
    marginTop: 0,
  },

  [`& .${classes.adGroupCell}`]: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 160,
  },

  [`& .${classes.adGroupHeader}`]: {
    minWidth: 130,
  },

  [`& .${classes.assigned}`]: {
    color: palette.secondary.main,
    marginLeft: -8, // aligning based on the left edge of the text
  },

  [`& .${classes.assignedRow}`]: {
    background: '#0000000a',
    opacity: 0.9,
  },

  [`& .${classes.check}`]: {
    width: '100%',
    marginLeft: -10,
  },

  [`& .${classes.displayButton}`]: {
    marginLeft: -3, // aligning based on the visible left edge of the button
  },

  [`& .${classes.fileSize}`]: {
    minWidth: 100,
  },

  [`& .${classes.libraryHeader}`]: {
    marginBottom: spacing(4),
  },

  [`& .${classes.name}`]: {
    fontWeight: 600,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 220,
  },

  [`& .${classes.play}`]: {
    marginTop: 4,
  },

  [`& .${classes.na}`]: {
    fontWeight: 500,
  },

  [`& .${classes.none}`]: {
    color: '#727383',
  },

  [`& .${classes.spinner}`]: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: 375,
    alignItems: 'center',
  },

  [`& .${classes.tableCell}`]: {
    minWidth: 120,
    minHeight: 81,
    height: 81,
  },

  [`& .${classes.table}`]: {
    maxHeight: 375,
    minHeight: 375,
  },

  [`& .${classes.unassigned}`]: {
    color: '#e57373',
    marginLeft: -8, // aligning based on the left edge of the text
  },
}));

const CreativeLibrary = ({
  assigned,
  handleBatchAssign,
  handleUnassign,
  setCurrentPreview,
  setIsPreview,
}) => {
  const adContext = useContext(AdvertiserContext);
  const domain = useDomain();
  const { useGet, useGetAll } = useAPI();
  const { isLoading, setIsLoading } = useLoader();
  const { hasPermission } = usePermissions();
  const { order, orderBy, handleRequestSort, getComparator, stableSort } =
    useSort();

  const [creatives, setCreatives] = useState([]);
  const [cachedCreatives, setCachedCreatives] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [search, setSearch] = useState('');
  const [CSOwner, setCSOwner] = useState(null);

  const [selected, setSelected] = useState([]);

  const showQCFunctionality = hasPermission([Scopes.CAN_VIEW_QC]);

  const columns = useMemo(() => {
    const columns = [
      { id: 'id', label: 'ID', align: 'left', sortable: true, class: '' },
      {
        id: 'name',
        label: 'Creative',
        align: 'left',
        sortable: true,
        class: '',
      },
      {
        id: 'adGroups',
        label: 'Ad groups',
        align: 'left',
        sortable: false,
        class: 'adGroupHeader',
      },
      {
        id: 'resolution',
        label: 'Resolution',
        align: 'left',
        sortable: false,
        class: '',
      },
      {
        id: 'fileSize',
        label: 'File size',
        align: 'left',
        sortable: false,
        class: 'fileSize',
      },
      {
        id: 'media_upload_date',
        align: 'left',
        label: 'Date created',
        sortable: true,
        class: '',
      },
    ];

    if (showQCFunctionality) {
      columns.push({
        id: 'qcStatus',
        label: '',
        align: 'left',
        sortable: false,
        class: '',
      });
    }

    columns.push({
      id: 'action',
      label: 'Action',
      align: 'left',
      sortable: false,
      class: 'action',
    });

    return columns;
  }, [showQCFunctionality]);

  useEffect(() => {
    getCreatives();
    getCSOwner();
  }, []);

  function getCreatives() {
    setIsLoading(true);

    return useGetAll('/creatives?expand=', [], total => {
      setCachedCreatives(prev => [...prev, ...total]);
      setCreatives(prev => [...prev, ...total]);
      setIsLoading(false);
    });
  }

  const getCSOwner = async () => {
    try {
      const response = await useGet(adContext.cs_owner);

      setCSOwner(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleAssignSelected = () => {
    if (selected.length > 0) {
      handleBatchAssign(
        selected.map(s => cachedCreatives.find(c => c.id === s)),
      );

      setSelected([]);
      setIsSelectAll(false);
    }
  };

  const handleToggleAssign = video => {
    const hasCreative = assigned.some(a => a.url === video.url);

    if (hasCreative) {
      handleUnassign(video);
    } else {
      handleBatchAssign([video]);
      setSelected(prev => prev.filter(p => p.url === video.url));
    }
  };

  const handleSearchFilter = value => {
    setSearch(value);

    if (value !== '') {
      setCreatives(() =>
        cachedCreatives.filter(
          p => p.name.toLowerCase().indexOf(value.toLowerCase()) > -1,
        ),
      );
    } else {
      setCreatives(cachedCreatives);
    }
  };

  const handleSelected = id => {
    if (selected.includes(id)) {
      setSelected(prev => prev.filter(p => p !== id));
    } else {
      setSelected(prev => [...prev, id]);
    }
  };

  const handleSelectAll = event => {
    const { checked } = event.target;

    setIsSelectAll(checked);

    if (checked) {
      setSelected(() =>
        creatives
          .filter(c => !assigned.some(a => a.url === c.url))
          .map(c => c.id),
      );
    } else {
      setSelected([]);
    }
  };

  const handlePreview = creative => {
    setCurrentPreview(creative);
    setIsPreview(true);
  };

  const renderMegaBytes = bytes => {
    return `${(parseInt(bytes, 10) / 1000000).toFixed(2)} MB`;
  };

  const renderLibraryHeader = () => (
    <Grid
      className={classes.libraryHeader}
      container
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item xs={6}>
        <Typography variant="h2">Creative</Typography>

        {domain.peacock && (
          <p>
            Select the checkbox next to a previously saved creative to assign
            it to your campaign.
          </p>
        )}
      </Grid>

      <Grid
        container
        item
        alignItems="center"
        justifyContent="space-between"
        spacing={2}
        xs={6}
      >
        <Grid item xs={9}>
          <TextField
            color="secondary"
            fullWidth
            inputProps={{
              'data-testid': 'creative-search',
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon fontSize="small" />
                </InputAdornment>
              ),
            }}
            onChange={event => handleSearchFilter(event.target.value)}
            size="small"
            placeholder="Search creative"
            value={search}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={3}>
          <Button
            disabled={selected.length === 0}
            color="secondary"
            onClick={handleAssignSelected}
            startIcon={<PlayCircleFilledIcon />}
            variant="contained"
          >
            Assign
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );

  const renderRow = creative => {
    const isAssigned = assigned.findIndex(a => a.url === creative.url) > -1;
    const isSelected = selected.includes(creative.id);
    const labelId = `creative-${creative.id}`;

    const { qc = {} } = creative;


    return (
      <Fragment key={creative.id}>
        <TableRow className={clsx(isAssigned ? classes.assignedRow : '')}>
          <TableCell className={classes.height} padding="none">
            {!isAssigned ? (
              <Checkbox
                checked={isSelected}
                disabled={isAssigned}
                inputProps={{ 'aria-labelledby': labelId }}
                onChange={() => handleSelected(creative.id)}
                size="small"
              />
            ) : (
              <Box
                display="flex"
                width={18}
                alignItems="center"
                justifyContent="center"
                my={0}
                mx="auto"
              >
                <img src={checkIcon} className={classes.check} />
              </Box>
            )}
          </TableCell>

          <TableCell>{creative.id}</TableCell>

          <TableCell component="th" id={labelId} scope="row" align="left">
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <IconButton
                  className={classes.displayButton}
                  onClick={() => handlePreview(creative)}
                  size="small"
                >
                  <PlayCircleFilledIcon color="secondary" />
                </IconButton>
              </Grid>

              <Grid className={classes.name} item xs>
                <Tooltip title={creative.name}>
                  <div>
                    <MiddleEllipsis>
                      <span>{creative.name}</span>
                    </MiddleEllipsis>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </TableCell>

          <TableCell
            className={classes.adGroupCell}
            component="th"
            scope="row"
            align="left"
            data-testid={
              creative.adgroup_count > 0 && creative.adgroup_count < 25
                ? 'assignable-creative'
                : 'not-assignable-creative'
            }
          >
            {creative.adgroup_count > 0 ? (
              `${creative.adgroup_count} Ad group${
                creative.adgroup_count > 1 ? 's' : ''
              }`
            ) : (
              <span className={classes.na} data-testid="not-assigned-creative">Not Assigned</span>
            )}
          </TableCell>

          <TableCell align="left">
            {creative.resolution ? creative.resolution : 'n/a'}
          </TableCell>

          <TableCell className={classes.fileSize} align="left">
            {creative.file_size_bytes
              ? renderMegaBytes(creative.file_size_bytes)
              : 'n/a'}
          </TableCell>

          <TableCell align="left">
            {creative.media_upload_date
              ? moment(creative.media_upload_date).format('l')
              : 'n/a'}
          </TableCell>

          {showQCFunctionality && (
            <TableCell align="center">
              <QCStatusCell
                creative={creative}
                qc={qc}
              />
            </TableCell>
          )}

          <TableCell align="left" className={classes.action}>
            <Button
              className={isAssigned ? classes.unassigned : classes.assigned}
              disableRipple
              onClick={() => handleToggleAssign(creative)}
            >
              {isAssigned ? 'UNASSIGN' : 'ASSIGN'}
            </Button>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <StyledBox>
      {/* Creatives Library Header */}
      {renderLibraryHeader()}

      {showQCFunctionality && (
        <CreativeQCAlerts
          email={CSOwner?.email || 'support@tvscientific.com'}
          items={creatives || [] }
        />
      )}

      {/* Creatives Library Table */}
      {isLoading ? (
        <Box className={classes.spinner}>
          <CircularProgress color="secondary" />
        </Box>
      ) : creatives.length === 0 ? (
        <Box className={classes.spinner}>
          <Typography className={classes.none}>No results</Typography>
        </Box>
      ) : (
        <Fade in={!isLoading}>
          <TableContainer className={classes.table}>
            <Table stickyHeader>
              <SortableTableHead
                dense
                headCells={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                onSelectAll={handleSelectAll}
                isSelectAll={isSelectAll}
              />

              <TableBody>
                {stableSort(creatives, getComparator(order, orderBy)).map(
                  (c, index) => renderRow(c, index),
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Fade>
      )}
    </StyledBox>
  );
};

CreativeLibrary.propTypes = {
  assigned: PropTypes.array,
  handleBatchAssign: PropTypes.func,
  handleUnassign: PropTypes.func,
  setCurrentPreview: PropTypes.func,
  setIsPreview: PropTypes.func,
};

export default memo(CreativeLibrary);
