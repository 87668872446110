import { flatMap, intersection, keys } from 'lodash';
import { useSnackbar } from 'notistack';

import { additional_error_keys } from './constants';
import { SWRHook, SWRConfiguration } from 'swr';

const getMessage = (data: Record<string, any>) => Object.values(data);

export const useNotification =
  (useSWRNext: SWRHook) =>
  (
    key: string,
    fetcher: <T>(...args: any[]) => Promise<T>,
    config?: SWRConfiguration,
  ) => {
    const { enqueueSnackbar } = useSnackbar();
    const modifiedFetcher = async (...args: any[]) => {
      try {
        return await fetcher(...args);
      } catch (e) {
        const { response } = e as { response: { data: Record<string, any> } };

        if (!response) {
          return;
        }

        if (
          !intersection(
            [
              'error',
              'detail',
              'errorType',
              'errorDetail',
              'non_field_errors',
              ...additional_error_keys,
            ],
            keys(response.data),
          ).length
        ) {
          throw e;
        }

        const messages = flatMap([...getMessage(response.data)]);

        messages.forEach(message =>
          enqueueSnackbar(message, { variant: 'error' }),
        );

        throw e;
      }
    };

    return useSWRNext(key, modifiedFetcher, config);
  };
