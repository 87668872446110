export const getDays = () => ({
  fr: {
    day: 'Fr',
    selected: false,
  },
  mo: {
    day: 'Mo',
    selected: false,
  },
  sa: {
    day: 'Sa',
    selected: false,
  },
  su: {
    day: 'Su',
    selected: false,
  },
  th: {
    day: 'Th',
    selected: false,
  },
  tu: {
    day: 'Tu',
    selected: false,
  },
  we: {
    day: 'We',
    selected: false,
  },
});
export const getEmptyValue = () => ({
  days: getDays(),
  time: [-12, 12],
});
