import { Button, Grid, styled, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const StyledSubmitButton = styled(Button)(({ theme }) => ({
  height: theme.spacing(7)
}));

export const StyledRightAlignedItem = styled(Grid)(({ theme }) => ({
  marginLeft: 'auto',
  marginRight: theme.spacing(3)
}));

export const StyledXIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.grey[4]
}));

export const StyledCircledIndexNumberContainer = styled(Grid)(({ theme }) => ({
  marginLeft: theme.spacing(-2.875)
}));

export const StyledCircledIndexNumber = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.blue[6],
  borderRadius: theme.spacing(1.5),
  color: theme.palette.text.overlay,
  height: theme.spacing(3),
  lineHeight: theme.spacing(3),
  textAlign: 'center',
  width: theme.spacing(3)
}));

export const StyledAddButton = styled('button')({
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
  marginLeft: -6
});

export const StyledAddPlusIcon = styled(FontAwesomeIcon)(({ theme }) => ({
  color: theme.palette.blue[6],
  marginLeft: theme.spacing(-1.375)
}));

export const StyledAddLink = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[1],
  display: 'inline-block',
  fontSize: '0.875rem',
  fontWeight: 700,
  marginLeft: theme.spacing(1.25)
}));
