import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const StyledGrid = styled(Grid)({
  display: 'flex',
  alignItems: 'center',
  marginTop: 8,
});

const InfoText = styled('p')({
  paddingLeft: 10,
  color: '#5C6B73',
  fontSize: 12,
});

const Info = ({ message }) => (
  <StyledGrid item>
    <InfoOutlinedIcon fontSize="small" htmlColor="#435159" />
    <InfoText>{message}</InfoText>
  </StyledGrid>
);

Info.propTypes = {
  message: PropTypes.string.isRequired,
};

export default Info;
