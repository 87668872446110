import { useState } from 'react';

// Ecommerce Platforms
const ecommerceSites: string[] = [
  'None',
  'Big Commerce',
  'Magento',
  'Salesforce',
  'SAP',
  'Shopify',
  'Squarespace',
  'Woo Commerce',
  'Wordpress',
  'Custom',
];

interface TrackingEvent {
  name: string;
  slug: string;
  value: string;
  window: number;
}

interface UseTrackingReturn {
  ecommerce: string;
  ecommerceSites: string[];
  handleEventSlug: (slug: string, name: string) => void;
  handleEventValue: (value: string, name: string) => void;
  handleSelectEvent: (tracking: TrackingEvent) => void;
  selectedEvents: TrackingEvent[];
  setEcommerce: React.Dispatch<React.SetStateAction<string>>;
  setSelectedEvents: React.Dispatch<React.SetStateAction<TrackingEvent[]>>;
  setTrackingMethod: React.Dispatch<React.SetStateAction<string>>;
  setTotalEvents: React.Dispatch<React.SetStateAction<TrackingEvent[]>>;
  totalEvents: TrackingEvent[];
  trackingEvents: TrackingEvent[];
  trackingMethod: string;
}

// Base tracking events
export const trackingEvents: TrackingEvent[] = [
  { name: 'Lead generated', slug: 'lead_generated', value: '', window: 30 },
  { name: 'Keyword search', slug: 'keyword_search', value: '', window: 30 },
  { name: 'Viewed product', slug: 'viewed_product', value: '', window: 30 },
  { name: 'Add to cart', slug: 'add_to_cart', value: '', window: 30 },
  { name: 'Start checkout', slug: 'start_checkout', value: '', window: 30 },
  { name: 'Complete purchase', slug: 'complete_purchase', value: '', window: 30 },
  { name: 'Complete registration', slug: 'complete_registration', value: '', window: 30 },
  { name: 'Trial Started', slug: 'trial_started', value: '', window: 30 },
];

export const useTracking = (method: string = ''): UseTrackingReturn => {
  const [ecommerce, setEcommerce] = useState<string>('');
  const [selectedEvents, setSelectedEvents] = useState<TrackingEvent[]>([]);
  const [trackingMethod, setTrackingMethod] = useState<string>(method);
  const [totalEvents, setTotalEvents] = useState<TrackingEvent[]>(trackingEvents);

  const handleEventSlug = (slug: string, name: string): void => {
    setSelectedEvents(prev => prev.map(s => {
      if (s.name !== name) {
        return s;
      }
      return {
        ...s,
        slug,
      };
    }));
  };

  const handleEventValue = (value: string, name: string): void => {
    setSelectedEvents(prev => prev.map(s => {
      if (s.name !== name) {
        return s;
      }
      return {
        ...s,
        value,
      };
    }));
  };

  const handleSelectEvent = (tracking: TrackingEvent): void => {
    if (selectedEvents.find(e => e.name === tracking.name)) {
      setSelectedEvents(prev => prev.filter(p => p.name !== tracking.name));
      if (!trackingEvents.find(t => t.name === tracking.name)) {
        setTotalEvents(prev => prev.filter(p => p.name !== tracking.name));
      }
    } else {
      setSelectedEvents(prev => [...prev, tracking]);
    }
  };

  return {
    ecommerce,
    ecommerceSites,
    handleEventSlug,
    handleEventValue,
    handleSelectEvent,
    selectedEvents,
    setEcommerce,
    setSelectedEvents,
    setTrackingMethod,
    setTotalEvents,
    totalEvents,
    trackingEvents,
    trackingMethod,
  };
};
