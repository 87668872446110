import React from 'react';
import { PropTypes } from 'prop-types';
import { Stack, styled } from '@mui/material';

const ImageContentWrapperStyled = styled(Stack)({
  position: 'relative',
});

const ImageStyled = styled('img')({
  position: 'absolute',
  width: '100%',
  height: '100%',
});

export const ImagePreview = ({ src, ...props }) => {
 return (
  <ImageContentWrapperStyled {...props}>
    <ImageStyled src={src} />
  </ImageContentWrapperStyled>
)
}

ImagePreview.propTypes = {
  ...Stack.propTypes,
  src: PropTypes.string,
};
