import { useGetCampaign } from '@apis/campaigns';
import { defaultTitle } from '../constants';

export const useCampaignSectionTitles = ({ campaignId }) => {
  const { campaign } = useGetCampaign(campaignId);
  const title = campaign ? campaign.name : defaultTitle
  const subtitle = campaign ? `Campaign ${campaign.id}` : null

  return {
    title,
    subtitle,
  };
};
