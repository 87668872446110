import { object } from 'yup';
import { fields } from './constants';

export const validationSchema = () => {
  const schema = Object.keys(fields).reduce((acc, key) => {
    const nextField = fields[key];
    acc[nextField.path] = nextField.rule;
    return acc;
  }, {});
  return object().shape(schema)
    .test(
      'no-editing',
      'All frequency caps must be saved before submitting',
      function (value) {
        if (value.isCapAds) {
          return value.freqCaps.every(s => s.isEditing !== true);
        }

        return true;
      }
    );
};

export const defaultValues = () => {
  const defaultVals = Object.entries(fields).reduce(
    (acc, [, value]) => ({
      ...acc,
      [value.path]: value.defaultValue,
    }),
    {},
  );
  return defaultVals;
};
