import * as yup from 'yup';

const MESSAGE =
  'The Creative Title is longer than 36 characters and may appear cut-off';

export const vastTagItemSchema = yup.object().shape({
  name: yup.string().max(36, MESSAGE).required('The field must not be empty'),
  vast_tag_url: yup
    .string()
    .matches(/^https:\/\/[^/]+/, 'The VAST Tag URL is not configured correctly')
    .required('The field must not be empty'),
});

export const vastTagListSchema = yup.object().shape({
  vastTags: yup
    .array()
    .of(vastTagItemSchema)
    .required('At least one VAST Tag is required'),
});
